import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_CONTACT_SOURCE = gql`
  mutation updateContactSource($id: Int!, $data: EditContactSourceInput!) {
    updateContactSource(id: $id, data: $data) {
      data {
        id
        sourceType
        maxContactUpdated
        email
        fetchInterval
      }
      message
      status
    }
  }
`;
