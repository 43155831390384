import React from "react";

//! User files
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import { generateRandomColor } from "../../../../../common/utils";

function SpaceTag({ spaceName, spaceLogo, single = false, spaceId }) {
  return (
    <span className={`space-tag ${single ? "single-tag" : " "}`}>
      {spaceLogo ? (
        <UserAvatar photo={spaceLogo} size="small" className="space-tag-logo" />
      ) : (
        <UserAvatar
          name={spaceName}
          size="small"
          className="space-tag-logo"
          color={generateRandomColor(parseInt(spaceId, 10))}
        />
      )}
      <span className="space-name">{spaceName}</span>
    </span>
  );
}

export default SpaceTag;
