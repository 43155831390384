import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import Cookies from "universal-cookie";
import { useWindowSize } from "react-use";

//! User files
import { isMobileBrowser } from "../../../common/utils";
import Layout from "../layout/Layout";
import LoginSignupForm from "../../../common/components/LoginSignupForm/LoginSignupForm";
import { AppContext } from "../../../AppContext";

//! Graphql files
import { LOGIN } from "../graphql/Mutations";
import { ROUTES } from "../../../common/constants";

const EmailLogin = () => {
  const history = useHistory();
  const cookies = new Cookies();
  const { width } = useWindowSize();
  const { initializeAuth } = useContext(AppContext);

  const [login, { loading }] = useMutation(LOGIN);

  const setCookieForLoggedInUser = () => {
    cookies.set("cms_logged_in", true, { domain: ".contactbook.app" });
  };

  const successCallback = (accessToken, user) => {
    initializeAuth(accessToken, user);
  };

  const onFinish = (e) => {
    // eslint-disable-next-line no-shadow
    const { password, email } = e;
    login({
      variables: { password, email: email?.toLowerCase() },
    })?.then((res) => {
      const { accessToken, userInfo } = res?.data?.login?.data;
      localStorage.setItem("currentSpace", userInfo?.spaceId);
      setCookieForLoggedInUser();
      if (successCallback) {
        successCallback(accessToken, userInfo);
      }
      history.push(
        isMobileBrowser(
          `${ROUTES?.CONTACTS}/${userInfo?.spaceId}/group/all`,
          width,
          userInfo?.spaceId
        )
      );
    });
  };

  return (
    <Layout>
      <LoginSignupForm login loading={loading} handleFinish={onFinish} />
    </Layout>
  );
};

export default EmailLogin;
