import React, { useHistory, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import filter from "lodash/filter";

//! Ant Imports
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Text from "antd/lib/typography/Text";
import Divider from "antd/lib/divider";

//! User Files
import {
  AttachmentIcon,
  DocumentIcon,
  MergedIcon,
} from "../../../../../assets/svg";
import {
  filterMergeValue,
  generateRandomColor,
  getNameTag,
  removeAutoTags,
} from "../../../../../common/utils";
import TagLabel from "../../contacts/components/TagLabel";
import ContactGroups from "../../contacts/components/ContactGroups";
import ContactAvatar from "../../../../../common/components/ContactAvatar";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { AppContext } from "../../../../../AppContext";
import {
  SET_DUPLICATE_CARD_ID,
  SET_DUPLICATE_CONTACT_CARD,
} from "../../../../../common/actionTypes";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";

const ContactMergeDataMeta = ({
  contactDetail,
  mergeContact,
  mergePreviewContact,
  isContactMerged,
  mergeLoader,
  mergeContactCount,
}) => {
  const [tagValue, setTagValue] = useState([]);
  const [mergeModal, setMergeModal] = useState(false);
  const { duplicateId } = useParams();
  const {
    state: { duplicateContactData },
    dispatch,
  } = useContext(AppContext);
  const { push } = useHistory();

  const {
    firstName,
    middleName,
    lastName,
    photo,
    jobTitle,
    tags,
    groups,
    spaceId,
    attachments,
    notes,
    numbers,
    emails,
    company,
  } = contactDetail;
  const contactName =
    // eslint-disable-next-line prefer-template
    (firstName?.value ?? "") +
    " " +
    (middleName?.value ?? "") +
    " " +
    (lastName?.value ?? "");

  useEffect(() => {
    const tagsVal = [];
    if (tags?.length) {
      tags?.map((tag) => tagsVal.push(tag?.value));
    }
    setTagValue(tagsVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  const closeMergeModal = () => {
    setMergeModal(false);
  };

  const handleMergeContact = () => {
    dispatch({
      type: SET_DUPLICATE_CARD_ID,
      data: parseInt(duplicateId, 10),
    });

    const data = {
      primaryContactId: contactDetail?.id,
      firstName: contactDetail?.firstName?.value,
      middleName: contactDetail?.middleName?.value,
      lastName: contactDetail?.lastName?.value,
      jobTitle: contactDetail?.jobTitle?.value,
      company: contactDetail?.company?.value,
      photo: contactDetail?.photo?.value,
      birthday: contactDetail?.birthday?.value,
      anniversary: contactDetail?.anniversary?.value,
      websites: filterMergeValue(contactDetail?.websites),
      emails: filterMergeValue(contactDetail?.emails),
      numbers: filterMergeValue(contactDetail?.numbers),
      tags: filterMergeValue(contactDetail?.tags, "tags"),
      groupIds: filterMergeValue(contactDetail?.groups, "groups"),
      socialProfiles: filterMergeValue(contactDetail?.socialProfiles),
    };
    closeMergeModal();
    if (data) {
      setTimeout(() => {
        dispatch({
          type: SET_DUPLICATE_CARD_ID,
          data: null,
        });
        dispatch({
          type: SET_DUPLICATE_CONTACT_CARD,
          data: filter(
            duplicateContactData,
            // eslint-disable-next-line no-shadow
            (data) => data.id !== parseInt(duplicateId, 10)
          ),
        });
      }, 300);
      setTimeout(() => {
        mergePreviewContact({
          variables: {
            id: parseInt(duplicateId, 10),
            data,
          },
        });
      }, 500);
    }
  };

  const handleRouteChange = (path) => {
    push(path);
  };

  const openConfirmMergeModal = () => {
    setMergeModal(true);
  };

  const popupContent = (
    <div className="merge-modal">
      <span className="popup-text-title font-inter">
        Are you sure you want to merge {contactName}?
      </span>
      <span className="font-inter merge-popup-content">
        This action will merge all {mergeContactCount} contact details to a
        single contact. You won't be able to undo this action.
      </span>
    </div>
  );

  return (
    <>
      <Row
        className={`${
          mergeContact ? "cms-merge-contact-upper-box-border" : ""
        } contact-user-info-wrapper`}
      >
        <Col span={24} className="contact-user">
          <Row className="contact-user-row">
            <Col span={12} className="contact-user-avatar-col">
              <ContactAvatar
                photo={photo?.value}
                size={56}
                color={generateRandomColor(parseInt(contactDetail?.id, 10))}
                name={firstName?.value}
              />
            </Col>
            <div className="cms-merge-btn">
              {isContactMerged ? (
                <>
                  <div className="cms-merge-btn-merge-icon">
                    <span>
                      <MergedIcon />
                    </span>
                    <span className="font-inter cms-merge-btn-merge-icon-text">
                      Merged
                    </span>
                  </div>
                  <IconButton
                    iconClass="cms-merge-contact-view-btn cms-merge-save-view-btn"
                    text={
                      <span className="font-inter cms-merge-save-view-btn-text">
                        View Contact
                      </span>
                    }
                    handleClick={() =>
                      handleRouteChange(
                        `/contacts/${spaceId}/group/all/contact/${contactDetail?.id}`
                      )
                    }
                  />
                </>
              ) : (
                <IconButton
                  iconClass="cms-merge-contact-save-btn cms-merge-save-view-btn"
                  text={
                    <span className="font-inter cms-merge-save-view-btn-text">
                      Save
                    </span>
                  }
                  handleClick={openConfirmMergeModal}
                  loading={mergeLoader}
                />
              )}
            </div>
          </Row>
        </Col>
        <Col span={24} className="contact-user-meta">
          <Row className="contact-user-meta-row">
            <Col span={12} className="contact-user-profile-col">
              <Row className="cms-username-top">
                <Col span={24}>
                  <Text className="username cms-font-weight-medium font-inter">
                    {getNameTag(contactName, company?.value)}
                    <span className="cms-active-merge-contact" />
                  </Text>
                </Col>
                <Col span={24}>
                  <Row className="description-row">
                    <Col span={24} className="designation-col">
                      <Text type="secondary" className="designation">
                        {jobTitle?.value ?? ""}
                      </Text>
                    </Col>
                    <Col span={24} className="designation-col mb-12">
                      <Text type="secondary" className="designation">
                        {numbers?.length > 0 && (
                          <span
                            className="cursor-pointer cms-contact-val-hover"
                            onClick={() => {
                              window.location = `tel:${numbers?.[0]?.value}`;
                            }}
                          >
                            {numbers?.[0]?.value}
                          </span>
                        )}
                        {numbers?.length > 0 && emails?.length > 0 && (
                          <Divider
                            type="vertical"
                            className="cms-value-divider"
                          />
                        )}
                        {emails?.length > 0 && (
                          <span
                            className="cursor-pointer cms-contact-val-hover"
                            onClick={() => {
                              window.location = `mailto:${emails?.[0]?.value}`;
                            }}
                          >
                            {emails?.[0]?.value}
                          </span>
                        )}
                      </Text>
                    </Col>
                    {tagValue?.length !== 0 && (
                      <Col span={24} className="tags-col with-border">
                        <TagLabel
                          tagArray={tagValue ? removeAutoTags(tagValue) : []}
                        />
                      </Col>
                    )}
                    {groups?.length !== 0 && (
                      <Col
                        span={24}
                        className="tags-col with-border group-tag-pt"
                      >
                        <ContactGroups tagArray={groups} spaceId={spaceId} />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="contact-user-meta-info-col">
              <IconButton
                iconClass="cms-doc-note-count-tag"
                text={attachments?.length}
                icon={<AttachmentIcon />}
              />
              <IconButton
                iconClass="cms-doc-note-count-tag"
                text={notes?.length}
                icon={<DocumentIcon />}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <RemovePopup
        visible={mergeModal}
        removeNote={closeMergeModal}
        content={popupContent}
        okText="Merge"
        closeText="Cancel"
        modalClass="cms-merge-contact-modal"
        handleRemove={handleMergeContact}
      />
    </>
  );
};

export default ContactMergeDataMeta;
