import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const ACTIVATE_REMINDER = gql`
  mutation activateReminderAddon($spaceId: Int!) {
    activateReminderAddon(spaceId: $spaceId) {
      message
      status
    }
  }
`;
