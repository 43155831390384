import React from "react";

//! User files
import { UserAccountIcon } from "../../../../../assets/svg";
import UserProfile from "./UserProfile";

const MyProfile = () => {
  return (
    <div className="cms-my-profile">
      <div className="cms-my-profile-header">
        <span className="cms-my-profile-icon mr-8">
          <UserAccountIcon />
        </span>
        <span className="font-inter username cms-profile-text">My Profile</span>
      </div>
      <div className="cms-user-profile cms-contact-scrollbar">
        <UserProfile />
      </div>
    </div>
  );
};

export default MyProfile;
