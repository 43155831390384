import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

//! Ant imports
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { Row, Col } from "antd";

//! Graphql files
import { CREATE_SPACE } from "../../../graphql/mutations/createNewSpace";
import { GET_SPACES_V2 } from "../../../graphql/queries/getSpacesV2";

//! User files
import ImageUpload from "../../../../../common/components/ImageUpload/ImageUpload";
import { AMPLITUDE_EVENT_LOG } from "../../../../../common/constants";
import { createAmplitudeEvent } from "../../../../../common/utils";
import IconButton from "../../../../../common/components/IconButton/IconButton";

function CreateSpaceModal({ setCreateSpaceModal, sharedUser }) {
  const history = useHistory();
  const [form] = useForm();
  const [imageLoading, setImageLoading] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [spaceName, setSpaceName] = useState("");

  const [createSpace, { loading }] = useMutation(CREATE_SPACE, {
    refetchQueries: [
      {
        query: GET_SPACES_V2,
      },
    ],
  });

  const onFinish = (e) => {
    const { name } = e;
    if (sharedUser) {
      setCreateSpaceModal(false);
      history.push(`/space/${sharedUser?.id}/plans`);
    } else {
      createSpace({
        variables: {
          name,
          photo: profileImage,
        },
      })
        .then((res) => {
          setCreateSpaceModal(false);
          const eventProperties = {
            "Space Id": res?.data?.createSpace?.data?.id,
            "Space Name": res?.data?.createSpace?.data?.name,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.SPACE_CREATED,
            eventProperties
          );
          form.resetFields();
          setProfileImage();
          localStorage.setItem(
            "currentSpace",
            res?.data?.createSpace?.data?.id
          );
          history.push(
            `/contacts/${res?.data?.createSpace?.data?.id}/groups/all`
          );
        })
        .catch(() => {});
    }
  };

  return (
    <div className="space">
      <div className="create-space-modal">
        <div className="create-space-modal-form">
          <Row>
            <Col span={24}>
              <ImageUpload
                imageLoading={imageLoading}
                setImageLoading={setImageLoading}
                profileImage={profileImage}
                setProfileImage={setProfileImage}
                spaceClass="cms-space-img-upload-icon"
                name={sharedUser?.name}
                sharedUser={sharedUser}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="create-group-form">
                <Form
                  layout="vertical"
                  onFinish={onFinish}
                  form={form}
                  initialValues={{ name: sharedUser?.name }}
                >
                  <Row className="cms-form-space-field">
                    <Form.Item colon={false} name="name">
                      <Input
                        onChange={(e) => setSpaceName(e?.target?.value?.trim())}
                        placeholder="Enter space name"
                        disabled={sharedUser?.name}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="cms-space-create-info mb-24">
                    <div className="cms-space-create-info-text">
                      <span className="font-inter cms-space-create-info-text-title">
                        {sharedUser
                          ? "You are about to activate your default space."
                          : "You are about to create a new contact space."}
                      </span>
                      <span className="font-inter cms-space-create-info-text-desc">
                        {sharedUser ? (
                          <>
                            Your default workspace requires you to subscribe to
                            any of our plans. You can activate your default
                            space to manage and share your own contacts and use
                            all other features of ContactBook.
                          </>
                        ) : (
                          <>
                            A new workspace requires you to subscribe to any of
                            our plans. You can create a new workspace for
                            another business or a team or a department where
                            collaboration of new set of people is required.
                          </>
                        )}
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <div className="create-space-modal-btn">
                      {sharedUser ? (
                        <IconButton
                          iconClass="space-next-button"
                          loading={loading}
                          text={
                            <span className="font-inter">Activate Space</span>
                          }
                          type="primary"
                          htmlType="submit"
                        />
                      ) : (
                        <IconButton
                          iconClass="space-next-button"
                          loading={loading}
                          text={
                            <span className="font-inter">Create Space</span>
                          }
                          disable={imageLoading || spaceName?.length === 0}
                          type="primary"
                          htmlType="submit"
                        />
                      )}
                    </div>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default CreateSpaceModal;
