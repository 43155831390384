import React, { useState } from "react";

//! User files
import ReminderCard from "../../../../../common/components/ReminderCard/ReminderCard";
import Loading from "../../../../../common/components/Loading";

const RemindersListItem = ({
  reminders,
  handleInfiniteScroll,
  reminderLoader,
}) => {
  const [reminderId, setReminderId] = useState();
  return (
    <>
      <div
        className="cms-reminder-list-item cms-contact-scrollbar"
        onScroll={(e) => handleInfiniteScroll(e)}
      >
        {reminders?.map((contact) => (
          <ReminderCard
            contact={contact}
            key={contact?.id}
            setReminderId={setReminderId}
            reminderId={reminderId}
          />
        ))}
        {reminderLoader && (
          <div className="cms-infinite-scroll-loader">
            <Loading />
          </div>
        )}
      </div>
    </>
  );
};

export default RemindersListItem;
