import React, { useContext, useEffect } from "react";

//! Antd import
import Modal from "antd/lib/modal";
import Title from "antd/lib/typography/Title";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Checkbox from "antd/lib/checkbox";
import Button from "antd/lib/button";

//! User files
import { HasRight } from "../../HasRight";
import { EDIT_GROUP_NAME, GOOGLE } from "../../constants";
import { AppContext } from "../../../AppContext";
import { formValidatorRules } from "../../utils";

const { groupName } = formValidatorRules;
const GroupCreateUpdateModal = ({
  visible,
  onCancel,
  footer,
  centered,
  initialValue,
  onFinish,
  setIsFormUpdated,
  loading,
  isFormUpdated,
  title,
  buttonText,
  isTag,
}) => {
  const [form] = Form.useForm();
  const {
    state: { userSubscriptionInfo },
  } = useContext(AppContext);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleValueChange = (changedValues, allValues) => {
    if (allValues?.name?.trim()?.length > 0 && allValues?.visibility !== null) {
      setIsFormUpdated(true);
    } else {
      setIsFormUpdated(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={footer}
      centered={centered}
    >
      <div className="create-group-update-modal">
        <Title
          level={3}
          className="font-inter group-list-heading cms-text-strong"
        >
          {title}
        </Title>
        <div className="create-group-area">
          <div className="create-group-form">
            <Form
              form={form}
              layout="vertical"
              initialValues={initialValue}
              onFinish={(e) => onFinish(e, form)}
              onValuesChange={handleValueChange}
            >
              <Form.Item
                colon={false}
                name="name"
                label={isTag ? "Tag Name" : "Group Name"}
                className="cms-input-field"
                rules={[groupName]}
              >
                <Input />
              </Form.Item>
              {userSubscriptionInfo?.type !== GOOGLE && !isTag && (
                <HasRight action={EDIT_GROUP_NAME} type="space">
                  <Form.Item name="visibility" valuePropName="checked">
                    <div className="group-setting-title-checkbox">
                      <Checkbox defaultChecked={initialValue?.visibility}>
                        Make this group as private
                      </Checkbox>
                    </div>
                  </Form.Item>
                </HasRight>
              )}
              <div className="create-group-update-modal-btn">
                <Button
                  className={`${
                    !isFormUpdated && "update-disable"
                  } group-add-contact`}
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                  disabled={!isFormUpdated}
                >
                  {buttonText}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GroupCreateUpdateModal;
