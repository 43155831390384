import { gql } from "@apollo/client/core";

const SUBSCRIBE_REMINDER = gql`
  mutation subscribeReminder($data: SubscribeReminderInput!) {
    subscribeReminder(data: $data) {
      message
      status
    }
  }
`;

export default SUBSCRIBE_REMINDER;
