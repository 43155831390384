//! Have to used here as multiple rules for single LOC
/* eslint-disable no-unused-expressions */
import React, { useContext, useState } from "react";
import clsx from "clsx";
import cloneDeep from "lodash/cloneDeep";
import indexOf from "lodash/indexOf";
import { useWindowSize } from "react-use";

//! Ant Imports
import List from "antd/lib/list";
import Avatar from "antd/lib/avatar";
import Checkbox from "antd/lib/checkbox";

//! User Files
import {
  Phone,
  Mail,
  AddContact,
  CloseCircle,
  ContactAddIcon,
} from "../../../assets/svg";
import { generateRandomColor, hasGroupRight, hasSpaceRight } from "../../utils";
import IconButton from "../IconButton/IconButton";
import ContactCardButton from "../ContactCardButton/ContactCardButton";
import { AppContext } from "../../../AppContext";
import { DELETE_CONTACT, WINDOW_SIZE } from "../../constants";

const ContactCard = ({
  actions,
  isSelected,
  photo,
  title,
  description,
  firstName,
  jobTitle,
  groups,
  contactLink,
  handleContactClick,
  size,
  toAdd,
  addContactToGroup,
  contactId,
  contactAdded,
  removeContactFromGroup,
  numbers,
  emails,
  duplicateClass,
  deleteDuplicateContact,
  removeContactFromDuplicateCard,
  openContactModal,
  duplicateId,
  shouldClick,
  selectAll,
  deleteContactIds,
  setDeleteContactIds,
  checkSpacePermission,
  contactsFromExistingPage,
  unAssigned,
  company,
}) => {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [dataType, setDataType] = useState();
  const [imgLoadError, setImgLoadError] = useState(false);
  const {
    state: { userSpacePermission, userGroupPermission },
  } = useContext(AppContext);
  const { width } = useWindowSize();
  const handleConfirmation = () => {
    setOpen(!open);
  };

  // eslint-disable-next-line no-nested-ternary
  const action = toAdd ? (
    <IconButton
      iconClass="cms-icon-btn-remove"
      icon={
        // eslint-disable-next-line no-nested-ternary
        contactAdded && !unAssigned ? (
          <CloseCircle onClick={() => removeContactFromGroup(contactId)} />
        ) : contactsFromExistingPage ? (
          <ContactAddIcon />
        ) : (
          <AddContact onClick={() => addContactToGroup(contactId)} />
        )
      }
    />
  ) : (
    deleteDuplicateContact && (
      <IconButton
        iconClass="cms-remove-icon-btn"
        icon={
          <CloseCircle
            onClick={(e) =>
              removeContactFromDuplicateCard(
                e,
                contactId,
                duplicateId,
                title?.props?.children
              )
            }
          />
        }
      />
    )
  );

  const handleCheckContact = (e, cntId) => {
    e.stopPropagation();
    if (deleteContactIds?.includes(cntId)) {
      const tempId = cloneDeep(deleteContactIds);
      const index = indexOf(deleteContactIds, cntId);
      if (index !== -1) {
        tempId.splice(index, 1);
      }
      setDeleteContactIds(tempId);
    } else {
      setDeleteContactIds([...deleteContactIds, cntId]);
    }
  };

  return (
    <>
      <List.Item
        className={clsx(
          isSelected && "selected-contact",
          !jobTitle?.length && !groups?.length && "cms-no-group",
          duplicateClass && "cms-duplicate-card-pd",
          shouldClick ? "cursor-pointer" : "",
          (checkSpacePermission
            ? hasSpaceRight(userSpacePermission, DELETE_CONTACT)
            : hasGroupRight(userGroupPermission, DELETE_CONTACT)) &&
            !duplicateClass &&
            !toAdd &&
            "contact-card-hover"
        )}
        onClick={(e) => {
          // eslint-disable-next-line no-nested-ternary
          deleteContactIds?.length > 0
            ? handleCheckContact(e, parseInt(contactId, 10))
            : !shouldClick
            ? e.preventDefault()
            : !toAdd &&
              !deleteDuplicateContact &&
              handleContactClick(e, contactLink);
          // eslint-disable-next-line no-unused-expressions
          deleteDuplicateContact && openContactModal(e, contactId);
        }}
        actions={
          actions
            ? [
                <div className="cms-contact-actions" key={contactId}>
                  {!duplicateClass && (
                    <div className="cms-contact-actions-item">
                      {numbers?.length > 0 && (
                        <IconButton
                          iconClass="cms-icon-btn"
                          icon={
                            <Phone className="cms-common-svg setting-svg" />
                          }
                          shape="circle"
                          handleClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setType("CALL");
                            setDataType(numbers);
                            handleConfirmation();
                          }}
                        />
                      )}
                      {emails?.length > 0 && (
                        <IconButton
                          iconClass="cms-icon-btn"
                          icon={<Mail className="cms-common-svg setting-svg" />}
                          shape="circle"
                          handleClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setType("EMAIL");
                            setDataType(emails);
                            handleConfirmation();
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>,
                action,
              ]
            : []
        }
      >
        <List.Item.Meta
          className={!jobTitle?.length && !groups?.length && "cms-no-group"}
          avatar={
            <>
              <Avatar
                size={size}
                style={
                  (!photo || imgLoadError) && {
                    backgroundColor: generateRandomColor(
                      parseInt(contactId, 10)
                    ),
                  }
                }
                src={imgLoadError ? "" : photo}
                onError={() => setImgLoadError(true)}
                className={
                  deleteContactIds?.includes(parseInt(contactId, 10)) ||
                  selectAll
                    ? "hide"
                    : ""
                }
              >
                {firstName
                  ? firstName?.[0]?.toUpperCase()
                  : company?.[0]?.toUpperCase()}
              </Avatar>
              {width >= WINDOW_SIZE && (
                <span
                  className={`${
                    deleteContactIds?.includes(parseInt(contactId, 10)) ||
                    selectAll
                      ? "d-flex op-1"
                      : ""
                  } cms-contact-checkbox`}
                >
                  <Checkbox
                    onClick={(e) =>
                      handleCheckContact(e, parseInt(contactId, 10))
                    }
                    className="cms-contact-checkbox-icon"
                    checked={
                      deleteContactIds?.includes(parseInt(contactId, 10)) ||
                      selectAll
                    }
                  />
                </span>
              )}
            </>
          }
          title={title}
          description={description}
        />
      </List.Item>
      <ContactCardButton
        title={title}
        data={dataType}
        visible={open}
        type={type}
        removeNote={handleConfirmation}
      />
    </>
  );
};

export default ContactCard;
