import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const RESYNC = gql`
  mutation reSync($sourceId: Int!) {
    reSync(sourceId: $sourceId) {
      status
      message
    }
  }
`;
