import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const REINVITE_GROUP_MEMBER = gql`
  mutation reInviteGroupMember($userId: Int!, $groupId: Int!) {
    reInviteGroupMember(userId: $userId, groupId: $groupId) {
      message
      status
    }
  }
`;
