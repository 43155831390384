import React from "react";

//! User files
import { LogoWhite } from "../../../assets/svg";

const SiteLogo = ({ handleLogoClick }) => {
  return (
    <div className="app-layout-sider-header">
      <div className="app-icon">
        <LogoWhite
          className="cms-svg"
          onClick={handleLogoClick}
          title="ContactBook"
        />
      </div>
    </div>
  );
};

export default SiteLogo;
