import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

//! Antd import
import Drawer from "antd/lib/drawer";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

//! User files
import { CONTACT_LIMIT } from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";
import { ContactReminderImage, NoSyncLogIcon } from "../../../../../assets/svg";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import SyncLogList from "./SyncLogList";
import {
  SET_START_SYNC,
  SET_SYNC_LOG_DRAWER,
} from "../../../../../common/actionTypes";

//! Graphql files
import START_SPACE_SYNC from "../../../graphql/mutations/startSpaceSync";
import { GET_SYNC_LOGS } from "../../../graphql/queries/getSyncLogs";
import NoGroupPlaceholder from "../../contacts/components/NoGroupPlaceholder";

const SyncLogs = () => {
  const {
    state: { syncLogDrawer, currentUser },
    dispatch,
  } = useContext(AppContext);
  const [spaceSyncLogs, setSpaceSyncLogs] = useState([]);
  const [offset, setOffset] = useState(0);
  const spaceId = parseInt(
    localStorage.getItem("currentSpace") || currentUser?.spaceId,
    10
  );

  const onClose = () => {
    dispatch({ type: SET_SYNC_LOG_DRAWER, data: false });
  };

  const { data: syncLogData, loading: syncLoader } = useQuery(GET_SYNC_LOGS, {
    fetchPolicy: "network-only",
    variables: {
      id: spaceId,
      offset: offset,
      limit: CONTACT_LIMIT,
    },
    onError() {},
  });

  const [startSpaceSync, { loading }] = useMutation(START_SPACE_SYNC, {
    onCompleted() {
      onClose();
      dispatch({
        type: SET_START_SYNC,
        data: true,
      });
    },
    onError() {
      dispatch({
        type: SET_START_SYNC,
        data: false,
      });
    },
  });

  const handleSpaceSync = () => {
    if (spaceId) {
      startSpaceSync({
        variables: {
          id: spaceId,
        },
      });
    }
  };

  const title = (
    <div className="cms-sync-log-header">
      <div className="cms-sync-log-header-left">
        <span className="cms-sync-log-icon">
          <ContactReminderImage />
        </span>
        <span className="cms-sync-log-title font-inter">Sync Logs </span>
      </div>
      <div className="cms-sync-log-header-right">
        <IconButton
          iconClass="cms-sync-log-btn"
          text={
            <span className="font-inter cms-sync-log-btn-text">Sync Now</span>
          }
          handleClick={handleSpaceSync}
          loading={loading}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (syncLogData?.getSyncLogs?.syncLogs) {
      setSpaceSyncLogs([
        ...spaceSyncLogs,
        ...syncLogData?.getSyncLogs?.syncLogs,
      ]);
    } else {
      setSpaceSyncLogs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncLogData]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
        Math.ceil(element.clientHeight) &&
      spaceSyncLogs?.length < syncLogData?.getSyncLogs?.count
    ) {
      setOffset(spaceSyncLogs?.length);
    }
  };

  return (
    <Drawer
      className="cms-sync-log-drawer"
      title={title}
      placement="left"
      closable={false}
      onClose={onClose}
      visible={syncLogDrawer}
      key="left"
      width={420}
      forceRender={spaceSyncLogs}
    >
      {spaceSyncLogs?.length > 0 ? (
        <Row
          className="overflow-auto full-height cms-contact-scrollbar"
          onScroll={(e) => {
            handleInfiniteScroll(e);
          }}
        >
          <Col span={24}>
            <SyncLogList syncLogs={spaceSyncLogs} loading={syncLoader} />
          </Col>
        </Row>
      ) : (
        <div className="cms-no-notification">
          <NoGroupPlaceholder
            icon={<NoSyncLogIcon />}
            title={
              <span className="cms-no-notification-text">No sync logs yet</span>
            }
            height={80}
            width={80}
            fontSize={20}
          />
        </div>
      )}
    </Drawer>
  );
};

export default SyncLogs;
