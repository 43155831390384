import { gql } from "@apollo/client";

export const CONNECT_WITH_GOOGLE = gql`
  query {
    connectWithGoogle {
      authorizationUri
    }
  }
`;
export const CONNECT_WITH_MICROSOFT = gql`
  query {
    connectWithMicrosoft {
      authorizationUri
    }
  }
`;

export const CHECK_ADMIN_USER_AND_DOMAIN_WIDE_ACCESS = gql`
  query {
    checkAdminUserAndDomainWideAccess {
      isAdmin
      domainWideAccess
    }
  }
`;

export const LIST_DOMAIN_USERS_AND_GROUPS = gql`
  query {
    listDomainUsersAndGroups {
      users {
        name
        email
        isAdmin
        type
      }
    }
  }
`;
