import React from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Tag } from "antd";
import { Assistant, BlankPage, Organizations } from "../../../assets/svg";
import { NOTIFY_UPCOMING_FEATURE } from "../../../modules/main/graphql/mutations/notifyUpcomingfeature";
import ComingSoonBackground from "../../../assets/images/coming-soon-background.png";

function ComingSoon() {
  const location = useLocation();
  const [notifyUpcomingFeature] = useMutation(NOTIFY_UPCOMING_FEATURE);

  const handleNotify = () => {
    notifyUpcomingFeature().then().catch();
  };
  return (
    <div className="coming-soon-wrapper">
      <div className="coming-soon-content">
        <BlankPage />
        <Tag
          icon={
            location.pathname.includes("clean-up") ? (
              <Assistant />
            ) : (
              <Organizations />
            )
          }
        >
          {location.pathname.includes("clean-up") ? "Clean up" : "Organization"}
        </Tag>
        <div className="coming-soon-content-title font-inter">Coming soon</div>

        {location.pathname.includes("clean-up") ? (
          <div className="coming-soon-content-description">
            Contact Clean up will help in keeping your contacts up-to-date by
            identifying duplicates. It will suggest to you the contacts with
            missing information so you can enrich them for everyone! You can
            also set up reminders to keep in touch with your contacts.{" "}
          </div>
        ) : (
          <div className="coming-soon-content-description">
            ContactBook will automatically associate your contacts to the
            organizations they belong to. So you can see all organizations and
            the contacts within them. This will be done using the
            website(domain) or the organization info you add to your contacts.{" "}
          </div>
        )}

        <div className="coming-soon-content-button">
          <Button
            onClick={handleNotify}
            className="coming-soon-content-button-section"
          >
            Notify me
          </Button>
        </div>
        <div className="coming-soon-content-background">
          <img src={ComingSoonBackground} alt="background" />
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
