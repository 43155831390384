import React, { useState, useEffect, useContext } from "react";
import filter from "lodash/filter";
import { useHistory } from "react-router-dom";

//! Antd import
import Radio from "antd/lib/radio";
import Space from "antd/lib/space";

//! User files
import { cloneDeep } from "lodash";
import { ReminderIcon } from "../../../../../assets/svg";
import {
  OWNER,
  REMINDER_MENU,
  REMINDER_TYPES,
} from "../../../../../common/constants";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { AppContext } from "../../../../../AppContext";

const ReminderMenu = (handleConnectionChange, reminders) => {
  const { push } = useHistory();
  const [connection, setConnection] = useState();

  const {
    state: { currentUser, userSubscriptionInfo, userSpacePermission },
  } = useContext(AppContext);
  const spaceId = parseInt(
    localStorage.getItem("currentSpace") || currentUser?.spaceId,
    10
  );
  const remindersList = reminders;

  useEffect(() => {
    setConnection();

    if (remindersList?.length) {
      const reminderArr = cloneDeep(remindersList);
      setConnection(
        ...filter(reminderArr, (e) => e.type === REMINDER_TYPES.CONNECTION)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders]);

  return (
    <div className="dropdown-menu font-inter">
      {!userSubscriptionInfo?.spaceAddon?.active ? (
        <>
          <div className="dropdown-menu-heading">
            <ReminderIcon className="mr-8" />
            <span className="font-inter username dropdown-menu-heading-title">
              Reminders
            </span>
          </div>
          <div className="dropdown-menu-content">
            <span className="dropdown-menu-content-desc mb-16 mt-8">
              Easily set up reminders for your contacts to get an update when
              it's time to connect with them.
            </span>
            <div className="dropdown-menu-button full-width">
              <IconButton
                iconClass={`full-width dropdown-menu-button-section ${
                  userSpacePermission !== OWNER ? "cms-request-admin" : ""
                }`}
                handleClick={() =>
                  push(`/addOns/${spaceId}/reminders/activate`)
                }
                type="primary"
                text={
                  userSpacePermission === OWNER ? (
                    <span className="font-inter dropdown-menu-button-section-text">
                      Activate
                    </span>
                  ) : (
                    <span className="font-inter cms-request-admin-text">
                      Request admin to activate
                    </span>
                  )
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="dropdown-menu-title">
            <span className="dropdown-menu-title-text">Reminders</span>
          </div>
          <Radio.Group
            value={connection?.frequency || "NONE"}
            name="reminderRad"
          >
            <Space direction="vertical" size="middle">
              {REMINDER_MENU?.map((option) => (
                <Radio
                  value={option?.value}
                  key={option?.value}
                  onChange={(e) => {
                    setConnection({ frequency: e.target.value });
                    handleConnectionChange(
                      REMINDER_TYPES.CONNECTION,
                      e.target.value
                    );
                  }}
                >
                  <span>{option?.label}</span>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </>
      )}
    </div>
  );
};

export default ReminderMenu;
