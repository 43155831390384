import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const MANAGE_BILLING = gql`
  query manageBilling($spaceId: Int!) {
    manageBilling(spaceId: $spaceId) {
      url
    }
  }
`;
