import { gql } from "@apollo/client/core";

const REMINDERS_LIST = gql`
  query remindersList($data: ReminderListInput!) {
    remindersList(data: $data) {
      contacts {
        id
        firstName
        middleName
        lastName
        photo
        birthday
        anniversary
        jobTitle
        company
        emails {
          type
          value
        }
        numbers {
          type
          value
        }
        spaceId
        scheduledAt
        type
        frequency
        contactId
      }
      count
    }
  }
`;

export default REMINDERS_LIST;
