import React from "react";

const NoGroupTagFound = ({ text, icon, isSpacePlanActive, isFreeTrial }) => {
  return (
    <div
      className={`cms-no-group-tag ${
        !isSpacePlanActive || isFreeTrial ? "new-height" : "no-group-tag-height"
      }`}
    >
      <div className="cms-no-group-tag-icon mb-12">{icon}</div>
      <div className="cms-no-group-tag-text">{text}</div>
    </div>
  );
};

export default NoGroupTagFound;
