import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const REINVITE_SPACE_MEMBER = gql`
  mutation reInviteSpaceMember($userId: Int!, $spaceId: Int!) {
    reInviteSpaceMember(userId: $userId, spaceId: $spaceId) {
      message
      status
    }
  }
`;
