import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_PRODUCT = gql`
  query products($interval: PlanInterval!, $spaceId: Int!) {
    products(interval: $interval, spaceId: $spaceId) {
      data {
        id
        name
        description
        stripeId
        active
        features
        price {
          id
          productId
          amount
          currency
          priceId
          interval
        }
      }
    }
  }
`;
