import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_CONTACT_ACTIVITY_LOG = gql`
  query getContactActivityLogs(
    $contactId: Int!
    $where: ContactActivityFilter!
  ) {
    getContactActivityLogs(contactId: $contactId, where: $where) {
      data {
        id
        message
        actorType
        type
        oldData
        newData
        createdAt
        updatedAt
        contactId
        user {
          id
          name
          email
          photo
          role
        }
      }
      count
    }
  }
`;
