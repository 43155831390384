import { gql } from "@apollo/client";

export const VERIFY_GOOGLE_CODE = gql`
  mutation verifyGoogleCode($code: String!, $spaceId: Int!) {
    verifyGoogleCode(code: $code, spaceId: $spaceId) {
      status
      message
      jobId
    }
  }
`;

export const VERIFY_MICROSOFT_CODE = gql`
  mutation verifyMicrosoftCode($code: String!, $spaceId: Int!) {
    verifyMicrosoftCode(code: $code, spaceId: $spaceId) {
      status
      message
      jobId
    }
  }
`;
export const COMPLETE_ONBOARDING = gql`
  mutation {
    completeOnBoarding {
      status
    }
  }
`;
