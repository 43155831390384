import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const REMOVE_CONTACT_SOURCE = gql`
  mutation removeContactSource($id: Int!) {
    removeContactSource(id: $id) {
      message
      status
    }
  }
`;
