import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const REMOVE_MEMBER_FROM_SPACE = gql`
  mutation removeMemberFromSpace($id: Int!) {
    removeMemberFromSpace(id: $id) {
      data {
        id
        name
        ownerId
      }
      message
      status
    }
  }
`;
