import React, { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { useWindowSize } from "react-use";

//! Antd imports
import ContactView from "../components/ContactView";

//! GraphQl files
import { GET_CONTACT_INFO } from "../../../graphql/queries/getContactInfo";
import { AppContext } from "../../../../../AppContext";
import { SET_CONTACT_REFETCH } from "../../../../../common/actionTypes";
import NoAccessComponent from "../../../../../common/components/NoAccessComponent/NoAccessComponent";
import {
  ADDON_PARAMS,
  ROUTES,
  WINDOW_SIZE,
} from "../../../../../common/constants";
import { BackArrow } from "../../../../../assets/svg";

const ContactDetail = () => {
  const { contactId, spaceId } = useParams();
  const [contactDetails, setContactDetails] = useState([]);
  const { push } = useHistory();
  const { width } = useWindowSize();
  const history = useHistory();
  const {
    state: { contactRefetch },
    dispatch,
  } = useContext(AppContext);

  const [getContactInfo, { data, loading }] = useLazyQuery(GET_CONTACT_INFO, {
    fetchPolicy: "network-only",
    onCompleted({ contactInfo }) {
      dispatch({
        type: SET_CONTACT_REFETCH,
        data: false,
      });
      if (data?.contactInfo?.data) {
        setContactDetails(contactInfo?.data);
      }
    },
    onError() {
      push(`/contacts/${spaceId}/group/all`);
    },
  });

  useEffect(() => {
    if (contactId !== "new" && contactId !== "activate") {
      getContactInfo({
        variables: {
          contactId: contactId && parseInt(contactId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    if (contactRefetch) {
      getContactInfo({
        variables: {
          contactId: contactId && parseInt(contactId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRefetch]);

  const handleBackClick = () => {
    history.push(`${ROUTES?.CONTACTS}/${spaceId}/all`);
  };

  return (
    <>
      {contactId !== "new" &&
        contactId !== "activate" &&
        (JSON?.parse(localStorage.getItem(ADDON_PARAMS))?.length > 0 ? (
          <NoAccessComponent />
        ) : (
          <div className={width <= WINDOW_SIZE ? "padding-15" : ""}>
            {width <= WINDOW_SIZE && (
              <div className="cms-contact-wrapper-header mobile">
                <div className="cms-contact-form-close mobile-close">
                  {contactId && (
                    <div className="cms-edit-back" onClick={handleBackClick}>
                      <span className="cms-contact-form-close-arrow">
                        <BackArrow />
                      </span>
                      <span className="cms-contact-form-close-back">Back</span>
                    </div>
                  )}
                </div>
              </div>
            )}
            <ContactView
              loading={loading}
              contactDetails={contactDetails}
              setContactDetails={setContactDetails}
            />
          </div>
        ))}
    </>
  );
};

export default ContactDetail;
