import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import get from "lodash/get";
import _isInteger from "lodash/isInteger";

//! Ant imports
import { Button, Checkbox, Progress, Skeleton, Tag, Typography } from "antd";
import Form, { useForm } from "antd/lib/form/Form";
import Input from "antd/lib/input/Input";

//! User Files
import {
  Documents,
  DocumentIcon,
  Warning,
  ContactsIcon,
  LeaveGroupIcon,
  GeneralSettingIcon,
  DustbinIcon,
} from "../../../../../assets/svg";
import {
  ROUTES,
  EDIT_USER_SPACE,
  LEAVE_SPACE,
  AMPLITUDE_EVENT_LOG,
  REMOVE,
  LEAVE,
  BASIC,
  GOOGLE,
  LTD,
} from "../../../../../common/constants";
import ImageUpload from "../../../../../common/components/ImageUpload/ImageUpload";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";
import SpaceTag from "../../space/components/SpaceTag";
import { AppContext } from "../../../../../AppContext";
import {
  SET_USER_SPACE_PERMISSION,
  SET_USER_SUBSCRIPTION_INFO,
} from "../../../../../common/actionTypes";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import {
  hasSpaceRight,
  createAmplitudeEvent,
  setProgressPercentage,
} from "../../../../../common/utils";
import UpgradeButtonComponent from "../../../../../common/UpgradeButtonComponent";
import SettingsHeader from "../../../../../common/components/SettingsHeader/SettingsHeader";
import Error404 from "../../../../../Error404";

//! Graphql files
import { EDIT_SPACE } from "../../../graphql/mutations/editSpace";
import { REMOVE_SPACE } from "../../../graphql/mutations/removeSpace";
import { LEAVE_USER_SPACE } from "../../../graphql/mutations/leaveSpace";
import { GET_GENERAL_SPACE_INFO } from "../../../graphql/queries/getGeneralSpaceInfo";
import { GET_SPACES_V2 } from "../../../graphql/queries/getSpacesV2";

function SpaceSetting() {
  const { spaceId } = useParams();
  const history = useHistory();
  const [photo, setPhoto] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [leaveSpaceModal, openLeaveSpaceModal] = useState(false);
  const [updateDisable, setUpdateDisable] = useState(true);
  const [spaceName, setSpaceName] = useState();
  const {
    state: { currentUser, userSpacePermission, userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);
  const [form] = useForm();
  const storageAllocate =
    parseInt(
      userSubscriptionInfo?.subscription?.product?.features?.f_documents,
      10
    ) * 1024;

  const [editSpace, { loading: mutationLoading }] = useMutation(EDIT_SPACE, {
    refetchQueries: [
      {
        query: GET_GENERAL_SPACE_INFO,
        fetchPolicy: "cache-and-network",
        variables: { id: parseInt(spaceId, 10) },
      },
    ],
  });

  const [removeSpace, { loading: deleting }] = useMutation(REMOVE_SPACE, {
    refetchQueries: [{ query: GET_SPACES_V2 }],
  });

  const [getGeneralSpaceInfo, { data, error, loading }] = useLazyQuery(
    GET_GENERAL_SPACE_INFO,
    {
      fetchPolicy: "cache-and-network",
      variables: { id: parseInt(spaceId, 10) },
    }
  );

  const [leaveSpace, { loading: leaveSpaceLoader }] = useMutation(
    LEAVE_USER_SPACE,
    {
      refetchQueries: [
        {
          query: GET_SPACES_V2,
          fetchPolicy: "network-only",
        },
      ],
      onCompleted() {
        history.push(`/contacts/${currentUser?.spaceId}/group/all`);
      },
      onError() {},
    }
  );

  useEffect(() => {
    if (spaceId && _isInteger(parseInt(spaceId, 10))) {
      getGeneralSpaceInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (data) {
      dispatch({
        type: SET_USER_SUBSCRIPTION_INFO,
        data: data?.spaceInfo?.data,
      });
      dispatch({
        type: SET_USER_SPACE_PERMISSION,
        data: data?.spaceInfo?.data?.permission,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <Error404 />;
  const onFinish = (e) => {
    const { name } = e;
    editSpace({
      variables: {
        name: name?.trim(),
        id: parseInt(spaceId, 10),
        photo: photo,
      },
    })
      .then((res) => {
        setUpdateDisable(true);
        const eventProperties = {
          "Space Id": res?.data?.updateSpace?.data?.id,
          "Space name": res?.data?.updateSpace?.data?.name,
          "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
          "Plan status": userSubscriptionInfo?.subscription?.planStatus,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.SPACE_UPDATED,
          eventProperties
        );
      })
      .catch(() => {});
  };

  const handleDelete = () => {
    form.submit();
  };

  const onSubmit = (e) => {
    const { consent, name } = e;
    if (consent) {
      removeSpace({
        variables: { id: parseInt(spaceId, 10), name },
      })
        .then(() => {
          const eventProperties = {
            "Space Id": spaceId,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.SPACE_DELETED,
            eventProperties
          );
          history.push(ROUTES.MY_PROFILE);
          localStorage.setItem("currentSpace", currentUser?.spaceId);
          form.resetFields();
          setOpen(!open);
        })
        .catch(() => {});
    }
  };

  const handleConfirmation = () => {
    setOpen(!open);
    form.resetFields();
  };

  const tagsArray = [
    {
      icon: <ContactsIcon />,
      tag: "Contacts",
      count: data?.spaceInfo?.data?.contactsCount,
    },
    {
      icon: <Documents />,
      tag: "Documents",
      count: data?.spaceInfo?.data?.attachmentsCount,
    },
    {
      icon: <DocumentIcon />,
      tag: "notes",
      count: data?.spaceInfo?.data?.notesCount,
    },
  ];

  const popupText = (
    <>
      <div className="popup-text-title font-inter cms-title-wrap">
        Are you sure you want to delete
        <SpaceTag
          spaceName={data?.spaceInfo?.data?.name}
          spaceLogo={data?.spaceInfo?.data?.photo}
          spaceId={data?.spaceInfo?.data?.id}
          single
        />
        space?
      </div>
      <div className="popup-text-content">
        <span className="popup-text-content-body">
          <div className="popup-text-content-body-text">
            You & {data?.spaceInfo?.data?.membersCount} team members will lose
          </div>
          <div className="popup-text-content-body-tags-container">
            {tagsArray.map((tag) => {
              return (
                <Tag
                  color="#F2F4F7"
                  className="popup-text-content-body-tags"
                  key={tag?.tag}
                >
                  {tag?.icon}&nbsp; {tag?.count}&nbsp;{tag?.tag}
                </Tag>
              );
            })}
          </div>
        </span>
      </div>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <div className="popup-text-content-checkbox">
          <Form.Item
            name="consent"
            valuePropName="checked"
            rules={[{ required: true }]}
          >
            <Checkbox>
              This is non-reversible action, I understand & I'll not able to
              recover this data
            </Checkbox>
          </Form.Item>
        </div>
        <div className="popup-text-content-checkbox">
          <Form.Item
            label="Type space name to confirm delete"
            name="name"
            rules={[{ required: true, message: "Space name is required" }]}
          >
            <Input placeholder="Enter space name" />
          </Form.Item>
        </div>
      </Form>
    </>
  );

  const handleLeaveSpace = () => {
    if (spaceId) {
      leaveSpace({
        variables: {
          id: parseInt(spaceId, 10),
        },
      })
        .then(() => {})
        .catch(() => {});
    }
  };

  const closeLeaveSpaceModal = () => {
    openLeaveSpaceModal(false);
  };

  const handleValueChange = (changedValues) => {
    const field = Object.keys(changedValues)[0];
    const fieldValue = get(data?.spaceInfo?.data, `${field}`);

    if (
      changedValues[field]?.trim()?.length === 0 ||
      fieldValue === changedValues[field]
    ) {
      setUpdateDisable(true);
    } else {
      setUpdateDisable(false);
    }
  };

  const hasEditSpaceRight = hasSpaceRight(userSpacePermission, EDIT_USER_SPACE);

  return (
    <>
      <SettingsHeader icon={<GeneralSettingIcon />} title="General" />
      <div className="cms-general-setting">
        <div className="space-setting">
          <>
            {loading ? (
              <Skeleton avatar active />
            ) : (
              <div className="cms-space-setting-section">
                <div className="space-setting-photo">
                  <ImageUpload
                    imageLoading={imageLoading}
                    setImageLoading={setImageLoading}
                    profileImage={photo}
                    setProfileImage={setPhoto}
                    className="space-setting-avatar"
                    existingImage={data?.spaceInfo?.data?.photo}
                    textbelowIcon
                    text={
                      data?.spaceInfo?.data?.photo
                        ? "Change photo"
                        : "Add photo"
                    }
                    name={data?.spaceInfo?.data?.name}
                    disable={!hasEditSpaceRight}
                    textWidthClass="space-setting-width"
                    setUpdateDisable={setUpdateDisable}
                  />
                </div>
                <div className="cms-general-setting-form">
                  <Form
                    onFinish={onFinish}
                    initialValues={data ? data?.spaceInfo?.data : ""}
                    onValuesChange={handleValueChange}
                    className="cms-general-setting-form-block"
                  >
                    <Form.Item name="name" className="cms-space-name-field">
                      <Input
                        disabled={!hasEditSpaceRight}
                        onChange={(e) => setSpaceName(e?.target?.value?.trim())}
                      />
                    </Form.Item>
                    <IconButton
                      iconClass="space-setting-update-button"
                      htmlType="submit"
                      loading={mutationLoading}
                      disable={
                        !hasEditSpaceRight ||
                        updateDisable ||
                        imageLoading ||
                        spaceName?.length === 0
                      }
                      text="Update"
                      type="primary"
                    />
                  </Form>
                </div>
              </div>
            )}
            {userSubscriptionInfo?.ownerId === parseInt(currentUser?.id, 10) &&
              !loading &&
              data?.spaceInfo?.data?.subscription?.product?.name !== BASIC && (
                <>
                  <div className="storage-info-container">
                    <div className="storage-info-container-icon">
                      <Documents />
                    </div>
                    <div className="storage-info-container-text">
                      <div className="storage-info-container-text-details">
                        <div className="storage-info-container-text-details-title">
                          <div className="storage-info-container-text-details-title-head font-inter">
                            Available Storage
                          </div>
                          {userSubscriptionInfo ? (
                            <div className="storage-info-container-text-details-title-storage">
                              {(
                                userSubscriptionInfo?.storage?.filesSize / 1024
                              ).toFixed(2) ?? 0}{" "}
                              GB of{" "}
                              {userSubscriptionInfo?.subscription?.product
                                ?.features?.f_documents ?? 0}{" "}
                              GB Used
                            </div>
                          ) : (
                            <Skeleton paragraph={false} title active />
                          )}
                        </div>
                        {userSubscriptionInfo ? (
                          <div>
                            <Tag>
                              {userSubscriptionInfo?.storage?.filesCount} files
                              |{" "}
                              {(
                                userSubscriptionInfo?.storage?.filesSize / 1024
                              ).toFixed(2)}{" "}
                              GB
                            </Tag>
                          </div>
                        ) : (
                          <Skeleton
                            className="storage-info-container-text-details-skeleton"
                            paragraph={false}
                            title
                            active
                          />
                        )}
                      </div>
                      <div className="storage-info-container-progress">
                        <Progress
                          percent={setProgressPercentage(
                            userSubscriptionInfo?.storage?.filesSize,
                            storageAllocate
                          )}
                          showInfo={false}
                        />
                      </div>
                    </div>
                  </div>
                  {parseInt(
                    userSubscriptionInfo?.subscription?.product?.features
                      ?.f_documents,
                    10
                  ) !== 100 &&
                    userSubscriptionInfo?.subscription?.product?.price
                      ?.interval !== LTD && (
                      <div className="upgrade-bar font-inter">
                        {userSubscriptionInfo?.type === GOOGLE
                          ? "Upgrade to a higher plan to get more document space!"
                          : "Upgrade to business plan to get 100 GB"}
                        <Link to={`/space/${spaceId}/plans`}>
                          <UpgradeButtonComponent />
                        </Link>
                      </div>
                    )}
                  {data?.spaceInfo?.data?.ownerId ===
                    parseInt(currentUser?.id, 10) &&
                    data?.spaceInfo?.data?.default &&
                    !loading && (
                      <div className="warning mt-16">
                        <div className="warning-box">
                          <Warning className="warning-icon" />
                          <Typography.Text className="warning-message">
                            You can not delete or leave the default space
                          </Typography.Text>
                        </div>
                      </div>
                    )}
                </>
              )}
            <div className="cms-general-setting-cta">
              {parseInt(data?.spaceInfo?.data?.ownerId, 10) ===
                parseInt(currentUser?.id, 10) &&
                !loading && (
                  <Button
                    className="delete-button"
                    onClick={handleConfirmation}
                    loading={deleting}
                    disabled={
                      data?.spaceInfo?.data?.default || !hasEditSpaceRight
                    }
                  >
                    <DustbinIcon
                      className={`${
                        !hasEditSpaceRight ||
                        (data?.spaceInfo?.data?.default &&
                          data?.spaceInfo?.data?.ownerId ===
                            parseInt(currentUser?.id, 10))
                          ? "cms-disable-delete-btn-icon"
                          : ""
                      } delete-button-icon`}
                    />
                    Delete this Space
                  </Button>
                )}

              {!loading &&
                parseInt(data?.spaceInfo?.data?.ownerId, 10) !==
                  parseInt(currentUser?.id, 10) && (
                  <IconButton
                    iconClass="font-inter cms-leave-space-btn"
                    icon={<LeaveGroupIcon />}
                    text={<span className="cms-leave-text">{LEAVE_SPACE}</span>}
                    handleClick={() => openLeaveSpaceModal(true)}
                    disable={
                      data?.spaceInfo?.data?.ownerId ===
                      parseInt(currentUser?.id, 10)
                    }
                  />
                )}
            </div>
            <RemovePopup
              content={popupText}
              okText={REMOVE}
              visible={open}
              removeNote={handleConfirmation}
              handleRemove={handleDelete}
              deleteLoader={deleting}
              disabled={deleting}
              maskClosable={false}
            />
          </>
          <RemovePopup
            content={
              <span className="popup-text-title font-inter">
                Are you sure you want to leave from the{" "}
                {data?.spaceInfo?.data?.name}?
              </span>
            }
            okText={LEAVE}
            visible={leaveSpaceModal}
            removeNote={closeLeaveSpaceModal}
            handleRemove={() => handleLeaveSpace(data?.spaceInfo?.data?.name)}
            deleteLoader={leaveSpaceLoader}
            disabled={leaveSpaceLoader}
            maskClosable={false}
          />
        </div>
      </div>
    </>
  );
}

export default SpaceSetting;
