import React, { createContext, useReducer } from "react";

//! User Files
import * as ActionTypes from "./common/actionTypes";
import client from "./apollo";
import api from "./common/api";
import {
  BLACK_FRIDAY_OFFER_MODAL,
  TOKEN,
  USER,
  USER_ID,
} from "./common/constants";

const getLoggedInUser = () => {
  let loggedInUser = localStorage.getItem(USER);
  loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;
  return loggedInUser;
};

const getUserId = () => {
  return localStorage.getItem(USER_ID)
    ? parseInt(localStorage.getItem(USER_ID), 10)
    : "";
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  userId: getUserId(),
  authToken: localStorage.getItem(TOKEN),
  authenticated: false,
  drawerVisible: false,
  currentSideTab: null,
  groupSettingDrawer: false,
  contactFormDrawer: false,
  contacts: {},
  contactsCount: null,
  spaces: [],
  spacesCount: null,
  isExistingContact: false,
  groupList: [],
  unassignedContacts: {},
  notificationDrawer: false,
  spaceInfo: {},
  userGroupPermission: null,
  userSpacePermission: null,
  userSubscriptionInfo: null,
  isLimitReached: false,
  spacePlanStatus: {},
  newNotification: false,
  isDoneButtonClick: false,
  addedContactsCount: 0,
  selectedTag: null,
  activeSwitch: "group",
  duplicateCardId: null,
  duplicateContactData: [],
  connectSourceParams: [],
  importStatus: null,
  duplicateContactCount: 0,
  syncLogDrawer: false,
  isGsuiteAdmin: null,
  isSyncStarted: false,
  tagsList: [],
  importStatusAndProgress: [],
  runTour: false,
  labelCheckList: [],
  spaceGroups: [],
  refetchTags: false,
  contactsRefetch: false,
  contactRefetch: false,
  blackFridayOfferModal:
    localStorage.getItem(BLACK_FRIDAY_OFFER_MODAL) || false,
  paymentMethodExists: JSON?.parse(
    localStorage?.getItem("payment_method_exists")
  ),
};

const reducer = (state, action) => {
  switch (action.type) {
    //! USER
    case ActionTypes.SET_CURRENT_USER:
      // eslint-disable-next-line no-case-declarations
      const user = action.data || {};
      localStorage.setItem(
        USER,
        user && Object.keys(user).length ? JSON.stringify(user) : null
      );
      return { ...state, currentUser: { ...user } };
    case ActionTypes.SET_USER_ID:
      localStorage.setItem(USER_ID, action.data);
      return { ...state, userId: action.data };
    case ActionTypes.SET_AUTHENTICATED:
      return { ...state, authenticated: action.data };
    case ActionTypes.SET_TOKEN:
      localStorage.setItem(TOKEN, action.data);
      return { ...state, authToken: action.data };
    //! Drawer
    case ActionTypes.SET_DRAWER_VISIBLE:
      return { ...state, drawerVisible: action.data };
    //! LOGOUT
    case ActionTypes.LOGOUT:
      delete api.defaults.headers.common.Authorization;
      localStorage.clear();
      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        currentUser: {},
      };
    case ActionTypes.SET_CURRENT_SIDE_TAB:
      if (action?.data) {
        localStorage.setItem("currentGroup", action.data);
      }
      return { ...state, currentSideTab: action.data };
    case ActionTypes.GROUP_SETTING_DRAWER:
      return { ...state, groupSettingDrawer: action.data };
    case ActionTypes.CONTACT_FORM_DRAWER:
      return { ...state, contactFormDrawer: action.data };
    case ActionTypes.SET_CONTACTS:
      return { ...state, contacts: action.data };
    case ActionTypes.SET_CONTACTS_COUNT:
      return { ...state, contactsCount: action.data };
    case ActionTypes.SET_SPACES:
      return { ...state, spaces: action.data };
    case ActionTypes.SET_SPACES_COUNT:
      return { ...state, spacesCount: action.data };
    case ActionTypes.SET_IS_EXISTING_CONTACT:
      return { ...state, isExistingContact: action.data };
    case ActionTypes.SET_GROUP_LIST:
      return { ...state, groupList: action.data };
    case ActionTypes.SET_UNASSIGNED_CONTACTS:
      return { ...state, unassignedContacts: action.data };
    case ActionTypes.SET_NOTIFICATION_DRAWER:
      return { ...state, notificationDrawer: action.data };
    case ActionTypes.SET_SPACE_INFO:
      return { ...state, spaceInfo: action.data };
    case ActionTypes.SET_USER_GROUP_PERMISSION:
      return { ...state, userGroupPermission: action.data };
    case ActionTypes.SET_USER_SPACE_PERMISSION:
      return { ...state, userSpacePermission: action.data };
    case ActionTypes.SET_USER_SUBSCRIPTION_INFO:
      return { ...state, userSubscriptionInfo: action.data };
    case ActionTypes.SET_CONTACT_LIMIT_EXCEEDED:
      return { ...state, isLimitReached: action.data };
    case ActionTypes.SET_SPACE_PLAN_STATUS:
      return { ...state, spacePlanStatus: action.data };
    case ActionTypes.SET_NEW_NOTIFICATION:
      return { ...state, newNotification: action.data };
    case ActionTypes.SET_EXISTING_DONE_CLICK:
      return { ...state, isDoneButtonClick: action.data };
    case ActionTypes.SET_ADDED_CONTACTS_COUNT:
      return { ...state, addedContactsCount: action.data };
    case ActionTypes.SET_CURRENT_SELECTED_TAG:
      if (action?.data) {
        localStorage.setItem("currentTag", action.data);
      }
      return { ...state, selectedTag: action.data };
    case ActionTypes.SET_CURRENT_SELECTED_SPACE:
      if (action?.data) {
        localStorage.setItem("currentSpace", action.data);
      }
      return { ...state, selectedTag: action.data };
    case ActionTypes.SET_ACTIVE_SWITCH:
      return { ...state, activeSwitch: action.data };
    case ActionTypes.SET_DUPLICATE_CARD_ID:
      return { ...state, duplicateCardId: action.data };
    case ActionTypes.SET_DUPLICATE_CONTACT_CARD:
      return { ...state, duplicateContactData: action.data };
    case ActionTypes.SET_CONNECT_SOURCE_PARAMS:
      return { ...state, connectSourceParams: action.data };
    case ActionTypes.SET_IMPORT_CONTACT_STATUS:
      return { ...state, importStatus: action.data };
    case ActionTypes.SET_DUPLICATE_CONTACT_COUNT:
      return { ...state, duplicateContactCount: action.data };
    case ActionTypes.SET_SYNC_LOG_DRAWER:
      return { ...state, syncLogDrawer: action.data };
    case ActionTypes.SET_IS_GSUITE_ADMIN:
      return { ...state, isGsuiteAdmin: action.data };
    case ActionTypes.SET_START_SYNC:
      return { ...state, isSyncStarted: action.data };
    case ActionTypes.SET_TAG_LIST:
      return { ...state, tagsList: action.data };
    case ActionTypes.SET_IMPORT_STATUS_AND_PROGRESS:
      return { ...state, importStatusAndProgress: action.data };
    case ActionTypes?.SET_CURRENT_EMAIL:
      if (action?.data) {
        localStorage.setItem("current_email", action.data);
      }
      return { ...state, selectedTag: action.data };
    case ActionTypes?.BLACK_FRIDAY_OFFER_MODAL:
      if (action?.data) {
        localStorage.setItem(BLACK_FRIDAY_OFFER_MODAL, action.data);
      }
      return { ...state, halloweenOfferModal: action.data };
    case ActionTypes?.SET_RUN_TOUR:
      return { ...state, runTour: action?.data };
    case ActionTypes?.SET_CURRENT_SELECTED_LABELS:
      return { ...state, labelCheckList: action?.data };
    case ActionTypes?.SET_SPACE_GROUPS:
      return { ...state, spaceGroups: action?.data };
    case ActionTypes?.SET_REFETCH_TAGS:
      return { ...state, refetchTags: action?.data };
    case ActionTypes?.SET_CONTACTS_REFETCH:
      return { ...state, contactsRefetch: action?.data };
    case ActionTypes?.SET_CONTACT_REFETCH:
      return { ...state, contactRefetch: action?.data };
    case ActionTypes?.PAYMENT_METHOD_EXISTS:
      localStorage.setItem("payment_method_exists", action.data);
      return { ...state, paymentMethodExists: action?.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    return localStorage.getItem(TOKEN) || null;
  };

  // eslint-disable-next-line
  const getCurrentUser = () => {
    return localStorage.getItem(USER)
      ? JSON.parse(localStorage.getItem(USER))
      : {};
  };

  const initializeAuth = (authToken, userData) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const userId = getUserId();
    if (token) {
      api.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
      };
      dispatch({ type: ActionTypes.SET_TOKEN, data: token });
      dispatch({ type: ActionTypes.SET_AUTHENTICATED, data: true });
      dispatch({ type: ActionTypes.SET_CURRENT_USER, data: user });
      dispatch({ type: ActionTypes.SET_USER_ID, data: userId });
    }
  };

  const value = {
    state,
    dispatch,
    initializeAuth,
    getToken,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
