import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const ADD_NOTE = gql`
  mutation addNote($contactId: Int!, $data: AddNoteInput!) {
    addNote(contactId: $contactId, data: $data) {
      data {
        id
        note
        visibility
        contactId
        userId
        createdAt
        updatedAt
      }
      message
      status
    }
  }
`;
