import React, { useContext, useEffect } from "react";
import { Checkbox, List, Modal } from "antd";
import clsx from "clsx";
import { cloneDeep, indexOf } from "lodash";

//! User files
import Loading from "../../../../../common/components/Loading";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { capitalizeWord } from "../../../../../common/utils";
import { AppContext } from "../../../../../AppContext";
import { CONTACT_LIMIT } from "../../../../../common/constants";
import SpacelistSkeleton from "../../../../../common/components/SpacelistSkeleton/SpacelistSkeleton";
import { TagIcon } from "../../../../../assets/svg";

const MultipleTagsModal = ({
  setTagListModal,
  tagListModal,
  fetchTagsList,
  manageGroupsTagsLoader,
  commonTags,
  manageMultipleGroupsTags,
  tagCheckList,
  setTagCheckList,
  tagsList,
  setCommonTags,
  tagsToUnassign,
  setTagsToUnassign,
  totalTags,
  tagOffset,
  setTagOffset,
  tagsLoader,
  tagsToAssign,
  setTagsToAssign,
  commonGroupsTagsLoader,
  setTagsList,
}) => {
  const {
    state: { connectSourceParams },
  } = useContext(AppContext);
  const { spaceId } = connectSourceParams;
  const currentSpace =
    parseInt(spaceId, 10) || parseInt(localStorage.getItem("currentSpace"), 10);
  useEffect(() => {
    fetchTagsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace, tagOffset]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;

    if (
      !tagsLoader &&
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      tagsList?.length < totalTags
    ) {
      setTagOffset(tagOffset + CONTACT_LIMIT);
    }
  };
  const handleCheckboxClick = (e, tagName) => {
    e.stopPropagation();

    if (tagCheckList?.includes(tagName)) {
      const tempId = cloneDeep(tagCheckList);
      const index = indexOf(tagCheckList, tagName);
      const indexOfAssign = indexOf(tagsToAssign, tagName);
      if (index !== -1) {
        tempId.splice(index, 1);
      }
      if (tagsToAssign?.includes(tagName)) {
        tagsToAssign?.splice(indexOfAssign, 1);
      }
      setTagCheckList(tempId);
      if (
        !e?.target?.checked &&
        commonTags?.includes(tagName) &&
        !tagsToUnassign?.includes(tagName)
      ) {
        setTagsToUnassign([...tagsToUnassign, tagName]);
      }
    } else {
      setTagCheckList([...tagCheckList, tagName]);

      if (!commonTags?.includes(tagName) && !tagsToAssign?.includes(tagName)) {
        setTagsToAssign([...tagsToAssign, tagName]);
      }

      if (tagsToUnassign?.includes(tagName)) {
        tagsToUnassign?.splice(indexOf(tagsToUnassign, tagName), 1);
      }
    }
  };

  const handleMultipleGroups = () => {
    manageMultipleGroupsTags();
    setTagListModal(false);
  };

  const handleCloseModal = () => {
    setTagsToAssign([]);
    setTagsToUnassign([]);
    setTagsList([]);
    setCommonTags([]);
    setTagCheckList([]);
    setTagOffset(0);
    setTagListModal(false);
  };

  return (
    <div>
      <Modal
        visible={tagListModal}
        className="multiple-group-modal-wrapper"
        centered
        title={
          <span className="font-inter cms-shared-space-title">Manage tags</span>
        }
        onCancel={handleCloseModal}
        maskClosable={false}
        footer={
          tagsList?.length > 0 && (
            <IconButton
              iconClass="cms-apply-btn"
              text="Apply"
              handleClick={handleMultipleGroups}
              loading={manageGroupsTagsLoader}
              disable={tagsToAssign?.length < 1 && tagsToUnassign?.length < 1}
            />
          )
        }
      >
        <div onScroll={handleInfiniteScroll} className="cms-infinite-scroll">
          {
            // eslint-disable-next-line no-nested-ternary
            commonGroupsTagsLoader ? (
              <SpacelistSkeleton loading={commonGroupsTagsLoader} />
            ) : tagsList?.length === 0 ? (
              <div className="cms-no-group-tag">
                <div className="cms-no-group-tag-icon mb-12">
                  <TagIcon />
                </div>
                <div className="cms-no-group-tag-text mb-24">No tags found</div>
              </div>
            ) : (
              <List
                dataSource={tagsList}
                renderItem={(item) => {
                  const { tag } = item;
                  const groupName = (
                    <span className="cms-font-ellipsis group-name">
                      {capitalizeWord(tag)}
                    </span>
                  );
                  const groupTitle = (
                    <div className="cms-group-title">{groupName}</div>
                  );

                  return (
                    <List.Item
                      className={`${clsx(
                        tagCheckList?.includes(tag) && "selected-list active-bg"
                      )} cursor-pointer`}
                      title={capitalizeWord(tag)}
                      onClick={(e) => handleCheckboxClick(e, tag)}
                    >
                      <List.Item.Meta
                        avatar={
                          <>
                            <span className="d-flex-center">
                              <Checkbox
                                onChange={(e) => handleCheckboxClick(e, tag)}
                                checked={tagCheckList?.includes(tag)}
                              />
                            </span>
                          </>
                        }
                        title={groupTitle}
                      />
                    </List.Item>
                  );
                }}
              />
            )
          }
          {tagsLoader && tagOffset !== 0 && (
            <div className="cms-infinite-scroll-loader">
              <Loading />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MultipleTagsModal;
