import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_TAGS_LIST = gql`
  query tagList($offset: Int!, $limit: Int!, $spaceId: Int!) {
    tagList(offset: $offset, limit: $limit, spaceId: $spaceId) {
      data {
        tags {
          id
          tag
          type
          contacts
        }
        count
      }
    }
  }
`;
