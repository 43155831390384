import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const ADD_ATTACHMENT = gql`
  mutation addAttachment($contactId: Int!, $data: AddAttachmentInput!) {
    addAttachment(contactId: $contactId, data: $data) {
      data {
        id
        name
        type
        size
        key
        url
        username
        contactId
        userId
        createdAt
        updatedAt
      }
      message
      status
    }
  }
`;
