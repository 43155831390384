import React, { useContext, useState } from "react";
import moment from "moment";

//! Antd import
import { Button } from "antd";

//! User files
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import {
  generateRandomColor,
  hasGroupRight,
} from "../../../../../common/utils";
import { Lock, RemoveIcon } from "../../../../../assets/svg";
import {
  EDIT,
  VIEW,
  VISIBILITY,
  REMOVE_NOTE,
} from "../../../../../common/constants";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";
import { AppContext } from "../../../../../AppContext";

const ContactNotesListItem = ({
  note,
  createdAt,
  visibility,
  username,
  handleRemoveNote,
  open,
  setOpen,
  id,
  deleteLoader,
  userId,
  contactPermission,
  notesLength,
  index,
}) => {
  const {
    state: { currentUser, userGroupPermission },
  } = useContext(AppContext);

  const popupText = (
    <span className="popup-text-title font-inter">
      Are you sure you want to remove the comment?
    </span>
  );

  const [noteId, setNoteId] = useState();
  const date = moment(createdAt).format("LLL");
  const splitDate = date.split(" ");
  const publishedDate =
    // eslint-disable-next-line prefer-template
    splitDate[3] +
    " " +
    splitDate[4] +
    " - " +
    splitDate[1].replace(/,/g, "") +
    " " +
    splitDate[0] +
    ", " +
    splitDate[2];

  // eslint-disable-next-line no-shadow
  const removeNote = (id) => {
    setNoteId(id);
    setOpen(!open);
  };

  return (
    <>
      {parseInt(currentUser?.id, 10) === userId ||
      visibility === VISIBILITY.PUBLIC ? (
        <div
          className={
            notesLength - 1 !== index
              ? "notes-tab notes-tab-border"
              : "notes-tab"
          }
        >
          <div className="notes-tab-name-time-info">
            <UserAvatar
              className="cms-notes-user-avatar"
              name={username}
              color={generateRandomColor(parseInt(userId, 10))}
              photo={currentUser?.photo}
            />
            <span className="notes-username">
              {parseInt(currentUser?.id, 10) === userId ? "You" : username}
            </span>
            <span className="cms-dot-separator" />
            <span className="notes-extra-info">{publishedDate}</span>
            <span className="cms-dot-separator" />
            {contactPermission &&
              hasGroupRight(
                userGroupPermission ?? contactPermission ? EDIT : VIEW,
                REMOVE_NOTE
              ) && (
                <Button
                  className={`${
                    id === undefined
                  } notes-extra-info notes-action`}
                  onClick={() => removeNote(id)}
                >
                  Remove
                </Button>
              )}
          </div>
          <div className="notes-tab-note-info">{note}</div>
        </div>
      ) : (
        <div
          className={
            notesLength - 1 !== index
              ? "notes-tab notes-tab-border"
              : "notes-tab"
          }
        >
          <div className="notes-tab-name-time-info">
            <UserAvatar
              className="cms-notes-user-avatar"
              name={username}
              color={generateRandomColor(parseInt(userId, 10))}
            />
            <span className="notes-username">{username}</span>
            <span className="cms-dot-separator" />
            <span className="notes-extra-info">{publishedDate}</span>
            <span className="notes-private">
              <Lock className="notes-private-lock" />
              Private
            </span>
          </div>
        </div>
      )}
      {noteId && open && (
        <RemovePopup
          visible={open}
          removeNote={removeNote}
          handleRemove={handleRemoveNote}
          icon={<RemoveIcon />}
          content={popupText}
          id={noteId}
          okText="Remove"
          deleteLoader={deleteLoader}
          disabled={deleteLoader}
          maskClosable={false}
        />
      )}
    </>
  );
};

export default ContactNotesListItem;
