import { gql } from "@apollo/client/core";
import { contactFragment } from "../fragments/contactFragment";

// eslint-disable-next-line import/prefer-default-export
export const GET_CONTACT_INFO = gql`
  query contactInfo($contactId: Int!) {
    contactInfo(contactId: $contactId) {
      data {
        ...ContactFragment
      }
    }
  }
  ${contactFragment}
`;
