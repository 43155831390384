import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const SHARE_GROUP_TO_USER = gql`
  mutation shareGroupToUser($groupId: Int!, $data: sharingInput!) {
    shareGroupToUser(groupId: $groupId, data: $data) {
      message
      status
    }
  }
`;
