import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const REVOKE_GROUP = gql`
  mutation revokeGroup($id: Int!, $groupId: Int!) {
    revokeGroup(id: $id, groupId: $groupId) {
      message
      status
    }
  }
`;
