import { gql } from "@apollo/client/core";

const SHARE_MULTIPLE_GROUP_TO_USER = gql`
  mutation shareMultiGroupToUser(
    $groupIds: [Int]
    $data: MultiGroupSharingInput!
  ) {
    shareMultiGroupToUser(groupIds: $groupIds, data: $data) {
      message
      status
    }
  }
`;

export default SHARE_MULTIPLE_GROUP_TO_USER;
