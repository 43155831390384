import React, { useEffect, useState } from "react";

//! Ant Import
import Input from "antd/lib/input";

//! Ant Icons
import SearchOutlined from "@ant-design/icons/SearchOutlined";

const { Search } = Input;
const SearchComponent = ({ setSearchTerm, groupId, spaceId, searchTerm }) => {
  const [value, setValue] = useState(searchTerm || null);
  const onSearch = (val) => {
    setValue(val?.target?.value);
  };

  useEffect(() => {
    setValue(searchTerm || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, spaceId]);

  useEffect(() => {
    setSearchTerm(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  return (
    <Search
      size="large"
      placeholder="Search"
      prefix={<SearchOutlined />}
      onChange={onSearch}
      value={value}
      name="searchText"
      allowClear
    />
  );
};

export default SearchComponent;
