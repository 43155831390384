import React from "react";
import { Button, Col, Row, Spin, Typography } from "antd";
import { CloseCircle, ImportFile } from "../../../assets/svg";
import UploadProgress from "../../main/modules/settings/components/UploadProgress";

function FileItem({
  name,
  setFile,
  disabled,
  errored,
  inAppFileItem,
  jobId,
  loading,
  handleUpload,
  errorMessage,
  setIsFileUploaded,
}) {
  const closeButton = (
    <Col>
      <Button
        disabled={disabled}
        onClick={() => {
          setFile();
          // eslint-disable-next-line no-unused-expressions
          setIsFileUploaded && setIsFileUploaded(false);
        }}
        type="text"
      >
        <CloseCircle />
      </Button>
    </Col>
  );
  return (
    <>
      <div
        className={
          // eslint-disable-next-line no-nested-ternary
          inAppFileItem
            ? errored
              ? "file-list-in-app-errored"
              : "file-list-in-app"
            : errored
            ? "file-list-item-errored"
            : "file-list-item"
        }
      >
        <Row justify="space-between" align="middle">
          <Col>
            <Row align="middle">
              <Col>
                <ImportFile
                  className={
                    errored
                      ? "file-list-item-icon-errored"
                      : "file-list-item-icon"
                  }
                />
              </Col>
              <Col>
                <Typography.Text
                  className={
                    errored
                      ? "file-list-item-name-errored"
                      : "file-list-item-name"
                  }
                >
                  {name}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
          {inAppFileItem && (
            <Col>
              {jobId ? (
                <UploadProgress jobId={jobId || null} setFile={setFile} />
              ) : (
                <Spin spinning={loading} />
              )}
              {errored && (
                <div className="file-list-in-app-error">
                  <Button
                    size="small"
                    type="primary"
                    shape="round"
                    loading={loading}
                    onClick={() => handleUpload()}
                  >
                    Try again
                  </Button>
                  {closeButton}
                </div>
              )}
            </Col>
          )}
          {!inAppFileItem && closeButton}
        </Row>
      </div>
      <div className="error-message">{errored && <p>{errorMessage}</p>}</div>
    </>
  );
}

export default FileItem;
