import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_GROUP_LIST_V2 = gql`
  query groupListV2($offset: Int, $limit: Int, $spaceId: ID!) {
    groupListV2(offset: $offset, limit: $limit, spaceId: $spaceId) {
      groups {
        id
        name
        type
        source
        sourceId
        ownerId
        spaceId
        contacts
        permissions
        membersCount
        groupOwner {
          id
          name
          email
          role
          photo
        }
      }
      count
    }
  }
`;
