import React from "react";
import { useLocation } from "react-router-dom";
import IncorrectDataList from "./IncorrectDataList";
import AssistantHeader from "./AssistantHeader";
import DuplicatesList from "./DuplicatesList";
import ReminderList from "./ReminderList";
import { CLEANUP_CONSTANTS } from "../../../../../common/constants";

function AssistantList() {
  const { pathname } = useLocation();
  const title = pathname?.split("/")[3];

  return (
    <div>
      <AssistantHeader title={title} />
      {title === CLEANUP_CONSTANTS?.DUPLICATES && <DuplicatesList />}
      {title === CLEANUP_CONSTANTS?.INCORRECT_DATA && <IncorrectDataList />}
      {title === CLEANUP_CONSTANTS?.STAY_CONNECTED && <ReminderList />}
      {title === CLEANUP_CONSTANTS?.BIRTHDAYS && <ReminderList />}
      {title === CLEANUP_CONSTANTS?.ANNIVERSARIES && <ReminderList />}
    </div>
  );
}

export default AssistantList;
