import React from "react";
import { Col, List, Row, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import { IssuesIcon } from "../../../../../assets/svg";
import ContactCard from "../../../../../common/components/ContactCard/ContactCard";

function IncorrectDataList() {
  const data = [
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 2,
    },
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 3,
    },
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 1,
    },
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 5,
    },
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 4,
    },
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 3,
    },
  ];

  return (
    <div className="incorrect-data-list-wrapper">
      <Row className="all-contacts">
        <Col className="contacts-col cms-contact-scrollbar">
          <List
            className={` contacts-list `}
            dataSource={data}
            renderItem={(item) => {
              const {
                firstName,
                middleName,
                lastName,
                photo,
                jobTitle,
                issues,
                id,
              } = item;
              const name =
                // eslint-disable-next-line prefer-template
                (firstName ?? "") +
                " " +
                (middleName ?? "") +
                " " +
                (lastName ?? "");
              const title = (
                <Text className="username cms-font-weight-medium font-inter">
                  {name?.trim()?.length ? name : "No Name"}
                </Text>
              );
              const description = (
                <Row className="description-row">
                  {jobTitle && (
                    <Col span={24} className="designation-col">
                      <Text type="secondary" className="designation">
                        {jobTitle}
                      </Text>
                    </Col>
                  )}
                  <Col>
                    <Tag icon={<IssuesIcon className="issues-icon" />}>
                      {issues} issues found
                    </Tag>
                  </Col>
                </Row>
              );
              return (
                <ContactCard
                  firstName={firstName}
                  photo={photo}
                  title={title}
                  description={description}
                  item={item}
                  size={56}
                  contactId={id}
                />
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default IncorrectDataList;
