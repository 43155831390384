import React from "react";
//! Antd import
import Row from "antd/lib/row";

//! User import
import { AddRow } from "../../../../../assets/svg";

const AddContactInfoRow = ({ text, handleRoute }) => (
  <Row
    className="contact-info-wrapper-info contact-info-wrapper-add-row"
    onClick={handleRoute}
  >
    <span className="contact-add-row">
      <AddRow />
    </span>
    <span>{text}</span>
  </Row>
);

export default AddContactInfoRow;
