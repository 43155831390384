import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription notification($id: Int!, $spaceId: Int!) {
    notification(id: $id, spaceId: $spaceId) {
      id
      message
      data
      type
      receiverId
      senderId
      createdAt
    }
  }
`;
