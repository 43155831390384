import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const UNSUBSCRIBE_GROUP_NOTIFICATION = gql`
  mutation unSubscribeGroupNotification($groupId: Int!) {
    unSubscribeGroupNotification(groupId: $groupId) {
      message
      status
    }
  }
`;
