/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { AddGroup, RemoveImageIcon } from "../../../assets/svg";
import Loading from "../Loading";
import { GENERATE_SIGNED_URL_FOR_PICTURE } from "../ContactForm/graphql/mutations/generateSignedUrlForPicture";
import { generateRandomColor, notificationToast } from "../../utils";
import UserAvatar from "../UserAvatar/UserAvatar";
import { AppContext } from "../../../AppContext";

function ImageUpload({
  textbelowIcon = false,
  profileImage: image,
  setProfileImage: setImage,
  existingImage,
  text,
  name,
  isProfilePage = false,
  disable,
  setImageLoading,
  imageLoading,
  onComplete,
  textWidthClass,
  setUpdateDisable,
  spaceClass,
  sharedUser,
}) {
  const [fileContent, setFileContent] = useState();
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const [generateSignedUrl] = useMutation(GENERATE_SIGNED_URL_FOR_PICTURE, {
    onCompleted({ generateSignedUrlForPicture }) {
      if (generateSignedUrlForPicture?.url) {
        const { signedRequest, url } = generateSignedUrlForPicture;
        const xhr = new XMLHttpRequest();

        xhr.open("PUT", signedRequest);
        xhr.setRequestHeader("Content-Type", fileContent?.type);
        xhr.setRequestHeader("x-amz-acl", "public-read");
        xhr.withCredentials = false;
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status !== 200) {
              notificationToast({
                message: "Image upload failed!",
                type: "error",
              });
            }
          }
        };
        xhr.send(fileContent);
        setTimeout(() => {
          setImageLoading(false);
          setImage(url);
          // eslint-disable-next-line no-unused-expressions
          onComplete && onComplete();
          // eslint-disable-next-line no-unused-expressions
          setUpdateDisable && setUpdateDisable(false);
        }, 2000);
      }
    },
    onError() {
      setImageLoading(false);
    },
  });
  useEffect(() => {
    const previousExistingImage = "";
    if (previousExistingImage !== existingImage) {
      setImage(existingImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingImage]);

  useEffect(() => {
    if (!existingImage) {
      setImage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingImage]);
  useEffect(() => {
    if (existingImage) {
      setImage(existingImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingImage]);

  const beforeUpload = (extension, size) => {
    const isJpgOrPng =
      extension === "jpeg" ||
      extension === "png" ||
      extension === "JPG" ||
      extension === "jpg";
    if (!isJpgOrPng) {
      notificationToast({
        message: "You can only upload JPG/PNG file!",
        type: "error",
      });
    }
    const isLt2M = size / 1024 / 1024 < 2;
    if (!isLt2M) {
      notificationToast({
        message: "Image must be smaller than 2MB!",
        type: "error",
      });
    }
    return isJpgOrPng && isLt2M;
  };
  // eslint-disable-next-line no-shadow
  const handleFileUpload = async (image) => {
    setFileContent(image);
    setImageLoading(true);
    const fileName = `test/${image?.name}`;
    const imgExt = image?.name?.split(".")?.pop();
    const canUpload = beforeUpload(imgExt, image?.size);

    if (canUpload) {
      await generateSignedUrl({
        variables: {
          data: {
            extension: image?.name.split(".").pop(),
            key: fileName,
          },
        },
      }).catch(() => {});
    } else {
      setImageLoading(false);
    }
  };

  const removeProfileImage = () => {
    setImage(null);
    // eslint-disable-next-line no-unused-expressions
    onComplete && onComplete();
    // eslint-disable-next-line no-unused-expressions
    setUpdateDisable && setUpdateDisable(false);
  };

  return (
    <div className="add-photo">
      <div className="upload-btn">
        {imageLoading ? (
          <span
            className={`${
              spaceClass ? "cms-space-profile-img" : ""
            } upload-btn-icon`}
          >
            <Loading />
          </span>
        ) : (
          <>
            {image && !disable && (
              <span
                className={`${
                  spaceClass ? "cms-space-remove-profile-btn" : ""
                } cms-remove-profile-btn cursor-pointer`}
                onClick={removeProfileImage}
              >
                <RemoveImageIcon />
              </span>
            )}
            {!image && !isProfilePage && !sharedUser && (
              <span className={spaceClass || "upload-btn-icon"}>
                <input
                  id="upload-file"
                  className={`input-file ${disable ? "cms-disable-field" : ""}`}
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  disabled={disable}
                />
                <AddGroup />
              </span>
            )}
            {image && (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label htmlFor="upload-file" className="image-label">
                <img
                  name="userProfile"
                  className={`${disable ? "cms-disable-field" : ""} ${
                    spaceClass ? "cms-space-profile-img" : ""
                  } upload-btn-icon`}
                  src={image}
                  alt="profile"
                />
              </label>
            )}
            {!image && (isProfilePage || sharedUser) && (
              // eslint-disable-next-line jsx-a11y/label-has-associated-control
              <label htmlFor="upload-file" className="image-label">
                <UserAvatar
                  className={`${
                    sharedUser ? "cms-space-profile-img" : ""
                  } upload-btn-icon cms-upload-btn-avatar`}
                  name={name?.[0]?.toUpperCase()}
                  color={generateRandomColor(parseInt(currentUser?.id, 10))}
                />
              </label>
            )}
            {!spaceClass && (
              <span className="upload-btn-photo-text">
                <input
                  id="upload-file"
                  className={`${textWidthClass || ""} input-file`}
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={(e) => handleFileUpload(e.target.files[0])}
                  disabled={disable}
                />
                {textbelowIcon && (
                  <span className="upload-btn-photo-text-item">{text}</span>
                )}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ImageUpload;
