import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";

//! Antd import
import { Col, Row, Layout } from "antd";

//! User files
import { FullLogoWhite } from "../../../assets/svg";
import { MOBILE_TAB_SIZE, ROUTES } from "../../../common/constants";
import OnBoardingImage from "../../../assets/images/onBoardingImage.png";
import { AppContext } from "../../../AppContext";

function AuthLayout({ children }) {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const history = useHistory();
  const year = new Date().getFullYear();
  const { width } = useWindowSize();
  const registeredUser =
    currentUser || JSON?.parse(localStorage?.getItem("user"));
  const isPaymentScreen = pathname?.includes(ROUTES.STRIPE_PAYMENT);

  useEffect(() => {
    if (localStorage.getItem("TOKEN") && registeredUser?.hasLoggedInBefore) {
      if (localStorage.getItem("currentSpace")) {
        history.push(
          `/contacts/${localStorage.getItem("currentSpace")}/group/all`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="auth-layout-container">
      {width <= MOBILE_TAB_SIZE ? (
        <div className="auth-layout-container-split">
          <div className="auth-layout-container-split-left">
            <div className="auth-layout-container-split-left-logo">
              <FullLogoWhite />
            </div>
            <div className="auth-layout-container-split-right">
              <Layout.Content className="card-layout">
                {children}
              </Layout.Content>
            </div>
            <div className="auth-layout-container-split-left-copyright">
              <div>@{year} ContactBook</div>
              <div
                onClick={() => {
                  window.location = "mailto:support@contactbook.app";
                }}
                className="need-help-text"
              >
                Need help?
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Row
          justify="end"
          align="middle"
          className="auth-layout-container-split"
        >
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className={`auth-layout-container-split-right ${
              isPaymentScreen && "padding-ltd-plan"
            }`}
          >
            <Layout.Content className="card-layout">{children}</Layout.Content>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="auth-layout-container-split-left"
          >
            <div className="auth-layout-container-split-left-logo">
              <FullLogoWhite />
            </div>
            <div className="auth-layout-container-split-left-onboardImage">
              <img src={OnBoardingImage} alt="On Boarding" />
            </div>
            <Row
              justify="space-between"
              className="auth-layout-container-split-left-copyright"
            >
              <Col>@{year} ContactBook</Col>
              <Col
                onClick={() => {
                  window.location = "mailto:support@contactbook.app";
                }}
                className="need-help-text"
              >
                Need help?
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default AuthLayout;
