import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

//! Antd imports
import Form from "antd/lib/form";
import { Skeleton } from "antd";

//! User files
import {
  AMPLITUDE_EVENT_LOG,
  UPGRADE_PLAN_TITLE,
  VISIBILITY,
} from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";
import {
  isPlanActive,
  createAmplitudeEvent,
} from "../../../../../common/utils";
import { SET_SPACE_PLAN_STATUS } from "../../../../../common/actionTypes";

//! Graphql files
import { GET_NOTES } from "../../../graphql/queries/getNotes";
import { ADD_NOTE } from "../../../graphql/mutations/addNote";
import { DELETE_NOTE } from "../../../graphql/mutations/deleteNote";
import ContactNotesList from "./ContactNotesList";

const ContactNotesContainer = ({ contactDetail, contactPermission }) => {
  const [visible, setVisible] = useState(false);
  const [notes, setNotes] = useState([]);
  const [visibility, setVisibility] = useState(VISIBILITY.PUBLIC);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const { contactId } = useParams();
  const {
    state: { userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);

  const openAddNoteModal = () => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      setVisible(!visible);
    } else {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: {
          planExpired: true,
          message: UPGRADE_PLAN_TITLE,
          spaceId: parseInt(contactDetail?.spaceId, 10),
        },
      });
    }
  };

  const { data: notesList } = useQuery(GET_NOTES, {
    fetchPolicy: "network-only",
    variables: {
      contactId: parseInt(contactId, 10),
    },
  });

  useEffect(() => {
    if (notesList?.getNotes?.data) {
      setNotes(notesList?.getNotes?.data?.notes);
      setLoading(false);
    }
  }, [notesList]);

  const [addNote, { loading: notesLoader }] = useMutation(ADD_NOTE, {
    refetchQueries: [
      {
        query: GET_NOTES,
        fetchPolicy: "network-only",
        variables: {
          contactId: parseInt(contactId, 10),
        },
      },
    ],
    onCompleted() {
      form.resetFields();
      setVisibility(VISIBILITY.PUBLIC);
      setVisible(false);
    },
    onError() {
      setVisible(false);
    },
  });

  const [deleteUserNote, { loading: deleteLoader }] = useMutation(DELETE_NOTE, {
    refetchQueries: [
      {
        query: GET_NOTES,
        fetchPolicy: "network-only",
        variables: {
          contactId: parseInt(contactId, 10),
        },
      },
    ],
    onCompleted() {
      setOpen(false);
    },
    onError() {
      setOpen(false);
    },
  });

  const addNotes = (values) => {
    const data = {
      note: values?.note?.trim(),
      visibility: visibility,
    };

    addNote({
      variables: { contactId: parseInt(contactId, 10), data },
    }).then((res) => {
      const eventProperties = {
        "Contact Id": contactId,
        "Added By": res?.data?.addNote?.data?.userId,
      };
      createAmplitudeEvent(AMPLITUDE_EVENT_LOG.NOTE_ADDED, eventProperties);
    });
  };

  const handleRemoveNote = (id) => {
    if (id) {
      deleteUserNote({
        variables: {
          noteId: id,
          contactId: parseInt(contactId, 10),
        },
      }).then((res) => {
        const eventProperties = {
          "Note Id": id,
          "Contact Id": contactId,
          "Removed By": res?.data?.removeNote?.data?.userId,
        };
        createAmplitudeEvent(AMPLITUDE_EVENT_LOG.NOTE_REMOVED, eventProperties);
      });
    }
  };

  return (
    <>
      {loading ? (
        <div className="skeleton-layout">
          <Skeleton active />
        </div>
      ) : (
        <div className="cms-contact-note-list cms-contact-scrollbar">
          <ContactNotesList
            openAddNoteModal={openAddNoteModal}
            visible={visible}
            notesList={notes}
            setVisible={setVisible}
            addNotes={addNotes}
            setVisibility={setVisibility}
            form={form}
            notesLoader={notesLoader}
            handleRemoveNote={handleRemoveNote}
            open={open}
            setOpen={setOpen}
            deleteLoader={deleteLoader}
            loading={loading}
            contactPermission={contactPermission}
          />
        </div>
      )}
    </>
  );
};

export default ContactNotesContainer;
