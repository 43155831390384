import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

//! Antd import
import Text from "antd/lib/typography/Text";

//! User files
import { Mail, Phone } from "../../../assets/svg";
import {
  generateRandomColor,
  getFullContactName,
  getNameTag,
  showContactDetail,
} from "../../utils";
import IconButton from "../IconButton/IconButton";
import UserAvatar from "../UserAvatar/UserAvatar";
import ContactCardButton from "../ContactCardButton/ContactCardButton";
import ReminderDateComponent from "../ReminderDateComponent/ReminderDateComponent";

const ReminderCard = ({ contact, setReminderId, reminderId }) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [dataType, setDataType] = useState();
  const { spaceId } = useParams();

  const handleConfirmation = () => {
    setOpen(!open);
  };

  const name = getFullContactName(
    contact?.firstName,
    contact?.middleName,
    contact?.lastName
  );

  const title = (
    <Text
      className="username cms-font-weight-medium font-inter"
      title={getNameTag(name, contact?.company)}
    >
      {getNameTag(name, contact?.company)}
    </Text>
  );

  const isSelected =
    pathname.includes(contact?.contactId) && reminderId === contact?.id;

  return (
    <>
      <div
        className={
          isSelected
            ? "selected-contact cms-reminder-card"
            : "cms-reminder-card"
        }
      >
        <div
          className="cms-reminder-card-top-section"
          onClick={() => {
            setReminderId(contact?.id);
            push(`/addOns/${spaceId}/reminders/${contact?.contactId}`);
          }}
        >
          <div className="cms-reminder-card-top-section-left">
            <UserAvatar
              className="cms-reminder-card-top-section-left-contact-avatar"
              name={contact?.firstName}
              color={generateRandomColor(1)}
              size={48}
              photo={contact?.photo}
            />
            <div className="cms-reminder-card-top-section-left-contact-info">
              {title}
              {(contact?.jobTitle ||
                contact?.company ||
                contact?.emails ||
                contact?.numbers) && (
                <Text
                  type="secondary"
                  className="designation cms-font-ellipsis"
                  title={showContactDetail(
                    contact?.jobTitle,
                    contact?.company,
                    contact?.emails,
                    contact?.numbers
                  )}
                >
                  {showContactDetail(
                    contact?.jobTitle,
                    contact?.company,
                    contact?.emails,
                    contact?.numbers
                  )}
                </Text>
              )}
            </div>
          </div>
          <div className="cms-reminder-card-top-section-right">
            {contact?.numbers?.length > 0 && (
              <IconButton
                iconClass="cms-icon-btn mr-12"
                icon={<Phone className="cms-common-svg setting-svg" />}
                shape="circle"
                handleClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setType("CALL");
                  setDataType(contact?.numbers);
                  handleConfirmation();
                }}
              />
            )}
            {contact?.emails?.length > 0 && (
              <IconButton
                iconClass="cms-icon-btn"
                icon={<Mail className="cms-common-svg setting-svg" />}
                shape="circle"
                handleClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setType("EMAIL");
                  setDataType(contact?.emails);
                  handleConfirmation();
                }}
              />
            )}
          </div>
        </div>
        <div className="cms-reminder-card-bottom-section">
          <div className="cms-reminder-card-bottom-section-reminder-info">
            <ReminderDateComponent
              reminderType={contact?.type}
              scheduleAt={contact?.scheduledAt}
              frequency={contact?.frequency}
            />
          </div>
          {/* //! May be use later */}
          {/* <div className="cms-reminder-card-bottom-section-reminder-action">
            <IconButton
              iconClass="cms-reminder-button mr-16"
              text={<span className="font-inter">Ignore</span>}
            />
            <IconButton
              iconClass="cms-reminder-button"
              text={
                <span className="font-inter cms-reminder-button-done">
                  Done
                </span>
              }
            />
          </div> */}
        </div>
      </div>
      <ContactCardButton
        title={title}
        data={dataType}
        visible={open}
        type={type}
        removeNote={handleConfirmation}
      />
    </>
  );
};

export default ReminderCard;
