import React, { useContext } from "react";

//! Antd imports
import Row from "antd/lib/row";
import Col from "antd/lib/col";

//! User import
import AddContactInfoRow from "./AddContactInfoRow";
import {
  capitalizeWord,
  getAddress,
  setHttps,
} from "../../../../../common/utils";
import { RemoveFieldIcon } from "../../../../../assets/svg";
import { AppContext } from "../../../../../AppContext";
import { VIEW } from "../../../../../common/constants";

const ContactMetaDetailsItem = ({
  icon,
  data,
  handleRoute,
  text,
  defaultValue,
  type,
  filterValueFromPreviewMerge,
  actualType,
  mergeContact,
  isEditable,
  isContactMerged,
}) => {
  const {
    state: { userGroupPermission },
  } = useContext(AppContext);

  const shouldRender = !isEditable
    ? !(data?.length === 0 || data === null)
    : true;

  return (
    shouldRender && (
      <div className="contact-info-mobile">
        <div className="contact-info-mobile-phone">{icon}</div>
        <div className="contact-info-mobile-number">
          {data?.length > 0
            ? data?.map((info, i) =>
                // eslint-disable-next-line no-nested-ternary
                type !== "Address" ? (
                  info?.value ? (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="contact-info-wrapper" key={i}>
                      <Row
                        className={`${
                          info?.new ? "cms-highlight-text" : ""
                        } contact-info-wrapper-info`}
                      >
                        <Col
                          span={4}
                          className="contact-info-wrapper-text"
                          title={
                            capitalizeWord(info?.type?.toLowerCase()) ??
                            defaultValue
                          }
                        >
                          {capitalizeWord(info?.type?.toLowerCase()) ??
                            defaultValue}
                        </Col>
                        <Col span={16} className="cms-font-ellipsis w-100-px">
                          {type === "Website" || type === "SocialProfile" ? (
                            <a
                              href={setHttps(info?.value.trim())}
                              target="_blank"
                              rel="noreferrer"
                              title={info?.value}
                            >
                              {info?.value}
                            </a>
                          ) : (
                            info?.value
                          )}
                        </Col>
                        {info?.new && !isContactMerged && (
                          <Col span={4} className="remove-merge-contact-field">
                            <RemoveFieldIcon
                              onClick={() =>
                                filterValueFromPreviewMerge(
                                  info?.id,
                                  actualType,
                                  info?.value
                                )
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  ) : (
                    !mergeContact &&
                    userGroupPermission !== VIEW && (
                      <AddContactInfoRow
                        text={text}
                        handleRoute={handleRoute}
                      />
                    )
                  )
                ) : info?.line1 ||
                  info?.line2 ||
                  info?.city ||
                  info?.zipCode ||
                  info?.state ||
                  info?.country ? (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="contact-info-wrapper" key={i}>
                    <Row
                      className={`${
                        info?.new ? "cms-highlight-text" : ""
                      } contact-info-wrapper-info`}
                    >
                      <Col
                        span={4}
                        className="contact-info-wrapper-text"
                        title={capitalizeWord(info?.label) ?? "Home"}
                      >
                        {capitalizeWord(info?.label?.toLowerCase()) ?? "Home"}
                      </Col>
                      <Col span={16} className="cms-font-ellipsis w-100-px">
                        <a
                          href={`http://maps.google.com/?q=${getAddress(info)}`}
                          target="_blank"
                          rel="noreferrer"
                          title={getAddress(info)}
                        >
                          {getAddress(info)}
                        </a>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  !mergeContact &&
                  userGroupPermission !== VIEW &&
                  i === 0 && (
                    <AddContactInfoRow text={text} handleRoute={handleRoute} />
                  )
                )
              )
            : !mergeContact &&
              userGroupPermission !== VIEW && (
                <AddContactInfoRow text={text} handleRoute={handleRoute} />
              )}
          {/* {type !== "SocialProfile" && (
            <div className="contact-info-mobile-border" />
          )} */}
        </div>
      </div>
    )
  );
};

export default ContactMetaDetailsItem;
