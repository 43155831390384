import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_BASIC_SPACE_INFO = gql`
  query spaceInfo($id: Int!) {
    spaceInfo(id: $id) {
      data {
        id
        ownerId
        permission
      }
    }
  }
`;
