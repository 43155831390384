import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const DOWNLOAD_ATTACHMENT = gql`
  mutation downloadAttachment($contactId: Int!, $key: String!) {
    downloadAttachment(contactId: $contactId, key: $key) {
      signedUrl
    }
  }
`;
