import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_REMINDER_SETTING = gql`
  mutation updateReminderSettings($data: UpdateReminderSettingsInput!) {
    updateReminderSettings(data: $data) {
      message
      status
    }
  }
`;
