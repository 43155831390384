import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { message, Progress } from "antd";
import { AppContext } from "../../../../../AppContext";
import { AMPLITUDE_EVENT_LOG } from "../../../../../common/constants";
import { createAmplitudeEvent } from "../../../../../common/utils";

function UploadProgress({ jobId, setFile }) {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [status, setStatus] = useState("PENDING");
  const [totalCount, setTotalCount] = useState(0);
  const [percent, setPercent] = useState(0);
  const { spaceId } = useParams();
  useEffect(() => {
    let testStatus;
    const timer = window.setInterval(() => {
      if (jobId && testStatus !== "COMPLETED") {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_SERVER_REST_URL}/status/${jobId}`,
          params: { userId: currentUser?.id, spaceId },
        }).then((res) => {
          setStatus(res?.data?.importStatus);
          if (res?.data?.importStatus === "COMPLETED") {
            testStatus = "COMPLETED";
            setFile();
            message.success("Contacts imported successfully");
          }
          if (res?.data?.contactCount && res?.data?.successCount) {
            setTotalCount(res?.data?.contactCount);
            const {
              data: { contactCount, successCount },
            } = res;
            setPercent((successCount * 100) / contactCount);
          }
        });
      }
    }, 1000);
    if (status === "COMPLETED") {
      window.clearInterval(timer);
      const eventProperties = {
        "Space Id": spaceId,
        "Total contacts": totalCount,
      };
      createAmplitudeEvent(
        AMPLITUDE_EVENT_LOG.CONTACTS_IMPORTED,
        eventProperties
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="progress-circle">
      <Progress
        type="circle"
        percent={percent}
        width={25}
        strokeWidth={8}
        strokeColor="#53A1FE"
        className="mr-4"
      />
      <span className="progress-circle-text">Importing Contacts</span>
    </div>
  );
}

export default UploadProgress;
