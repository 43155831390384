import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const CHANGE_GROUP_OWNER = gql`
  mutation changeGroupOwner($id: Int!, $userId: Int!) {
    changeGroupOwner(id: $id, userId: $userId) {
      message
      status
    }
  }
`;
