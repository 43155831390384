import React, { useContext, useEffect, useState, useMemo } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Prompt, useHistory } from "react-router-dom";
import countryList from "react-select-country-list";
import get from "lodash/get";
import moment from "moment";
import isEqual from "lodash/isEqual";

//! Ant imports
import Form, { useForm } from "antd/lib/form/Form";
import Input from "antd/lib/input";
import {
  Row,
  Col,
  Button,
  Divider,
  Checkbox,
  Radio,
  DatePicker,
  Select,
} from "antd";
import Modal from "antd/lib/modal/Modal";

//! Graphql files
import { CURRENT_USER } from "../../../../auth/graphql/Queries";
import { UPDATE_PROFILE } from "../../../graphql/mutations/updateProfile";
import { DELETE_ACCOUNT } from "../../../graphql/queries/deleteAccount";

//! User files
import { DustbinIcon, SignOutIcon } from "../../../../../assets/svg";
import ImageUpload from "../../../../../common/components/ImageUpload/ImageUpload";
import Loading from "../../../../../common/components/Loading";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";
import { ROUTES } from "../../../../../common/constants";
import Error404 from "../../../../../Error404";
import {
  capitalizeWord,
  formValidatorRules,
} from "../../../../../common/utils";
import { AppContext } from "../../../../../AppContext";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import ChangePassword from "./ChangePassword";

const { phoneNumber, required } = formValidatorRules;
const { Option } = Select;
const UserProfile = () => {
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [updateProfile, { loading: updating }] = useMutation(UPDATE_PROFILE, {
    refetchQueries: [{ query: CURRENT_USER }],
  });
  const [photo, setPhoto] = useState();
  const [imageLoading, setImageLoading] = useState(false);
  const [initial, setInitial] = useState({});
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const [consent, setConsent] = useState(false);
  const [updateDisable, setUpdateDisable] = useState(true);
  const [isPromptRequired, setIsPromptRequired] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [errorMsg, showErrorMsg] = useState(false);
  const {
    state: { currentUser, userSubscriptionInfo },
  } = useContext(AppContext);
  const { push } = useHistory();
  const [deleteAccount, { loading: deleteAccountLoader }] = useLazyQuery(
    DELETE_ACCOUNT,
    {
      onCompleted() {
        setOpen(!open);
        setTimeout(() => {
          push(ROUTES.LOGOUT);
        }, 1000);
      },
      onError() {},
    }
  );
  const { data, loading, error } = useQuery(CURRENT_USER);
  const { getProfile } = data;

  useEffect(() => {
    if (getProfile) {
      const initialValuesObject = {
        ...getProfile,
        birthday: getProfile?.birthday ? moment(getProfile?.birthday) : null,
      };
      const parsedData = JSON.parse(
        JSON.stringify(initialValuesObject),
        (key, value) => {
          if (
            key !== "__typename" &&
            key !== "spaceId" &&
            key !== "hasLoggedInBefore" &&
            key !== "id" &&
            key !== "isConsentGiven" &&
            key !== "isDisabled" &&
            key !== "provider" &&
            key !== "role" &&
            key !== "emailVerified" &&
            key !== "photo"
          ) {
            if (key === "birthday") {
              return value ? moment(value) : "";
            }
            return value;
          }
        }
      );
      setInitial(parsedData);
    }
  }, [getProfile]);

  useEffect(() => {
    form.resetFields();
  }, [form, initial]);

  if (error) return <Error404 />;

  const onFinish = (e) => {
    const {
      name,
      email,
      phone,
      city,
      country,
      designation,
      company,
      gender,
      birthday,
    } = e;
    updateProfile({
      variables: {
        name,
        email,
        photo,
        phone,
        city,
        country,
        designation,
        company,
        gender,
        birthday: birthday || null,
      },
    })
      .then(() => {
        setIsPromptRequired(false);
      })
      .catch(() => {});
  };

  const handleAccountDelete = () => {
    if (consent) {
      deleteAccount();
      showErrorMsg(false);
    } else {
      showErrorMsg(true);
    }
  };

  const popupText = (
    <>
      <span className="popup-text-title font-inter">
        Are you sure you want to delete your account?
      </span>
      {userSubscriptionInfo?.membersCount > 1 &&
        userSubscriptionInfo?.ownerId === parseInt(currentUser?.id, 10) &&
        parseInt(userSubscriptionInfo?.id, 10) === currentUser?.spaceId && (
          <div className="popup-text-content">
            {
              //! Will remove after testing
              /* <span className="popup-text-content-body">
            You have created&nbsp;
            {converter.toWords(
              filter(
                spaces,
                (e) => e?.ownerId === parseInt(currentUser?.id, 10)
              )?.length
            )}{" "}
            additional space.
          </span>
          <div className="space-tags-container flex-wrap">
            {spaces?.map((space) => {
              return (
                parseInt(currentUser?.id, 10) === space?.ownerId && (
                  <div key={space?.id} className="space-tag-box mb-4">
                    <SpaceTag
                      spaceName={space?.name}
                      spaceLogo={space?.photo}
                      spaceId={space?.id}
                    />
                  </div>
                )
              );
            })}
          </div> */
            }
            <span className="popup-text-content-body">
              You must remove shared members from all Spaces to delete your
              account.
            </span>
          </div>
        )}
      <div className="popup-text-content-checkbox">
        <Checkbox
          onChange={(e) => {
            setConsent(e.target.checked);
          }}
        >
          This is non-reversible action, I understand & I'll not able to recover
          this data
        </Checkbox>
        {errorMsg && <span className="color-red">Consent is required</span>}
      </div>
    </>
  );

  const handleConfirmation = () => {
    setOpen(!open);
  };

  //! hiding for MVP as it is not needed
  // const handleChange = (e) => {
  //   if (selectedValue.length < 2) {
  //     setSelectedValue([...selectedValue, e.target.value]);
  //   } else {
  //     selectedValue.shift();
  //     setSelectedValue([...selectedValue, e.target.value]);
  //   }
  // };

  const disabledDate = (current) => {
    //! Can not select days before today
    return current > moment().endOf("day");
  };

  const handleValuesChange = (changedValues, allValues) => {
    const field = Object.keys(changedValues)[0];
    const fieldValue = get(initial, `${field}`);

    if (
      (fieldValue === null && changedValues[field]?.length === 0) ||
      isEqual(initial, allValues)
    ) {
      setUpdateDisable(true);
      setIsPromptRequired(false);
    } else {
      setUpdateDisable(false);
      setIsPromptRequired(true);
    }
  };

  const handleChangePasswordModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <div className="space-detail">
      <Prompt
        message="You have unsaved changes! Are you sure you want to leave this page?"
        when={isPromptRequired}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="space-detail-profile-form">
            <Form
              form={form}
              layout="vertical"
              initialValues={initial || ""}
              onFinish={onFinish}
              onValuesChange={handleValuesChange}
            >
              <Row align="middle" className="space-detail-profile-form-head">
                <ImageUpload
                  onComplete={() => {
                    setUpdateDisable(false);
                  }}
                  imageLoading={imageLoading}
                  setImageLoading={setImageLoading}
                  textbelowIcon
                  text="Update Profile Image"
                  setProfileImage={setPhoto}
                  profileImage={photo}
                  name={currentUser?.name}
                  existingImage={getProfile?.photo}
                  isProfilePage
                />
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="name" label="Name" rules={[required]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    label={
                      <Row justify="space-between" className="label-width">
                        <Col>Email</Col>
                        <Col>
                          {currentUser?.provider === "EMAIL" ? (
                            <div
                              onClick={handleChangePasswordModal}
                              className="cursor-pointer cms-change-pwd-btn"
                            >
                              Change password
                            </div>
                          ) : (
                            <>
                              <span>You've signed up via</span>
                              <span className="cms-provider-text">
                                {" "}
                                {capitalizeWord(
                                  currentUser?.provider?.toLowerCase()
                                )}
                              </span>
                            </>
                          )}
                        </Col>
                      </Row>
                    }
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="company" label="Company">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="designation" label="Designation">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="phone" label="Phone" rules={[phoneNumber]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="birthday" label="Birth date">
                    <DatePicker
                      allowClear
                      format="MM-DD-YYYY"
                      placeholder=""
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="gender" label="Gender">
                <Radio.Group className="radio-button">
                  <Radio value="MALE">Male</Radio>
                  <Radio value="FEMALE">Female</Radio>
                </Radio.Group>
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="city" label="City">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12} id="cms-country-list">
                  <Form.Item name="country" label="Country">
                    <Select
                      getPopupContainer={() =>
                        document.getElementById("cms-country-list")
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      showSearch
                    >
                      {countryOptions.map((country) => (
                        <Option value={country?.value} key={country?.value}>
                          {country?.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <div className="justify-end">
                <IconButton
                  iconClass="space-detail-profile-form-button"
                  text="Update"
                  htmlType="submit"
                  type="primary"
                  loading={updating}
                  disable={imageLoading || updateDisable}
                />
              </div>
            </Form>
            <Divider className="divider" />
            {/* hiding this component for MVP  */}
            {/* <div>
              <div className="user-contact-card-option">
                <div className="user-contact-wrapper">
                  <div className="user-contact-card-div">
                    <div className="user-avatar-circle"></div>
                    <div className="user-info-div">
                      <div className="user-name-div"></div>
                      <div className="user-post-div"></div>
                    </div>
                  </div>
                  <div className="user-select-option">
                    <span className="user-phone-option user-mRight"></span>
                    <span className="user-phone-option"></span>
                  </div>
                </div>
                <div className="user-contact-tag-wrapper">
                  <span className="user-contact-tag tag-mRight"></span>
                  <span className="user-contact-tag tag-mRight"></span>
                  <span className="user-contact-tag"></span>
                </div>
              </div>
              <div className="user-set-contact-option">
                <span className="user-set-contact-option-title font-inter">
                  Set contact card buttons
                </span>
                <span className="user-set-contact-option-sub-title">
                  Select any two options which you want to see on contact cards
                </span>
                <div className="user-select-option">
                  {CONTACT_CARD_OPTION?.map((option, key) => (
                    <Radio
                      onChange={handleChange}
                      value={option?.value}
                      checked={selectedValue.includes(option?.value)}
                    >
                      <Tag className="user-option-radio" icon={option?.icon}>
                        <span className="user-option-name">
                          {option?.label}
                        </span>
                      </Tag>
                    </Radio>
                  ))}
                </div>
                <IconButton iconClass="user-choice-save-btn" text={SAVE} />
              </div>
            </div>
            <Divider className="divider" /> */}
            <div className="cms-profile-action-btn">
              <IconButton
                iconClass="font-inter cms-logout-btn mr-16"
                icon={<DustbinIcon />}
                text={
                  <span className="font-inter cms-logout-text">
                    Delete Account
                  </span>
                }
                handleClick={handleConfirmation}
              />
              <Button
                className="font-inter delete-button"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  if (window.confirm("Are you sure you want to logout?")) {
                    push(`${ROUTES.LOGOUT}`);
                  }
                }}
              >
                <SignOutIcon className="delete-button-icon" />
                <span className="font-inter cms-delete-btn">Logout</span>
              </Button>
            </div>
            {
              //! Will be using this later on
              /* <div className="warning">
              <div className="warning-box">
                <Warning className="warning-icon" />
                <Typography.Text className="warning-message">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </Typography.Text>
              </div>
            </div> */
            }
          </div>
          <RemovePopup
            content={popupText}
            okText="Remove"
            visible={open}
            removeNote={handleConfirmation}
            handleRemove={handleAccountDelete}
            deleteLoader={deleteAccountLoader}
            maskClosable={false}
          />
          <Modal
            visible={openModal}
            footer={null}
            onCancel={handleChangePasswordModal}
            centered
          >
            <ChangePassword
              handleChangePasswordModal={handleChangePasswordModal}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default UserProfile;
