import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import filter from "lodash/filter";

//! Ant Imports

import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Collapse from "antd/lib/collapse";
import { useWindowSize } from "react-use";
import Text from "antd/lib/typography/Text";

//! User files
import { AppContext } from "../../../../../AppContext";
import { SET_SPACE_PLAN_STATUS } from "../../../../../common/actionTypes";
import {
  ROUTES,
  UPGRADE_PLAN_TITLE,
  WINDOW_SIZE,
} from "../../../../../common/constants";
import { isAddressNull, isPlanActive } from "../../../../../common/utils";
import ContactMetaDetailItemFields from "../../../../../common/components/ContactMetaDetailItemFields/ContactMetaDetailItemFields";
import { DownArrowIcon } from "../../../../../assets/svg";

const { Panel } = Collapse;

function ContactMetaDetails({
  contactDetail,
  mergeContact,
  filterValueFromPreviewMerge,
  isContactMerged,
}) {
  const [collapsed, setCollapsed] = useState(true);
  const { push, goBack } = useHistory();
  const { contactId, spaceId } = useParams();
  const { width } = useWindowSize();
  const groupOrTag = useParams()?.[0]?.split("/");
  const {
    dispatch,
    state: { userSubscriptionInfo },
  } = useContext(AppContext);

  const {
    numbers,
    emails,
    birthday,
    anniversary,
    websites,
    contactAddresses,
    socialProfiles,
    isEditable,
    reminders,
    notes,
  } = contactDetail;

  const handleCollapse = () => {
    if (!mergeContact) {
      if (collapsed) {
        push("?view=collapse");
      } else {
        goBack();
      }
      setCollapsed(!collapsed);
    }
  };
  const header = (
    <Text className="font-inter cms-noselect">
      {collapsed ? "View less" : "View more"}
    </Text>
  );

  const noDetails =
    (numbers?.length === 0 || numbers === null) &&
    (emails?.length === 0 || emails === null) &&
    (notes?.length === 0 || notes === null) &&
    (birthday === null || birthday?.length === 0 || birthday?.value === null) &&
    (anniversary === null ||
      anniversary?.length === 0 ||
      anniversary?.value === null) &&
    (websites?.length === 0 || websites === null) &&
    (socialProfiles?.length === 0 || socialProfiles === null) &&
    isAddressNull(contactAddresses);

  const handleRoute = (type) => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      if (groupOrTag) {
        push(
          `/contacts/${spaceId}/${groupOrTag[0]}/${groupOrTag[1]}/contact/${contactId}/edit?field=${type}`
        );
      } else {
        // eslint-disable-next-line no-lonely-if
        if (width <= WINDOW_SIZE) {
          push(
            `${ROUTES?.CONTACTS}/${spaceId}/all/${contactId}/edit?field=${type}`
          );
        } else {
          push(`${ROUTES?.CONTACTS}/all/${contactId}/edit?field=${type}`);
        }
      }
    } else {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: {
          planExpired: true,
          message: UPGRADE_PLAN_TITLE,
          spaceId: parseInt(contactDetail?.spaceId, 10),
        },
      });
    }
  };

  return (
    <Row
      className={`${
        noDetails && !isEditable ? "cms-user-info-meta-box" : ""
      } contact-user-more-info-wrapper`}
    >
      <Col span={24} className="contact-user-more-info-col">
        {mergeContact ? (
          <div className="contact-info-collapse">
            <div className="contact-info-collapse-panel cms-merge-contact-meta-detail">
              <ContactMetaDetailItemFields
                numbers={filter(numbers, (n) => n)}
                emails={filter(emails, (n) => n)}
                websites={filter(websites, (n) => n)}
                contactAddresses={filter(contactAddresses, (n) => n)}
                birthday={birthday}
                anniversary={anniversary}
                handleRoute={handleRoute}
                mergeContact={mergeContact}
                filterValueFromPreviewMerge={filterValueFromPreviewMerge}
                socialProfiles={filter(socialProfiles, (n) => n)}
                isEditable={isEditable}
                notes={notes}
                isContactMerged={isContactMerged}
              />
            </div>
          </div>
        ) : (
          !noDetails && (
            <Collapse
              ghost
              onChange={handleCollapse}
              className="contact-info-collapse"
              expandIcon={({ isActive }) => (
                <DownArrowIcon rotate={isActive ? 180 : 0} />
              )}
              activeKey={collapsed ? "1" : "0"}
            >
              <Panel
                key="1"
                header={header}
                className={`${
                  noDetails && mergeContact ? "no-contact-detail" : ""
                } contact-info-collapse-panel`}
              >
                <ContactMetaDetailItemFields
                  numbers={filter(numbers, (n) => n)}
                  emails={filter(emails, (n) => n)}
                  websites={filter(websites, (n) => n)}
                  contactAddresses={filter(contactAddresses, (n) => n)}
                  birthday={birthday}
                  anniversary={anniversary}
                  socialProfiles={filter(socialProfiles, (n) => n)}
                  handleRoute={handleRoute}
                  isEditable={isEditable}
                  reminders={reminders}
                  notes={notes}
                />
              </Panel>
            </Collapse>
          )
        )}
      </Col>
    </Row>
  );
}

export default ContactMetaDetails;
