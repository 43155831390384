import React, { useContext } from "react";
import moment from "moment";
import difference from "lodash/difference";
import cloneDeep from "lodash/cloneDeep";

//! Antd import
import Tag from "antd/lib/tag";
import Divider from "antd/lib/divider";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";

//! User files
import {
  capitalizeWord,
  generateRandomColor,
  getFullContactName,
  getIcon,
  getNotificationCreatedTime,
} from "../../../../../common/utils";
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import {
  DropdownIcon,
  NoteFolderIcon,
  ReminderIcon,
  TagIcon,
} from "../../../../../assets/svg";
import {
  ANNIVERSARY,
  BIRTHDAY,
  NOTIFICATION_TYPE,
  REMINDER_MENU_LISTING,
} from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";
import NotificationLink from "../../../../../common/components/NotificationLink";

const NotificationListItemCard = ({ notification, closeDrawer }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const item = notification?.data;

  const getTagInfo = (data1, data2) => {
    return difference(data1, data2);
  };

  const menu = (items) => {
    const contact = cloneDeep(items);
    contact.name = getFullContactName(
      items?.firstName,
      items?.middleName,
      items?.lastName
    );

    return (
      <Menu>
        {Object?.entries(contact)?.map(
          ([key, value], i) =>
            key !== "updatedAt" &&
            key !== "tags" &&
            key !== "groupIds" &&
            key !== "sourceType" &&
            key !== "firstName" &&
            key !== "middleName" &&
            key !== "lastName" && (
              <Menu.Item
                className={`${
                  key === "photo" || key === "addressCount"
                    ? "cms-order1"
                    : "cms-order-1"
                } cms-log-field`}
                // eslint-disable-next-line react/no-array-index-key
                key={i}
              >
                {key === "name" && value?.trim()?.length > 0 && (
                  <>
                    <span className="cms-log-field-name">Name updated</span>
                    <span className="cms-log-field-value" title={value}>
                      {value}
                    </span>
                  </>
                )}
                {(key === "emails" ||
                  key === "numbers" ||
                  key === "websites" ||
                  key === "socialProfiles") && (
                  <>
                    <span className="cms-log-field-name">
                      {key === "socialProfiles"
                        ? "Social Profile"
                        : capitalizeWord(key?.toLowerCase())}{" "}
                      updated
                    </span>
                    <div className="cms-log-field-name-multiple">
                      {value?.map((val) => (
                        <span
                          className={`${
                            value?.length > 1
                              ? "cms-log-field-name-multiple-value"
                              : ""
                          } cms-log-field-value`}
                          key={val?.value}
                        >
                          {val?.value}
                        </span>
                      ))}
                    </div>
                  </>
                )}
                {(key === "jobTitle" || key === "company") && (
                  <>
                    <span className="cms-log-field-name">
                      {capitalizeWord(key?.toLowerCase())} updated
                    </span>
                    <span className="cms-log-field-value">{value}</span>
                  </>
                )}
                {(key === "anniversary" || key === "birthday") && (
                  <>
                    <span className="cms-log-field-name">
                      {capitalizeWord(key?.toLowerCase())} updated
                    </span>
                    <span className="cms-log-field-value">
                      {moment(value).format("DD-MM-YYYY")}
                    </span>
                  </>
                )}
                {key === "addressCount" && value > 0 && (
                  <span className="cms-log-field-name">
                    Contact address updated
                  </span>
                )}
                {key === "photo" && (
                  <span className="cms-log-field-name">
                    Profile photo updated
                  </span>
                )}
              </Menu.Item>
            )
        )}
      </Menu>
    );
  };

  return (
    <>
      {item?.key === NOTIFICATION_TYPE?.GROUP_SHARE && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <NotificationLink
                    link={`/contacts/${item?.group?.spaceId}/group/${item?.group?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={<NoteFolderIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                      title={item?.group?.name}
                    >
                      <span className="cms-user-name cms-long-name-ellipsis">
                        {item?.group?.name}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-space-right">shared with</span>
                  {parseInt(currentUser?.id, 10) === item?.sharedWith?.id ? (
                    <span className="cms-text-space">you</span>
                  ) : (
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.sharedWith?.name}
                          color={generateRandomColor(
                            parseInt(item?.sharedWith?.id, 10)
                          )}
                          photo={item?.sharedWith?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={item?.sharedWith?.name}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(item?.sharedWith?.name)}
                      </span>
                    </Tag>
                  )}
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.NEW_GROUP && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <NotificationLink
                    link={`/contacts/${item?.group?.spaceId}/group/${item?.group?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={<NoteFolderIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                      title={item?.group?.name}
                    >
                      <span className="cms-user-name cms-long-name-ellipsis">
                        {item?.group?.name}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-space-right">added in</span>
                  <NotificationLink
                    link={`/my-profile/${item?.space?.id}/space-setting`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.space?.name}
                          color={generateRandomColor(
                            parseInt(item?.space?.id, 10)
                          )}
                          photo={item?.space?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={item?.space?.name}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(item?.space?.name)}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.GROUP_UPDATE && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <NotificationLink
                    link={`/contacts/${item?.group?.spaceId}/group/${item?.group?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={<NoteFolderIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                      title={item?.group?.name}
                    >
                      <span className="cms-user-name cms-font-ellipsis cms-long-name-ellipsis">
                        {item?.group?.name}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span>updated by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.GROUP_REVOKE && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <Tag
                    icon={
                      <UserAvatar
                        className="cms-notification-avatar"
                        name={item?.removedUser?.name}
                        color={generateRandomColor(
                          parseInt(item?.removedUser?.id, 10)
                        )}
                        photo={item?.removedUser?.photo}
                      />
                    }
                    className="cms-user-tag"
                    title={item?.removedUser?.name}
                  >
                    <span className="cms-user-name">
                      {capitalizeWord(item?.removedUser?.name)}
                    </span>
                  </Tag>
                  <span className="cms-space-right">removed from</span>
                  <NotificationLink
                    link={`/contacts/${item?.group?.spaceId}/group/${item?.group?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={<NoteFolderIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                      title={item?.group?.name}
                    >
                      <span className="cms-user-name cms-long-name-ellipsis">
                        {item?.group?.name}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.GROUP_REMOVE && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <NotificationLink
                    link={`/contacts/${item?.group?.spaceId}/group/${item?.group?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={<NoteFolderIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                      title={item?.group?.name}
                    >
                      <span className="cms-user-name cms-long-name-ellipsis">
                        {item?.group?.name}
                      </span>
                    </Tag>
                  </NotificationLink>

                  <span className="cms-text-space">removed by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.CONTACT_REMOVED && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <Tag
                    icon={
                      <UserAvatar
                        className="cms-notification-avatar"
                        name={item?.contact?.firstName}
                        color={generateRandomColor(
                          parseInt(item?.contact?.id, 10)
                        )}
                        photo={item?.contact?.photo}
                      />
                    }
                    className="cms-user-tag"
                    title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                  >
                    <span className="cms-user-name">
                      {capitalizeWord(
                        `${item?.contact?.firstName} ${item?.contact?.lastName}`
                      )}
                    </span>
                  </Tag>
                  <span className="cms-space-right">removed from</span>
                  {item?.groups?.map((group, i) => (
                    <NotificationLink
                      link={`/contacts/${group?.spaceId}/group/${group?.id}`}
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      closeDrawer={closeDrawer}
                    >
                      <Tag
                        icon={<NoteFolderIcon className="cms-group-folder" />}
                        className="cms-group-tag"
                        title={group?.name}
                      >
                        <span className="cms-user-name">{group?.name}</span>
                      </Tag>
                    </NotificationLink>
                  ))}
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.NEW_CONTACT && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          `${item?.contact?.firstName} ${item?.contact?.lastName}`
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">added in</span>
                  {item?.groups?.map((group) => (
                    <NotificationLink
                      link={`/contacts/${group?.spaceId}/group/${group?.id}`}
                      closeDrawer={closeDrawer}
                      key={group?.id}
                    >
                      <Tag
                        icon={<NoteFolderIcon className="cms-group-folder" />}
                        className="cms-group-tag"
                        title={group?.name}
                      >
                        <span className="cms-user-name">{group?.name}</span>
                      </Tag>
                    </NotificationLink>
                  ))}
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.NEW_ATTACHMENT && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}?tab=documents`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={getIcon(item?.attachment?.type)}
                      className="cms-group-tag"
                      title={item?.attachment?.name}
                    >
                      <span className="cms-user-name">
                        {item?.attachment?.name}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-space-right">is added to</span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-space-right">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.ATTACHMENT_REMOVED && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}?tab=documents`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={getIcon(item?.attachment?.type)}
                      className="cms-group-tag"
                      title={item?.attachment?.name}
                    >
                      <span className="cms-user-name">
                        {item?.attachment?.name}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-space-right">is removed from</span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                          title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                        />
                      }
                      className="cms-user-tag"
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.NEW_NOTE && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <span className="cms-space-right">A note added to</span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.NOTE_REMOVED && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <span className="cms-space-right">A note removed from</span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.SPACE_SHARE && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <span className="cms-space-right">
                    The space has been shared with
                  </span>
                  {parseInt(currentUser?.id, 10) === item?.sharedWith?.id ? (
                    <span className="cms-text-space">you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {item?.sharedWith?.name}
                    </span>
                  )}
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.SPACE_REVOKE && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <span className="cms-user-name cms-space-user-name">
                    {item?.removedUser?.name}
                  </span>

                  <span className="cms-space-right">
                    removed from the space
                  </span>

                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.CONTACT_UPDATE && (
        <>
          <div className="cms-notification-card">
            {notification?.recent && (
              <div className="cms-recent-notification" />
            )}
            <div className="cms-notification-detail">
              <div className="cms-notification-card-info" id="contactUpdate">
                <span className="cms-user-info">
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-space-right">was updated by</span>
                  <span>
                    {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                      <span>you</span>
                    ) : (
                      <span className="cms-user-name cms-space-user-name">
                        {
                          // eslint-disable-next-line no-nested-ternary
                          item?.user?.name ? (
                            item.user.name.includes("@") ? (
                              <>{item.user.name}</>
                            ) : (
                              <>{capitalizeWord(item.user.name)}</>
                            )
                          ) : (
                            item?.user?.email
                          )
                        }
                      </span>
                    )}
                  </span>
                </span>
                <Dropdown
                  overlay={menu(item?.contact?.newData)}
                  trigger={["click"]}
                  placement="bottomRight"
                  className="cms-user-detail-dropdown"
                  getPopupContainer={() =>
                    document.getElementById("contactUpdate")
                  }
                >
                  <DropdownIcon />
                </Dropdown>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.CONTACT_GROUP_ADDED && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  {item?.contact?.newData?.map((group) => (
                    <NotificationLink
                      link={`/contacts/${group?.spaceId}/group/${group?.id}`}
                      closeDrawer={closeDrawer}
                      key={group?.name}
                    >
                      <Tag
                        icon={<NoteFolderIcon className="cms-group-folder" />}
                        className="cms-group-tag"
                        title={group?.name}
                      >
                        <span className="cms-user-name">{group?.name}</span>
                      </Tag>
                    </NotificationLink>
                  ))}
                  <span className="cms-space-right">added to</span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name cms-space-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.CONTACT_GROUP_REMOVED && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  {item?.contact?.oldData?.map((group) => (
                    <NotificationLink
                      link={`/contacts/${group?.spaceId}/group/${group?.id}`}
                      closeDrawer={closeDrawer}
                      key={group?.name}
                    >
                      <Tag
                        icon={<NoteFolderIcon className="cms-group-folder" />}
                        className="cms-group-tag"
                        title={group?.name}
                      >
                        <span className="cms-user-name">{group?.name}</span>
                      </Tag>
                    </NotificationLink>
                  ))}
                  <span className="cms-space-right">removed from</span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.NEW_TAG && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  {item?.contact?.newData?.map((tag) => (
                    <Tag
                      icon={<TagIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                      title={tag}
                      key={tag}
                    >
                      <span className="cms-user-name">{tag}</span>
                    </Tag>
                  ))}
                  <span className="cms-space-right">added to</span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {item?.key === NOTIFICATION_TYPE?.TAG_REMOVED && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  {getTagInfo(
                    item?.contact?.oldData,
                    item?.contact?.newData
                  )?.map((tag) => (
                    <Tag
                      icon={<TagIcon className="cms-group-folder" />}
                      className="cms-group-tag"
                      title={tag}
                      key={tag}
                    >
                      <span className="cms-user-name">{tag}</span>
                    </Tag>
                  ))}
                  <span className="cms-space-right">removed from</span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                  <span className="cms-text-space">by</span>
                  {item?.user?.id === parseInt(currentUser?.id, 10) ? (
                    <span>you</span>
                  ) : (
                    <span className="cms-user-name cms-space-user-name">
                      {
                        // eslint-disable-next-line no-nested-ternary
                        item?.user?.name ? (
                          item.user.name.includes("@") ? (
                            <>{item.user.name}</>
                          ) : (
                            <>{capitalizeWord(item.user.name)}</>
                          )
                        ) : (
                          item?.user?.email
                        )
                      }
                    </span>
                  )}
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
      {(item?.key === NOTIFICATION_TYPE?.CONNECTION ||
        item?.key === NOTIFICATION_TYPE?.BIRTHDAY ||
        item?.key === NOTIFICATION_TYPE?.ANNIVERSARY) && (
        <>
          <div className="cms-notification-card">
            <div className="cms-notification-detail">
              {notification?.recent && (
                <div className="cms-recent-notification" />
              )}
              <div className="cms-notification-card-info">
                <span className="cms-user-info">
                  <ReminderIcon height={18} width={18} className="mr-6" />
                  <span className="cms-text-space">
                    {
                      // eslint-disable-next-line no-nested-ternary
                      item?.key === NOTIFICATION_TYPE?.BIRTHDAY
                        ? BIRTHDAY
                        : item?.key === NOTIFICATION_TYPE?.ANNIVERSARY
                        ? ANNIVERSARY
                        : REMINDER_MENU_LISTING[item?.frequency]
                    }{" "}
                    reminder for
                  </span>
                  <NotificationLink
                    link={`/contacts/${item?.contact?.spaceId}/group/${item?.contact?.groupIds?.[0]}/contact/${item?.contact?.id}`}
                    closeDrawer={closeDrawer}
                  >
                    <Tag
                      icon={
                        <UserAvatar
                          className="cms-notification-avatar"
                          name={item?.contact?.firstName}
                          color={generateRandomColor(
                            parseInt(item?.contact?.id, 10)
                          )}
                          photo={item?.contact?.photo}
                        />
                      }
                      className="cms-user-tag"
                      title={`${item?.contact?.firstName} ${item?.contact?.lastName}`}
                    >
                      <span className="cms-user-name">
                        {capitalizeWord(
                          getFullContactName(
                            item?.contact?.firstName,
                            item?.contact?.lastName
                          )
                        )}
                      </span>
                    </Tag>
                  </NotificationLink>
                </span>
              </div>
              <div className="cms-notification-card-meta-info">
                <span className="cms-notification-time">
                  {getNotificationCreatedTime(notification?.createdAt)}
                </span>
              </div>
            </div>
          </div>
          <Divider className="cms-divider" />
        </>
      )}
    </>
  );
};

export default NotificationListItemCard;
