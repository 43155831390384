import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const EDIT_ATTACHMENT = gql`
  mutation editAttachment($id: Int!, $contactId: Int!, $name: String!) {
    editAttachment(id: $id, contactId: $contactId, name: $name) {
      message
      status
    }
  }
`;
