import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const RETRIEVE_SUBSCRIPTION_CHANGES = gql`
  mutation retrieveSubscriptionChanges(
    $spaceId: Int!
    $priceId: Int!
    $quantity: Int!
  ) {
    retrieveSubscriptionChanges(
      spaceId: $spaceId
      priceId: $priceId
      quantity: $quantity
    ) {
      upcomingInvoice
      immediateTotal
      nextInvoiceTotal
      nextPaymentAttempt
      currentPlan {
        id
        name
        description
        price {
          id
          productId
          amount
          currency
          priceId
          interval
        }
        startDate
        endDate
        quantity
      }
      newPlan {
        id
        name
        description
        price {
          id
          productId
          amount
          currency
          priceId
          interval
        }
        startDate
        endDate
        quantity
      }
    }
  }
`;
