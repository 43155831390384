import React from "react";

//! Antd imports
import Progress from "antd/lib/progress";

//! User files
import { GoogleImport } from "../../../assets/svg";

const ProgressScreen = ({ percent }) => {
  return (
    <div className="cms-progress-wrapper">
      <div className="cms-progress-wrapper-icon">
        <GoogleImport />
      </div>
      <div className="cms-progress-wrapper-progress-bar flex-all-center">
        <Progress percent={percent} showInfo={false} status="active" />
      </div>
    </div>
  );
};

export default ProgressScreen;
