import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const CHANGE_SPACE_OWNER = gql`
  mutation changeSpaceOwner($id: Int!, $userId: Int!) {
    changeSpaceOwner(id: $id, userId: $userId) {
      message
      status
    }
  }
`;
