import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_SPACE_SUBSCRIPTION = gql`
  query spaceSubscription($id: Int!) {
    spaceSubscription(id: $id) {
      data {
        id
        ownerId
        permissions
        default
        contactsCount
        subscription {
          id
          planStatus
          quantity
          endDate
          startDate
          product {
            id
            name
            description
            features
            price {
              id
              amount
              interval
              currency
            }
            quantity
          }
        }
        type
      }
    }
  }
`;

export const GET_SPACE_MEMBERS = gql`
  query spaceMembers($id: Int!, $data: SpaceMembersFilter!) {
    spaceMembers(id: $id, data: $data) {
      data {
        membersCount
      }
    }
  }
`;
