import { gql } from "@apollo/client/core";

const UNSUBSCRIBE_REMINDER = gql`
  mutation unSubscribeReminder($contactId: Int!, $type: ReminderTypes!) {
    unSubscribeReminder(contactId: $contactId, type: $type) {
      message
      status
    }
  }
`;

export default UNSUBSCRIBE_REMINDER;
