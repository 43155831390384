import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_CLEAN_UP_SPACE_INFO = gql`
  query space($id: Int!) {
    space(id: $id) {
      id
      name
      ownerId
      photo
      permission
      default
      duplicateContactsCount
      type
    }
  }
`;
