import React from "react";

//! Ant Imports
import Spin from "antd/lib/spin";

//! Ant Icons
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

function Loading({ size }) {
  const loadingIcon = <LoadingOutlined spin />;
  return (
    <div className="cb-loading-wrapper">
      <Spin size={size || "large"} indicator={loadingIcon} />
    </div>
  );
}

export default Loading;
