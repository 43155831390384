import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const REMOVE_DUPLICATE_CONTACT = gql`
  mutation removeDuplicate($id: Int!, $contactId: Int!) {
    removeDuplicate(id: $id, contactId: $contactId) {
      message
      status
    }
  }
`;
