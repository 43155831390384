import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import slice from "lodash/slice";

//! Ant import
import Tabs from "antd/lib/tabs";

const ContactTabs = ({ tabsArray = [] }) => {
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState(0);
  const tabSlice = slice(tabsArray, 0);
  const history = useHistory();
  const currentTab = history?.location?.search?.split("=")[1];

  useEffect(() => {
    if (currentTab !== "expanded") {
      setActiveTab(currentTab);
    } else {
      setActiveTab("activity");
    }
  }, [currentTab, tabsArray]);

  const renderTabs =
    tabsArray.length > 0 &&
    tabSlice.map((tabs) => {
      return (
        <TabPane tab={tabs?.icon} key={tabs?.tab}>
          {tabs?.component}
        </TabPane>
      );
    });

  return <Tabs activeKey={activeTab}>{renderTabs}</Tabs>;
};

export default ContactTabs;
