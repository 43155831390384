import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_NOTIFICATION_LIST = gql`
  query notifications($data: NotificationFilter!) {
    notifications(data: $data) {
      notifications {
        id
        message
        data
        type
        receiverId
        senderId
        createdAt
      }
      count
    }
  }
`;
