import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const PREVIEW_MERGE = gql`
  query previewContactMerge($id: Int!, $spaceId: Int!) {
    previewContactMerge(id: $id, spaceId: $spaceId) {
      id
      sourceType {
        new
        value
      }
      firstName {
        new
        value
      }
      middleName {
        new
        value
      }
      lastName {
        new
        value
      }
      jobTitle {
        new
        value
      }
      company {
        new
        value
      }
      photo {
        new
        value
      }
      birthday {
        new
        value
      }
      anniversary {
        new
        value
      }
      websites {
        new
        type
        value
      }
      emails {
        new
        type
        value
      }
      numbers {
        new
        type
        value
      }
      tags {
        new
        value
      }
      groups {
        id
        name
        ownerId
        spaceId
        new
      }
      contactAddresses {
        id
        label
        line1
        line2
        city
        county
        state
        country
        zipCode
        contactId
        new
      }
      notes {
        id
        note
        visibility
        username
        userId
        contactId
        new
      }
      attachments {
        id
        name
        type
        url
        username
        contactId
        userId
        new
      }
      socialProfiles {
        type
        value
        new
      }
      spaceId
    }
  }
`;
