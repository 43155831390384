import React from "react";

//! User files
import { NoActivityIcon } from "../../../../../assets/svg";
import ContactActivityListItem from "./ContactActivityListItem";
import NoGroupPlaceholder from "./NoGroupPlaceholder";

const ContactActivityList = ({ activityLogs, contactDetail }) => {
  return activityLogs?.length > 0 ? (
    activityLogs?.map((activity, i) => (
      <ContactActivityListItem
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        activity={activity}
        contactDetail={contactDetail}
        activityLength={activityLogs?.length}
        index={i}
      />
    ))
  ) : (
    <div className="cms-no-activity">
      <NoGroupPlaceholder
        title="No activities yet"
        showButton={false}
        icon={<NoActivityIcon />}
      />
    </div>
  );
};

export default ContactActivityList;
