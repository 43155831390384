import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const CLEAR_NOTIFICATION = gql`
  mutation clearNotification($ids: [Int!]!) {
    clearNotification(ids: $ids) {
      message
      status
    }
  }
`;
