//! User files
import { notificationToast } from "./utils";

const SomethingWentWrong = (message) => {
  notificationToast({
    message: message ?? `Something went wrong`,
    type: "error",
  });
};

export default SomethingWentWrong;
