import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Dropdown, Menu } from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";
import { MAIN_SITE_URL } from "../../../../../common/constants";
import { generateRandomColor } from "../../../../../common/utils";
import ContactAvatar from "../../../../../common/components/ContactAvatar";
import api from "../../../../../common/api";
import { GET_CONTACT_BY_PUBLIC_ID } from "../graphql/Queries";
import { FullLogo } from "../../../../../assets/svg";

const PublicLinkComponent = () => {
  const { linkId } = useParams();
  const [parsedCSVContact, setParsedCSVContact] = useState("");
  const [parsedVCFContact, setParsedVCFContact] = useState("");
  const { loading, error, data } = useQuery(GET_CONTACT_BY_PUBLIC_ID, {
    variables: { id: linkId },
  });
  const contact = data?.getContactByPublicId?.data || {};
  const {
    id: contactId,
    firstName,
    middleName,
    lastName,
    jobTitle,
    company,
    photo,
    numbers,
    emails,
  } = contact;

  const fullName = `${firstName || ""} ${middleName || ""} ${lastName || ""}`;

  const generateContactDescription = () => {
    let description = "";

    if (jobTitle && company) {
      description = `${jobTitle} at ${company}`;
    } else if (jobTitle) {
      description = jobTitle;
    } else if (company) {
      description = company;
    }
    // email & phone
    if (emails && emails?.length > 0) {
      description = `${description} ${description ? "," : ""} ${
        emails?.[0]?.value
      }`;
    }
    if (numbers && numbers?.length > 0) {
      description = `${description} ${description ? "," : ""} ${
        numbers?.[0]?.value
      }`;
    }
    return description;
  };

  const downloadCSV = (CSVData) => {
    if (!CSVData || CSVData === "") return;
    // Create a Blob with the CSV content and trigger download
    const blob = new Blob([CSVData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fullName.replace(/ /g, "_")}_contact.csv`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const downloadVCF = (vCardData) => {
    if (!vCardData || vCardData === "") return;

    // Create a Blob with the vCard content and trigger download
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${fullName.replace(/ /g, "_")}_contact.vcf`;
    document.body.appendChild(a);
    a.click();

    // Clean up: revoke the object URL and remove the anchor element
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleDownloadClick = (type) => {
    switch (type) {
      case "CSV":
        if (parsedCSVContact && parsedCSVContact !== "") {
          downloadCSV(parsedCSVContact);
        } else {
          api
            ?.post("/api/v1/contact-download/csv", {
              linkId,
            })
            .then(function (response) {
              const csvContent = response?.data;
              downloadCSV(csvContent);
              setParsedCSVContact(csvContent);
            })
            .catch(function (e) {
              // eslint-disable-next-line no-console
              console.log("error :>> ", e);
            });
        }
        break;
      case "VCF":
        if (parsedVCFContact && parsedVCFContact !== "") {
          downloadVCF(parsedVCFContact);
        } else {
          api
            ?.post("/api/v1/contact-download/vcf", {
              linkId,
            })
            .then(function (response) {
              const vcfContent = response?.data;
              setParsedVCFContact(vcfContent);
              downloadVCF(vcfContent);
            })
            .catch(function (e) {
              // eslint-disable-next-line no-console
              console.log("error :>> ", e);
            });
        }
        break;
      default:
    }
  };

  const downloadMenu = () => {
    return (
      <Menu>
        <Menu.Item onClick={() => handleDownloadClick("CSV")}>
          CSV file
        </Menu.Item>
        <Menu.Item onClick={() => handleDownloadClick("VCF")}>
          VCF file
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className="thankyou-page-wrapper">
      <Card
        cover={
          <div className="share-powered-by">
            <p>Powered by</p>
            <Link
              to={`//${MAIN_SITE_URL.replace("https://", "")}`}
              target="_blank"
            >
              <FullLogo />
            </Link>
          </div>
        }
      >
        {loading && <p>Loading...</p>}
        {error && <p>{error?.message}</p>}
        {contactId && (
          <Meta
            avatar={
              <ContactAvatar
                photo={photo}
                size={42}
                color={generateRandomColor(parseInt(contactId, 10))}
                name={firstName}
              />
            }
            title={fullName}
            description={generateContactDescription()}
          />
        )}
      </Card>
      {contactId && (
        <div className="text-sub-heading download">
          <Dropdown overlay={downloadMenu} placement="bottomLeft">
            <Button type="primary">
              Download <VerticalAlignBottomOutlined />
            </Button>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default PublicLinkComponent;
