import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const ThankYouPage = () => {
  const history = useHistory();
  const spaceId = localStorage?.getItem("currentSpace");
  useEffect(() => {
    setTimeout(() => {
      history?.push(`/space/${spaceId}/plans`);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="thankyou-page-wrapper">
      <div className="text-heading">Thank you for subscribing!</div>
      <div className="text-sub-heading">
        Congratulations! You have successfully updated the subscription for this
        space. Enjoy all the features of the plan you have just subscribed to.
      </div>
    </div>
  );
};

export default ThankYouPage;
