import React, { useContext, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { CURRENT_USER } from "./modules/auth/graphql/Queries";
import { AppContext } from "./AppContext";
import * as ActionTypes from "./common/actionTypes";
import { ROUTES } from "./common/constants";
import Loading from "./common/components/Loading";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { getToken, dispatch } = useContext(AppContext);
  const idToken = getToken();
  const { data, loading, error } = useQuery(CURRENT_USER);
  const currentSpace = localStorage?.getItem("currentSpace");

  useEffect(() => {
    if (!idToken) {
      history.push(`${ROUTES.LOGIN}`);
    }
  }, [idToken, history]);

  useEffect(() => {
    if (data?.getProfile && !loading) {
      dispatch({ type: ActionTypes.SET_CURRENT_USER, data: data.getProfile });
      if (currentSpace === null) {
        dispatch({
          type: ActionTypes.SET_CURRENT_SELECTED_SPACE,
          data: data.getProfile.spaceId,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, dispatch]);

  if (loading)
    return (
      <div className="cms-center-loading">
        <Loading />
      </div>
    );

  if (error) {
    history.push(`${ROUTES.LOGOUT}`);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !idToken ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};
export default PrivateRoute;
