import React from "react";
import { useWindowSize } from "react-use";

//! Antd Imports
import Col from "antd/lib/col";

//! User files
import { WINDOW_SIZE } from "../../../common/constants";

const NavPanel = ({ children }) => {
  const { width } = useWindowSize();

  return (
    <Col
      span={4}
      className={
        width <= WINDOW_SIZE ? "hide" : "dashboard-groups-col h-inherit"
      }
    >
      {children}
    </Col>
  );
};

export default NavPanel;
