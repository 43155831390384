import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_CONTACT_SOURCE_LIST = gql`
  query spaceContactSources($id: Int!) {
    spaceContactSources(id: $id) {
      data {
        id
        name
        ownerId
        contactSources {
          id
          sourceType
          maxContactUpdated
          email
          fetchInterval
        }
      }
    }
  }
`;
