import React, { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";

//! Antd imports
import Col from "antd/lib/col";

const ListPanel = ({ children, hidePaths, size, isReminder }) => {
  const location = useLocation();
  const [showListPanel, setShowListPanel] = useState(true);

  useEffect(() => {
    setShowListPanel(true);
    // eslint-disable-next-line no-restricted-syntax
    for (const path of hidePaths) {
      const match = matchPath(location.pathname, {
        path: path,
        exact: true,
        strict: false,
      });
      if (match) {
        setShowListPanel(false);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, hidePaths, showListPanel]);
  return (
    showListPanel && (
      <Col
        span={size || 8}
        className={`${
          isReminder ? "cms-reminder-state" : ""
        } dashboard-contacts-col h-inherit`}
      >
        <div
          className={`${isReminder ? "full-height" : ""} contact-list-wrapper`}
        >
          {children}
        </div>
      </Col>
    )
  );
};

export default ListPanel;
