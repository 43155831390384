import React from "react";
import { Link } from "react-router-dom";

//! User files
import UpgradeButtonComponent from "../../UpgradeButtonComponent";

function UpgradePlanPopup({ title, content, spaceId }) {
  return (
    <div className="upgrade-popup-container">
      <div className="upgrade-popup-container-section">
        <div className="font-inter upgrade-popup-title">{title}</div>
        <div className="font-inter upgrade-popup-content">{content}</div>
      </div>
      <div className="upgrade-popup-button">
        <Link to={`/space/${spaceId}/plans`}>
          <UpgradeButtonComponent />
        </Link>
      </div>
    </div>
  );
}

export default UpgradePlanPopup;
