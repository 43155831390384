import { useContext } from "react";

//! User files
import { AppContext } from "../AppContext";
import { hasGroupRight, hasSpaceRight } from "./utils";

// eslint-disable-next-line import/prefer-default-export
export const HasRight = ({ action, children, type }) => {
  let value;
  const {
    state: { userGroupPermission, userSpacePermission, userSubscriptionInfo },
  } = useContext(AppContext);

  if (type === "space") {
    value = hasSpaceRight(
      userSpacePermission || userSubscriptionInfo?.permission,
      action
    )
      ? children
      : null;
  } else {
    value = hasGroupRight(userGroupPermission, action) ? children : null;
  }

  return value;
};
