import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const IGNORE_DUPLICATE = gql`
  mutation ignoreDuplicate($id: Int!) {
    ignoreDuplicate(id: $id) {
      message
      status
    }
  }
`;
