import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const REMOVE_TAG = gql`
  mutation removeTag($id: Int!, $spaceId: Int!) {
    removeTag(id: $id, spaceId: $spaceId) {
      message
      status
    }
  }
`;
