import React, { useEffect, useState, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { cloneDeep, filter } from "lodash";

//! Antd import
import { Skeleton, Row, Col } from "antd";

//! Graphql files
import { PREVIEW_MERGE } from "../../../graphql/queries/previewMerge";
import { MERGE_CONTACT } from "../../../graphql/mutations/mergeContact";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";

//! User files
import ContactData from "../../contacts/components/ContactData";
import { AMPLITUDE_EVENT_LOG, SUCCESS } from "../../../../../common/constants";
import ContactCardSkeleton from "../../../../../common/components/ContactCardSkeleton/ContactCardSkeleton";
import { AppContext } from "../../../../../AppContext";
import { createAmplitudeEvent } from "../../../../../common/utils";
import { SET_DUPLICATE_CONTACT_COUNT } from "../../../../../common/actionTypes";

const MergeContact = () => {
  const { spaceId, duplicateId } = useParams();
  const [previewData, setPreviewData] = useState([]);
  const [isContactMerged, setIsContactMerged] = useState(false);
  const { state } = useLocation();
  const { push } = useHistory();
  const {
    state: { userSubscriptionInfo, duplicateContactCount },
    dispatch,
  } = useContext(AppContext);

  const [
    previewMerge,
    { data: previewMergeData, loading: previewLoader },
  ] = useLazyQuery(PREVIEW_MERGE, {
    fetchPolicy: "network-only",
    onError() {
      push(`/clean-up/${spaceId}/duplicates`);
    },
  });

  const [mergePreviewContact, { loading: mergeLoader }] = useMutation(
    MERGE_CONTACT,
    {
      refetchQueries: [
        {
          query: GET_SPACE_DETAIL_V2,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
          },
        },
      ],
      onCompleted({ mergeContact }) {
        if (mergeContact?.status === SUCCESS) {
          dispatch({
            type: SET_DUPLICATE_CONTACT_COUNT,
            data: duplicateContactCount - 1,
          });
          if (userSubscriptionInfo?.duplicateContactsCount === 1) {
            push({
              pathname: `/clean-up/${spaceId}/duplicates`,
              state: { duplicateCount: 0 },
            });
          }
          const eventProperties = {
            "Space Id": spaceId,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
            "Merge Contact": "Merged duplicate contact",
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.MERGE_CONTACT,
            eventProperties
          );
          setIsContactMerged(true);
        }
      },
    }
  );

  useEffect(() => {
    if (spaceId && duplicateId) {
      previewMerge({
        variables: {
          id: parseInt(duplicateId, 10),
          spaceId: parseInt(spaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId, duplicateId]);

  useEffect(() => {
    if (previewMergeData?.previewContactMerge) {
      setPreviewData(previewMergeData?.previewContactMerge);
      setIsContactMerged(false);
    }
  }, [previewMergeData]);

  const filterValueFromPreviewMerge = (id, type, value) => {
    const temp = cloneDeep(previewData);
    let filterValue;
    if (type === "contactAddresses") {
      filterValue = filter(temp[type], (e) => e?.id !== id);
    } else if (type === "birthday" || type === "anniversary") {
      const tData = temp[type];
      temp[type] = {};
      temp[type][0] = tData;
      filterValue = filter(temp[type], (e) => e?.value !== value);
    } else {
      filterValue = filter(temp[type], (e) => e?.value !== value);
    }
    temp[type] = filterValue;
    setPreviewData(temp);
  };

  return previewLoader || previewData?.length === 0 ? (
    <>
      <div className="merge-contact-user-info-wrapper-skeleton merge-contact-skeleton">
        <Skeleton avatar paragraph={{ rows: 3 }} title={false} active />
      </div>
      <Row className="contact-user-more-info-wrapper">
        <Col span={24} className="contact-user-more-info-col">
          <div className="contact-info-collapse">
            <div className="contact-info-collapse-panel cms-merge-contact-meta-detail cms-meta-skeleton">
              <ContactCardSkeleton loading={previewLoader} />
            </div>
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <div>
      <ContactData
        contactDetails={previewData}
        mergeContact
        filterValueFromPreviewMerge={filterValueFromPreviewMerge}
        mergePreviewContact={mergePreviewContact}
        isContactMerged={isContactMerged}
        mergeLoader={mergeLoader}
        mergeContactCount={state?.mergeContactCount}
      />
    </div>
  );
};

export default MergeContact;
