//! Not allowing 2 rules for the same line so kept on the top
/* eslint-disable no-unused-expressions */
import { DatePicker, Menu } from "antd";
import { useWindowSize } from "react-use";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { cloneDeep, filter } from "lodash";
import moment from "moment";
import _isInteger from "lodash/isInteger";

//! Ant Imports
import Divider from "antd/lib/divider";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Dropdown from "antd/lib/dropdown";
import Text from "antd/lib/typography/Text";
import Tag from "antd/lib/tag";

//! User Files
import {
  VerticalMore,
  ContactLocationIcon,
  Mail,
  Phone,
  WhatsAppIconOutlined,
  StayConnectedNotificationIcon,
  ContactReminderImage,
  LinkInSocialIcon,
  InstagramSocialIcon,
  TwitterSocialIcon,
  FacebookSocialIcon,
  GoogleSocialIcon,
  CustomEarthIcon,
  RenameIcon,
  DustbinIcon,
} from "../../../../../assets/svg";
import {
  createAmplitudeEvent,
  generateRandomColor,
  hasGroupRight,
  isAddressNull,
  isPlanActive,
  notificationToast,
  removeAutoTags,
  getAddress,
  getNameTag,
} from "../../../../../common/utils";
import TagLabel from "./TagLabel";
import ContactGroups from "./ContactGroups";
import ContactAvatar from "../../../../../common/components/ContactAvatar";
import ReminderMenu from "./ReminderMenu";
import ContactCardButton from "../../../../../common/components/ContactCardButton/ContactCardButton";
import { AppContext } from "../../../../../AppContext";
import {
  SET_CONTACTS,
  SET_CONTACTS_COUNT,
  SET_SPACE_PLAN_STATUS,
} from "../../../../../common/actionTypes";
import {
  AMPLITUDE_EVENT_LOG,
  CONTACT_LIMIT,
  DELETE_CONTACT,
  EDIT,
  EDIT_CONTACT,
  REMINDER_MENU_LISTING,
  REMINDER_TYPES,
  ROUTES,
  UPGRADE_PLAN_TITLE,
  VIEW,
  WINDOW_SIZE,
} from "../../../../../common/constants";
import * as ActionTypes from "../../../../../common/actionTypes";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";
import SomethingWentWrong from "../../../../../common/SomethingWentWrong";
import ShowContactPublicLinkPopup from "../../../../../common/components/ShowContactPublicLinkPopup/ShowContactPublicLinkPopup";
import GenerateContactPublicLinkPopup from "../../../../../common/components/GenerateContactPublicLinkPopup/GeneratePublicLinkPopup";

//! Graphql files
import { RESTORE_CONTACT } from "../../../graphql/mutations/restoreContact";
import SUBSCRIBE_REMINDER from "../../../graphql/mutations/subscribeReminder";
import UNSUBSCRIBE_REMINDER from "../../../graphql/mutations/unsubscribeReminder";
import { GET_CONTACT_INFO } from "../../../graphql/queries/getContactInfo";
import { REMOVE_MULTI_CONTACTS } from "../../../graphql/mutations/removeMultiContacts";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";
import { GET_GROUP_INFO_V2 } from "../../../graphql/queries/getGroupInfoV2";
import { GENERATE_CONTACT_PUBLIC_LINK } from "../graphql/Mutations";
import { GET_ALL_CONTACTS } from "../../../graphql/queries/getContactsList";

const ContactDataMeta = ({ contactDetail, setContactDetails }) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const { width } = useWindowSize();
  const [dataType, setDataType] = useState();

  const {
    firstName,
    middleName,
    lastName,
    photo,
    jobTitle,
    company,
    tags,
    groups,
    isEditable,
    emails,
    numbers,
    spaceId,
    birthday,
    anniversary,
    websites,
    socialProfiles,
    contactAddresses,
    reminders,
  } = contactDetail;

  const contactName =
    // eslint-disable-next-line prefer-template
    (firstName ?? "") + " " + (middleName ?? "") + " " + (lastName ?? "");
  const { push } = useHistory();
  const { contactId } = useParams();
  const { pathname } = useLocation();
  const group = pathname?.split("/")[3] === "group";
  const tagName = pathname?.split("/")[4];
  const groupId = pathname?.split("/")[4];
  const {
    state: {
      userSubscriptionInfo,
      contacts,
      contactsCount,
      userSpacePermission,
    },
    dispatch,
  } = useContext(AppContext);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentReminder, setCurrentReminder] = useState();
  const [refetchContactInfo, setRefetchContactInfo] = useState(false);

  const currentSpaceId = pathname.split("/")[2];
  const tag = pathname?.split("/")[3] === "tag";
  const history = useHistory();
  const [openDeleteContact, setOpenDeleteContact] = useState(false);
  const [
    publicLinkGenerateModelVisibility,
    setPublicLinkGenerateModelVisibility,
  ] = useState(false);
  const [
    linkGeneratedPopupVisibility,
    setLinkGeneratedPopupVisibility,
  ] = useState(false);
  const [contactGeneratedPublicLink, setContactGeneratedPublicLink] = useState(
    ""
  );
  const [linkExpiryDate, setLinkExpiryDate] = useState(null);
  const spaceGroups = groups;

  const noDetails =
    (numbers?.length === 0 || numbers === null) &&
    (emails?.length === 0 || emails === null) &&
    (birthday === null || birthday?.length === 0 || birthday?.value === null) &&
    (anniversary === null ||
      anniversary?.length === 0 ||
      anniversary?.value === null) &&
    (websites?.length === 0 || websites === null) &&
    (socialProfiles?.length === 0 || socialProfiles === null) &&
    isAddressNull(contactAddresses);

  const deletedContacts = pathname?.split("/")[4] === "deleted-contacts";

  const handleFilteredContact = () => {
    const filteredContacts = filter(
      contacts,
      (contact) => contact.id !== contactId
    );
    dispatch({
      type: SET_CONTACTS,
      data: filter(contacts, (contact) => contact.id !== contactId),
    });
    dispatch({
      type: SET_CONTACTS_COUNT,
      data: contactsCount - 1,
    });
    if (filteredContacts.length > 0) {
      push(
        `/contacts/${spaceId}/group/deleted-contacts/contact/${filteredContacts[0]?.id}`
      );
    } else {
      push(`/contacts/${spaceId}/group/all`);
    }
  };

  const [restoreContact, { loading: restoreLoader }] = useMutation(
    RESTORE_CONTACT,
    {
      refetchQueries: [
        {
          query: GET_SPACE_DETAIL_V2,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
          },
        },
      ],
      onCompleted() {
        const eventProperties = {
          "Space Id": spaceId,
          "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
          "Plan status": userSubscriptionInfo?.subscription?.planStatus,
          "Restore Contact": "Restore contact",
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.CONTACT_RESTORE,
          eventProperties
        );
        handleFilteredContact();
      },
      onError() {},
    }
  );

  const [getContactInfo] = useLazyQuery(GET_CONTACT_INFO, {
    fetchPolicy: "network-only",
    onCompleted({ contactInfo }) {
      setRefetchContactInfo(false);
      if (contactInfo?.data) {
        setContactDetails(contactInfo?.data);
      }
    },
    onError(error) {
      if (error?.message === "Contact not found!") {
        push(`/contacts/${spaceId}/group/all`);
      }
    },
  });

  const [subscribeReminder] = useMutation(SUBSCRIBE_REMINDER, {
    onCompleted() {
      setRefetchContactInfo(true);
    },
    onError() {},
  });

  const [unsubscribeReminder] = useMutation(UNSUBSCRIBE_REMINDER, {
    onCompleted() {
      setRefetchContactInfo(true);
    },
    onError() {},
  });

  const [multiContactDelete, { loading: multiDeleteLoader }] = useMutation(
    REMOVE_MULTI_CONTACTS,
    {
      refetchQueries: [
        {
          query: GET_SPACE_DETAIL_V2,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
          },
        },
        {
          query: GET_ALL_CONTACTS,
          fetchPolicy: "network-only",
          variables: {
            // eslint-disable-next-line no-nested-ternary
            tag: tag ? (tagName !== "all" ? tagName : null) : null,
            spaceId: spaceId ? parseInt(spaceId, 10) : null,
            // eslint-disable-next-line no-nested-ternary
            groupId: group ? (groupId ? parseInt(groupId, 10) : null) : null,
            data: {
              limit: CONTACT_LIMIT,
              offset: 0,
              sortBy: "ASC",
              sortOn: "firstName",
            },
          },
        },
      ],
      onCompleted() {
        if (openDeleteContact) {
          dispatch({
            type: ActionTypes.SET_REFETCH_TAGS,
            data: true,
          });
          setOpenDeleteContact(false);
        } else {
          const eventProperties = {
            "Space Id": spaceId,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
            "Delete Contact": "Contact deleted",
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.CONTACT_DELETED,
            eventProperties
          );
          handleFilteredContact();
          setDeleteModal(false);
        }
      },
      onError() {},
    }
  );

  const handleRouteChange = () => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      // eslint-disable-next-line no-nested-ternary
      tag
        ? push(`/contacts/${spaceId}/tag/${tagName}/contact/${contactId}/edit`)
        : pathname.includes(`${ROUTES?.CONTACTS}/all`) ||
          pathname.includes("reminders") ||
          pathname.includes(`${ROUTES?.CONTACTS}/${spaceId}/all/${contactId}`)
        ? push(`${ROUTES?.CONTACTS}/${spaceId}/all/${contactId}/edit`)
        : push(
            `/contacts/${spaceId}/group/${groupId}/contact/${contactId}/edit`
          );
    } else {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE, spaceId },
      });
    }
  };

  const afterDeleteContactUserMeta = () => {
    const eventProperties = {
      "Contact Id": contactId,
      "Space Id": currentSpaceId,
    };
    createAmplitudeEvent(AMPLITUDE_EVENT_LOG.CONTACT_DELETED, eventProperties);
    dispatch({
      type: ActionTypes.SET_CONTACTS,
      data: filter(contacts, (contact) => contact.id !== contactId),
    });
    dispatch({
      type: ActionTypes.SET_CONTACTS_COUNT,
      data: contactsCount - 1,
    });

    if (width <= WINDOW_SIZE) {
      history.push(`${ROUTES?.CONTACTS}/${currentSpaceId}/all`);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (tag && tagName) {
        history.push(`${ROUTES?.CONTACTS}/${currentSpaceId}/tag/${tagName}`);
      } else if (_isInteger(parseInt(groupId, 10))) {
        history.push(
          `${ROUTES?.CONTACTS}/${currentSpaceId}/group/${groupId}/settings`
        );
      } else {
        history.push(`${ROUTES?.CONTACTS}/${currentSpaceId}/group/${groupId}`);
      }
    }
  };

  const [getGroupInfo] = useLazyQuery(GET_GROUP_INFO_V2, {
    fetchPolicy: "network-only",
    onCompleted({ groupInfoV2 }) {
      afterDeleteContactUserMeta();
      dispatch({
        type: ActionTypes.SET_USER_GROUP_PERMISSION,
        data: groupInfoV2?.permission,
      });
    },
    onError() {
      history?.push(`/contacts/${spaceId}/group/all`);
    },
  });

  const handleRemoveContact = () => {
    multiContactDelete({
      variables: {
        data: {
          ids: [parseInt(contactId, 10)],
          spaceId: parseInt(spaceId, 10),
          sourceType: null,
          sourceId: null,
          permanent: false,
          search: null,
        },
      },
    })
      .then(() => {
        if (groupId > 0 && _isInteger(parseInt(groupId, 10))) {
          getGroupInfo({
            fetchPolicy: "network-only",
            variables: {
              id: parseInt(groupId, 10),
            },
          });
        } else {
          afterDeleteContactUserMeta();
        }
      })
      .catch(() => {
        SomethingWentWrong();
      });
  };

  const handleConfirmation = () => {
    setOpenDeleteContact(!openDeleteContact);
  };

  const handlePublicLinkGenerateModelVisibility = () => {
    setPublicLinkGenerateModelVisibility(!publicLinkGenerateModelVisibility);
  };

  const handleLinkGeneratedPopupVisibility = () => {
    setLinkGeneratedPopupVisibility(!linkGeneratedPopupVisibility);
  };

  const [
    generateContactPublicLink,
    { loading: multiContactDeleteLoaderLoader },
  ] = useMutation(GENERATE_CONTACT_PUBLIC_LINK, {
    variables: {
      id: parseInt(contactId, 10),
      expiryDate: linkExpiryDate?.format() || null,
    },
    onCompleted(data) {
      setContactGeneratedPublicLink(data?.generateContactPublicLink?.link);
      handlePublicLinkGenerateModelVisibility();
      handleLinkGeneratedPopupVisibility();
      setLinkExpiryDate(null);
    },
    onError() {},
  });

  const handleGeneratePublicContactLink = () => {
    generateContactPublicLink();
  };

  const popUpContent = (
    <>
      <span className="popup-text-title font-inter">
        Are you sure you want to delete this contact?
      </span>
      {spaceGroups?.length !== 0 && (
        <div className="popup-text-content">
          <div className="delete-contact-content">
            <span className="popup-text-content-body margin-right">
              It will be deleted from
            </span>
            <ContactGroups
              tagArray={spaceGroups}
              groupsLength={spaceGroups ? spaceGroups?.length : null}
            />
          </div>
        </div>
      )}
    </>
  );

  const disabledDate = (current) => {
    //! Disable date selection for now +2min
    return current < moment().add(2, "minute");
  };

  const publicLinkGeneratePopUpContent = (
    <>
      <div className="public-contact-link-generator-popup">
        <span className="popup-text-title font-inter">
          Generate a shareable link to easily share this contact's information
          with others.
        </span>
        <span className="mr-12 cursor-pointer">
          <Row gutter={16} className="justify-center mt-16">
            <Col span={12}>
              <p>Select expiry date (optional)</p>
              <DatePicker
                allowClear
                value={linkExpiryDate}
                showTime
                placement="bottomLeft"
                id="public-link-expiry-date"
                placeholder="Expiry date & time"
                onOk={(value) => setLinkExpiryDate(value)}
                disabledDate={disabledDate}
              />{" "}
            </Col>
          </Row>
        </span>
      </div>
    </>
  );

  const handleVisibleChange = () => {
    setVisible(!visible);
  };
  const handleModal = () => {
    setOpen(!open);
  };

  const restoreSpaceContact = () => {
    restoreContact({
      variables: {
        id: parseInt(contactId, 10),
      },
    });
  };

  const handleDeleteModal = () => {
    setDeleteModal(true);
  };

  const afterDeleteContact = () => {
    const eventProperties = {
      "Contact Id": contactId,
      "Space Id": spaceId,
      "Contact delete type": "permanent",
    };
    createAmplitudeEvent(AMPLITUDE_EVENT_LOG.CONTACT_DELETED, eventProperties);
  };

  const deleteContactPermanently = () => {
    multiContactDelete({
      variables: {
        data: {
          ids: [parseInt(contactId, 10)],
          spaceId: parseInt(spaceId, 10),
          sourceType: null,
          sourceId: null,
          permanent: true,
          search: null,
        },
      },
    })
      .then(() => {
        afterDeleteContact();
      })
      .catch(() => {
        SomethingWentWrong();
      });
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleUnsubscribeReminder = (reminderType) => {
    unsubscribeReminder({
      variables: {
        contactId: parseInt(contactId, 10),
        type: reminderType,
      },
    });
  };

  const handleConnectionChange = (reminderType, value) => {
    if (
      (birthday === null && reminderType === REMINDER_TYPES.BIRTHDAY) ||
      (anniversary === null && reminderType === REMINDER_TYPES.ANNIVERSARY)
    ) {
      notificationToast({
        message: `This contact does not have ${reminderType.toLowerCase()}`,
        type: "error",
      });
      return;
    }

    if (value === "NONE") {
      handleUnsubscribeReminder(REMINDER_TYPES.CONNECTION);
    } else {
      subscribeReminder({
        variables: {
          data: {
            contactId: parseInt(contactId, 10),
            type: reminderType,
            frequency: value || null,
            timeZoneOffSet: moment().utcOffset(),
          },
        },
      }).then(() => {
        setCurrentReminder(value);
        const eventProperties = {
          "Space Id": spaceId,
          "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
          "Plan status": userSubscriptionInfo?.subscription?.planStatus,
          "Contact Id": contactId,
          "Contact name": contactName,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.CONTACT_REMINDER,
          eventProperties
        );
      });
    }
  };

  useEffect(() => {
    if (reminders) {
      const reminderArr = cloneDeep(reminders);
      const connectionArr = filter(
        reminderArr,
        (e) => e.type === REMINDER_TYPES.CONNECTION
      );
      setCurrentReminder(connectionArr[0]?.frequency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reminders]);

  useEffect(() => {
    if (refetchContactInfo) {
      getContactInfo({
        variables: {
          contactId: contactId && parseInt(contactId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchContactInfo]);

  const getSocialIcon = (item) => {
    switch (item?.type) {
      case "linkedIn":
        return <LinkInSocialIcon />;
      case "instagram":
        return <InstagramSocialIcon />;
      case "twitter":
        return <TwitterSocialIcon />;
      case "facebook":
        return <FacebookSocialIcon />;
      case "google":
        return <GoogleSocialIcon />;
      default:
        return <CustomEarthIcon />;
    }
  };

  const userMetaMenu = (
    <Menu className="cms-menu">
      {/* {hasGroupRight(isEditable ? EDIT : VIEW, EDIT_CONTACT) && (
          <Menu.Item
            className="cms-menu-item"
            onClick={handleRouteChange}
            icon={
              <span className="cms-menu-item-svg">
                <RenameIcon height={16} width={16} />
              </span>
            }
          >
            Edit
          </Menu.Item>
        )} */}
      {hasGroupRight(isEditable ? EDIT : VIEW, DELETE_CONTACT) &&
        !pathname.includes("reminders") && (
          <>
            <Menu.Item
              className="cms-menu-item"
              icon={
                <span className="cms-menu-item-svg">
                  <CustomEarthIcon height={12} width={16} />
                </span>
              }
              onClick={handlePublicLinkGenerateModelVisibility}
            >
              Generate public link
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              className="cms-menu-item cms-delete-text"
              icon={
                <span className="cms-menu-item-svg cms-delete">
                  <DustbinIcon height={12} width={16} />
                </span>
              }
              onClick={handleConfirmation}
            >
              Delete
            </Menu.Item>
          </>
        )}
    </Menu>
  );

  return (
    <>
      <Row
        className={`contact-user-info-wrapper
        ${noDetails && !isEditable ? "cms-contact-detail-box" : ""}`}
      >
        <Col span={24} className="contact-user">
          <Row className="contact-user-row">
            <Col span={12} className="contact-user-avatar-col">
              <ContactAvatar
                photo={photo}
                size={56}
                color={generateRandomColor(parseInt(contactId, 10))}
                name={firstName}
              />
            </Col>
            {deletedContacts ? (
              hasGroupRight(
                userSpacePermission,
                "deleteContactPermanently"
              ) && (
                <div className="cms-merge-btn">
                  <IconButton
                    iconClass="cms-delete-contact-common-btn cms-delete-contact-delete-btn"
                    text={
                      <span className="font-inter cms-delete-contact-delete-btn-text">
                        Delete Permanently
                      </span>
                    }
                    handleClick={handleDeleteModal}
                  />
                  <IconButton
                    iconClass="cms-delete-contact-common-btn cms-delete-contact-restore-btn"
                    text={
                      <span className="font-inter cms-delete-contact-restore-btn-text">
                        Restore
                      </span>
                    }
                    handleClick={restoreSpaceContact}
                    loading={restoreLoader}
                  />
                </div>
              )
            ) : (
              <Col span={12} className="contact-user-more-col">
                <Row justify="end" align="center">
                  <Col className="reminder-dropdown">
                    <Dropdown
                      overlay={ReminderMenu(
                        handleConnectionChange,
                        reminders,
                        handleUnsubscribeReminder,
                        birthday,
                        anniversary
                      )}
                      placement="bottomRight"
                      trigger={["click"]}
                      onVisibleChange={handleVisibleChange}
                      visible={visible}
                    >
                      {currentReminder ? (
                        <Tag
                          icon={<StayConnectedNotificationIcon />}
                          className="cursor-pointer"
                        >
                          <span className="font-inter reminder-text">
                            {REMINDER_MENU_LISTING[currentReminder]}
                          </span>
                        </Tag>
                      ) : (
                        <StayConnectedNotificationIcon />
                      )}
                    </Dropdown>
                  </Col>
                  {hasGroupRight(isEditable ? EDIT : VIEW, EDIT_CONTACT) && (
                    <Col className="reminder-dropdown">
                      <RenameIcon
                        onClick={handleRouteChange}
                        height={24}
                        width={24}
                      />
                    </Col>
                  )}
                  <Col className={!isEditable && "hide"}>
                    <Dropdown overlay={userMetaMenu} trigger={["click"]}>
                      <VerticalMore className="cms-svg-regular" />
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24} className="contact-user-meta">
          <Row className="contact-user-meta-row">
            <Col
              span={width <= WINDOW_SIZE ? "24" : "12"}
              className="contact-user-profile-col"
            >
              <Row>
                <Col span={24} className="align-flex-start">
                  <Text className="username cms-font-weight-medium font-inter">
                    {getNameTag(contactName, company)}
                  </Text>
                  {socialProfiles?.map((item) => {
                    let links;
                    if (!item?.value.includes("http")) {
                      links = `http://${item?.value}`;
                    } else {
                      links = item?.value;
                    }
                    return (
                      <>
                        <a
                          href={links}
                          target="_blank"
                          rel="noreferrer"
                          className="social-icon-link"
                        >
                          <div className="svg-wrapper contact-data-meta-social-icon">
                            {getSocialIcon(item)}
                          </div>
                        </a>
                      </>
                    );
                  })}
                </Col>
                <Col span={24}>
                  <Row className="description-row">
                    <Col span={24} className="designation-col mb-2">
                      <Text type="secondary" className="designation">
                        {jobTitle && company
                          ? `${jobTitle} | ${company}`
                          : jobTitle || company || ""}
                      </Text>
                    </Col>
                    <Col span={24} className="designation-col mb-12">
                      <Text
                        type="secondary"
                        className="designation d-flex-center"
                      >
                        {numbers?.length > 0 && (
                          <span
                            className="cursor-pointer cms-contact-val-hover cms-font-ellipsis"
                            onClick={() => {
                              window.location = `tel:${
                                filter(numbers, (n) => n)?.[0]?.value
                              }`;
                            }}
                            title={filter(numbers, (n) => n)?.[0]?.value}
                          >
                            {filter(numbers, (n) => n)?.[0]?.value}
                          </span>
                        )}
                        {numbers?.length > 0 && emails?.length > 0 && (
                          <Divider
                            type="vertical"
                            className="cms-value-divider"
                          />
                        )}
                        {emails?.length > 0 && (
                          <span
                            className="cursor-pointer cms-contact-val-hover cms-font-ellipsis"
                            onClick={() => {
                              window.location = `mailto:${
                                filter(emails, (n) => n)?.[0]?.value
                              }`;
                            }}
                            title={filter(emails, (n) => n)?.[0]?.value}
                          >
                            {filter(emails, (n) => n)?.[0]?.value}
                          </span>
                        )}
                        {reminders?.length > 0 && (
                          <>
                            {(numbers?.length > 0 || emails?.length > 0) && (
                              <Divider
                                type="vertical"
                                className="cms-value-divider"
                              />
                            )}
                            <span className="d-flex-center flex-start">
                              <ContactReminderImage />
                              <span className="ml-4 cms-font-ellipsis">
                                Reminder -{" "}
                                {moment(
                                  filter(reminders, (n) => n)?.[0]?.scheduledAt
                                ).format("DD MMMM")}
                              </span>
                            </span>
                          </>
                        )}
                      </Text>
                    </Col>
                    {tags?.length !== 0 && (
                      <Col span={24} className="tags-col with-border">
                        <TagLabel tagArray={tags ? removeAutoTags(tags) : []} />
                      </Col>
                    )}
                    {groups?.length !== 0 && (
                      <Col
                        span={24}
                        className="tags-col with-border group-tag-pt"
                      >
                        <ContactGroups tagArray={groups} spaceId={spaceId} />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col
              span={width <= WINDOW_SIZE ? "24" : "12"}
              className="contact-user-meta-info-col"
            >
              {numbers?.length > 0 && (
                <>
                  <div
                    className="svg-wrapper"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setType("CALL");
                      setDataType(numbers);
                      handleModal();
                    }}
                  >
                    <Phone className="cms-svg-sm" />
                  </div>
                  <Divider type="vertical" />
                  <div
                    className="svg-wrapper"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setType("WHATSAPP");
                      setDataType(numbers);
                      handleModal();
                    }}
                  >
                    <WhatsAppIconOutlined className="cms-svg-sm" />
                  </div>
                </>
              )}
              {emails?.length > 0 && (
                <>
                  {numbers?.length > 0 && <Divider type="vertical" />}
                  <div
                    className="svg-wrapper"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setType("EMAIL");
                      setDataType(emails);
                      handleModal();
                    }}
                  >
                    <Mail className="cms-svg-sm" />
                  </div>
                  {!isAddressNull(contactAddresses) && (
                    <Divider type="vertical" />
                  )}
                </>
              )}
              {!isAddressNull(contactAddresses) && (
                <>
                  {!emails?.length && numbers?.length > 0 && (
                    <Divider type="vertical" />
                  )}
                  <a
                    href={`http://maps.google.com/?q=${getAddress(
                      contactAddresses?.[0]
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="svg-wrapper">
                      <ContactLocationIcon className="cms-svg-sm" />
                    </div>
                  </a>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <ContactCardButton
        title={contactName}
        data={dataType}
        visible={open}
        type={type}
        removeNote={handleModal}
      />
      <RemovePopup
        visible={deleteModal}
        content={
          <span className="popup-text-title font-inter">
            This action will permanently delete your contact and is not
            reversible
          </span>
        }
        okText="Delete"
        handleRemove={deleteContactPermanently}
        removeNote={closeDeleteModal}
        deleteLoader={multiDeleteLoader}
        disabled={multiDeleteLoader}
        maskClosable={false}
      />
      <RemovePopup
        content={popUpContent}
        visible={openDeleteContact}
        okText="Delete Contact"
        removeNote={handleConfirmation}
        handleRemove={handleRemoveContact}
        deleteLoader={multiDeleteLoader}
        disabled={multiDeleteLoader}
        maskClosable={false}
      />
      <GenerateContactPublicLinkPopup
        id={contactId}
        content={publicLinkGeneratePopUpContent}
        visible={publicLinkGenerateModelVisibility}
        okText="Generate Public Link"
        removeNote={handlePublicLinkGenerateModelVisibility}
        handleOkClick={handleGeneratePublicContactLink}
        deleteLoader={multiContactDeleteLoaderLoader}
        disabled={multiContactDeleteLoaderLoader}
        maskClosable
      />
      <ShowContactPublicLinkPopup
        id={contactId}
        link={contactGeneratedPublicLink}
        visible={linkGeneratedPopupVisibility}
        removeNote={handleLinkGeneratedPopupVisibility}
      />
    </>
  );
};

export default ContactDataMeta;
