import React, { useContext, useEffect } from "react";

//! User files
import { AppContext } from "../../../../../AppContext";
import NotificationListItem from "./NotificationListItem";
import * as ActionTypes from "../../../../../common/actionTypes";
import Loading from "../../../../../common/components/Loading";

const NotificationList = ({ notification, closeDrawer, loading }) => {
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({ type: ActionTypes.SET_NEW_NOTIFICATION, data: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="cms-notification-list">
        <NotificationListItem
          notification={notification}
          closeDrawer={closeDrawer}
        />
      </div>
      {loading && (
        <div className="cms-infinite-scroll-loader">
          <Loading />
        </div>
      )}
    </>
  );
};

export default NotificationList;
