import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

//! User Files
import * as ActionTypes from "../../../common/actionTypes";
import { AppContext } from "../../../AppContext";
import { ROUTES } from "../../../common/constants";

const Logout = () => {
  const { dispatch } = useContext(AppContext);
  const { push } = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    cookies?.remove("cms_logged_in", { domain: ".contactbook.app" });
    dispatch({ type: ActionTypes.LOGOUT });
    push(ROUTES.LOGIN);
    // eslint-disable-next-line
  }, []);

  return <div />;
};

export default Logout;
