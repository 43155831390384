import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_GROUP_INFO_V2 = gql`
  query groupInfoV2($id: ID!) {
    groupInfoV2(id: $id) {
      id
      membersCount
      name
      type
      source
      sourceId
      ownerId
      spaceId
      createdAt
      updatedAt
      notificationsSubscribed
      permission
      contacts
    }
  }
`;
