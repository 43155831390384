import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const ASSIGN_UNASSIGN_CONTACT_TO_GROUP = gql`
  mutation assignOrUnassignContactsToGroup(
    $id: Int!
    $contactIds: [Int!]!
    $type: CHOICE
  ) {
    assignOrUnassignContactsToGroup(
      id: $id
      contactIds: $contactIds
      type: $type
    ) {
      message
      status
    }
  }
`;
