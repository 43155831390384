import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Link, useHistory, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { useWindowSize } from "react-use";
import Cookies from "universal-cookie";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import Layout from "../layout/Layout";
import { LINK, ROUTES, WINDOW_SIZE } from "../../../common/constants";
import {
  GOOGLE_AUTH_V2,
  IS_EMAIL_EXISTS,
  SOCIAL_AUTH_V2,
} from "../graphql/Mutations";
import { AppContext } from "../../../AppContext";
import { formValidatorRules, isMobileBrowser } from "../../../common/utils";
import { auth } from "../../../services/firebase";
import { GET_GOOGLE_AUTH_URL } from "../graphql/Queries";
import Loading from "../../../common/components/Loading";
import IconButton from "../../../common/components/IconButton/IconButton";
import {
  EmailLetter,
  MicrosoftIcon,
  Apple,
  GoogleLoginLogo,
} from "../../../assets/svg";
import TermsComponent from "../../../common/TermsComponent";
import { SET_CURRENT_EMAIL } from "../../../common/actionTypes";

function Login() {
  const [form] = useForm();
  const history = useHistory();
  const [socialAuth] = useMutation(SOCIAL_AUTH_V2);
  const {
    initializeAuth,
    state: { currentUser },
    dispatch,
  } = useContext(AppContext);
  const { width } = useWindowSize();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const authCode = params.get("code");
  const cookies = new Cookies();
  const isGoogleUser = params?.get("mode");
  const [currentEmail, setCurrentEmail] = useState(null);
  function successCallback(accessToken, user) {
    initializeAuth(accessToken, user);
  }
  const { required, email } = formValidatorRules;
  const [emailExists, { loading: emailExistsLoader }] = useMutation(
    IS_EMAIL_EXISTS,
    {
      onCompleted(data) {
        if (data?.isEmailExist?.isLoggedIn) {
          dispatch({ type: SET_CURRENT_EMAIL, data: currentEmail });
          history?.push(ROUTES?.EMAIL_LOGIN);
        } else {
          dispatch({ type: SET_CURRENT_EMAIL, data: currentEmail });
          history?.push(ROUTES?.SIGNUP);
        }
      },
    }
  );

  const setCookieForUtmSource = (value) => {
    cookies.set("UTMTAGS", value, { domain: ".contactbook.app" });
  };

  useEffect(() => {
    if (search.includes("utm_campaign")) {
      setCookieForUtmSource(search.substring(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser?.id) {
      if (width <= WINDOW_SIZE) {
        history?.push(ROUTES?.CONTACTS);
      } else {
        // eslint-disable-next-line no-unused-expressions, no-nested-ternary
        localStorage?.getItem("TOKEN")
          ? history?.push(`/contacts/${currentUser?.spaceId}/group/all`)
          : history?.push("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const setCookieForLoggedInUser = () => {
    cookies.set("cms_logged_in", true, { domain: ".contactbook.app" });
  };

  const [googleAuthUrl] = useLazyQuery(GET_GOOGLE_AUTH_URL, {
    onCompleted(data) {
      window.location.href = data?.getGoogleAuthUrl?.authorizationUri;
    },
    onError() {},
  });

  const [googleAuth] = useMutation(GOOGLE_AUTH_V2, {
    onError() {},
  });

  useEffect(() => {
    if (authCode && !currentUser?.id) {
      googleAuth({
        variables: {
          data: {
            authorizationCode: authCode,
            screen: "login",
          },
        },
      })
        ?.then((res) => {
          const { accessToken, userInfo } = res?.data?.googleAuthV2;
          setCookieForLoggedInUser();
          if (!userInfo?.hasLoggedInBefore) {
            ReactGA.event({
              action: "cb-user-signup",
              label: "Signup with Google",
            });
          }

          if (!userInfo?.hasLoggedInBefore) {
            localStorage?.setItem("currentSpace", userInfo?.spaceId);
            successCallback(accessToken, userInfo);
            localStorage?.setItem("userId", userInfo?.userId);
            localStorage?.setItem("provider", "signIn");
            localStorage?.setItem(
              "user",
              userInfo && Object?.keys(userInfo).length
                ? JSON?.stringify(userInfo)
                : null
            );
            localStorage?.setItem("currentSpace", userInfo?.spaceId);
            history?.replace(ROUTES?.TELL_US_MORE);
          } else {
            localStorage?.setItem("currentSpace", userInfo?.spaceId);
            successCallback(accessToken, userInfo);
          }

          if (res?.data?.googleAuth?.status === "Success") {
            history?.push(
              isMobileBrowser(
                `${ROUTES?.CONTACTS}/${userInfo?.spaceId}/group/all`,
                width,
                userInfo?.spaceId
              )
            );
          }
        })
        ?.catch((error) => {
          history?.push(ROUTES?.LOGIN);
          localStorage.clear();
          return error;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, currentUser]);

  const signInWithGoogle = () => {
    const provider = new auth.GoogleAuthProvider();
    provider.addScope(LINK.GOOGLE_API);
    return googleAuthUrl({
      variables: {
        screen: "login",
      },
    });
  };

  const signInWithMicrosoft = () => {
    const provider = new auth.OAuthProvider("microsoft.com");
    provider.setCustomParameters({
      prompt: "select_account",
    });
    return auth()
      .signInWithPopup(provider)
      .then((result) => {
        auth()
          .currentUser.getIdToken()
          .then(function (idToken) {
            socialAuth({
              variables: {
                firebaseToken: idToken,
              },
            })
              ?.then((res) => {
                const { accessToken, userInfo } = res?.data?.socialAuthV2;
                setCookieForLoggedInUser();
                if (result?.additionalUserInfo?.isNewUser) {
                  ReactGA.event({
                    category: "CB Signup",
                    action: "cb-user-signup",
                    label: "Signup with Microsoft",
                  });
                }
                if (!userInfo?.hasLoggedInBefore) {
                  successCallback(accessToken, userInfo);
                  localStorage?.setItem(
                    "user",
                    userInfo && Object?.keys(userInfo)?.length
                      ? JSON?.stringify(userInfo)
                      : null
                  );
                  localStorage?.setItem("currentSpace", userInfo?.spaceId);
                  history?.replace(ROUTES?.TELL_US_MORE);
                } else {
                  localStorage?.setItem("currentSpace", userInfo?.spaceId);
                  successCallback(accessToken, userInfo);
                }
                localStorage?.setItem("currentSpace", userInfo?.spaceId);
              })
              ?.catch(() => {
                history?.push(ROUTES?.LOGIN);
              });
          })
          ?.catch(() => {});
      });
  };

  const signInWithApple = () => {
    const provider = new auth.OAuthProvider("apple.com");
    return auth()
      .signInWithPopup(provider)
      .then((result) => {
        auth()
          .currentUser.getIdToken()
          .then(function (idToken) {
            socialAuth({
              variables: {
                firebaseToken: idToken,
              },
            })
              .then((res) => {
                const { accessToken, userInfo } = res?.data?.socialAuthV2;
                setCookieForLoggedInUser();
                if (result?.additionalUserInfo?.isNewUser) {
                  ReactGA.event({
                    category: "CB Signup",
                    action: "cb-user-signup",
                    label: "Signup with Apple",
                  });
                }

                if (!userInfo?.hasLoggedInBefore) {
                  successCallback(accessToken, userInfo);
                  localStorage?.setItem(
                    "user",
                    userInfo && Object?.keys(userInfo)?.length
                      ? JSON?.stringify(userInfo)
                      : null
                  );
                  localStorage?.setItem("currentSpace", userInfo?.spaceId);
                  history?.replace(ROUTES?.TELL_US_MORE);
                } else {
                  localStorage?.setItem("currentSpace", userInfo?.spaceId);
                  successCallback(accessToken, userInfo);
                }
                localStorage?.setItem("currentSpace", userInfo?.spaceId);
              })
              ?.catch((error) => {
                history?.push(ROUTES?.LOGIN);
                return error;
              });
          })
          ?.catch((error) => {
            return error;
          });
      });
  };

  const handleFinish = (value) => {
    const emailAddress = value?.email;
    setCurrentEmail(emailAddress);
    emailExists({
      variables: { email: emailAddress },
    })?.catch((error) => {
      return error;
    });
  };
  useEffect(() => {
    if (isGoogleUser) {
      signInWithGoogle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGoogleUser]);

  return authCode || isGoogleUser ? (
    <div className="cms-center-loading">
      <Loading />
    </div>
  ) : (
    <Layout>
      <div className="login-container">
        <div className="login-container-content">
          <div className="login-container-content-top-section">
            <span className="font-inter google-text mt-20">
              Welcome to ContactBook
            </span>

            <div className="flex-all-center full-width google-btn-wrapper">
              <Button
                className="font-roboto mt-32 google-brand-btn continue-google-btn-text"
                icon={<GoogleLoginLogo />}
                onClick={signInWithGoogle}
              >
                Sign in with Google
              </Button>
            </div>

            <div className="d-flex-center cms-signup-btn">
              <IconButton
                iconClass="cms-signup-btn-auth mt-16 cms-microsoft-auth-button  continue-google-btn"
                text={
                  <span className="font-inter auth-btn-text">
                    Continue with Microsoft
                  </span>
                }
                handleClick={signInWithMicrosoft}
                icon={<MicrosoftIcon className="mr-8" />}
              />
            </div>
            <div className="d-flex-center cms-signup-btn">
              <IconButton
                iconClass="cms-signup-btn-auth mt-16 cms-apple-auth-button  continue-google-btn"
                text={
                  <span className="font-inter auth-btn-text">
                    Continue with Apple
                  </span>
                }
                handleClick={signInWithApple}
                icon={<Apple className="mr-8" />}
              />
            </div>
            <div className="cms-upload-section-divider">
              <span className="font-inter cms-import-contact-link-or-text cms-text-border">
                OR
              </span>
            </div>
          </div>
          <div className="login-container-content-bottom-section">
            <span className="flex-all-center full-width">
              <Form
                form={form}
                layout="vertical"
                className="full-width"
                onFinish={handleFinish}
                requiredMark={false}
              >
                <span className="forgot-password-link">
                  <Link to={ROUTES?.FORGOT_PASSWORD}>Forgot password?</Link>
                </span>
                <Form.Item
                  name="email"
                  rules={[required, email]}
                  validateTrigger={["onPressEnter"]}
                >
                  <Input
                    placeholder="Enter email address"
                    className="cms-cont-input"
                  />
                </Form.Item>
                <Form.Item name="email">
                  <IconButton
                    htmlType="submit"
                    iconClass="cms-cont-email mt-16 mb-16"
                    text={
                      <span className="font-inter cms-cont-email-text ml-8">
                        Continue with Email
                      </span>
                    }
                    loading={emailExistsLoader}
                    icon={<EmailLetter />}
                  />
                </Form.Item>
              </Form>
            </span>
          </div>
          <div className="mt-24 cms-text-center">
            <TermsComponent />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Login;
