import React from "react";
import { Link, useHistory } from "react-router-dom";

//! Antd imports
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import Typography from "antd/lib/typography";

//! User files
import { formValidatorRules, trimWhiteSpace } from "../../utils";
import { ROUTES } from "../../constants";
import IconButton from "../IconButton/IconButton";
import { BackArrow, CloseEyeIcon, OpenEyeIcon } from "../../../assets/svg";
import TermsComponent from "../../TermsComponent";

const { required, email, password } = formValidatorRules;

const LoginSignupForm = ({ login, loading, handleFinish }) => {
  const [form] = useForm();
  const history = useHistory();
  const initialValues = { email: localStorage?.getItem("current_email") };

  return (
    <div className="cms-email-container">
      <IconButton
        iconClass="cms-login-back mb-16"
        text={<span className="font-inter cms-login-back-text">Back</span>}
        icon={<BackArrow />}
        handleClick={() => history.goBack()}
      />
      <div className="cms-login-title mb-8">
        {login ? "Sign In with Email" : "Sign Up with Email"}
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        requiredMark={!login}
        onFieldsChange={(e) => {
          trimWhiteSpace(form, e, ["password"]);
        }}
        initialValues={initialValues}
      >
        {login ? (
          <div>
            <Form.Item
              label="Email"
              name="email"
              rules={[required, email]}
              validateTrigger={["onPressEnter"]}
            >
              <Input
                placeholder="john.doe@gmail.com"
                disabled={!!initialValues?.email}
              />
            </Form.Item>
            <Form.Item
              label={
                <Row justify="space-between" className="label-width">
                  <Col>Password</Col>
                  <Col>
                    <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
                  </Col>
                </Row>
              }
              rules={[required, password]}
              name="password"
              validateTrigger={["onPressEnter"]}
            >
              <Input.Password
                placeholder="At least 8 characters"
                iconRender={(visible) =>
                  visible ? <OpenEyeIcon /> : <CloseEyeIcon />
                }
              />
            </Form.Item>
          </div>
        ) : (
          <div className="sigup-form">
            <Form.Item
              label="Full Name"
              name="name"
              rules={[required]}
              validateTrigger={["onPressEnter"]}
            >
              <Input placeholder="John Doe" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[required, email]}
              validateTrigger={["onPressEnter"]}
            >
              <Input
                placeholder="john.doe@gmail.com"
                disabled={!!initialValues?.email}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[required, password]}
              validateTrigger={["onPressEnter"]}
            >
              <Input.Password
                placeholder="At least 8 characters"
                iconRender={(visible) =>
                  visible ? <OpenEyeIcon /> : <CloseEyeIcon />
                }
              />
            </Form.Item>
            <Form.Item
              label="Confirm password"
              name="confirmPassword"
              rules={[required, password]}
              validateTrigger={["onPressEnter"]}
            >
              <Input.Password
                placeholder="At least 8 characters"
                iconRender={(visible) =>
                  visible ? <OpenEyeIcon /> : <CloseEyeIcon />
                }
              />
            </Form.Item>
          </div>
        )}
        <Row justify="end">
          <Col xs={24} sm={24} md={6} lg={6} xl={24}>
            <IconButton
              iconClass="cms-email-login-btn"
              text={
                <span className="font-inter cms-email-login-btn-text">
                  {login ? "Sign In" : "Sign Up"}
                </span>
              }
              htmlType="submit"
              loading={loading}
            />
          </Col>
        </Row>
      </Form>
      <div className="align-text-center">
        {login ? (
          <Typography.Text className="cms-no-account mb-16">
            Do not have an account? &nbsp;
            <Link to={ROUTES.SIGNUP}>Sign Up</Link>
          </Typography.Text>
        ) : (
          <Typography.Text className="cms-no-account mb-16">
            Already have an account? &nbsp;
            <Link to={ROUTES.EMAIL_LOGIN}>Sign In</Link>
          </Typography.Text>
        )}
      </div>
      <TermsComponent />
    </div>
  );
};

export default LoginSignupForm;
