import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const SUBSCRIBE_GROUP_NOTIFICATION = gql`
  mutation subscribeGroupNotification($groupId: Int!) {
    subscribeGroupNotification(groupId: $groupId) {
      message
      status
    }
  }
`;
