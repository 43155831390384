import React, { useContext, useEffect, useState } from "react";

//! Ant Imports
import Row from "antd/lib/row";
import Col from "antd/lib/col";

//! User files
import { Button } from "antd";
import {
  SET_CURRENT_SELECTED_LABELS,
  SET_RUN_TOUR,
} from "../../../../../common/actionTypes";
import GroupListItems from "./GroupListItems";
import SpacelistSkeleton from "../../../../../common/components/SpacelistSkeleton/SpacelistSkeleton";
import { AppContext } from "../../../../../AppContext";
import {
  ADMIN,
  GOOGLE,
  GROUP_LIST_LIMIT,
  MANAGE,
  OWNER,
} from "../../../../../common/constants";
import { GroupShareIcon, OneShareIcon } from "../../../../../assets/svg";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import ShareTick from "../../../../../assets/images/shareTick.gif";
import ReactJoyrideGroup from "../../../../../common/ReactJoyrideGroup";

const GroupList = ({
  space,
  groupListLoader,
  isSpacePlanActive,
  isFreeTrial,
  checkAll,
  setGroupCheckList,
  groupCheckList,
  groups,
  offset,
  setRunTour,
  runTour,
  setCheckAll,
  setOffset,
  loadMoreBtnDisabled = false,
  showLoadMoreBtn,
}) => {
  const {
    state: { userSubscriptionInfo, currentUser },
    dispatch,
  } = useContext(AppContext);
  const [currentStep, setCurrentStep] = useState(0);
  const permissionArray = [OWNER, ADMIN, MANAGE];

  const handleNext = () => {
    setCurrentStep(1);
  };

  //! Will use this later on for dynamic css
  // useEffect(() => {
  //   const currentSpotLight = document.querySelector("#react-joyride-portal");
  //   if (currentStep === 0 && currentSpotLight) {
  //     currentSpotLight.children[0]
  //       .querySelector(".react-joyride__spotlight")
  //       .classList.add("step-0-spotLight");
  //   }
  // }, [currentStep]);

  const tooltip = (
    <div className="cms-share-tooltip-wrapper">
      <span className="cms-share-tooltip-wrapper-title font-inter mb-24">
        Select groups
      </span>
      <span>
        <img src={ShareTick} alt="Done" height={96} width={96} />
      </span>
      <span className="cms-share-tooltip-wrapper-content mb-24 mt-24">
        Select a single or multiple groups to share it with other google users.
        Select all to share all groups with users.
      </span>
      <IconButton
        iconClass={`${
          groupCheckList?.length > 0 ? "active-btn" : ""
        } cms-share-tooltip-wrapper-next-btn`}
        text={<span className="font-inter cms-next-btn">Next</span>}
        disable={groupCheckList?.length === 0}
        handleClick={handleNext}
      />
    </div>
  );

  const shareTooltip = (
    <div className="cms-share-tooltip-wrapper">
      <span className="cms-share-tooltip-wrapper-title font-inter mb-24">
        Share Groups
      </span>
      <div className="cms-group-share-empty-state">
        <div className="cms-group-share-empty-state-top-section">
          <span>
            <OneShareIcon />
          </span>
          <span className="cms-dash-border" />
          <span>
            <GroupShareIcon />
          </span>
        </div>
      </div>
      <span className="cms-share-tooltip-wrapper-content mt-24">
        Click on Share icon to share contacts with other Google users.
      </span>
    </div>
  );

  const steps = [
    {
      content: tooltip,
      placement: "right-start",
      target: ".cms-group-share-joyride",
      textAlign: "center",
      placementBeacon: "top-end",
      run: localStorage.getItem("runTour") !== "false" || runTour,
    },
    {
      content: shareTooltip,
      placement: "bottom",
      target: ".cms-label-share-icon",
      textAlign: "center",
      placementBeacon: "top",
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    if (
      currentUser?.hasLoggedInBefore &&
      groups?.length > 0 &&
      document.getElementsByClassName("cms-space-list")?.length > 0 &&
      userSubscriptionInfo?.type === GOOGLE &&
      permissionArray?.includes(userSubscriptionInfo?.permission) &&
      localStorage.getItem("runTour") !== "false"
    ) {
      setRunTour(true);
      dispatch({
        type: SET_RUN_TOUR,
        data: true,
      });
      setCurrentStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, groups, userSubscriptionInfo]);

  const handleClose = (data) => {
    if (data?.action === "close" && data?.type === "step:after") {
      localStorage.setItem("runTour", false);
      setRunTour(false);
      dispatch({
        type: SET_CURRENT_SELECTED_LABELS,
        data: false,
      });
      setCurrentStep(0);
      setGroupCheckList([]);
      dispatch({
        type: SET_CURRENT_SELECTED_LABELS,
        data: [],
      });
      setCheckAll(false);
    }
    if (data?.action === "start" && data?.type === "tour:start") {
      setRunTour(true);
      dispatch({
        type: SET_CURRENT_SELECTED_LABELS,
        data: true,
      });
      setCurrentStep(0);
    }
  };

  return (
    <>
      {groupListLoader && offset === 0 ? (
        <div className="cms-skeleton-layout">
          <SpacelistSkeleton loading={groupListLoader} />
        </div>
      ) : (
        <>
          <div
            className={`${
              !isSpacePlanActive || isFreeTrial ? "new-height" : "normal-height"
            } ${
              userSubscriptionInfo?.type === GOOGLE
                ? "google-space-height"
                : "normal-space-height"
            } cms-space-list cms-contact-scrollbar`}
          >
            {space?.initialized && (
              <div key={space?.id}>
                <Row className="lists-data-row cms-scrollbar">
                  <Col className="group-list-col">
                    <GroupListItems
                      noGroupContactsCount={space?.noGroupContactsCount}
                      groups={groups}
                      spacePermission={space?.permission}
                      spaceId={space?.id}
                      deletedContactsCount={space?.deletedContactsCount}
                      checkAll={checkAll}
                      setGroupCheckList={setGroupCheckList}
                      groupCheckList={groupCheckList}
                      spaceType={space?.type}
                      spaceOwnerId={space?.ownerId}
                    />
                  </Col>
                </Row>
                {showLoadMoreBtn && (
                  <div className="d-flex justify-center mb-8 mt-10">
                    <Button
                      loading={groupListLoader}
                      type="primary"
                      onClick={() => setOffset(offset + GROUP_LIST_LIMIT)}
                      disabled={loadMoreBtnDisabled}
                    >
                      Load More
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          {currentUser?.hasLoggedInBefore &&
            groups?.length > 0 &&
            document.getElementsByClassName("cms-space-list")?.length > 0 &&
            userSubscriptionInfo?.type === GOOGLE &&
            permissionArray?.includes(userSubscriptionInfo?.permission) && (
              <ReactJoyrideGroup
                steps={steps}
                runTour={runTour}
                currentStep={currentStep}
                handleClose={handleClose}
              />
            )}
        </>
      )}
    </>
  );
};

export default GroupList;
