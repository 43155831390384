import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_SYNC_STATUS = gql`
  query getSyncStatus($id: Int!) {
    getSyncStatus(id: $id) {
      inProgress
    }
  }
`;
