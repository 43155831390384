import React, { useEffect } from "react";

//! Antd imports
import Button from "antd/lib/button";
import Skeleton from "antd/lib/skeleton";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Divider } from "antd";

//! User files
import {
  GOOGLE,
  MONTH,
  PLAN_STATUS,
  YEAR,
} from "../../../../../common/constants";
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import {
  capitalizeWord,
  generateRandomColor,
} from "../../../../../common/utils";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { GoogleLogo, OneMemberIcon, InfoIcon } from "../../../../../assets/svg";

const SubscriptionHeader = ({
  setInterval,
  interval,
  setSliderValue,
  sliderValue,
  spaceDetail,
  paymentLoader,
  retryPayment,
  isLTDPlan,
}) => {
  useEffect(() => {
    if (sliderValue === null) {
      setSliderValue(spaceDetail?.type === GOOGLE ? 5 : 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderValue]);

  // eslint-disable-next-line no-shadow
  const handleIntervalSwitch = (interval) => {
    setInterval(interval);
  };

  return (
    <div className="cms-subs-header">
      {spaceDetail?.subscription?.planStatus === PLAN_STATUS.CANCELLED && (
        <div className="cms-subs-header-user-message cms-message-error">
          <span className="cms-subs-header-user-message-text cms-message-error-text font-inter">
            Your subscription is cancelled
          </span>
        </div>
      )}
      {spaceDetail?.subscription?.planStatus === PLAN_STATUS.PAST_DUE && (
        <div className="cms-subs-header-user-message">
          <span className="cms-subs-header-user-message-text font-inter">
            You need to confirm the purchase of your updated subscription or you
            can contact support to cancel changes.
          </span>
          <IconButton
            iconClass="cms-subs-update-payment-btn"
            text={
              <span className="cms-subs-update-payment-btn-text">
                Confirm updated subscription
              </span>
            }
            icon={<ArrowRightOutlined />}
            handleClick={retryPayment}
            loading={paymentLoader}
          />
        </div>
      )}
      <div className="cms-subs-header-wrapper">
        {!isLTDPlan && (
          <div className="cms-subs-header-wrapper-left-section">
            {spaceDetail?.name ? (
              <div className="space-details-card">
                <div className="cms-subs-space-name">
                  <UserAvatar
                    className="cms-subs-space-avatar"
                    name={spaceDetail?.name}
                    photo={spaceDetail?.photo}
                    color={generateRandomColor(parseInt(spaceDetail?.id, 10))}
                  />
                  {spaceDetail?.type === GOOGLE && (
                    <span className="cms-google-space-sider-logo space-list-logo">
                      <GoogleLogo />
                    </span>
                  )}
                  <span
                    className="font-inter cms-subs-space-text font-ellipsis"
                    title={capitalizeWord(spaceDetail?.name)}
                  >
                    {capitalizeWord(spaceDetail?.name)}
                  </span>
                </div>
                <Divider />
                <div className="justify-space-between">
                  <span className="flex-all-center">
                    <span className="mr-6 ml-5">
                      <OneMemberIcon />
                    </span>
                    <span className="member-text">Members:</span>
                  </span>
                  {spaceDetail?.subscription?.id === null ? (
                    <span className="mr-8">-</span>
                  ) : (
                    <span className="member-count-text">
                      <span
                        className={`${
                          spaceDetail?.membersCount ===
                          spaceDetail?.subscription?.quantity
                            ? "member-count-red"
                            : "member-count-blue"
                        }`}
                      >
                        {" "}
                        {spaceDetail?.membersCount || 0}
                      </span>{" "}
                      /{" "}
                      {spaceDetail?.subscription?.planStatus ===
                      PLAN_STATUS?.TRIAL
                        ? 3
                        : spaceDetail?.subscription?.quantity}
                    </span>
                  )}
                </div>
                <Divider />
                <div className="d-flex">
                  <span className="mr-8">
                    <InfoIcon />
                  </span>
                  <span className="info-text">
                    Update your plan to add more seats so you can share contacts
                    with more members.
                  </span>
                </div>
              </div>
            ) : (
              <div className="cms-subs-space-name">
                <Skeleton avatar active title={false} paragraph={{ rows: 1 }} />
              </div>
            )}
          </div>
        )}
        <div className="full-width flex-all-center">
          {!isLTDPlan && (
            <div className="cms-subs-header-wrapper-right-section">
              <div className="button-switch">
                <div
                  className="button-inside-div"
                  onClick={() => handleIntervalSwitch(MONTH)}
                >
                  <Button className={interval === MONTH && "active"}>
                    Monthly
                  </Button>
                </div>
                <div
                  className="button-inside-div"
                  onClick={() => handleIntervalSwitch(YEAR)}
                >
                  <Button className={interval === YEAR && "active"}>
                    Annually
                  </Button>
                </div>
              </div>
              <span className="font-inter annual-subs-text">
                Save <span className="font-brand-color">&nbsp;20%&nbsp;</span>{" "}
                on annual subscription
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionHeader;
