import React from "react";
import { Button, Col, List, Row, Tag } from "antd";
import Text from "antd/lib/typography/Text";
import { getNameTag } from "../../../../../common/utils";
import ContactCard from "../../../../../common/components/ContactCard/ContactCard";

function ReminderList() {
  const data = [
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 2,
    },
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 3,
    },
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 2,
    },
    {
      firstName: "Vaastav",
      lastName: "Parikh",
      jobTitle: "Jr. product manager",
      issues: 3,
    },
  ];
  return (
    <div className="reminders-list-wrapper">
      <Row className="all-contacts">
        <Col className="contacts-col cms-contact-scrollbar">
          <List
            className={` contacts-list `}
            dataSource={data}
            renderItem={(item) => {
              const {
                firstName,
                middleName,
                lastName,
                photo,
                jobTitle,
                id,
                company,
              } = item;
              const name =
                // eslint-disable-next-line prefer-template
                (firstName ?? "") +
                " " +
                (middleName ?? "") +
                " " +
                (lastName ?? "");
              const title = (
                <Text className="username cms-font-weight-medium font-inter">
                  {getNameTag(name, company)}
                </Text>
              );
              const description = (
                <Row className="description-row">
                  {jobTitle && (
                    <Col span={24} className="designation-col">
                      <Text type="secondary" className="designation">
                        {jobTitle}
                      </Text>
                    </Col>
                  )}
                </Row>
              );
              return (
                <div>
                  <ContactCard
                    firstName={firstName}
                    company={company}
                    photo={photo}
                    title={title}
                    description={description}
                    item={item}
                    size={56}
                    contactId={id}
                  />
                  <div
                    style={{
                      padding: "12px 0 12px 15px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tag>Weekly | 8 jul</Tag>
                    <div>
                      <Button type="text">Ignore</Button>
                      <Button type="text">Done</Button>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </Col>
      </Row>
      {/* <RemindersCard /> */}
    </div>
  );
}

export default ReminderList;
