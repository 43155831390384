import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_SPACE_PERMISSION = gql`
  mutation updateSpacePermission(
    $id: Int!
    $permissions: SpacePermissions!
    $spaceId: Int!
  ) {
    updateSpacePermission(
      id: $id
      permissions: $permissions
      spaceId: $spaceId
    ) {
      data {
        id
        name
        ownerId
        createdAt
        updatedAt
      }
      status
      message
    }
  }
`;
