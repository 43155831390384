import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import filter from "lodash/filter";
import isInteger from "lodash/isInteger";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";

//! Antd imports
import { Col, Row } from "antd";

//! Graphql files
import { GET_TAGS_LIST } from "../../../graphql/queries/getTagsList";

//! User files
import TagsListItems from "./TagsListItems";
import SpacelistSkeleton from "../../../../../common/components/SpacelistSkeleton/SpacelistSkeleton";
import {
  CONTACT_LIMIT,
  GOOGLE,
  SOURCE_MOBILE,
} from "../../../../../common/constants";
import NoGroupTagFound from "../../../../../common/components/NoGroupTagFound/NoGroupTagFound";
import { TagIcon } from "../../../../../assets/svg";
import Loading from "../../../../../common/components/Loading";
import { AppContext } from "../../../../../AppContext";
import {
  SET_REFETCH_TAGS,
  SET_TAG_LIST,
} from "../../../../../common/actionTypes";

function TagsList({ isSpacePlanActive, isFreeTrial, space }) {
  const [tags, setTags] = useState([]);
  const [customTag, setCustomTag] = useState([]);
  const [spaceId, setSpaceId] = useState();
  const [offset, setOffset] = useState(0);
  const { pathname } = useLocation();
  const currentSpaceId = pathname?.split("/")[2];
  const {
    state: { userSubscriptionInfo, refetchTags },
    dispatch,
  } = useContext(AppContext);
  const deletedTagId = localStorage.getItem("deletedTagId");

  const [fetchTagsList, { data, loading }] = useLazyQuery(GET_TAGS_LIST, {
    fetchPolicy: "network-only",
    variables: {
      offset: offset,
      limit: CONTACT_LIMIT,
      spaceId:
        currentSpaceId === "all" || currentSpaceId === undefined
          ? parseInt(spaceId, 10)
          : parseInt(currentSpaceId, 10),
    },
    onCompleted() {
      dispatch({
        type: SET_REFETCH_TAGS,
        data: false,
      });
    },
    onError() {},
  });

  useEffect(() => {
    if (space) {
      setTimeout(() => {
        setSpaceId(currentSpaceId === "all" ? space?.id : currentSpaceId);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space]);

  useEffect(() => {
    if (refetchTags && !loading) {
      setTags([]);
      fetchTagsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchTags]);
  useEffect(() => {
    if (spaceId && isInteger(parseInt(spaceId, 10))) {
      fetchTagsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (tags) {
      const getTags = () => {
        return filter(
          tags,
          (item) =>
            item?.type !== "AUTO" ||
            (item?.tag === SOURCE_MOBILE && item?.contacts !== 0)
        );
      };

      let uniqTags = "";
      if (deletedTagId) {
        uniqTags = filter(
          uniqBy(getTags(), "id"),
          (e) => e?.id !== parseInt(deletedTagId, 10),
          "id"
        );
        localStorage.removeItem("deletedTagId");
      } else {
        uniqTags = uniqBy(getTags(), "id");
      }
      setCustomTag(uniqTags);
      dispatch({
        type: SET_TAG_LIST,
        data: uniqTags,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    if (data && !loading) {
      setTags([...tags, ...data?.tagList?.data?.tags]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (deletedTagId) {
      const updateTags = filter(
        tags,
        (val) => val?.id !== parseInt(deletedTagId, 10)
      );
      setTags(updateTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedTagId]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;

    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      customTag?.length < data?.tagList?.data?.count
    ) {
      setOffset(offset + CONTACT_LIMIT);
    }
  };

  return (
    <>
      {
        // eslint-disable-next-line no-nested-ternary
        loading && customTag?.length === 0 && offset === 0 ? (
          <SpacelistSkeleton loading={loading} />
        ) : customTag?.length === 0 && !loading && offset === 0 ? (
          <NoGroupTagFound text="No tags found" icon={<TagIcon />} />
        ) : (
          <div
            className={`${
              !isSpacePlanActive || isFreeTrial ? "new-height" : "normal-height"
            } ${
              userSubscriptionInfo?.type === GOOGLE
                ? "google-space-height"
                : "normal-space-height"
            } cms-space-list cms-contact-scrollbar tag-list`}
            onScroll={handleInfiniteScroll}
          >
            <div key={space?.id}>
              <Row className="lists-data-row cms-scrollbar">
                <Col className="group-list-col" span={24}>
                  <TagsListItems
                    tags={sortBy(customTag, "tag")}
                    spaceId={spaceId}
                  />
                </Col>
              </Row>
              {loading && (
                <div className="cms-infinite-scroll-loader">
                  <Loading />
                </div>
              )}
            </div>
          </div>
        )
      }
    </>
  );
}

export default TagsList;
