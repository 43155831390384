import React, { useContext } from "react";
import difference from "lodash/difference";
import moment from "moment";

//! Antd files
import Divider from "antd/lib/divider";
import Tag from "antd/lib/tag";
import Tooltip from "antd/lib/tooltip";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";
import cloneDeep from "lodash/cloneDeep";

//! User files
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";
import {
  capitalizeWord,
  generateRandomColor,
  getFullContactName,
  getIcon,
  validateEmail,
} from "../../../../../common/utils";
import {
  DocumentIcon,
  TagIcon,
  DropdownIcon,
  NoteFolderIcon,
} from "../../../../../assets/svg";
import { AppContext } from "../../../../../AppContext";
import { ACTIVITY_TYPE } from "../../../../../common/constants";

const ContactActivityListItem = ({ activity, activityLength, index }) => {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const menu = (items) => {
    const contact = cloneDeep(items);
    contact.name = getFullContactName(
      items?.firstName,
      items?.middleName,
      items?.lastName
    );
    return (
      <Menu>
        {Object?.entries(contact)?.map(
          ([key, value], i) =>
            key !== "updatedAt" &&
            key !== "tags" &&
            key !== "groupIds" &&
            key !== "sourceType" &&
            key !== "firstName" &&
            key !== "middleName" &&
            key !== "lastName" &&
            key !== "duplicate" &&
            value !== 0 &&
            value?.length > 0 && (
              // eslint-disable-next-line react/no-array-index-key
              <Menu.Item className="cms-log-field 1" key={i}>
                {key === "name" && value?.trim()?.length > 0 && (
                  <>
                    <span className="cms-log-field-name">Name updated</span>
                    <span className="cms-log-field-value" title={value}>
                      {value}
                    </span>
                  </>
                )}
                {(key === "emails" ||
                  key === "numbers" ||
                  key === "websites" ||
                  key === "socialProfiles") && (
                  <>
                    <span className="cms-log-field-name">
                      {key === "socialProfiles"
                        ? "Social Profile"
                        : capitalizeWord(key?.toLowerCase())}{" "}
                      updated
                    </span>
                    <div className="cms-log-field-name-multiple">
                      {value?.map((val) => (
                        <span
                          className={`${
                            value?.length > 1
                              ? "cms-log-field-name-multiple-value"
                              : ""
                          } cms-log-field-value`}
                          key={val?.value}
                          title={val?.value}
                        >
                          {val?.value}
                        </span>
                      ))}
                    </div>
                  </>
                )}
                {(key === "jobTitle" || key === "company") && (
                  <>
                    <span className="cms-log-field-name">
                      {capitalizeWord(key?.toLowerCase())} updated
                    </span>
                    <span className="cms-log-field-value">{value}</span>
                  </>
                )}
                {(key === "anniversary" || key === "birthday") && (
                  <>
                    <span className="cms-log-field-name">
                      {capitalizeWord(key?.toLowerCase())} updated
                    </span>
                    <span className="cms-log-field-value">
                      {moment(value).format("DD-MM-YYYY")}
                    </span>
                  </>
                )}
                {(key === "addressCount" || key === "contactAddresses") &&
                  (value > 0 || value?.length > 0) && (
                    <span className="cms-log-field-name">
                      Contact address updated
                    </span>
                  )}
                {key === "photo" && (
                  <span className="cms-log-field-name">
                    Profile photo updated
                  </span>
                )}
              </Menu.Item>
            )
        )}
      </Menu>
    );
  };

  const getTagInfo = (data1, data2) => {
    return difference(data1, data2);
  };

  const renderDivider =
    activityLength - 1 !== index ? (
      <Divider className="cms-activity-log-divider" />
    ) : (
      ""
    );

  const checkIfEmail = (name) => {
    return validateEmail(name) ? name : capitalizeWord(name);
  };

  return (
    <div className="activity-log-tab">
      {activity?.type === ACTIVITY_TYPE?.NEW_CONTACT && (
        <>
          <div className="activity-log-content">
            <Tag
              icon={
                <UserAvatar
                  className="font-inter cms-activity-log-avatar"
                  name={activity?.newData?.firstName}
                  color={generateRandomColor(parseInt(activity?.contactId, 10))}
                  photo={activity?.newData?.photo}
                />
              }
              className="activity-log-content-name"
            >
              <span className="cms-user-name">
                {capitalizeWord(
                  // eslint-disable-next-line prefer-template
                  activity?.newData?.firstName +
                    " " +
                    activity?.newData?.lastName
                )}
              </span>
            </Tag>
            <span className="cms-text-space">added by</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>you</span>
            ) : (
              <Tag
                icon={
                  <UserAvatar
                    className="font-inter cms-activity-log-avatar"
                    name={activity?.user?.name}
                    color={generateRandomColor(
                      parseInt(activity?.user?.id, 10)
                    )}
                    photo={activity?.user?.photo}
                  />
                }
                className="activity-log-content-name cms-mLeft"
              >
                <span className="cms-user-name">
                  {checkIfEmail(activity?.user?.name)}
                </span>
              </Tag>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.NEW_NOTE && (
        <>
          <div className="activity-log-content">
            <Tag
              icon={<DocumentIcon className="activity-log-content-note-icon" />}
              className="activity-log-content-note"
            >
              <span className="cms-activity-user-name">Note</span>
            </Tag>
            <span className="cms-text-space">added by</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>you</span>
            ) : (
              <Tag
                icon={
                  <UserAvatar
                    className="font-inter cms-activity-log-avatar"
                    name={activity?.user?.name}
                    color={generateRandomColor(
                      parseInt(activity?.user?.id, 10)
                    )}
                    photo={activity?.user?.photo}
                  />
                }
                className="activity-log-content-name cms-mLeft"
              >
                <span className="cms-user-name">
                  {checkIfEmail(activity?.user?.name)}
                </span>
              </Tag>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.NOTE_REMOVED && (
        <>
          <div className="activity-log-content">
            <Tag
              icon={<DocumentIcon className="activity-log-content-note-icon" />}
              className="activity-log-content-note"
            >
              <span className="cms-activity-user-name">Note</span>
            </Tag>
            <span className="cms-text-space">removed by</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>you</span>
            ) : (
              <Tag
                icon={
                  <UserAvatar
                    className="font-inter cms-activity-log-avatar"
                    name={activity?.user?.name}
                    color={generateRandomColor(
                      parseInt(activity?.user?.id, 10)
                    )}
                    photo={activity?.user?.photo}
                  />
                }
                className="activity-log-content-name cms-mLeft"
              >
                <span className="cms-user-name">
                  {checkIfEmail(activity?.user?.name)}
                </span>
              </Tag>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.NEW_ATTACHMENT && (
        <>
          <div className="activity-log-content">
            <Tooltip title={activity?.newData?.name}>
              <Tag
                icon={getIcon(activity?.newData?.type)}
                className="activity-log-content-note"
              >
                <span className="cms-activity-user-name">
                  {activity?.newData?.name}
                </span>
              </Tag>
            </Tooltip>
            <span className="cms-text-space">added by</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>you</span>
            ) : (
              <Tag
                icon={
                  <UserAvatar
                    className="font-inter cms-activity-log-avatar"
                    name={activity?.user?.name}
                    color={generateRandomColor(
                      parseInt(activity?.user?.id, 10)
                    )}
                    photo={activity?.user?.photo}
                  />
                }
                className="activity-log-content-name cms-mLeft"
              >
                <span className="cms-user-name">
                  {checkIfEmail(activity?.user?.name)}
                </span>
              </Tag>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.ATTACHMENT_REMOVED && (
        <>
          <div className="activity-log-content">
            <Tooltip title={activity?.oldData?.name}>
              <Tag
                icon={getIcon(activity?.oldData?.type)}
                className="activity-log-content-note"
              >
                <span className="cms-activity-user-name">
                  {activity?.oldData?.name}
                </span>
              </Tag>
            </Tooltip>
            <span className="cms-text-space">removed by</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>you</span>
            ) : (
              <Tag
                icon={
                  <UserAvatar
                    className="font-inter cms-activity-log-avatar"
                    name={activity?.user?.name}
                    color={generateRandomColor(
                      parseInt(activity?.user?.id, 10)
                    )}
                    photo={activity?.user?.photo}
                  />
                }
                className="activity-log-content-name cms-mLeft"
              >
                <span className="cms-user-name">
                  {checkIfEmail(activity?.user?.name)}
                </span>
              </Tag>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.ATTACHMENT_UPDATE && (
        <>
          <div className="activity-log-content">
            <Tooltip title={activity?.oldData?.name}>
              <Tag
                icon={getIcon(activity?.oldData?.type)}
                className="activity-log-content-note"
              >
                <span className="cms-activity-user-name">
                  {activity?.oldData?.name}
                </span>
              </Tag>
            </Tooltip>
            <span className="cms-text-space">renamed to</span>
            <Tooltip title={activity?.newData?.name}>
              <Tag
                icon={getIcon(activity?.newData?.type)}
                className="activity-log-content-note"
              >
                <span className="cms-activity-user-name">
                  {activity?.newData?.name}
                </span>
              </Tag>
            </Tooltip>
            {activity?.user?.id === currentUser?.id ? (
              <span>by you</span>
            ) : (
              <>
                <span>by</span>
                <Tag
                  icon={
                    <UserAvatar
                      className="font-inter cms-activity-log-avatar"
                      name={activity?.user?.name}
                      color={generateRandomColor(
                        parseInt(activity?.user?.id, 10)
                      )}
                      photo={activity?.user?.photo}
                    />
                  }
                  className="activity-log-content-name cms-mLeft"
                >
                  <span className="cms-user-name">
                    {checkIfEmail(activity?.user?.name)}
                  </span>
                </Tag>
              </>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.NEW_TAG && (
        <>
          <div className="activity-log-content">
            {getTagInfo(activity?.newData, activity?.oldData)?.map((tag) => (
              <Tag
                icon={<TagIcon />}
                className="activity-log-content-note mb-4"
                key={tag}
              >
                <span className="cms-activity-user-name">{tag}</span>
              </Tag>
            ))}
            <span className="cms-text-space">added to the contact</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>by you</span>
            ) : (
              <>
                <span>by</span>
                <Tag
                  icon={
                    <UserAvatar
                      className="font-inter cms-activity-log-avatar"
                      name={activity?.user?.name}
                      color={generateRandomColor(
                        parseInt(activity?.user?.id, 10)
                      )}
                      photo={activity?.user?.photo}
                    />
                  }
                  className="activity-log-content-name cms-mLeft cms-avatar-mLeft"
                >
                  <span className="cms-user-name">
                    {checkIfEmail(activity?.user?.name)}
                  </span>
                </Tag>
              </>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.TAG_REMOVED && (
        <>
          <div className="activity-log-content">
            {getTagInfo(activity?.oldData, activity?.newData)?.map((tag) => (
              <Tag
                icon={<TagIcon />}
                className="activity-log-content-note"
                key={tag}
              >
                <span className="cms-activity-user-name">{tag}</span>
              </Tag>
            ))}
            <span className="cms-text-space">removed from the contact</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>by you</span>
            ) : (
              <>
                <span>by</span>
                <Tag
                  icon={
                    <UserAvatar
                      className="font-inter cms-activity-log-avatar"
                      name={activity?.user?.name}
                      color={generateRandomColor(
                        parseInt(activity?.user?.id, 10)
                      )}
                      photo={activity?.user?.photo}
                    />
                  }
                  className="activity-log-content-name cms-avatar-mLeft"
                >
                  <span className="cms-user-name">
                    {checkIfEmail(activity?.user?.name)}
                  </span>
                </Tag>
              </>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.CONTACT_UPDATE && (
        <>
          <div className="activity-log-wrapper">
            <div className="activity-log-content">
              <span>Contact details updated by </span>
              {activity?.user?.id === currentUser?.id ? (
                <span className="cms-mLeft">you</span>
              ) : (
                <Tag
                  icon={
                    <UserAvatar
                      className="font-inter cms-activity-log-avatar"
                      name={activity?.user?.name}
                      color={generateRandomColor(
                        parseInt(activity?.user?.id, 10)
                      )}
                      photo={activity?.user?.photo}
                    />
                  }
                  className="activity-log-content-name cms-mLeft"
                >
                  <span className="cms-user-name">
                    {checkIfEmail(activity?.user?.name)}
                  </span>
                </Tag>
              )}
            </div>
            <div
              className="activity-log-dropdown"
              id={`activityUpdate-${activity?.id}`}
            >
              <Dropdown
                overlay={menu(activity?.newData)}
                className="cms-activity-log-dropdown"
              >
                <span
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <DropdownIcon />
                </span>
              </Dropdown>
            </div>
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.NEW_GROUP && (
        <>
          <div className="activity-log-content">
            {activity?.newData?.map((group) => (
              <Tag
                icon={<NoteFolderIcon />}
                className="activity-log-content-note mb-4"
                key={group?.name}
              >
                <span className="cms-activity-user-name">{group?.name}</span>
              </Tag>
            ))}
            <span className="cms-text-space">added to the contact</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>by you</span>
            ) : (
              <>
                <span>by</span>
                <Tag
                  icon={
                    <UserAvatar
                      className="font-inter cms-activity-log-avatar"
                      name={activity?.user?.name}
                      color={generateRandomColor(
                        parseInt(activity?.user?.id, 10)
                      )}
                      photo={activity?.user?.photo}
                    />
                  }
                  className="activity-log-content-name cms-mLeft cms-avatar-mLeft"
                >
                  <span className="cms-user-name">
                    {checkIfEmail(activity?.user?.name)}
                  </span>
                </Tag>
              </>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
      {activity?.type === ACTIVITY_TYPE?.GROUP_REMOVE && (
        <>
          <div className="activity-log-content">
            {activity?.oldData?.map((group) => (
              <Tag
                icon={<NoteFolderIcon />}
                className="activity-log-content-note"
                key={group?.name}
              >
                <span className="cms-activity-user-name">{group?.name}</span>
              </Tag>
            ))}
            <span className="cms-text-space">removed from the contact</span>
            {activity?.user?.id === currentUser?.id ? (
              <span>by you</span>
            ) : (
              <>
                <span>by</span>
                <Tag
                  icon={
                    <UserAvatar
                      className="font-inter cms-activity-log-avatar"
                      name={activity?.user?.name}
                      color={generateRandomColor(
                        parseInt(activity?.user?.id, 10)
                      )}
                      photo={activity?.user?.photo}
                    />
                  }
                  className="activity-log-content-name cms-avatar-mLeft"
                >
                  <span className="cms-user-name">
                    {checkIfEmail(activity?.user?.name)}
                  </span>
                </Tag>
              </>
            )}
          </div>
          <div className="cms-activity-log-time">
            {moment(activity?.updatedAt).calendar()}
          </div>
          {renderDivider}
        </>
      )}
    </div>
  );
};

export default ContactActivityListItem;
