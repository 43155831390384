import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const EDIT_CONTACT = gql`
  mutation editContact($data: EditContactInput!, $id: Int!) {
    editContact(data: $data, id: $id) {
      data {
        id
        sourceType
        firstName
        middleName
        lastName
        jobTitle
        company
        photo
        birthday
        anniversary
        websites {
          type
          value
        }
        emails {
          type
          value
        }
        numbers {
          type
          value
        }
        tags
        groups {
          id
          name
          ownerId
          spaceId
        }
        contactAddresses {
          id
          label
          line1
          line2
          city
          county
          state
          country
          zipCode
          contactId
        }
        space {
          id
          name
        }
        spaceId
      }
      message
      status
    }
  }
`;
