import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const REMOVE_SPACE = gql`
  mutation removeSpace($id: Int!, $name: String!) {
    removeSpace(id: $id, name: $name) {
      message
      status
    }
  }
`;
