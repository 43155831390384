import React from "react";
import { FullLogo } from "../../../assets/svg";

function Layout({ children }) {
  return (
    <div className="onboarding">
      <div className="onboarding-layout">{children}</div>
      <center className="onboarding-layout-logo">
        <div>
          <FullLogo />
        </div>
      </center>
    </div>
  );
}

export default Layout;
