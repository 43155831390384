import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_ATTACHMENTS = gql`
  query getAttachments($contactId: Int!) {
    getAttachments(contactId: $contactId) {
      data {
        attachments {
          id
          name
          type
          size
          key
          url
          username
          contactId
          userId
          createdAt
          updatedAt
        }
        count
      }
    }
  }
`;
