import React from "react";

const WarningMessage = ({ content }) => {
  return (
    <div className="cms-warning-msg-wrapper">
      <span className="font-inter cms-warning-msg-wrapper-content">
        {content}
      </span>
    </div>
  );
};

export default WarningMessage;
