import { Button, Col, Row } from "antd";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useWindowSize } from "react-use";
import { useLazyQuery, useMutation } from "@apollo/client";

//! Antd import
import Modal from "antd/lib/modal";
import Tooltip from "antd/lib/tooltip";

//! Graphql files
import { COMPLETE_ONBOARDING } from "../graphql/Mutations";
import { CHECK_ADMIN_USER_AND_DOMAIN_WIDE_ACCESS } from "../graphql/Queries";

//! User files
import {
  CheckIcon,
  ToolTipIcon,
  WelcomeImage,
  DomainAccess,
  Logo,
  GoogleLogo,
  GoogleLoginLogo,
  ContactBookIcon,
  GoogleIcon,
} from "../../../assets/svg";
import IconButton from "../../../common/components/IconButton/IconButton";
import ContactSourceCard from "./ContactSourceCard";
import SpaceSharing from "./SpaceSharing";
import MoreFeatures from "./MoreFeatures";
import UserAvatar from "../../../common/components/UserAvatar/UserAvatar";
import { generateRandomColor } from "../../../common/utils";
import GooglePlus from "../../../assets/images/google-plus 1.png";
import TextTag from "../../../common/components/TextTag";
import { AppContext } from "../../../AppContext";
import Loading from "../../../common/components/Loading";
import { SET_IS_GSUITE_ADMIN } from "../../../common/actionTypes";
import {
  GOOGLE,
  GOOGLE_SPACE_ONBOARDING,
  WINDOW_SIZE,
} from "../../../common/constants";
import ProgressScreen from "../../../common/components/ProgressScreen/ProgressScreen";
import ContactsImportDone from "../../../common/components/ContactsImportDone/ContactsImportDone";

const OnBoardingModal = ({
  visible,
  closeOnBoardingModal,
  closeOnBoardingModalWithGoogleSwitch,
}) => {
  const [current, setCurrent] = useState(0);
  const [isImportContact, setIsImportContact] = useState(false);
  const [email, setEmail] = useState();
  const connectServiceRef = useRef();
  const importRef = useRef();
  const {
    state: { currentUser, userSubscriptionInfo, importStatusAndProgress },
    dispatch,
  } = useContext(AppContext);
  const currentStep = localStorage.getItem("currentStep");
  const { status, percent } = importStatusAndProgress;
  const [completeOnBoarding] = useMutation(COMPLETE_ONBOARDING);
  const jobId = localStorage.getItem("jobId");
  const googleSpaceOnboarding = localStorage.getItem(GOOGLE_SPACE_ONBOARDING);
  const googleLoader = localStorage.getItem("GoogleLoader");
  const { width } = useWindowSize();
  const googleSwitchComplete = localStorage.getItem("GoogleSwitchComplete");

  const [
    checkAdminUserAndDomainAccess,
    { data: checkAdminState, loading },
  ] = useLazyQuery(CHECK_ADMIN_USER_AND_DOMAIN_WIDE_ACCESS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      dispatch({
        type: SET_IS_GSUITE_ADMIN,
        data: data?.checkAdminUserAndDomainWideAccess?.isAdmin,
      });
    },
    onError() {},
  });

  const next = (step) => {
    setCurrent(current + 1);
    const currStep = current + 1;
    localStorage.setItem("currentStep", currStep);
    if (step === 1) {
      completeOnBoarding().then().catch();
    }
  };

  useEffect(() => {
    if (currentStep) {
      setCurrent(parseInt(currentStep, 10));
    }
  }, [currentStep, current]);

  useEffect(() => {
    if (
      (current === 1 || currentStep === 1) &&
      userSubscriptionInfo?.type === GOOGLE
    ) {
      checkAdminUserAndDomainAccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, currentStep, userSubscriptionInfo]);

  const connectSource = () => {
    if (isImportContact) {
      importRef?.current?.click();
    } else {
      connectServiceRef?.current?.click();
    }
  };

  const steps = [
    {
      id: 1,
      text: "Sign Up",
      status: "completed",
    },
    {
      id: 2,
      text: userSubscriptionInfo?.type === GOOGLE ? "Source" : "Add",
      status: current > 1 && "completed",
    },
    {
      id: 3,
      text: "Share",
      status: current > 2 && "completed",
    },
    {
      id: 4,
      text: "Done",
      status: current === 3 && "completed",
    },
  ];

  const tooltipText = (
    <span className="font-inter tooltip-text">
      All the contacts added in your space will be shared with these users. They
      can access the contacts after signup. You can share specific groups of
      contacts from the dashboard.
    </span>
  );

  const handleAccessLink = () => {
    window.open(
      `https://workspace.google.com/marketplace/dwi/${process.env.REACT_APP_MP_APPLICATION_ID}?redirect_url=${process.env.REACT_APP_BASE_URL}&authuser=${currentUser?.email}`,
      "_blank"
    );
  };

  const renderOnBoardingScreen = () => {
    if (userSubscriptionInfo?.type === GOOGLE) {
      //! Use this for domain admin and access not given
      if (
        checkAdminState?.checkAdminUserAndDomainWideAccess?.isAdmin &&
        !checkAdminState?.checkAdminUserAndDomainWideAccess?.domainWideAccess
      ) {
        return {
          title: (
            <span className="font-inter">
              Provide{" "}
              <span className="font-inter cms-contactbook">domain access </span>
              to your workspace
            </span>
          ),
          content: (
            <div className="d-flex-center cms-connect-section">
              <div className="d-flex-center cms-connect-wrapper mb-24">
                <div className="d-flex-center position-relative">
                  <UserAvatar
                    className="cms-domain-space-logo"
                    name={userSubscriptionInfo?.name}
                    size={48}
                    color={generateRandomColor(1)}
                  />
                  <span className="cms-domain-space-google-logo">
                    <GoogleLogo className="cms-svg-16" />
                  </span>
                </div>
                <span className="cms-dash-border" />
                <IconButton
                  iconClass="d-flex-center cms-cb-logo"
                  icon={<Logo />}
                  shape="circle"
                />
              </div>
              <div className="cms-domain-admin-content">
                When you provide domain wide access, your workspace members do
                not need to sign up to get shared contacts in their{" "}
                <span className="text-bold">Google Contacts.</span>
              </div>
              <IconButton
                iconClass="cms-form-save-btn cms-allow-access-btn"
                text={<span className="font-inter">Allow access</span>}
                handleClick={handleAccessLink}
                loading={loading}
              />
              <div className="cms-user-gmail d-flex-center mb-40">
                <div className="d-flex-center">
                  <img
                    src={GooglePlus}
                    alt="Google Plus"
                    className="cms-user-gmail-logo mr-12"
                  />
                  <span className="cms-user-gmail-text">
                    {currentUser?.email}
                  </span>
                </div>
                <TextTag
                  text="primary"
                  className="font-inter cms-shared-tag cms-user-gmail-tag"
                />
              </div>
            </div>
          ),
          buttonText: "Continue",
          event: next,
          isDisable: false,
        };
      }
      //! End - Admin but no access

      //! Admin and domain access given
      if (
        checkAdminState?.checkAdminUserAndDomainWideAccess?.isAdmin &&
        checkAdminState?.checkAdminUserAndDomainWideAccess?.domainWideAccess
      ) {
        return {
          title: (
            <span className="font-inter">
              <span className="font-inter cms-contactbook">Domain access </span>{" "}
              already available in your workspace
            </span>
          ),
          content: (
            <div className="d-flex-center cms-connect-section mt-53">
              <div className="d-flex-center cms-connect-wrapper mb-40">
                <div className="d-flex-center position-relative">
                  <UserAvatar
                    className="cms-domain-space-logo"
                    name={userSubscriptionInfo?.name}
                    size={48}
                    color={generateRandomColor(1)}
                  />
                  <span className="cms-domain-space-google-logo">
                    <GoogleLogo className="cms-svg-16" />
                  </span>
                </div>
                <span className="cms-dash-border cms-dash-width" />
                <DomainAccess className="position-absolute" />
                <IconButton
                  iconClass="d-flex-center cms-cb-logo"
                  icon={<Logo />}
                  shape="circle"
                />
              </div>
              <div className="cms-domain-admin-content cms-content-margin">
                When you provide domain wide access, your workspace members do
                not need to sign up to get shared contacts in their{" "}
                <span className="text-bold">Google Contacts.</span>
              </div>
              <div className="cms-user-gmail d-flex-center mb-40">
                <div className="d-flex-center">
                  <img
                    src={GooglePlus}
                    alt="Google Plus"
                    className="cms-user-gmail-logo mr-12"
                  />
                  <span className="cms-user-gmail-text">
                    {currentUser?.email}
                  </span>
                </div>
                <TextTag
                  text="primary"
                  className="font-inter cms-shared-tag cms-user-gmail-tag"
                />
              </div>
            </div>
          ),
          buttonText: "Continue",
          event: next,
          isDisable: false,
        };
      }
      //! End - Admin & domain access given

      //! Not an admin & no access
      if (
        (!checkAdminState?.checkAdminUserAndDomainWideAccess?.isAdmin &&
          !checkAdminState?.checkAdminUserAndDomainWideAccess
            ?.domainWideAccess) ||
        (!checkAdminState?.checkAdminUserAndDomainWideAccess?.isAdmin &&
          checkAdminState?.checkAdminUserAndDomainWideAccess?.domainWideAccess)
      ) {
        return {
          title: (
            <span className="font-inter">
              Your <span className="font-inter cms-contactbook">google </span>
              contact<span className="font-inter cms-contactbook"> source</span>
            </span>
          ),
          content: (
            <div className="d-flex-center cms-connect-section cms-max-width">
              <div className="d-flex-center cms-connect-wrapper mb-34">
                <IconButton
                  iconClass="d-flex-center cms-cb-logo cms-cb-large-logo"
                  icon={<Logo />}
                  shape="circle"
                />
                <span className="cms-dash-border cms-dash-width" />
                <span className="d-flex-center cms-cb-logo cms-cb-large-logo">
                  <img src={GooglePlus} alt="Google Plus" />
                </span>
              </div>
              <div className="cms-domain-admin-content mb-34">
                Your <span className="text-bold">Google Contacts</span> are
                synced with <span className="text-bold">ContactBook</span>.
                <br /> i.e. changes you make in Google Contacts will reflect in
                ContactBook and the other way around.
              </div>
              <div className="cms-user-gmail d-flex-center mb-40">
                <div className="d-flex-center">
                  <img
                    src={GooglePlus}
                    alt="Google Plus"
                    className="cms-user-gmail-logo mr-12"
                  />
                  <span className="cms-user-gmail-text">
                    {currentUser?.email}
                  </span>
                </div>
                <TextTag
                  text="primary"
                  className="font-inter cms-shared-tag cms-user-gmail-tag"
                />
              </div>
            </div>
          ),
          buttonText: "Continue",
          event: next,
          isDisable: false,
        };
      }
      //! End - Not an admin & no access
    }

    //! Users other than google
    if (
      userSubscriptionInfo?.type === GOOGLE ||
      userSubscriptionInfo?.type === undefined
    ) {
      return {
        content: (
          <div className="cms-step-loader">
            <Loading />
          </div>
        ),
        buttonText: "Continue",
        event: connectSource,
        isDisable: true,
      };
    }

    if (
      userSubscriptionInfo?.type !== GOOGLE &&
      userSubscriptionInfo?.type !== undefined
    ) {
      return {
        title: (
          <span className="font-inter">
            <span className="font-inter cms-contactbook">Add </span>your
            existing{" "}
            <span className="font-inter cms-contactbook">contacts</span>
          </span>
        ),
        content: (
          <ContactSourceCard
            connectServiceRef={connectServiceRef}
            importRef={importRef}
            current={current}
            setIsImportContact={setIsImportContact}
            isImportContact={isImportContact}
          />
        ),
        buttonText: "Continue",
        event: connectSource,
        isDisable: false,
      };
    }

    //! End - users other than google
  };

  const onBoardingSteps = [
    {
      image: <WelcomeImage />,
      title: (
        <span className="font-inter cms-text-center">
          Welcome to{" "}
          <span className="font-inter cms-contactbook">
            {userSubscriptionInfo?.type === GOOGLE
              ? "Google Workspace Sync"
              : "ContactBook"}
          </span>
        </span>
      ),
      content:
        userSubscriptionInfo?.type === GOOGLE
          ? "Your Google Contacts will be in sync with ContactBook. Share contacts with Google users and they will be able to access them in Google Contacts."
          : "Organize, manage and share contacts across your teams so everyone has access to relevant contacts all the time!",
      buttonText: "Let's Go",
      googleButtonText:
        "Switch to Google Space and start managing your contacts",
      googleEvent: closeOnBoardingModalWithGoogleSwitch,
      event: next,
      isDisable: false,
    },
    renderOnBoardingScreen(),
    //! With no labels
    {
      title: (
        <>
          {userSubscriptionInfo?.type === GOOGLE ? (
            <span className="font-inter">
              <span className="font-inter cms-contactbook">
                Syncing contacts{" "}
              </span>
              from your Google account
            </span>
          ) : (
            <>
              <span className="cms-contactbook">Share</span> your{" "}
              <span className="cms-contactbook">contacts</span> with your team{" "}
              <Tooltip
                title={tooltipText}
                placement="bottomRight"
                color="#101326"
              >
                <ToolTipIcon className="mt-4" />
              </Tooltip>
            </>
          )}
        </>
      ),
      content:
        // eslint-disable-next-line no-nested-ternary
        userSubscriptionInfo?.type !== GOOGLE ? (
          <SpaceSharing email={email} setEmail={setEmail} />
        ) : status !== "COMPLETED" && jobId > 0 ? (
          <ProgressScreen percent={percent} />
        ) : (
          <ContactsImportDone />
        ),
      buttonText: "Continue",
      event: next,
      isDisable:
        userSubscriptionInfo?.type === GOOGLE
          ? status !== "COMPLETED" && jobId > 0
          : email?.length > 0,
    },
    //! End - no labels
    {
      title: "You are all set!",
      content: <MoreFeatures spaceType={userSubscriptionInfo?.type} />,
      buttonText: "Start managing your contacts",
      event: closeOnBoardingModal,
      isDisable: false,
    },
  ];

  const Stepper = () =>
    steps?.map((step, index) => (
      <div
        className={`cms-step-default ${
          step?.status === "completed" ? "cms-step-completed" : ""
        } ${current !== 3 && index === current ? "cms-step-progress" : ""}`}
        key={step?.id}
      >
        <div className="cms-step-circle mb-4">
          {step?.status === "completed" && <CheckIcon />}
          {index === current && <span className="cms-inner-circle" />}
        </div>
        <div className="cms-step-text font-inter">{step?.text}</div>
      </div>
    ));

  useEffect(() => {
    window.addEventListener("visibilitychange", function () {
      if (
        !document?.hidden &&
        checkAdminState?.checkAdminUserAndDomainWideAccess?.isAdmin &&
        !checkAdminState?.checkAdminUserAndDomainWideAccess?.domainWideAccess
      ) {
        setTimeout(() => {
          checkAdminUserAndDomainAccess();
        }, 2000);
      }
    });

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("visibilitychange", function () {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAdminState]);

  return (
    <Modal
      className="cms-onboarding-modal"
      keyboard={false}
      maskClosable={false}
      closable={false}
      centered
      visible={visible}
      footer={null}
    >
      <div className="cms-boarding-steps">
        {current !== 0 &&
          current !== 3 &&
          userSubscriptionInfo?.type !== GOOGLE && (
            <span
              className="cms-step-skip cursor-pointer"
              onClick={() => next(current)}
            >
              {" "}
              Skip
            </span>
          )}
        {current === 0 && (
          <div className="cms-welcome-page mt-16">
            <div className="cms-welcome-page-top">
              <span className="font-inter cms-welcome-page-top-title">
                <span className="font-inter cms-title-wrapper">
                  {onBoardingSteps[current]?.title}
                </span>
              </span>
              <span className="cms-welcome-page-top-subtitle">
                {
                  // eslint-disable-next-line no-nested-ternary
                  currentUser?.provider === GOOGLE &&
                  userSubscriptionInfo?.type !== GOOGLE
                    ? "It looks like you have signed up with a Google account!"
                    : userSubscriptionInfo?.type === GOOGLE
                    ? "Share your Google Contacts with other Google accounts with ease!"
                    : "Contact management made easy!"
                }
              </span>
              {
                /* //! Domain name for google sync */
                userSubscriptionInfo?.type === GOOGLE && (
                  <span className="cms-domain-name mt-16">
                    <div className="d-flex-center cms-avatar-wrapper">
                      <UserAvatar
                        className="cms-domain-name-image mr-12"
                        name={userSubscriptionInfo?.name}
                        size={24}
                        color={generateRandomColor(1)}
                      />
                      <span className="cms-domain-name-google-logo">
                        <GoogleLogo className="cms-svg-16" />
                      </span>
                    </div>
                    <span className="username">
                      {userSubscriptionInfo?.name}
                    </span>
                  </span>
                )
              }
              {/* //! End of domain name */}
            </div>
          </div>
        )}
        {current !== 0 && (
          <div className="cms-stepper mt-16 mb-24">
            <Stepper />
          </div>
        )}
        {onBoardingSteps[current]?.image &&
          currentUser?.provider === GOOGLE &&
          userSubscriptionInfo?.type !== GOOGLE &&
          !googleSpaceOnboarding &&
          !googleSwitchComplete &&
          !googleLoader && (
            <div className="cms-image-wrapper">
              <Row className="cms-form-row cms-text-center">
                <Col span={12} className="padding-15">
                  <GoogleIcon
                    height={150}
                    width={225}
                    className="mb-16 mt-16 small-svg"
                  />
                  <h2>Two-way sync with Google</h2>
                  <p className="cms-welcome-content-google">
                    Continue here if you want to sync and share your Google
                    Contacts directly with other Google accounts.
                  </p>
                  <Button
                    className="font-roboto google-brand-btn continue-google-btn-text m-auto cms-steps-action-button-google"
                    icon={<GoogleLoginLogo height={30} width={30} />}
                    onClick={onBoardingSteps[current]?.googleEvent}
                  >
                    {width <= WINDOW_SIZE ? "Google" : "Continue with Google"}
                  </Button>
                </Col>
                <Col span={12} className="padding-15">
                  <ContactBookIcon
                    height={150}
                    width={225}
                    className="mb-16 mt-16 small-svg"
                    title="ContactBook"
                  />
                  <h2>Standalone Contact Manager</h2>
                  <p className="cms-welcome-content-google">
                    Centralize all contacts and manage them with your team using
                    ContactBook on the web and mobile.
                  </p>
                  <IconButton
                    iconClass="cms-steps-action-button m-auto"
                    text={
                      <span className="cms-steps-action-button-text font-inter">
                        {width <= WINDOW_SIZE
                          ? "ContactBook"
                          : "Continue with ContactBook"}
                      </span>
                    }
                    type="primary"
                    handleClick={onBoardingSteps[current]?.event}
                    disable={onBoardingSteps[current]?.isDisable}
                  />
                </Col>
              </Row>
            </div>
          )}
        {current === 0 &&
          userSubscriptionInfo?.type === GOOGLE &&
          currentUser?.provider === GOOGLE && (
            <div className="cms-image-wrapper">
              <WelcomeImage className="mb-16 mt-16" />
            </div>
          )}
        {current === 0 &&
          userSubscriptionInfo?.type !== GOOGLE &&
          currentUser?.provider !== GOOGLE && (
            <div className="cms-image-wrapper">
              <WelcomeImage className="mb-16 mt-16" />
            </div>
          )}
        {current === 0 &&
          googleLoader &&
          userSubscriptionInfo?.type !== GOOGLE && (
            <div className="cms-step-loader">
              <Loading />
            </div>
          )}
        {onBoardingSteps[current]?.title && current !== 0 && !loading && (
          <div className="step-title">
            <div className="cb-text-center">
              <span className="font-inter cms-welcome-page-top-title cms-current-steps-title">
                <span className="font-inter cms-title-wrapper">
                  {onBoardingSteps[current]?.title}
                </span>
              </span>
            </div>
          </div>
        )}
        {current !== 0 &&
          onBoardingSteps[current]?.content &&
          (loading || (loading && userSubscriptionInfo?.type === GOOGLE) ? (
            <div className="cms-step-loader">
              <Loading />
            </div>
          ) : (
            <div
              className={`${current === 0 ? "mb-16 cms-welcome-content" : ""} ${
                userSubscriptionInfo?.type !== GOOGLE && current === 0
                  ? "mb-40"
                  : ""
              } cms-step-content`}
            >
              <div className="cms-step-content-text" type="secondary">
                {onBoardingSteps[current]?.content}
              </div>
            </div>
          ))}
        {current === 0 &&
          userSubscriptionInfo?.type === GOOGLE &&
          onBoardingSteps[current]?.content &&
          (loading || (loading && userSubscriptionInfo?.type === GOOGLE) ? (
            <div className="cms-step-loader">
              <Loading />
            </div>
          ) : (
            <div
              className={`${current === 0 ? "mb-16 cms-welcome-content" : ""} ${
                userSubscriptionInfo?.type !== GOOGLE && current === 0
                  ? "mb-40"
                  : ""
              } cms-step-content`}
            >
              <div className="cms-step-content-text" type="secondary">
                {onBoardingSteps[current]?.content}
              </div>
            </div>
          ))}
        {current === 0 &&
          userSubscriptionInfo?.type !== GOOGLE &&
          currentUser?.provider !== GOOGLE &&
          onBoardingSteps[current]?.content &&
          (loading || (loading && userSubscriptionInfo?.type === GOOGLE) ? (
            <div className="cms-step-loader">
              <Loading />
            </div>
          ) : (
            <div
              className={`${current === 0 ? "mb-16 cms-welcome-content" : ""} ${
                userSubscriptionInfo?.type !== GOOGLE && current === 0
                  ? "mb-40"
                  : ""
              } cms-step-content`}
            >
              <div className="cms-step-content-text" type="secondary">
                {onBoardingSteps[current]?.content}
              </div>
            </div>
          ))}
      </div>
      {current !== 0 && (
        <div className={loading ? "hide" : "cms-steps-action mb-16"}>
          <IconButton
            iconClass="cms-steps-action-button"
            text={
              <span className="cms-steps-action-button-text font-inter">
                {onBoardingSteps[current]?.buttonText}
              </span>
            }
            type="primary"
            handleClick={onBoardingSteps[current]?.event}
            disable={onBoardingSteps[current]?.isDisable}
          />
        </div>
      )}
      {current === 0 && userSubscriptionInfo?.type === GOOGLE && (
        <div className={loading ? "hide" : "cms-steps-action mb-16"}>
          <IconButton
            iconClass="cms-steps-action-button"
            text={
              <span className="cms-steps-action-button-text font-inter">
                {onBoardingSteps[current]?.buttonText}
              </span>
            }
            type="primary"
            handleClick={onBoardingSteps[current]?.event}
            disable={onBoardingSteps[current]?.isDisable}
          />
        </div>
      )}
      {current === 0 &&
        userSubscriptionInfo?.type === GOOGLE &&
        currentUser?.provider !== GOOGLE && (
          <div className={loading ? "hide" : "cms-steps-action mb-16"}>
            <IconButton
              iconClass="cms-steps-action-button"
              text={
                <span className="cms-steps-action-button-text font-inter">
                  {onBoardingSteps[current]?.buttonText}
                </span>
              }
              type="primary"
              handleClick={onBoardingSteps[current]?.event}
              disable={onBoardingSteps[current]?.isDisable}
            />
          </div>
        )}
      {current === 0 &&
        userSubscriptionInfo?.type !== GOOGLE &&
        currentUser?.provider !== GOOGLE && (
          <div className={loading ? "hide" : "cms-steps-action mb-16"}>
            <IconButton
              iconClass="cms-steps-action-button"
              text={
                <span className="cms-steps-action-button-text font-inter">
                  {onBoardingSteps[current]?.buttonText}
                </span>
              }
              type="primary"
              handleClick={onBoardingSteps[current]?.event}
              disable={onBoardingSteps[current]?.isDisable}
            />
          </div>
        )}
    </Modal>
  );
};

export default OnBoardingModal;
