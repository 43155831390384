import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_SPACE_MEMBERS = gql`
  query spaceMembers($id: Int!, $data: SpaceMembersFilter!) {
    spaceMembers(id: $id, data: $data) {
      data {
        id
        name
        ownerId
        photo
        createdAt
        updatedAt
        permission
        default
        members {
          id
          userId
          name
          email
          permissions
          emailVerified
        }
        membersCount
        subscription {
          id
          planStatus
          quantity
          endDate
          startDate
          product {
            id
            name
            description
            features
            price {
              id
              amount
              interval
              currency
            }
            quantity
          }
        }
        type
      }
    }
  }
`;
