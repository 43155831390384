import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_GROUPS_FOR_CONTACT = gql`
  query getGroupsForContact($offset: Int, $limit: Int, $spaceId: ID!) {
    getGroupsForContact(offset: $offset, limit: $limit, spaceId: $spaceId) {
      groups {
        id
        name
        type
        source
        sourceId
        ownerId
        spaceId
      }
      count
    }
  }
`;
