import React, { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

//! Antd import
import Col from "antd/lib/col";

const DetailPanel = ({ children, hidePaths }) => {
  const [span, setSpan] = useState(12);
  const location = useLocation();
  useEffect(() => {
    setSpan(12);
    // eslint-disable-next-line no-restricted-syntax
    for (const path of hidePaths) {
      const match = matchPath(location.pathname, {
        path: path,
        exact: true,
        strict: false,
      });
      if (match) {
        setSpan(20);
        break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, hidePaths, span]);
  return (
    <Col
      span={span}
      className="dashboard-contact-detail-col cms-contact-scrollbar h-inherit"
    >
      {children}
    </Col>
  );
};

export default DetailPanel;
