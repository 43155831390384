import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $name: String
    $photo: String
    $company: String
    $designation: String
    $phone: String
    $city: String
    $country: String
    $birthday: Date
    $gender: Gender
  ) {
    updateProfile(
      data: {
        name: $name
        photo: $photo
        company: $company
        designation: $designation
        phone: $phone
        birthday: $birthday
        gender: $gender
        city: $city
        country: $country
      }
    ) {
      data {
        id
        name
        email
        role
        photo
        isConsentGiven
        company
        designation
        phone
        birthday
        gender
        city
        country
      }
      status
      message
    }
  }
`;
