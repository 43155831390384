import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_SHARED_GROUP_PERMISSION = gql`
  mutation updateSharedGroupPermissions(
    $id: Int!
    $groupId: Int!
    $permissions: PERMISSIONS
  ) {
    updateSharedGroupPermissions(
      id: $id
      groupId: $groupId
      permissions: $permissions
    ) {
      message
      status
    }
  }
`;
