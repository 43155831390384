import React from "react";
//! User files
import {
  capitalizeWord,
  generateRandomColor,
} from "../../../../../common/utils";
import { Inquiry } from "../../../../../assets/svg";

//! Antd Imports
import UserAvatar from "../../../../../common/components/UserAvatar/UserAvatar";

const AllTagInfo = ({
  tagArray,
  userDetail,
  sharedUser,
  userId,
  mergeContact,
}) => {
  return (
    <div
      className={`${userDetail ? "group-circle-user" : "all-tag-info"}
      ${mergeContact && "all-tag-merge-info"}`}
    >
      <div className="all-tag-info-details">
        {!userDetail ? (
          <>
            {tagArray &&
              tagArray.map((group) => (
                <div className="all-tag-info-name" key={group?.name}>
                  <span
                    className="all-tag-info-name-tag"
                    title={group?.name ?? group}
                  >
                    {group?.name ?? group}
                  </span>
                  {group?.ownerId !== userId && (
                    <span>
                      <Inquiry />
                    </span>
                  )}
                </div>
              ))}
          </>
        ) : (
          <>
            {sharedUser?.map((user) => (
              <div className="all-tag-info-name" key={user?.id}>
                <span>
                  <UserAvatar
                    className="cms-tag-avatar"
                    color={generateRandomColor(parseInt(user?.id, 10))}
                    name={user?.username}
                    photo={user?.photo}
                  />
                </span>
                <span
                  className="all-tag-contact-name"
                  title={capitalizeWord(user?.username)}
                >
                  {capitalizeWord(user?.username)}
                </span>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AllTagInfo;
