import { gql } from "@apollo/client/core";
//! Will remove after testing
// export const REGISTER_EMAIL = gql`
//   mutation register(
//     $name: String!
//     $email: String!
//     $isConsentGiven: Boolean!
//     $currency: Currency!
//     $utmSource: UtmSource!
//   ) {
//     register(
//       data: {
//         name: $name
//         email: $email
//         isConsentGiven: $isConsentGiven
//         currency: $currency
//         utmSource: $utmSource
//       }
//     ) {
//       id
//       message
//       status
//       accessToken
//     }
//   }
// `;
export const REGISTER_EMAIL_V4 = gql`
  mutation registerV4($data: RegisterV4Input!) {
    registerv4(data: $data) {
      message
      status
      data {
        accessToken
        userInfo {
          id
          name
          email
          role
          isConsentGiven
          emailVerified
          hasLoggedInBefore
          provider
          isDisabled
          spaceId
        }
      }
    }
  }
`;
export const VERIFY_TOKEN = gql`
  mutation verifyToken($id: Int!, $token: String!) {
    verifyToken(data: { id: $id, token: $token }) {
      message
      status
    }
  }
`;
export const SET_PASSWORD = gql`
  mutation resetPassword($id: Int!, $token: String!, $password: String!) {
    resetPassword(data: { id: $id, token: $token, password: $password }) {
      message
      status
      data {
        accessToken
        userInfo {
          id
          name
          email
          role
          isConsentGiven
          emailVerified
          hasLoggedInBefore
          provider
          isDisabled
          spaceId
        }
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      data {
        accessToken
        userInfo {
          id
          name
          email
          role
          isConsentGiven
          emailVerified
          hasLoggedInBefore
          provider
          isDisabled
          spaceId
        }
      }
      message
      status
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgetPassword($email: String!) {
    forgetPassword(email: $email) {
      message
      status
    }
  }
`;
export const SOCIAL_SIGNUP = gql`
  mutation socialSignup(
    $firebaseToken: String!
    $currency: Currency!
    $utmSource: UtmSource!
  ) {
    socialSignup(
      firebaseToken: $firebaseToken
      currency: $currency
      utmSource: $utmSource
    ) {
      accessToken
      message
      status
      userInfo {
        id
        name
        email
        role
        spaceId
        isConsentGiven
        emailVerified
        provider
        isDisabled
      }
    }
  }
`;

export const SOCIAL_LOGIN = gql`
  mutation socialLogin($firebaseToken: String!) {
    socialLogin(firebaseToken: $firebaseToken) {
      accessToken
      message
      status
      userInfo {
        id
        name
        email
        role
        spaceId
        isConsentGiven
        hasLoggedInBefore
        emailVerified
        provider
        isDisabled
      }
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation resendEmailVerification($email: String!) {
    resendEmailVerification(email: $email) {
      message
      status
    }
  }
`;
//! Will remove after testing
// export const SOCIAL_AUTH = gql`
//   mutation socialAuth(
//     $firebaseToken: String!
//     $currency: Currency!
//     $utmSource: UtmSource!
//   ) {
//     socialAuth(
//       firebaseToken: $firebaseToken
//       currency: $currency
//       utmSource: $utmSource
//     ) {
//       accessToken
//       message
//       status
//       userInfo {
//         id
//         name
//         email
//         role
//         spaceId
//         isConsentGiven
//         hasLoggedInBefore
//         emailVerified
//         provider
//         isDisabled
//       }
//     }
//   }
// `;

export const SOCIAL_AUTH_V2 = gql`
  mutation socialAuthV2($firebaseToken: String!) {
    socialAuthV2(firebaseToken: $firebaseToken) {
      accessToken
      message
      status
      userInfo {
        id
        name
        email
        role
        spaceId
        isConsentGiven
        hasLoggedInBefore
        emailVerified
        provider
        isDisabled
      }
      jobId
    }
  }
`;
//! Will remove after testing
// export const GOOGLE_AUTH = gql`
//   mutation googleAuth($data: GoogleAuthInput!) {
//     googleAuth(data: $data) {
//       accessToken
//       message
//       status
//       jobId
//       userInfo {
//         id
//         name
//         email
//         role
//         spaceId
//         isConsentGiven
//         hasLoggedInBefore
//         emailVerified
//         provider
//         isDisabled
//         domain
//       }
//     }
//   }
// `;

export const GOOGLE_AUTH_V2 = gql`
  mutation googleAuthV2($data: GoogleAuthV2Input!) {
    googleAuthV2(data: $data) {
      accessToken
      message
      status
      jobId
      userInfo {
        id
        name
        email
        role
        spaceId
        isConsentGiven
        hasLoggedInBefore
        emailVerified
        provider
        isDisabled
        domain
      }
    }
  }
`;

export const IS_EMAIL_EXISTS = gql`
  mutation IsEmailExist($email: String!) {
    isEmailExist(email: $email) {
      isLoggedIn
    }
  }
`;

export const USER_DETAILS = gql`
  mutation UserDetails($data: UserDetailsInput, $email: String!) {
    userDetails(data: $data, email: $email) {
      message
      status
    }
  }
`;
