import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const LEAVE_USER_SPACE = gql`
  mutation leaveSpace($id: Int!) {
    leaveSpace(id: $id) {
      message
      status
    }
  }
`;
