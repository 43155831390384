import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_COMMON_GROUPS_AND_TAGS = gql`
  query getCommonContactGroupsTags($data: CommonGroupTagContactInput!) {
    getCommonContactGroupsTags(data: $data) {
      groupIds
      tags
    }
  }
`;
