import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

//! User files
import { AppContext } from "../../../../../AppContext";
import {
  hasGroupRight,
  hasSpaceRight,
  isPlanActive,
} from "../../../../../common/utils";
import {
  ADD_EXISTING,
  CREATE_NEW_CONTACT,
  IMPORT_CONTACTS,
  UPGRADE_PLAN_TITLE,
} from "../../../../../common/constants";
import {
  PersonAdd,
  ImportContact,
  ContactSpace,
} from "../../../../../assets/svg";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { SET_SPACE_PLAN_STATUS } from "../../../../../common/actionTypes";

const NoContactPlaceholder = ({
  groupId,
  spaceId,
  searchTerm,
  isExistingPage,
  contactsCount,
  tagId,
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const {
    state: { userGroupPermission, userSubscriptionInfo, userSpacePermission },
    dispatch,
  } = useContext(AppContext);

  const handleRoute = (value) => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      if (value === "addContact") {
        if (tagId) {
          push(`/contacts/${spaceId}/tag/${tagId}/contact/new`);
        } else {
          push(`/contacts/${spaceId}/group/${groupId}/contact/new`);
        }
      } else if (value === "importContact") {
        push(`/settings/${spaceId}/import-contacts`);
      }
    } else {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: {
          planExpired: true,
          message: UPGRADE_PLAN_TITLE,
          spaceId: parseInt(userSubscriptionInfo?.id, 10),
        },
      });
    }
  };

  const addExistingContactRoute = () => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      if (tagId) {
        push(`/contacts/${spaceId}/tag/${tagId}/add-contacts`);
      } else {
        push(`/contacts/${spaceId}/group/${groupId}/add-contacts`);
      }
    } else {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
      });
    }
  };

  const isDisabledButton = () => {
    if (tagId) {
      return !hasSpaceRight(userSpacePermission, "addContact");
      // eslint-disable-next-line no-else-return
    } else {
      // eslint-disable-next-line no-lonely-if
      if (groupId === "all") {
        return !hasSpaceRight(userSpacePermission, "addContact");
        // eslint-disable-next-line no-else-return
      } else {
        return !hasGroupRight(userGroupPermission, "addContact");
      }
    }
  };

  return (
    <div
      className={`${
        isExistingPage ? "no-existing-contact no-group" : "no-group no-contact"
      } ${contactsCount === 0 ? "cms-no-contact-list-height" : ""}`}
    >
      {!searchTerm &&
        isExistingPage !== true &&
        (pathname.includes("tag") || pathname.includes("group")) &&
        ((groupId === "all"
          ? hasSpaceRight(userSpacePermission, "addContact")
          : hasGroupRight(userGroupPermission, "addContact")) ||
          tagId) && (
          <div className="no-group-btn-wrapper no-contact-btn-wrapper">
            <IconButton
              iconClass="no-group-create-btn"
              handleClick={() => handleRoute("addContact")}
              disable={isDisabledButton()}
              icon={<PersonAdd className="no-group-svg" />}
              text={CREATE_NEW_CONTACT}
            />
            <span className="no-group-or">OR</span>
            {userSubscriptionInfo?.contactsCount === 0 ? (
              <IconButton
                iconClass={`${
                  (
                    groupId === "all"
                      ? !hasSpaceRight(userSpacePermission, "addContact")
                      : !hasGroupRight(userGroupPermission, "addContact")
                  )
                    ? "existing-btn-disable"
                    : ""
                }
              no-group-create-btn existing-btn `}
                handleClick={() => handleRoute("importContact")}
                icon={<ImportContact className="no-group-svg" />}
                text={IMPORT_CONTACTS}
                disable={
                  // eslint-disable-next-line no-nested-ternary
                  tagId
                    ? !hasSpaceRight(userSpacePermission, "addContact")
                    : groupId === "all"
                    ? !hasSpaceRight(userSpacePermission, "addContact")
                    : !hasGroupRight(userGroupPermission, "addContact")
                }
              />
            ) : (
              <IconButton
                iconClass={`${
                  // eslint-disable-next-line no-nested-ternary
                  tagId
                    ? !hasSpaceRight(userSpacePermission, "addExistingContact")
                      ? "existing-btn-disable"
                      : ""
                    : // eslint-disable-next-line no-nested-ternary
                    groupId === "all"
                    ? !hasSpaceRight(userSpacePermission, "addExistingContact")
                      ? "existing-btn-disable"
                      : ""
                    : !hasGroupRight(userGroupPermission, "addExistingContact")
                    ? "existing-btn-disable"
                    : ""
                } no-group-create-btn existing-btn`}
                icon={<ContactSpace className="no-group-svg" />}
                disable={isDisabledButton()}
                text={ADD_EXISTING}
                handleClick={addExistingContactRoute}
              />
            )}
          </div>
        )}
    </div>
  );
};

export default NoContactPlaceholder;
