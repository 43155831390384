import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

//! Antd import
import { Progress } from "antd";

//! User files
import {
  WorkSpaceIcon,
  OneShareIcon,
  CloseBlack,
  ChevronRight,
  DoneIcon,
  UpgradeIcon,
  ExternalLinkIcon,
  AddOnsFeatureIcon,
  DownloadAppIcon,
  AppleDownload,
  GooglePlayBadge,
} from "../../../../../assets/svg";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { AppContext } from "../../../../../AppContext";
import {
  APP_STORE_URL,
  LTD,
  PLAY_STORE_URL,
} from "../../../../../common/constants";

const ContactEmptyState = () => {
  const [percent, setPercent] = useState(33);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const [currentCard, setCurrentCard] = useState(1);
  const [showEffect, setShowEffect] = useState(false);
  const { pathname } = useLocation();
  const {
    state: { userSubscriptionInfo, contacts, currentUser },
  } = useContext(AppContext);
  const spaceId = pathname?.split("/")[2];
  const { push } = useHistory();

  const features = [
    {
      id: 1,
      icon: <WorkSpaceIcon />,
      text: "Create your ContactBook workspace",
      link: "",
      isDone: userSubscriptionInfo?.default,
    },
    {
      id: 2,
      icon: <ExternalLinkIcon />,
      text: "Connect external contact sources",
      link: `/settings/${spaceId}/contact-sources`,
      isDone: userSubscriptionInfo?.contactsCount > 0,
    },
    {
      id: 3,
      icon: <OneShareIcon />,
      text: "Share your contacts",
      link: `/settings/${spaceId}/members`,
      isDone: userSubscriptionInfo?.membersCount > 1,
    },
    //! Will be using this in near future
    // {
    //   id: 4,
    //   icon: <CleanUpCircleIcon />,
    //   text: "Clean up duplicate contacts",
    //   link: `/clean-up/${spaceId}/duplicates`,
    // },
  ];

  const featuresCardForOwner = [
    {
      id: 1,
      icon: <UpgradeIcon />,
      title: "Upgrade to a plan",
      content:
        "Manage and keep shared contacts with your colleagues and other relevant people. Choose a plan based on your needs and get started with a ContactBook subscription.",
      route: `/space/${spaceId}/plans`,
      buttonText: "Choose Plan",
    },
    {
      id: 2,
      icon: <AddOnsFeatureIcon />,
      title: "Stay in touch with your contacts",
      content:
        "Use smart Reminders to strengthen your relationship with important contacts of your business. You can set up custom reminders and also get notified on their important days.",
      route: !userSubscriptionInfo?.spaceAddon?.active
        ? `/addOns/${spaceId}/reminders/activate`
        : `/addOns/${spaceId}/reminders`,
      buttonText: "Explore",
    },
    {
      id: 3,
      icon: <DownloadAppIcon />,
      title: "Access Contacts on all Devices",
      content:
        "Download ContactBook Mobile app for your Android and iOS devices and access all your contacts at your fingertips.",
    },
  ];

  const featuresCardForSharedUser = [
    {
      id: 1,
      icon: <AddOnsFeatureIcon />,
      title: "Stay in touch with your contacts",
      content:
        "Use smart Reminders to strengthen your relationship with important contacts of your business. You can set up custom reminders and also get notified on their important days.",
      route: !userSubscriptionInfo?.spaceAddon?.active
        ? `/addOns/${spaceId}/reminders/activate`
        : `/addOns/${spaceId}/reminders`,
      buttonText: "Explore",
    },
    {
      id: 2,
      icon: <DownloadAppIcon />,
      title: "Access Contacts on all Devices",
      content:
        "Download ContactBook Mobile app for your Android and iOS devices and access all your contacts at your fingertips.",
    },
  ];

  const featuresCard =
    userSubscriptionInfo?.ownerId === parseInt(currentUser?.id, 10) &&
    userSubscriptionInfo?.subscription?.product?.price?.interval !== LTD
      ? featuresCardForOwner
      : featuresCardForSharedUser;

  const handleCardClick = () => {
    setShowEffect(true);
    setTimeout(() => {
      setShowEffect(false);
      if (featuresCard?.length === currentCard) {
        setCurrentCard(1);
      } else {
        setCurrentCard(currentCard + 1);
      }
    }, 500);
  };

  useEffect(() => {
    const calculateProgress = () => {
      if (
        userSubscriptionInfo?.contactsCount > 0 &&
        userSubscriptionInfo?.membersCount > 1
      ) {
        setPercent(100);
        localStorage.setItem("contactEmptyState", false);
      } else if (
        userSubscriptionInfo?.contactsCount > 0 &&
        userSubscriptionInfo?.default
      ) {
        localStorage.setItem("contactEmptyState", true);
        setPercent(66);
      } else if (
        userSubscriptionInfo?.default &&
        userSubscriptionInfo?.membersCount > 1
      ) {
        localStorage.setItem("contactEmptyState", true);
        setPercent(66);
      }
    };
    calculateProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptionInfo]);

  useEffect(() => {
    if (
      localStorage.getItem("contactEmptyState") === "false" &&
      contacts?.length !== 0
    ) {
      setShowEmptyState(false);
    }
  }, [contacts]);

  const closeEmptyContactState = () => {
    localStorage.setItem("contactEmptyState", false);
    setShowEmptyState(false);
  };

  return (
    <div className={!showEmptyState ? "hide" : "cms-contact-empty-state"}>
      <div className="cms-contact-empty-state-title mb-24">
        <span className="font-inter cms-contact-empty-state-title-span">
          Set-up Your Account
        </span>
        <span
          className="cms-empty-state-dismiss cursor-pointer"
          onClick={closeEmptyContactState}
        >
          <CloseBlack />
        </span>
      </div>
      <div className="cms-contact-empty-state-progress">
        <span className="font-inter cms-progress-percent">{percent}%</span>
        <span>
          <Progress percent={percent} showInfo={false} />
        </span>
      </div>
      <div className="cms-contact-empty-state-content mb-32">
        {features?.map((item) => (
          <div className="cms-contact-empty-state-content-block" key={item?.id}>
            <div className="d-flex-center">
              <span className="cms-feature-icon mr-12">{item?.icon}</span>
              <span className="username">{item?.text}</span>
            </div>
            {item?.isDone ? (
              <span className="cms-merged-icon">
                <DoneIcon />
              </span>
            ) : (
              <Link
                to={item?.link}
                className="font-inter cms-redirect-tag cursor-pointer"
              >
                <ChevronRight />
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className="cms-contact-empty-state-cards">
        {featuresCard?.map((card) => (
          <div
            className={`${
              card?.id !== currentCard ? "hide" : "cms-card-slider"
            } ${showEffect ? "close-card-effect" : ""}`}
            key={card?.id}
          >
            <div className="cms-card-slider-single">
              <div className="cms-card-slider-single-left-section">
                <span className="cms-card-slider-single-left-section-icon mr-8">
                  {card?.icon}
                </span>
                <div>
                  <span className="font-inter username cms-card-slider-single-left-section-title">
                    {card?.title}
                  </span>
                  <div
                    className={`${
                      card?.id === 3 ? "mb-24" : ""
                    } font-inter cms-card-slider-content mb-12 mt-4`}
                  >
                    {card?.content}
                  </div>
                  {card?.buttonText ? (
                    <IconButton
                      iconClass="cms-redirect-btn"
                      text={
                        <span className="font-inter username cms-redirect-btn-text">
                          {card?.buttonText}
                        </span>
                      }
                      type="primary"
                      handleClick={() => push(card?.route)}
                    />
                  ) : (
                    <div>
                      <span>
                        <a
                          href={APP_STORE_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <AppleDownload className="mr-16" />
                        </a>
                        <a
                          href={PLAY_STORE_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <GooglePlayBadge />
                        </a>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="cms-card-slider-single-right-section cursor-pointer"
                onClick={handleCardClick}
              >
                <CloseBlack />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactEmptyState;
