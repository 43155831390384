import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_SYNC_LOGS = gql`
  query getSyncLogs($id: Int!, $offset: Int, $limit: Int) {
    getSyncLogs(id: $id, offset: $offset, limit: $limit) {
      count
      syncLogs {
        id
        spaceId
        type
        message
        createdAt
      }
    }
  }
`;
