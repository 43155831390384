import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const USER_PAYMENT_METHOD = gql`
  query userPaymentMethod {
    userPaymentMethod {
      available
    }
  }
`;
