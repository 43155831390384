import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const DELETE_NOTE = gql`
  mutation removeNote($noteId: Int!, $contactId: Int!) {
    removeNote(noteId: $noteId, contactId: $contactId) {
      data {
        id
        note
        visibility
        contactId
        userId
        createdAt
        updatedAt
        deletedAt
      }
      message
      status
    }
  }
`;
