import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_SPACE_DETAIL_V2 = gql`
  query spaceV2($id: ID!) {
    spaceV2(id: $id) {
      id
      name
      photo
      ownerId
      storage {
        filesSize
        filesCount
      }
      contactsCount
      attachmentsCount
      membersCount
      notesCount
      spaceOwner {
        id
        name
        email
        photo
        emailVerified
      }
      permission
      default
      noGroupContactsCount
      initialized
      duplicateContactsCount
      deletedContactsCount
      spaceAddon {
        id
        spaceId
        name
        active
      }
      type
      subscription {
        id
        planStatus
        quantity
        endDate
        startDate
        product {
          id
          name
          description
          features
          price {
            id
            amount
            interval
            currency
          }
          quantity
        }
      }
    }
  }
`;
