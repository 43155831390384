import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const ADD_MEMBER_TO_SPACE = gql`
  mutation addMemberToSpace($data: AddMemberToSpaceInput!) {
    addMemberToSpace(data: $data) {
      data {
        id
        userId
        name
        email
        permissions
      }
      message
      status
    }
  }
`;
