import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const NEW_SPACE_SUBSCRIPTION = gql`
  mutation newSpaceSubscription(
    $priceId: Int!
    $spaceId: Int!
    $quantity: Int!
  ) {
    newSpaceSubscription(
      priceId: $priceId
      spaceId: $spaceId
      quantity: $quantity
    ) {
      url
    }
  }
`;
