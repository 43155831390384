import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CREATE_SPACE = gql`
  mutation createSpace($name: String!, $photo: String) {
    createSpace(data: { name: $name, photo: $photo }) {
      data {
        id
        name
        ownerId
        createdAt
        updatedAt
      }
      message
      status
    }
  }
`;
