import React from "react";

//! Antd imports
import Button from "antd/lib/button";

const IconButton = ({
  iconClass,
  icon,
  shape,
  text,
  handleClick,
  loading,
  disable,
  type,
  htmlType,
  buttonRef,
  title,
}) => {
  return (
    <Button
      className={iconClass}
      shape={shape}
      icon={icon}
      onClick={handleClick}
      loading={loading}
      disabled={disable}
      type={type}
      htmlType={htmlType}
      ref={buttonRef}
      title={title}
    >
      {text && text}
    </Button>
  );
};

export default IconButton;
