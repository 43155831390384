import React from "react";
import { Divider, Skeleton } from "antd";

function ContactCardSkeleton({ loading, active }) {
  const n = 4;
  return (
    <div className="contact-card-skeleton-layout">
      {[...Array(n)].map((i, k) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={k}>
          <Skeleton
            loading={loading}
            avatar={{ shape: "circle", size: "large" }}
            title={false}
            paragraph={{ rows: 2 }}
            active={active}
          />
          <Divider />
        </div>
      ))}
    </div>
  );
}

export default ContactCardSkeleton;
