import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GENERATE_SIGNED_URL_FOR_ATTACHMENT = gql`
  mutation generateSignedUrlForAttachment($data: SignedUrlAttachmentInput!) {
    generateSignedUrlForAttachment(data: $data) {
      signedRequest
      key
      url
    }
  }
`;
