import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_SUBSCRIPTION = gql`
  mutation updateSubscription($spaceId: Int!, $priceId: Int!, $quantity: Int!) {
    updateSubscription(
      spaceId: $spaceId
      priceId: $priceId
      quantity: $quantity
    ) {
      message
      status
      url
    }
  }
`;
