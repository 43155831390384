import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const CURRENT_USER = gql`
  query {
    getProfile {
      id
      name
      role
      photo
      email
      phone
      company
      designation
      birthday
      gender
      city
      country
      isConsentGiven
      hasLoggedInBefore
      emailVerified
      provider
      isDisabled
      spaceId
    }
  }
`;

export const GET_GOOGLE_AUTH_URL = gql`
  query getGoogleAuthUrl($screen: Screen!) {
    getGoogleAuthUrl(screen: $screen) {
      authorizationUri
    }
  }
`;
export const GENERATE_CLIENT_SECRET = gql`
  query generateSetupIntentClientSecret {
    generateSetupIntentClientSecret {
      clientSecret
      paymentMethodExists
    }
  }
`;

export const ADD_SHIPPING_DETAILS = gql`
  mutation addShippingDetails($data: ShippingDetailsInput!) {
    addShippingDetails(data: $data) {
      status
    }
  }
`;
