import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CHANGE_PASSWORD = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(
      data: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      message
      status
    }
  }
`;
