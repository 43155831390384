import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Col, Row } from "antd";
import Layout from "../layout/Layout";
import { Document } from "../../../assets/svg";
import ImportContactCard from "./ImportContactsCard";
import ContactSourceCard from "./ContactSourceCard";
import { DOCUMENT_LINKS } from "../../../common/constants";
import { COMPLETE_ONBOARDING } from "../graphql/Mutations";
import { AppContext } from "../../../AppContext";
import { CURRENT_USER } from "../../auth/graphql/Queries";

function ImportContacts() {
  const history = useHistory();

  const [completeOnBoarding] = useMutation(COMPLETE_ONBOARDING, {
    refetchQueries: [
      {
        query: CURRENT_USER,
      },
    ],
  });
  const [isActiveCard, setIsActiveCard] = useState("");

  const {
    state: { currentUser },
  } = useContext(AppContext);

  const handleOnboardingStatus = () => {
    completeOnBoarding();
    history.push(`/contacts/${currentUser?.spaceId}/group/all`);
  };
  useEffect(() => {
    const contactSource = localStorage.getItem("contactSource");
    if (currentUser.hasLoggedInBefore && !contactSource) {
      history.push(`/contacts/${currentUser?.spaceId}/group/all`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div className="cms-source-card-block">
        <p className="cms-onboarding-text">Let's assemble all your contacts!</p>
        <Row justify="center" align="middle">
          <Col>
            <ContactSourceCard
              setIsActiveCard={setIsActiveCard}
              isActiveCard={isActiveCard}
            />
          </Col>
          <Col>
            <ImportContactCard
              isActiveCard={isActiveCard}
              setIsActiveCard={setIsActiveCard}
            />
          </Col>
        </Row>
        <center className="guide-button">
          <a
            href={DOCUMENT_LINKS.IMPORT_GUIDE}
            target="_blank"
            rel="noreferrer"
          >
            <Button shape="round">
              Not sure how to import? Follow our guide
              <Document className="guide-button-icon" />
            </Button>
          </a>
        </center>
      </div>
      <div className="skip-button">
        <center>
          <Button type="text" onClick={handleOnboardingStatus}>
            Skip
          </Button>
        </center>
      </div>
    </Layout>
  );
}

export default ImportContacts;
