import { gql } from "@apollo/client/core";

const START_SPACE_SYNC = gql`
  mutation startSpaceSync($id: Int!) {
    startSpaceSync(id: $id) {
      message
      status
    }
  }
`;

export default START_SPACE_SYNC;
