import React, { useContext, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import isInteger from "lodash/isInteger";
import filter from "lodash/filter";

//! Graphql files
import { LIST_DUPLICATE_CONTACTS } from "../../../graphql/queries/listDuplicateContacts";
import { REMOVE_DUPLICATE_CONTACT } from "../../../graphql/mutations/removeDuplicateContact";
import { IGNORE_DUPLICATE } from "../../../graphql/mutations/ignoreDuplicate";
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";

//! User files
import DuplicatesCard from "../../../../../common/components/DuplicatesCard/DuplicatesCard";
import ContactCardSkeleton from "../../../../../common/components/ContactCardSkeleton/ContactCardSkeleton";
import { AppContext } from "../../../../../AppContext";
import {
  SET_DUPLICATE_CONTACT_CARD,
  SET_DUPLICATE_CONTACT_COUNT,
} from "../../../../../common/actionTypes";
import {
  ASSISTANT_DUPLICATE_CONTACT,
  CONTACT_LIMIT,
} from "../../../../../common/constants";
import Loading from "../../../../../common/components/Loading";
import { hasSpaceRight, isPlanActive } from "../../../../../common/utils";
import NoGroupPlaceholder from "../../contacts/components/NoGroupPlaceholder";
import { NoContacts } from "../../../../../assets/svg";

const DuplicatesList = () => {
  const { spaceId } = useParams();
  const {
    state: { duplicateContactData, userSpacePermission, userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);
  const [duplicateContactCount, setDuplicateContactCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [singleContactRemove, setSingleContactRemove] = useState(false);
  const [removeContactModal, setRemoveContactModal] = useState(false);
  const [ignoreDuplicateCard, setIgnoreDuplicateCard] = useState(false);
  const [ignoreContactModal, setIgnoreContactModal] = useState(false);
  const [listId, setListId] = useState();
  const { state } = useLocation();

  const [
    listDuplicateContacts,
    { data: duplicateData, loading: duplicateLoader },
  ] = useLazyQuery(LIST_DUPLICATE_CONTACTS, {
    fetchPolicy: "network-only",
    onError() {},
  });

  const [removeDuplicateContact, { loading: removeLoader }] = useMutation(
    REMOVE_DUPLICATE_CONTACT,
    {
      refetchQueries: [
        {
          query: GET_SPACE_DETAIL_V2,
          fetchPolicy: "network-only",
          variables: {
            id: parseInt(spaceId, 10),
          },
        },
      ],
      onError() {},
    }
  );

  const [ignoreDuplicateList] = useMutation(IGNORE_DUPLICATE, {
    refetchQueries: [
      {
        query: GET_SPACE_DETAIL_V2,
        fetchPolicy: "network-only",
        variables: {
          id: parseInt(spaceId, 10),
        },
      },
    ],
    onCompleted() {
      dispatch({
        type: SET_DUPLICATE_CONTACT_COUNT,
        data: duplicateContactCount - 1,
      });
      setDuplicateContactCount(duplicateContactCount - 1);
    },
    onError() {},
  });

  useEffect(() => {
    if (spaceId) {
      setOffset(0);
      dispatch({
        type: SET_DUPLICATE_CONTACT_CARD,
        data: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (duplicateData?.listDuplicateContacts?.data) {
      if (duplicateData?.listDuplicateContacts?.data?.length !== 0) {
        if (singleContactRemove || ignoreDuplicateCard) {
          dispatch({
            type: SET_DUPLICATE_CONTACT_CARD,
            data: [...duplicateData?.listDuplicateContacts?.data],
          });
        } else {
          dispatch({
            type: SET_DUPLICATE_CONTACT_CARD,
            data: [
              ...duplicateContactData,
              ...duplicateData?.listDuplicateContacts?.data,
            ],
          });
        }
      } else {
        dispatch({
          type: SET_DUPLICATE_CONTACT_CARD,
          data: [],
        });
      }
      setSingleContactRemove(false);
      setIgnoreDuplicateCard(false);
      setDuplicateContactCount(duplicateData?.listDuplicateContacts?.count);
      dispatch({
        type: SET_DUPLICATE_CONTACT_COUNT,
        data: duplicateData?.listDuplicateContacts?.count,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateData]);

  useEffect(() => {
    if (spaceId) {
      // eslint-disable-next-line no-unused-expressions
      userSpacePermission !== null &&
        hasSpaceRight(userSpacePermission, ASSISTANT_DUPLICATE_CONTACT) &&
        listDuplicateContacts({
          variables: {
            spaceId: parseInt(spaceId, 10),
            offset: offset,
            limit: CONTACT_LIMIT,
          },
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId, userSpacePermission, offset]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
        Math.ceil(element.clientHeight) &&
      duplicateContactData.length < duplicateContactCount
    ) {
      setOffset(duplicateContactData.length);
    }
  };

  const removeSingleDuplicateContact = async (duplicateId, contactId) => {
    setSingleContactRemove(true);
    const res = await removeDuplicateContact({
      variables: {
        id: parseInt(duplicateId, 10),
        contactId: parseInt(contactId, 10),
      },
    });

    if (res?.data?.removeDuplicate) {
      setRemoveContactModal(false);
      listDuplicateContacts({
        variables: {
          spaceId: parseInt(spaceId, 10),
          offset: 0,
          limit: offset + CONTACT_LIMIT,
        },
      });
    }
  };

  const ignoreDuplicateContactCard = (mainId) => {
    setIgnoreDuplicateCard(true);
    setIgnoreContactModal(false);
    setTimeout(() => {
      setListId(mainId);
    }, 200);
    setTimeout(() => {
      if (mainId && isInteger(mainId)) {
        dispatch({
          type: SET_DUPLICATE_CONTACT_CARD,
          data: filter(duplicateContactData, (data) => data.id !== mainId),
        });
        const res = ignoreDuplicateList({
          variables: {
            id: parseInt(mainId, 10),
          },
        });
        if (res?.data?.ignoreDuplicate) {
          setIgnoreContactModal(false);
          listDuplicateContacts({
            variables: {
              spaceId: parseInt(spaceId, 10),
              offset: 0,
              limit: offset + CONTACT_LIMIT,
            },
          });
        }
      }
    }, 500);
  };

  const isSpacePlanActive = isPlanActive(
    userSubscriptionInfo?.subscription?.planStatus,
    userSubscriptionInfo?.subscription?.endDate
  );

  // eslint-disable-next-line no-nested-ternary
  return duplicateLoader && !singleContactRemove && offset === 0 ? (
    <ContactCardSkeleton loading={duplicateLoader} active />
  ) : duplicateContactData?.length ? (
    <div
      className={`${
        !isSpacePlanActive ? "cms-cal-height" : ""
      } duplicates-wrapper all-contacts cms-contact-scrollbar`}
      onScroll={(e) => handleInfiniteScroll(e)}
    >
      <DuplicatesCard
        contacts={duplicateContactData}
        removeLoader={removeLoader}
        spaceId={spaceId}
        setRemoveContactModal={setRemoveContactModal}
        removeContactModal={removeContactModal}
        removeSingleDuplicateContact={removeSingleDuplicateContact}
        ignoreDuplicateContactCard={ignoreDuplicateContactCard}
        setIgnoreContactModal={setIgnoreContactModal}
        ignoreContactModal={ignoreContactModal}
        listId={listId}
      />
      {duplicateLoader && <Loading />}
    </div>
  ) : (
    (duplicateData?.listDuplicateContacts?.count === 0 ||
      state?.duplicateCount === 0) && (
      <div className="cms-no-duplicate-contact">
        <NoGroupPlaceholder
          title="No duplicate contacts found!"
          showButton={false}
          icon={<NoContacts />}
        />
      </div>
    )
  );
};

export default DuplicatesList;
