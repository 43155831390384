import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const RESTORE_CONTACT = gql`
  mutation restoreContact($id: Int!) {
    restoreContact(id: $id) {
      message
      status
    }
  }
`;
