import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_CONTACTS = gql`
  query getContacts(
    $data: ContactInput!
    $groupId: Int
    $spaceId: Int
    $tag: String
  ) {
    getContacts(data: $data, groupId: $groupId, spaceId: $spaceId, tag: $tag) {
      data {
        contacts {
          id
          firstName
          middleName
          lastName
          jobTitle
          company
          photo
          birthday
          anniversary
          websites {
            type
            value
          }
          emails {
            type
            value
          }
          numbers {
            type
            value
          }
          tags
          groups {
            id
            name
            spaceId
            ownerId
          }
          spaceId
          space {
            id
            name
            photo
          }
        }
        count
      }
    }
  }
`;
