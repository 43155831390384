import React from "react";

//! User files
import ContactMetaDetails from "./ContactMetaDetails";
import ContactMergeDataMeta from "../../assistant/components/ContactMergeDataMeta";
import ContactDataMeta from "./ContactDataMeta";

const ContactData = ({
  contactDetails,
  mergeContact,
  filterValueFromPreviewMerge,
  mergePreviewContact,
  isContactMerged,
  mergeLoader,
  mergeContactCount,
  setContactDetails,
}) => {
  return (
    <>
      {mergeContact ? (
        <ContactMergeDataMeta
          contactDetail={contactDetails}
          mergeContact={mergeContact}
          mergePreviewContact={mergePreviewContact}
          isContactMerged={isContactMerged}
          mergeLoader={mergeLoader}
          mergeContactCount={mergeContactCount}
        />
      ) : (
        <ContactDataMeta
          contactDetail={contactDetails}
          setContactDetails={setContactDetails}
        />
      )}
      <ContactMetaDetails
        contactDetail={contactDetails}
        mergeContact={mergeContact}
        filterValueFromPreviewMerge={filterValueFromPreviewMerge}
        isContactMerged={isContactMerged}
      />
      {/* Hiding for MVP it will be useful in future */}
      {/* <ActionBanner /> */}
    </>
  );
};

export default ContactData;
