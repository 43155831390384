import React, { useContext } from "react";

//! Antd import
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import AutoComplete from "antd/lib/auto-complete";

//! User files
import { AppContext } from "../../../AppContext";
import { PersonIcon, DownArrowIcon, AddSpaceIcon } from "../../../assets/svg";
import { ADD, VIEW, PERMISSIONS, SHARE } from "../../constants";
import IconButton from "../IconButton/IconButton";
import SharedUserList from "../SharedUserList/SharedUserList";
import UserAvatar from "../UserAvatar/UserAvatar";
import { generateRandomColor } from "../../utils";

const { Option } = Select;
const { Option: AutoCompleteOption } = AutoComplete;
const SpaceSharingComponent = ({
  email,
  setEmail,
  inputFocus,
  setPermission,
  members,
  setVisible,
  visible,
  handleRemoveMember,
  handleMemberPermission,
  handleAddMember,
  removeLoader,
  memberLoading,
  noFlex,
  isGoogleSpace,
  domainUsers,
  sharingLoader,
  openShareModal,
  spaceName,
  membersCount,
}) => {
  const {
    state: { userSubscriptionInfo, currentUser, isGsuiteAdmin },
  } = useContext(AppContext);

  return (
    <div
      className={`${
        noFlex ? "" : "d-flex-center"
      } full-width cms-share-wrapper`}
    >
      <div className="cms-space-sharing-box-block-top-section">
        <div className="cms-share-with-text">Share with</div>
        <div className="cms-member-count">
          <PersonIcon />{" "}
          <span className="group-setting-total-contacts font-inter">
            {membersCount || 0}/
            {userSubscriptionInfo?.subscription?.quantity || 0}
          </span>
        </div>
      </div>
      <div className="cms-space-sharing-box-block-middle-section cms-add-member-block">
        <div span={20} className="cms-email-block" id="share-modal">
          {isGoogleSpace && isGsuiteAdmin ? (
            <AutoComplete
              id="name"
              className="cms-add-member-block-input"
              type="text"
              placeholder="Email Address"
              name="email"
              value={email}
              onChange={(e) => setEmail(e)}
              ref={inputFocus}
              getPopupContainer={() => document.getElementById("share-modal")}
              filterOption={(inputValue, option) =>
                option?.value
                  ?.toUpperCase()
                  ?.indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              {domainUsers?.map((member, i) => (
                <AutoCompleteOption value={member?.email} key={member?.id}>
                  <UserAvatar
                    className="cms-owner-avatar"
                    name={member?.name}
                    color={generateRandomColor(parseInt(i, 10))}
                    photo={member?.photo}
                  />
                  {member?.email}
                </AutoCompleteOption>
              ))}
            </AutoComplete>
          ) : (
            <>
              <Input
                id="name"
                className="cms-add-member-block-input"
                type="text"
                placeholder="Email Address"
                name="email"
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                ref={inputFocus}
              />
              {!isGoogleSpace && (
                <Select
                  defaultValue={VIEW}
                  className="cms-select-after"
                  optionLabelProp="label"
                  suffixIcon={<DownArrowIcon />}
                  onChange={(val) => setPermission(val)}
                >
                  {PERMISSIONS.map((rights) => (
                    <Option
                      value={rights?.value}
                      label={rights?.label}
                      key={rights?.value}
                    >
                      <div className="rights-option">{rights?.label}</div>
                    </Option>
                  ))}
                </Select>
              )}
            </>
          )}
        </div>
        <div span={3} className="cms-add-btn">
          <IconButton
            iconClass="cms-add-email-btn"
            text={
              <span className="font-inter">{isGoogleSpace ? SHARE : ADD}</span>
            }
            icon={<AddSpaceIcon />}
            handleClick={handleAddMember}
            loading={memberLoading || sharingLoader}
          />
        </div>
      </div>
      <SharedUserList
        members={members}
        ownerId={parseInt(currentUser?.id, 10)}
        setVisible={setVisible}
        visible={visible}
        handleRemoveMember={handleRemoveMember}
        removeLoader={removeLoader}
        handleMemberPermission={handleMemberPermission}
        noBorder
        noFlex={noFlex}
        openShareModal={openShareModal}
        offset={0}
        spaceName={spaceName}
      />
    </div>
  );
};

export default SpaceSharingComponent;
