import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_GENERAL_SPACE_INFO = gql`
  query spaceInfo($id: Int!) {
    spaceInfo(id: $id) {
      data {
        id
        name
        photo
        ownerId
        permission
        storage {
          filesSize
          filesCount
        }
        contactSources {
          id
        }
        contactsCount
        attachmentsCount
        membersCount
        notesCount
        default
        subscription {
          id
          planStatus
          quantity
          endDate
          startDate
          product {
            id
            name
            description
            features
            price {
              id
              amount
              interval
              currency
            }
            quantity
          }
        }
        type
      }
    }
  }
`;
