import React from "react";
import { Skeleton } from "antd";

function PlanPageSkeleton({ className }) {
  return (
    <div className={`${className} plan-skeleton-wrapper`}>
      <div className="plan-skeleton-body">
        <Skeleton paragraph={{ rows: 10 }} title={false} active />
        <Skeleton paragraph={{ rows: 10 }} title={false} active />
        <Skeleton paragraph={{ rows: 10 }} title={false} active />
        <Skeleton paragraph={{ rows: 10 }} title={false} active />
      </div>
    </div>
  );
}

export default PlanPageSkeleton;
