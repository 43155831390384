/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";

//! Antd imports
import { Card, Row, Col, Typography, Skeleton } from "antd";

//! graphql files
import { CANCEL_SPACE_SUBSCRIPTION } from "../../../graphql/mutations/cancelSubscription";
import { MANAGE_BILLING } from "../../../graphql/queries/manageBilling";
import { USER_PAYMENT_METHOD } from "../../../graphql/queries/userPaymentMethod";
import {
  GET_SPACE_SUBSCRIPTION,
  GET_SPACE_MEMBERS,
} from "../../../graphql/queries/getSpaceSubscription";

//! User files
import {
  Calendar,
  PaymentIcon,
  PersonBackground,
  PlanIcon,
  PlansIcon,
  Subscription,
  SubscriptionAmount,
  Wallet,
} from "../../../../../assets/svg";
import SubscriptionWarning from "../../../../../common/components/SubscriptionWarning/SubscriptionWarning";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import RemovePopup from "../../../../../common/components/RemovePopup/RemovePopup";
import {
  createAmplitudeEvent,
  formatDate,
  getCurrencySymbol,
  getDateDifference,
  isPlanActive,
} from "../../../../../common/utils";
import {
  AMPLITUDE_EVENT_LOG,
  defaultDateFormat,
  LTD,
  MONTH,
  PLAN_STATUS,
  SUCCESS,
} from "../../../../../common/constants";
import SettingsHeader from "../../../../../common/components/SettingsHeader/SettingsHeader";
import { AppContext } from "../../../../../AppContext";

function SubscriptionDetail() {
  const [open, setOpen] = useState(false);
  const [spaceMember, setSpaceMember] = useState(null);
  const [spaceDetail, setSpaceDetail] = useState([]);
  const [isTrialPlan, setIsTrialPlan] = useState(false);
  const [isLTDPlan, setIsLTDPlan] = useState(false);
  const { spaceId } = useParams();
  const { push } = useHistory();
  const {
    state: { userSubscriptionInfo },
  } = useContext(AppContext);
  const [getSpaceDetail, { loading: spaceLoading }] = useLazyQuery(
    GET_SPACE_SUBSCRIPTION,
    {
      fetchPolicy: "network-only",
      onCompleted({ spaceSubscription }) {
        setSpaceDetail(spaceSubscription?.data);
        if (
          spaceSubscription?.data?.subscription?.planStatus ===
          PLAN_STATUS.TRIAL
        ) {
          setIsTrialPlan(true);
        }
      },
    }
  );

  useEffect(() => {
    if (userSubscriptionInfo?.subscription?.product?.price?.interval === LTD) {
      setIsLTDPlan(true);
    }
  }, [userSubscriptionInfo]);

  const [getSpaceMembers] = useLazyQuery(GET_SPACE_MEMBERS, {
    fetchPolicy: "network-only",
    onCompleted(res) {
      setSpaceMember(res?.spaceMembers?.data?.membersCount);
    },
  });

  const [cancelSpaceSubscription, { loading: cancelLoading }] = useMutation(
    CANCEL_SPACE_SUBSCRIPTION,
    {
      onCompleted({ cancelSpaceSubscription }) {
        if (cancelSpaceSubscription?.status === SUCCESS) {
          setOpen(false);
          setTimeout(() => {
            getSpaceDetail({
              variables: {
                id: parseInt(spaceId, 10),
              },
            });
            window.location.reload();
          }, 2000);
        }
      },
    }
  );

  const [manageBilling, { loading: billingLoader }] = useLazyQuery(
    MANAGE_BILLING,
    {
      onCompleted({ manageBilling }) {
        window.location.href = manageBilling?.url;
      },
    }
  );

  const { data: userPaymentDetail } = useQuery(USER_PAYMENT_METHOD, {
    onError() {},
  });

  useEffect(() => {
    if (spaceId > 0) {
      getSpaceDetail({
        variables: {
          id: parseInt(spaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (spaceDetail?.length !== 0)
      getSpaceMembers({
        variables: {
          id: parseInt(spaceId, 10),
          data: {
            offset: 0,
            limit: spaceDetail?.subscription?.quantity,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceDetail]);

  const handleConfirmation = () => {
    setOpen(!open);
  };

  const popupText = (
    <span className="popup-text-title font-inter">
      Are you sure you want to cancel the Subscription?
    </span>
  );

  const handleCancelSubscription = (spaceId) => {
    cancelSpaceSubscription({
      variables: {
        id: parseInt(spaceId, 10),
      },
    }).then(() => {
      const eventProperties = {
        "Space Id": spaceId,
        "plan status": "cancelled",
      };
      createAmplitudeEvent(
        AMPLITUDE_EVENT_LOG.CANCEL_SUBSCRIPTION,
        eventProperties
      );
    });
  };

  const updatePaymentDetails = () => {
    manageBilling({
      fetchPolicy: "network-only",
      variables: {
        spaceId: parseInt(spaceId, 10),
      },
    });
  };

  const daysLeft =
    spaceDetail &&
    getDateDifference(spaceDetail?.subscription?.endDate, moment(), "days");

  return (
    <>
      <SettingsHeader icon={<Subscription />} title="Subscription" />
      <div className="subscription-detail">
        <div className="subscription-detail-block">
          <Row gutter={24} className="mb-24">
            <Col span={24}>
              <Card>
                <div className="cms-current-subs-block">
                  <div className="cms-current-subs-block-left">
                    <div className="mr-16">
                      <PlanIcon />
                    </div>
                    <div className="cms-current-subs-block-left-section">
                      <span className="cms-current-plan-text">
                        Your current plan
                      </span>
                      {spaceDetail?.subscription ? (
                        <div className="cms-current-plan-name">
                          {isTrialPlan ? (
                            !isPlanActive(
                              spaceDetail?.subscription?.planStatus,
                              spaceDetail?.subscription?.endDate
                            ) ? (
                              <span>
                                Your free trial has{" "}
                                <span className="cms-current-plan-name-trial">
                                  ended
                                </span>
                              </span>
                            ) : (
                              <span>
                                {spaceDetail?.subscription?.product?.name}{" "}
                                Plan&nbsp;
                              </span>
                            )
                          ) : (
                            <span>
                              {spaceDetail?.subscription?.product?.name}{" "}
                              Plan&nbsp;
                            </span>
                          )}
                          {isPlanActive(
                            spaceDetail?.subscription?.planStatus,
                            spaceDetail?.subscription?.endDate
                          ) &&
                            !isLTDPlan && (
                              <span className="cms-current-plan-name-interval">
                                {spaceDetail?.subscription?.product?.price
                                  ?.interval === MONTH
                                  ? "(billed monthly)"
                                  : "(billed yearly)"}
                              </span>
                            )}
                        </div>
                      ) : (
                        <Skeleton paragraph={false} title active />
                      )}
                    </div>
                  </div>
                  {isPlanActive(
                    spaceDetail?.subscription?.planStatus,
                    spaceDetail?.subscription?.endDate
                  ) && (
                    <IconButton
                      iconClass="font-inter cms-change-plan-btn"
                      text="Change Plan"
                      handleClick={() => push(`/space/${spaceId}/plans`)}
                    />
                  )}
                </div>
              </Card>
            </Col>
          </Row>
          {isTrialPlan &&
            isPlanActive(
              spaceDetail?.subscription?.planStatus,
              spaceDetail?.subscription?.endDate
            ) && (
              <Row gutter={24} className="mb-24">
                <Col span={24}>
                  <Card>
                    <div className="cms-current-subs-block">
                      <div className="cms-current-subs-block-left">
                        <div className="mr-16">
                          <PlansIcon />
                        </div>
                        <div className="cms-current-subs-block-left-section">
                          <span className="cms-current-plan-text">
                            Your current plan
                          </span>
                          {spaceDetail?.subscription ? (
                            <div className="cms-current-plan-name">
                              <span>
                                You are currently on a{" "}
                                <span className="cms-current-plan-name-trial">
                                  free trial
                                </span>
                              </span>
                            </div>
                          ) : (
                            <Skeleton paragraph={false} title active />
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
          {!isTrialPlan &&
            !isLTDPlan &&
            isPlanActive(
              spaceDetail?.subscription?.planStatus,
              spaceDetail?.subscription?.endDate
            ) && (
              <Row gutter={24} className="mb-24">
                <Col span={24}>
                  <Card>
                    <div className="cms-current-subs-block">
                      <div className="cms-current-subs-block-left">
                        <div className="mr-16">
                          <PersonBackground />
                        </div>
                        <div className="cms-current-subs-block-left-section">
                          <span className="cms-current-plan-text">Members</span>
                          {spaceDetail?.subscription && spaceMember ? (
                            <div className="cms-current-plan-name">
                              {isTrialPlan ? (
                                !isPlanActive(
                                  spaceDetail?.subscription?.planStatus,
                                  spaceDetail?.subscription?.endDate
                                ) ? (
                                  <span>
                                    Your free trial has{" "}
                                    <span className="cms-current-plan-name-trial">
                                      ended
                                    </span>
                                  </span>
                                ) : (
                                  <span>
                                    {spaceMember || 0} /{" "}
                                    {spaceDetail?.subscription?.quantity}
                                  </span>
                                )
                              ) : (
                                <span>
                                  {spaceMember || 0} /{" "}
                                  {spaceDetail?.subscription?.quantity}
                                </span>
                              )}
                            </div>
                          ) : (
                            <Skeleton paragraph={false} title active />
                          )}
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
          {!isTrialPlan && !isLTDPlan && (
            <>
              <Row gutter={24} className="mb-24">
                <Col span={12}>
                  <Card>
                    <Row align="middle">
                      <Col span={7}>
                        <Calendar />
                      </Col>
                      <Col span={16}>
                        <div className="subscription-detail-text">
                          Next billing date
                        </div>
                        <div className="subscription-detail-date-amount">
                          {spaceDetail?.subscription?.planStatus ===
                          PLAN_STATUS?.CANCELLED ? (
                            "-"
                          ) : spaceDetail?.subscription ? (
                            formatDate(
                              spaceDetail?.subscription?.endDate,
                              defaultDateFormat
                            )
                          ) : (
                            <Skeleton paragraph={false} title active />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <Row align="middle">
                      <Col span={7}>
                        <Wallet />
                      </Col>
                      <Col span={16}>
                        <div className="subscription-detail-text">
                          Next billing amount
                        </div>
                        <div className="subscription-detail-date-amount">
                          {spaceDetail?.subscription?.planStatus ===
                          PLAN_STATUS?.CANCELLED ? (
                            "-"
                          ) : spaceDetail?.subscription ? (
                            <>
                              {getCurrencySymbol(
                                spaceDetail?.subscription?.product?.price
                                  ?.currency
                              )}
                              {(
                                spaceDetail?.subscription?.quantity *
                                spaceDetail?.subscription?.product?.price
                                  ?.amount
                              ).toFixed(2)}
                            </>
                          ) : (
                            <Skeleton paragraph={false} title active />
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Card>
                    <div className="cms-payment-method-block">
                      <div className="cms-payment-method-block-left-section">
                        <div className="mr-16">
                          <PaymentIcon />
                        </div>
                        <div>
                          <div className="subscription-detail-text">
                            Payment Method
                          </div>
                          <div className="subscription-detail-date-amount">
                            Through card
                          </div>
                        </div>
                      </div>
                      <IconButton
                        iconClass="font-inter cms-update-detail-btn"
                        text="Update Payment Details"
                        handleClick={updatePaymentDetails}
                        loading={billingLoader}
                      />
                    </div>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {!spaceLoading &&
            !isPlanActive(
              spaceDetail?.subscription?.planStatus,
              spaceDetail?.subscription?.endDate
            ) && (
              <div className="subscription-detail-upgrade-box">
                <Typography.Text className="font-inter subscription-detail-upgrade-box-text">
                  Explore plans and select which suits your needs
                </Typography.Text>
                <IconButton
                  iconClass="font-inter cms-change-plan-btn cms-border-radius"
                  text={
                    <span className="font-inter cms-border-radius-text">
                      Choose Plan
                    </span>
                  }
                  handleClick={() => push(`/space/${spaceId}/plans`)}
                />
              </div>
            )}
          <div className="cms-update-btn-block">
            {spaceDetail?.subscription?.planStatus !== PLAN_STATUS?.CANCELLED &&
              !isLTDPlan &&
              !isTrialPlan && (
                <div className="subscription-detail-title-cancel-subscription">
                  <IconButton
                    type="text"
                    iconClass="font-inter subscription-detail-title-cancel-subscription-btn"
                    icon={<SubscriptionAmount />}
                    text={
                      <span className="cms-leave-text">
                        Cancel Subscription
                      </span>
                    }
                    handleClick={handleConfirmation}
                  />
                </div>
              )}
          </div>

          {spaceDetail?.subscription?.planStatus === PLAN_STATUS?.CANCELLED &&
          !userPaymentDetail?.userPaymentMethod?.available ? (
            <SubscriptionWarning
              daysLeft={daysLeft}
              subscriptionStatus="expired"
              warningText="You subscription has expired"
            />
          ) : (
            spaceDetail?.subscription?.planStatus ===
              PLAN_STATUS?.CANCELLED && (
              <SubscriptionWarning
                daysLeft={daysLeft}
                subscriptionStatus="cancel"
                warningText="You have cancelled the subscription."
              />
            )
          )}
          {
            //! Will remove after testing
            /* {spaceDetail?.subscription?.planStatus === PLAN_STATUS?.TRIAL && (
            <SubscriptionWarning
              daysLeft={daysLeft}
              subscriptionStatus="freeTrial"
              warningText="You are using free trial of Contactbook. You will be charged from the next billing date."
            />
          )} */
          }
        </div>
      </div>
      <RemovePopup
        content={popupText}
        okText="Remove"
        visible={open}
        removeNote={handleConfirmation}
        handleRemove={() => handleCancelSubscription(spaceId)}
        deleteLoader={cancelLoading}
        disabled={cancelLoading}
        maskClosable={false}
      />
    </>
  );
}

export default SubscriptionDetail;
