import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const DELETE_ATTACHMENT = gql`
  mutation deleteAttachment($id: Int!, $contactId: Int!, $key: String!) {
    deleteAttachment(id: $id, contactId: $contactId, key: $key) {
      message
      status
    }
  }
`;
