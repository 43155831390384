import React, { useState, useCallback, useEffect, useContext } from "react";
import Dropzone from "react-dropzone";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";
import { nth } from "lodash";

//! Antd import
import { Skeleton } from "antd";

//! User files
import { AttachmentIcon, UploadFile } from "../../../../../assets/svg";
import {
  createAmplitudeEvent,
  getFileType,
  notificationToast,
  isPlanActive,
} from "../../../../../common/utils";
import ContactDocumentsList from "./ContactDocumentsList";
import { AppContext } from "../../../../../AppContext";
import {
  AMPLITUDE_EVENT_LOG,
  ATTACHMENT_EXTENSION,
  BASIC,
  OWNER,
  UPGRADE_PLAN_TITLE,
} from "../../../../../common/constants";
import NoGroupPlaceholder from "./NoGroupPlaceholder";
import { SET_SPACE_PLAN_STATUS } from "../../../../../common/actionTypes";
import UpgradeProPopup from "../../../../../common/components/UpgradeProPopup/UpgradeProPopup";

//! Graphql files
import { GENERATE_SIGNED_URL_FOR_ATTACHMENT } from "../../../graphql/mutations/generateSignedUrlForAttachment";
import { GET_ATTACHMENTS } from "../../../graphql/queries/getAttachments";
import { ADD_ATTACHMENT } from "../../../graphql/mutations/addAttachment";
import { DELETE_ATTACHMENT } from "../../../graphql/mutations/deleteAttachment";
import { EDIT_ATTACHMENT } from "../../../graphql/mutations/editAttachment";
import { DOWNLOAD_ATTACHMENT } from "../../../graphql/mutations/downloadAttachment";

const ContactDocumentsContainer = ({ contactPermission, contactDetail }) => {
  const [fileContent, setFileContent] = useState();
  const [attachment, setAttachment] = useState([]);
  const [progress, setProgress] = useState(0);
  const { contactId } = useParams();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const isDeletedContact = nth(pathname?.split("/"), 4) === "deleted-contacts";

  const {
    state: { userGroupPermission, userSubscriptionInfo, userSpacePermission },
    dispatch,
  } = useContext(AppContext);

  const [generateSignedUrlForAttachment] = useMutation(
    GENERATE_SIGNED_URL_FOR_ATTACHMENT,
    {
      // eslint-disable-next-line no-shadow
      onCompleted({ generateSignedUrlForAttachment }) {
        if (generateSignedUrlForAttachment?.url) {
          const { signedRequest } = generateSignedUrlForAttachment;
          const xhr = new XMLHttpRequest();

          xhr.open("PUT", signedRequest);
          xhr.setRequestHeader("Content-Type", fileContent?.type);
          xhr.setRequestHeader("x-amz-acl", "private");
          xhr.withCredentials = false;

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status !== 200) {
                notificationToast({
                  message: "Profile image upload failed!",
                  type: "error",
                });
              }
            }
          };

          xhr.send(fileContent);
        }
      },
      onError() {},
    }
  );

  const { data: attachments, loading } = useQuery(GET_ATTACHMENTS, {
    fetchPolicy: "network-only",
    variables: {
      contactId: parseInt(contactId, 10),
    },
    // eslint-disable-next-line no-shadow
    onCompleted(attachments) {
      setAttachment(attachments?.getAttachments?.data?.attachments);
    },
  });

  useEffect(() => {
    if (attachments?.getAttachments?.data?.attachments) {
      setAttachment(attachments?.getAttachments?.data?.attachments);
    }
  }, [attachments]);
  const [addAttachment] = useMutation(ADD_ATTACHMENT, {
    refetchQueries: [
      {
        fetchPolicy: "network-only",
        query: GET_ATTACHMENTS,
        variables: {
          contactId: parseInt(contactId, 10),
        },
      },
    ],
    variables: { contactId: parseInt(contactId, 10) },
    onCompleted(data) {
      const eventProperties = {
        "Contact Id": contactId,
        "Attachment Name": data?.addAttachment?.data?.name,
        "Added By": data?.addAttachment?.data?.userId,
      };
      createAmplitudeEvent(
        AMPLITUDE_EVENT_LOG.ATTACHMENT_ADDED,
        eventProperties
      );
      setProgress(100);
    },
    onError() {},
  });

  const [deleteAttachment, { loading: deleteLoader }] = useMutation(
    DELETE_ATTACHMENT,
    {
      refetchQueries: [
        {
          fetchPolicy: "network-only",
          query: GET_ATTACHMENTS,
          variables: {
            contactId: parseInt(contactId, 10),
          },
        },
      ],
      onCompleted() {
        setProgress(100);
      },
    }
  );

  const [downloadAttachment] = useMutation(DOWNLOAD_ATTACHMENT, {
    // eslint-disable-next-line no-shadow
    onCompleted({ downloadAttachment }) {
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.href = downloadAttachment?.signedUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });

  const [editFileName, { loading: editLoading }] = useMutation(
    EDIT_ATTACHMENT,
    {
      refetchQueries: [
        {
          fetchPolicy: "network-only",
          query: GET_ATTACHMENTS,
          variables: {
            contactId: parseInt(contactId, 10),
          },
        },
      ],
    }
  );

  const beforeUpload = (extension, size) => {
    const isExtensionValid = ATTACHMENT_EXTENSION.includes(extension);
    if (!isExtensionValid) {
      notificationToast({
        message:
          "You can only upload JPG/PNG/PDF/DOCS/MP3/AVI/MOV/WAV/MP4 file!",
        type: "error",
      });
    }

    const isLt2M = size / 1024 / 1024 <= 10;
    if (!isLt2M) {
      notificationToast({
        message: `File must be smaller than 10MB!`,
        type: "warning",
      });
    }
    return isExtensionValid && isLt2M;
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (
      isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      if (acceptedFiles.length > 1) {
        notificationToast({
          message: "Only single file allowed at a time.",
          type: "error",
        });
        return false;
      }

      const file = acceptedFiles[0];
      setFileContent(file);
      const { name, size } = file;
      const fileName = `document/${name}`;
      const imgExt = name?.split(".")?.pop();
      const fileType = getFileType(imgExt);
      const canUpload = beforeUpload(imgExt, size);
      const totalFileSize =
        parseFloat((size / (1024 * 1024)).toFixed(2)) +
        userSubscriptionInfo?.storage?.filesSize;

      if (
        totalFileSize >
        parseInt(
          userSubscriptionInfo?.subscription?.product?.features?.f_documents,
          10
        ) *
          1024
      ) {
        notificationToast({
          message: "You have exceeded your space documents upload limit",
          type: "error",
        });
        return false;
      }

      if (canUpload) {
        setProgress(30);
        generateSignedUrlForAttachment({
          variables: {
            data: {
              extension: name.split(".").pop(),
              key: fileName,
              spaceId: parseInt(contactDetail?.spaceId, 10),
              size: parseFloat((size / (1024 * 1024)).toFixed(2)),
            },
          },
        }).then((data) => {
          setProgress(60);
          if (data?.data?.generateSignedUrlForAttachment) {
            addAttachment({
              variables: {
                data: {
                  name: name,
                  type: fileType,
                  size: parseFloat((size / (1024 * 1024)).toFixed(2)),
                  key: data?.data?.generateSignedUrlForAttachment?.key,
                  url: data?.data?.generateSignedUrlForAttachment?.url,
                },
              },
            })
              .then(() => {})
              .catch(() => {});
          }
        });
        // eslint-disable-next-line no-empty
      } else {
      }
    } else {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: {
          planExpired: true,
          message: UPGRADE_PLAN_TITLE,
          spaceId: parseInt(contactDetail?.spaceId, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeDocument = (docId, key) => {
    if (docId > 0 && key) {
      deleteAttachment({
        variables: {
          contactId: parseInt(contactId, 10),
          id: docId,
          key: key,
        },
      })
        .then(() => {
          const eventProperties = {
            "Contact Id": contactId,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.ATTACHMENT_REMOVED,
            eventProperties
          );
          setOpen(false);
        })
        .catch(() => {});
    }
  };

  const handleDownloadAttachment = (key) => {
    if (key) {
      downloadAttachment({
        variables: {
          contactId: parseInt(contactId, 10),
          key: key,
        },
      });
    }
  };

  const editDocumentName = (id, name) => {
    if (name?.trim()?.length > 0) {
      editFileName({
        variables: {
          id: parseInt(id, 10),
          contactId: parseInt(contactId, 10),
          name,
        },
      });
    }
  };

  const openDocExternalLink = (key) => {
    if (key) {
      downloadAttachment({
        variables: {
          contactId: parseInt(contactId, 10),
          key: key,
        },
      });
    }
  };

  return userSubscriptionInfo?.subscription?.product?.name === BASIC ? (
    <UpgradeProPopup
      content={
        userGroupPermission === OWNER || userSpacePermission === OWNER
          ? "Upgrade to Pro or Business plan to add documents to contacts"
          : "This space is shared with you and Attachments feature is not available in your space. Please contact Space Owner to switch to a plan with this feature."
      }
      spaceId={contactDetail?.spaceId}
      hideButton={
        userGroupPermission !== OWNER && userSpacePermission !== OWNER
      }
    />
  ) : (
    <Dropzone onDrop={onDrop} disabled={isDeletedContact}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          className={`${
            isDragActive ? "cms-dropzone-active" : ""
          } cms-document-wrapper`}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            loading ? (
              <div className="skeleton-layout">
                <Skeleton active />
              </div>
            ) : attachment?.length > 0 ? (
              <div className="cms-document-scroller cms-contact-scrollbar">
                <ContactDocumentsList
                  attachments={attachment}
                  removeDocument={removeDocument}
                  deleteLoader={deleteLoader}
                  handleDownloadAttachment={handleDownloadAttachment}
                  editDocumentName={editDocumentName}
                  editLoading={editLoading}
                  progress={progress}
                  setOpen={setOpen}
                  open={open}
                  contactPermission={contactPermission}
                  openDocExternalLink={openDocExternalLink}
                />
              </div>
            ) : (
              !contactPermission && (
                <div className="cms-no-notes">
                  <NoGroupPlaceholder
                    title="No attachment found!"
                    showButton={false}
                    icon={<AttachmentIcon />}
                  />
                </div>
              )
            )
          }
          {!loading && contactPermission && (
            <div
              className={`${
                attachment?.length > 0 && "cms-upload-wrapper-block"
              } cms-upload-wrapper`}
            >
              <div
                className={`${
                  attachment?.length > 0
                    ? "cms-dropzone-block"
                    : "cms-document-empty-height"
                } ${isDragActive ? "cms-dropzone-zIndex" : ""} cms-dropzone ${
                  !isDeletedContact && "cursor-pointer"
                }`}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div
                  className={`${
                    attachment?.length > 0 && "cms-dropzone-block-content"
                  } cms-dropzone-content`}
                >
                  <span
                    className={`${
                      attachment?.length > 0 && "cms-upload-icon-block"
                    } cms-upload-icon`}
                  >
                    <UploadFile />
                  </span>
                  <span className="font-inter cms-upload-text">
                    Drag & Drop files here or click to upload
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default ContactDocumentsContainer;
