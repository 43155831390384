import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const NOTIFY_UPCOMING_FEATURE = gql`
  mutation {
    notifyUpComingFeatures {
      message
      status
    }
  }
`;
