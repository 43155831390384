import React from "react";

//! User files
import { NoNotification } from "../../../../../assets/svg/common";
import NoGroupPlaceholder from "../../contacts/components/NoGroupPlaceholder";

const NoNotificationYet = () => {
  return (
    <div className="cms-no-notification">
      <NoGroupPlaceholder
        icon={<NoNotification />}
        title="No notifications yet"
        height={80}
        width={80}
        fontSize={20}
      />
    </div>
  );
};

export default NoNotificationYet;
