import React, { useContext, useEffect } from "react";
import { Checkbox, List, Modal } from "antd";
import clsx from "clsx";
import { cloneDeep, indexOf } from "lodash";

//! User files
import Loading from "../../../../../common/components/Loading";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import { capitalizeWord } from "../../../../../common/utils";
import { AppContext } from "../../../../../AppContext";
import { CONTACT_LIMIT } from "../../../../../common/constants";
import SpacelistSkeleton from "../../../../../common/components/SpacelistSkeleton/SpacelistSkeleton";
import { Folder } from "../../../../../assets/svg";

const MultipleGroupsModal = ({
  setGroupListModal,
  groupListModal,
  groupList,
  groupListLoader,
  setGroupOffset,
  groupOffset,
  commonGroups,
  manageMultipleGroupsTags,
  groupCheckList,
  setGroupCheckList,
  setCommonGroups,
  setGroupsToUnassign,
  groupsToUnassign,
  totalGroups,
  manageGroupsTagsLoader,
  groupsToAssign,
  setGroupsToAssign,
  commonGroupsTagsLoader,
  setGroupList,
  getGroupsForContacts,
}) => {
  const {
    state: { connectSourceParams },
  } = useContext(AppContext);
  const { spaceId } = connectSourceParams;
  const currentSpace =
    parseInt(spaceId, 10) || parseInt(localStorage.getItem("currentSpace"), 10);
  useEffect(() => {
    getGroupsForContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace, groupOffset]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;

    if (
      !groupListLoader &&
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      groupList?.length < totalGroups
    ) {
      setGroupOffset(groupOffset + CONTACT_LIMIT);
    }
  };

  const handleCheckboxClick = (e, grpId) => {
    e.stopPropagation();
    if (groupCheckList?.includes(grpId)) {
      const tempId = cloneDeep(groupCheckList);
      const index = indexOf(groupCheckList, grpId);
      const indexOfAssign = indexOf(groupsToAssign, grpId);

      if (index !== -1) {
        tempId.splice(index, 1);
      }
      if (groupsToAssign?.includes(grpId)) {
        groupsToAssign?.splice(indexOfAssign, 1);
      }
      if (
        !e?.target?.checked &&
        commonGroups?.includes(parseInt(grpId, 10)) &&
        !groupsToUnassign?.includes(grpId)
      ) {
        setGroupsToUnassign([...groupsToUnassign, grpId]);
      }
      setGroupCheckList(tempId);
    } else {
      setGroupCheckList([...groupCheckList, grpId]);

      if (!commonGroups?.includes(grpId) && !groupsToAssign?.includes(grpId)) {
        setGroupsToAssign([...groupsToAssign, grpId]);
      }

      if (groupsToUnassign?.includes(grpId)) {
        groupsToUnassign?.splice(indexOf(groupsToUnassign, grpId), 1);
      }
    }
  };

  const handleMultipleGroups = () => {
    manageMultipleGroupsTags();
    setGroupListModal(false);
  };

  const handleCloseModal = () => {
    setGroupListModal(false);
    setCommonGroups([]);
    setGroupList([]);
    setGroupCheckList([]);
    setGroupsToAssign([]);
    setGroupOffset(0);
    setGroupsToUnassign([]);
  };

  return (
    <div>
      <Modal
        visible={groupListModal}
        className="multiple-group-modal-wrapper"
        centered
        title={
          <span className="font-inter cms-shared-space-title">
            Manage groups
          </span>
        }
        onCancel={handleCloseModal}
        maskClosable={false}
        footer={
          groupList?.length > 0 && (
            <IconButton
              iconClass="cms-apply-btn"
              text="Apply"
              handleClick={handleMultipleGroups}
              loading={manageGroupsTagsLoader}
              disable={
                groupsToAssign?.length < 1 && groupsToUnassign?.length < 1
              }
            />
          )
        }
      >
        <div onScroll={handleInfiniteScroll} className="cms-infinite-scroll">
          {
            // eslint-disable-next-line no-nested-ternary
            commonGroupsTagsLoader ? (
              <SpacelistSkeleton loading={commonGroupsTagsLoader} />
            ) : groupList?.length === 0 ? (
              <div className="cms-no-group-tag">
                <div className="cms-no-group-tag-icon mb-12">
                  <Folder />
                </div>
                <div className="cms-no-group-tag-text mb-24">
                  No groups found
                </div>
              </div>
            ) : (
              <List
                dataSource={groupList}
                renderItem={(item) => {
                  const { name, id, type } = item;

                  const groupName = (
                    <span className="cms-font-ellipsis group-name">
                      {capitalizeWord(name)}
                    </span>
                  );
                  const groupTitle = (
                    <div className="cms-group-title">{groupName}</div>
                  );

                  return (
                    <List.Item
                      className={`${clsx(
                        groupCheckList?.includes(parseInt(id, 10)) &&
                          "selected-list active-bg"
                      )} cursor-pointer`}
                      title={capitalizeWord(name)}
                      onClick={(e) => handleCheckboxClick(e, id)}
                    >
                      <List.Item.Meta
                        avatar={
                          type === "PRIVATE" ? (
                            <>
                              <span className="d-flex-center ">
                                <Checkbox
                                  onChange={(e) => handleCheckboxClick(e, id)}
                                  checked={groupCheckList?.includes(
                                    parseInt(id, 10)
                                  )}
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex-center ">
                                <Checkbox
                                  className={`${
                                    groupCheckList?.includes(parseInt(id, 10))
                                      ? "group-checkbox-checked"
                                      : "group-checkbox"
                                  } `}
                                  onChange={(e) => handleCheckboxClick(e, id)}
                                  checked={groupCheckList?.includes(
                                    parseInt(id, 10)
                                  )}
                                />
                              </span>
                            </>
                          )
                        }
                        title={groupTitle}
                      />
                    </List.Item>
                  );
                }}
              />
            )
          }
          {groupListLoader && groupOffset !== 0 && (
            <div className="cms-infinite-scroll-loader">
              <Loading />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MultipleGroupsModal;
