import { gql } from "@apollo/client/core";

export const contactFragment = gql`
  fragment ContactFragment on ContactInfoResponseData {
    sourceType
    firstName
    middleName
    lastName
    jobTitle
    company
    photo
    birthday
    anniversary
    websites {
      type
      value
    }
    emails {
      type
      value
    }
    numbers {
      type
      value
    }
    tags
    groups {
      id
      name
      ownerId
      spaceId
    }
    contactAddresses {
      id
      label
      line1
      line2
      city
      county
      state
      country
      zipCode
      contactId
    }
    socialProfiles {
      type
      value
    }
    reminders {
      id
      frequency
      type
      contactId
      userId
      spaceId
      scheduledAt
      timeZoneOffSet
    }
    spaceId
    isEditable
    notes
  }
`;
export const createContactFragment = gql`
  fragment CreateContactFragment on CreateContactResponse {
    sourceType
    firstName
    middleName
    lastName
    jobTitle
    company
    photo
    birthday
    anniversary
    websites {
      type
      value
    }
    emails {
      type
      value
    }
    numbers {
      type
      value
    }
    tags
    groups
    contactAddresses {
      id
      label
      line1
      line2
      city
      county
      state
      country
      zipCode
      contactId
    }
    spaceId
    isEditable
  }
`;
