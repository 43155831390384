import React, { useContext } from "react";
import {
  BASIC,
  BUSINESS,
  GOOGLE,
  PRO,
  PROFFESSIONAL,
  TEAM,
} from "../../../../../common/constants";
import { capitalizeWord } from "../../../../../common/utils";
import { AppContext } from "../../../../../AppContext";

const SetSubscriptionFeatures = ({ features, planName, isLTDPlan }) => {
  const subsFeature = [
    features?.f_contacts,
    "",
    "",
    features?.f_sync_sources,
    features?.f_groups_tags,
    features?.f_notes,
    "",
    "",
    features?.f_permission_management,
    "",
    features?.f_mobile_apps,
    "",
    "",
    "",
  ];
  const googleSubsFeature = [
    "",
    "",
    features?.f_space_sharing,
    features?.f_groups_tags,
    features?.f_notes,
    features?.f_documents,
    features?.f_contact_activities,
    features?.f_permission_management,
    features?.f_reminders,
    "",
    features?.f_mobile_apps,
    "",
    "",
    "",
  ];

  const LTDPlanFeatures = [
    `${features?.f_contacts} Contacts`,
    features?.f_space_sharing === "Yes"
      ? "Unlimited users"
      : `Up to ${features?.f_space_sharing} users`,
    `${features?.f_groups_tags} Groups & Tags`,
    `${
      features?.f_permission_management === "Yes"
        ? "Manage permissions"
        : "No permissions management"
    }`,
    "Real-time Updates",
    `${
      features?.f_mobile_app === "Yes"
        ? "Android / iOS Applications"
        : "No Android / iOS Applications"
    }`,

    `${features?.f_notes} Notes`,
    `${features?.f_documents} GB storage for attachments`,
    features?.f_reminders === "Yes"
      ? "Unlimited Reminders"
      : `${features?.f_reminders} Reminders`,
    features?.f_contact_activities === "Yes"
      ? "Lifetime activity history"
      : `${features?.f_contact_activities} months activity history`,
  ];

  const {
    state: { userSubscriptionInfo },
  } = useContext(AppContext);

  const isGoogleWorkSpace = userSubscriptionInfo?.type === GOOGLE;
  const planFeatures = isGoogleWorkSpace ? googleSubsFeature : subsFeature;

  return isLTDPlan
    ? LTDPlanFeatures?.map((feature, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="cms-feature-list cms-pro-feature-list">
          <span className="font-inter cms-feature-list-title">{feature}</span>
        </div>
      ))
    : planFeatures?.map((feature, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i} className="cms-feature-list cms-pro-feature-list">
          <span className="font-inter cms-feature-list-title">
            {i === 0 &&
              isGoogleWorkSpace &&
              ((planName === PROFFESSIONAL && 1500) ||
                (planName === TEAM && 25000))}
            {i === 1 &&
              isGoogleWorkSpace &&
              ((planName === PROFFESSIONAL && 5) ||
                (planName === TEAM && "Unlimited"))}
            {i === 3 && planName === PRO && "Up to "}
            {i === 6 && planName === BASIC ? (
              "-"
            ) : (
              <>
                {capitalizeWord(feature) || (i === 10 && "Yes")}
                &nbsp;
                {isGoogleWorkSpace && i === 5 && "GB"}
              </>
            )}
          </span>
          {i === 1 && (
            <>
              {" "}
              <span className="font-inter cms-feature-list-title">
                {(planName === BASIC && "Up to 5") ||
                  (planName === BUSINESS && "Unlimited") ||
                  (planName === PRO && "Up to 50 ")}
              </span>
            </>
          )}

          {i === 2 && !isGoogleWorkSpace && (
            <>
              <span className="font-inter cms-feature-list-title">
                {(planName === BASIC && "Up to 5 users") ||
                  (planName === PRO && "Up to 50 users") ||
                  (planName === BUSINESS && "Unlimited users")}
              </span>
            </>
          )}

          {i === 3 && (
            <span className="font-inter cms-feature-list-title">
              {planName === BASIC && " Source"}
            </span>
          )}
          {!isGoogleWorkSpace && i === 6 && planName !== BASIC && (
            <span className="font-inter cms-feature-list-title">
              {planName === PRO && "Up to 10 GB"}{" "}
              {planName === BUSINESS && "Up to 100 GB"}
              <span className="cms-subs-plan-text cms-text-mLeft">
                per space
              </span>
            </span>
          )}

          {!isGoogleWorkSpace && i === 9 && (
            <span className="font-inter cms-feature-list-title">
              {(planName === BASIC && "No") ||
                (planName === PRO && "5") ||
                (planName === BUSINESS && "Unlimited")}
            </span>
          )}

          {isGoogleWorkSpace && i === 9 && (
            <span className="font-inter cms-feature-list-title">
              {(planName === PROFFESSIONAL && "Once a day") ||
                (planName === TEAM && "Every 4 hours")}
            </span>
          )}

          {!isGoogleWorkSpace && i === 7 && (
            <span className="font-inter cms-feature-list-title">
              {planName === BUSINESS && "Lifetime activity history"}
              {planName === PRO && "30 Days activity history"}
              {planName === BASIC && "-"}
            </span>
          )}

          {i === 11 && (
            <span className="font-inter cms-feature-list-title">
              {planName === BASIC && "Email"}
              {planName === PRO && "Priority"}
              {planName === BUSINESS && "Dedicated"}
              {planName === PROFFESSIONAL && "Email"}
              {planName === TEAM && "Priority"}
            </span>
          )}
          {i === 12 && (
            <span className="font-inter cms-feature-list-title">
              {planName === BASIC && "No"}
              {planName === PRO && "No"}
              {planName === BUSINESS && "Yes"}
              {planName === PROFFESSIONAL && "No"}
              {planName === TEAM && "Yes"}
            </span>
          )}
          {i === 13 && (
            <span className="font-inter cms-feature-list-title">
              {planName === BASIC && "No"}
              {planName === PRO && "No"}
              {planName === BUSINESS && "Yes"}
              {planName === PROFFESSIONAL && "No"}
              {planName === TEAM && "Yes"}
            </span>
          )}
        </div>
      ));
};

export default SetSubscriptionFeatures;
