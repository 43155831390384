import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_GROUP_MEMBERS = gql`
  query groupMembers($offset: Int, $limit: Int, $id: ID!) {
    groupMembers(offset: $offset, limit: $limit, id: $id) {
      sharingDetails {
        id
        groupId
        permissions
        userId
        sharedBy
        space
        username
        photo
        emailVerified
        email
        groupMapping {
          id
          groupId
          userId
          syncStatus
          spaceId
        }
      }
      count
    }
  }
`;
