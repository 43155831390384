import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

//! User files
import { ADDON_PARAMS, NO_ACCESS } from "../../constants";
import { NoAccessIcon, NoContacts } from "../../../assets/svg";
import IconButton from "../IconButton/IconButton";
import UpgradeButtonComponent from "../../UpgradeButtonComponent";
import UserAvatar from "../UserAvatar/UserAvatar";
import { capitalizeWord, generateRandomColor } from "../../utils";

const NoAccessComponent = ({
  title,
  content,
  spaceLock,
  upgradeButton,
  spaceName,
  spacePhoto,
}) => {
  useEffect(() => {
    return () => {
      localStorage.removeItem(ADDON_PARAMS);
    };
  }, []);
  const { pathname } = useLocation();
  const spaceId = pathname.split("/")[2];
  return !spaceLock ? (
    <div className="cms-no-access">
      <div className="no-group">
        <div className="no-group-circle">
          <NoContacts />
        </div>
        <div className="no-group-text font-inter">{NO_ACCESS}</div>
        <div className="no-group-btn-wrapper">
          <Link to={`/contacts/${spaceId}/group/all`}>
            <IconButton
              iconClass="no-group-create-access-btn font-inter"
              text="Go back to All Contacts"
            />
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className="cms-space-lock">
      <div className="cms-space-lock-section">
        <div className="cms-space-lock-section-circle">
          <NoAccessIcon />
        </div>
        <div className="cms-space-indicator">
          <UserAvatar
            className="cms-space-avatar"
            name={spaceName}
            size={20}
            color={generateRandomColor(parseInt(spaceId, 10))}
            photo={spacePhoto}
          />
          <span className="cms-space-name">{capitalizeWord(spaceName)}</span>
        </div>
        <div className="no-group-text no-subscription-msg font-inter">
          {title}
        </div>
        <div
          className={`${
            !upgradeButton ? "cms-page-lock-note" : ""
          } no-group-btn-wrapper`}
        >
          {content}
        </div>
        {upgradeButton && (
          <Link to={`/space/${spaceId}/plans`}>
            <div>
              <UpgradeButtonComponent />
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default NoAccessComponent;
