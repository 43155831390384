import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const RETRY_PAYMENT = gql`
  query retryPayment($id: Int!) {
    retryPayment(id: $id) {
      url
    }
  }
`;
