import React from "react";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Divider, Space, Typography } from "antd";
import Layout from "../layout/Layout";
import email from "../../../assets/images/Email.png";
import { RESEND_EMAIL } from "../graphql/Mutations";

function EmailSent() {
  const location = useLocation();
  const [resendEmail] = useMutation(RESEND_EMAIL);
  const emailId = location?.email?.toLowerCase();
  const handleResendEmail = () => {
    resendEmail({
      variables: {
        email: emailId,
      },
    });
  };
  return (
    <Layout>
      <div className="align-text-center">
        <Space direction="vertical" size="large">
          <img src={email} alt="email" />
          <Typography.Title level={3} className="dark-text">
            Email Sent!
          </Typography.Title>
          <Typography.Text>
            We've emailed a special link to &nbsp;
            <span className="email-underline">{emailId}</span>
          </Typography.Text>
          <Typography.Text>
            Click the link to confirm your email address and get started
          </Typography.Text>
        </Space>
        <Divider className="divider" />
        <Typography.Text>Didn't receive email?&nbsp; </Typography.Text>
        <span
          type="link"
          onClick={handleResendEmail}
          className="email-underline"
        >
          <u>Resend Email</u>
        </span>
      </div>
    </Layout>
  );
}

export default EmailSent;
