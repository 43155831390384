import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_SPACES_V2 = gql`
  query spacesV2 {
    spacesV2 {
      data {
        id
        name
        permissions
        default
        photo
        ownerId
        type
        initialized
      }
      count
    }
  }
`;
