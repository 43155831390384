import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

//! User files
import { MiniAppIcon, ReminderIcon } from "../../../../../assets/svg";
import AddOnsList from "../../../../../common/components/AddOnsList/AddOnsList";
import { AppContext } from "../../../../../AppContext";

const AppsNav = () => {
  const {
    state: { currentUser, userSubscriptionInfo },
  } = useContext(AppContext);
  const spaceId = localStorage.getItem("currentSpace") || currentUser?.spaceId;
  const { pathname } = useLocation();
  const currentPath = pathname?.split("/")[3];

  const addOns = [
    {
      name: "Reminders",
      icon: <ReminderIcon />,
      route: userSubscriptionInfo?.spaceAddon?.active
        ? `/addOns/${spaceId}/reminders`
        : `/addOns/${spaceId}/reminders/activate`,
      alias: "reminders",
    },
    //! Will be used in near future
    // {
    //   name: "Enrichments",
    //   icon: <EnrichmentIcon />,
    //   route: ROUTES.ADDONS_ENRICHMENTS,
    //   alias: "enrichments",
    // },
    // {
    //   name: "Visitor Sign Up",
    //   icon: <VisitorSignUpIcon />,
    //   route: ROUTES.ADDONS_VISITORS_SIGNUP,
    //   alias: "visitor-sign-up",
    // },
    // {
    //   name: "Digital Tickets",
    //   icon: <DigitalTicketIcon />,
    //   route: ROUTES.ADDONS_DIGITAL_TICKETS,
    //   alias: "digital-tickets",
    // },
  ];

  const { push } = useHistory();

  useEffect(() => {
    if (currentPath?.trim()?.length === 0 || currentPath === undefined) {
      push(`/addOns/${spaceId}/reminders`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <div className="assistant-nav cms-contact-scrollbar">
      <div className="assistant-nav-title">
        <div className="assistant-nav-title-icon">
          <MiniAppIcon />
        </div>
        <div className="assistant-nav-title-text">Add Ons</div>
      </div>
      <div className="cms-add-ons cms-contact-scrollbar">
        <AddOnsList addOns={addOns} />
      </div>
    </div>
  );
};

export default AppsNav;
