import React, { useContext, useEffect, useState } from "react";
import { Card, Carousel, Progress, Space, Spin, Typography } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import axios from "axios";
import { Pointer } from "../../../assets/svg";
import { AppContext } from "../../../AppContext";
import {
  createAmplitudeEvent,
  importIconMap,
  notificationToast,
} from "../../../common/utils";
import Layout from "../layout/Layout";
import {
  COMPLETE_ONBOARDING,
  VERIFY_GOOGLE_CODE,
  VERIFY_MICROSOFT_CODE,
} from "../graphql/Mutations";
import { AMPLITUDE_EVENT_LOG, ROUTES } from "../../../common/constants";
import client from "../../../apollo";

function ImportProgress() {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const history = useHistory();
  const [status, setStatus] = useState();
  const [successCount, setSuccessCount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [jobId, setJobId] = useState();
  const [completeOnBoarding] = useMutation(COMPLETE_ONBOARDING);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const code = params.get("code");
  const location = useLocation();
  const provider = params.get("provider") ?? location.provider;
  const paramsError = params.get("error");

  const props = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    if (location.jobId) {
      setJobId(location.jobId);
    }
  }, [location]);

  useEffect(() => {
    const isOnBoardingDone = localStorage.getItem("contactSource");
    const spaceId = localStorage.getItem("currentSpaceId");

    if (isOnBoardingDone && code && provider) {
      history.push({
        pathname: `/settings/${spaceId}/contact-sources`,
        code: code,
        provider: provider,
      });
    } else if (code && provider === "GOOGLE") {
      client
        .mutate({
          mutation: VERIFY_GOOGLE_CODE,
          variables: { code, spaceId: currentUser?.spaceId },
        })
        .then((res) => {
          setJobId(res?.data?.verifyGoogleCode?.jobId);
        })
        .catch(() => {});
    } else if (code && provider === "MICROSOFT") {
      client
        .mutate({
          mutation: VERIFY_MICROSOFT_CODE,
          variables: { code, spaceId: currentUser?.spaceId },
        })
        .then((res) => {
          setJobId(res?.data?.verifyMicrosoftCode?.jobId);
        })
        .catch(() => {});
    } else if (paramsError === "access_denied") {
      if (isOnBoardingDone) {
        history.push(`/settings/${spaceId}/contact-sources`);
      } else {
        history.push(ROUTES.ONBOARDING);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, history]);

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (jobId && status !== "COMPLETED") {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_SERVER_REST_URL}/status/${jobId}`,
          params: { userId: currentUser?.id, spaceId: currentUser?.spaceId },
        }).then((res) => {
          setStatus(res?.data?.importStatus);
          if (res?.data?.importStatus === "COMPLETED") {
            notificationToast({
              message: `Contacts imported successfully`,
              type: "Success",
            });
          }
          if (res?.data?.contactCount && res?.data?.successCount) {
            setTotalCount(res?.data?.contactCount);
            const {
              // eslint-disable-next-line no-shadow
              data: { contactCount, successCount },
            } = res;
            setPercent((successCount * 100) / contactCount);
            setSuccessCount(successCount);
          }
        });
      }
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);
  if (status === "COMPLETED") {
    completeOnBoarding().then().catch();
    history.push(`/contacts/${currentUser?.spaceId}/group/all`);
    if (provider === "GOOGLE" || provider === "MICROSOFT") {
      const eventProperties = {
        "Space Id": currentUser?.spaceId,
        Service: provider,
        "Total contacts": totalCount,
      };
      createAmplitudeEvent(
        AMPLITUDE_EVENT_LOG.SOURCE_CONNECTED,
        eventProperties
      );
    } else {
      const eventProperties = {
        "Space Id": currentUser?.spaceId,
        "Total contacts": totalCount,
      };
      createAmplitudeEvent(
        AMPLITUDE_EVENT_LOG.CONTACTS_IMPORTED,
        eventProperties
      );
    }
  }
  return (
    <Layout>
      <div className="progress-box-container">
        <Carousel {...props} className="info-box-slider">
          <div>
            <Pointer />
            <Typography.Title level={5}>
              Create or Import Contacts
            </Typography.Title>
            <Typography.Text>
              ContactBook makes it easy to create your contacts. You can also
              import contacts using various methods.
            </Typography.Text>
          </div>
          <div>
            <Pointer />
            <Typography.Title level={5}>
              Organize your contacts
            </Typography.Title>
            <Typography.Text>
              Organize your contacts using Contact Groups and Spaces. You can
              also manage your contacts using Tags.
            </Typography.Text>
          </div>
          <div>
            <Pointer />
            <Typography.Title level={5}>Shared Contacts</Typography.Title>
            <Typography.Text>
              You can seamlessly share Groups or Spaces with others that allows
              a collaborative contact management.
            </Typography.Text>
          </div>
        </Carousel>
        <Card className="progress-box">
          <Space direction="vertical" size="middle">
            <div className="progress-box-image">
              <img src={importIconMap[provider]} alt="Social icon" />
            </div>
            <div className="progress-box-title">
              <Typography.Text strong>
                Imported {successCount} Contacts
              </Typography.Text>
            </div>
          </Space>
          <Spin spinning={percent === 0}>
            <Progress percent={percent} showInfo={false} status="active" />
          </Spin>
        </Card>
      </div>
    </Layout>
  );
}

export default ImportProgress;
