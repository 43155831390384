import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const DEACTIVATE_REMINDER = gql`
  mutation deActivateReminderAddon($spaceId: Int!) {
    deActivateReminderAddon(spaceId: $spaceId) {
      message
      status
    }
  }
`;
