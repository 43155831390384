import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const MANAGE_MULTIPLE_GROUPS_AND_TAGS = gql`
  mutation manageContactGroupsTags($data: ManageContactGroupsTagsInput!) {
    manageContactGroupsTags(data: $data) {
      message
      status
    }
  }
`;
