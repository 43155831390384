import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import FileItem from "../../../../onboard/components/FileItem";
import { AppContext } from "../../../../../AppContext";
import { notificationToast } from "../../../../../common/utils";
import { FILE_UPLOAD_ERROR_MESSAGE } from "../../../../../common/constants";

function FilesWrapper({ file, setFile }) {
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [errored, setErrored] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(FILE_UPLOAD_ERROR_MESSAGE);
  const [jobId, setJobId] = useState();
  const { spaceId } = useParams();

  const handleUpload = async () => {
    setLoading(true);
    setErrored(false);
    let fileType;
    if (file?.name.includes(".csv")) {
      fileType = "CSV";
    } else if (file?.name.includes(".vcf")) {
      fileType = "VCF";
    } else {
      message.error("Invalid file format");
    }

    const { originFileObj: currentFile } = file;
    const formData = new FormData();
    formData.append("file", currentFile);
    formData.append("type", fileType);
    try {
      await axios
        .post(`${process.env.REACT_APP_SERVER_REST_URL}/parser`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          params: { userId: currentUser?.id, spaceId },
        })
        .then((data) => {
          setJobId(data?.data?.jobId);
          if (data?.message) {
            notificationToast({
              message: "Not valid",
              type: "error",
            });
          }
        });
    } catch (error) {
      setLoading(false);
      setErrored(true);
      setErrorMessage(error?.response?.data?.message);
      setJobId();
    }

    setLoading(false);
  };
  useEffect(() => {
    if (file) {
      handleUpload();
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <FileItem
        setFile={setFile}
        name={file?.name}
        file={file}
        id={file?.uid}
        errored={errored}
        inAppFileItem
        key={file?.uid}
        jobId={jobId}
        handleUpload={handleUpload}
        loading={loading}
        errorMessage={errorMessage}
      />
    </div>
  );
}

export default FilesWrapper;
