import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import isInteger from "lodash/isInteger";
import { useHistory, useLocation } from "react-router-dom";

//! Antd import
import Tabs from "antd/lib/tabs";

//! Graphql files
import { GET_SPACE_DETAIL_V2 } from "../../../graphql/queries/getSpaceDetailV2";
import REMINDERS_LIST from "../../../graphql/queries/remindersList";

//! User files
import { NoReminderIcon, ReminderIcon } from "../../../../../assets/svg";
import ContactListHeader from "../../contacts/components/ContactListHeader";
import { REMINDER_TYPES } from "../../../../../common/constants";
import ContactCardSkeleton from "../../../../../common/components/ContactCardSkeleton/ContactCardSkeleton";
import NoGroupPlaceholder from "../../contacts/components/NoGroupPlaceholder";
import RemindersListItem from "./RemindersListItems";
import useDebounce from "../../../../../common/useDebounce";
import { AppContext } from "../../../../../AppContext";
import {
  SET_USER_SPACE_PERMISSION,
  SET_USER_SUBSCRIPTION_INFO,
} from "../../../../../common/actionTypes";
import IconButton from "../../../../../common/components/IconButton/IconButton";

const Reminders = () => {
  const [searchTerm, setSearchTerm] = useState(null);
  const [reminderType, setReminderType] = useState(REMINDER_TYPES.CONNECTION);
  const [activeTab, setActiveTab] = useState("Upcoming 7 days");
  const [offset, setOffset] = useState(0);
  const [reminders, setReminders] = useState([]);
  const [remindersCount, setRemindersCount] = useState(0);
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext);
  const { TabPane } = Tabs;
  const { push } = useHistory();
  const filterTabs = [
    {
      tab: "Upcoming 7 days",
    },
    {
      tab: "Past 7 days",
    },
  ];
  const spaceId = parseInt(
    localStorage.getItem("currentSpace") || currentUser?.spaceId,
    10
  );
  const { state } = useLocation();

  const debounceSearchVal = useDebounce(searchTerm, 500);
  const [getRemindersList, { loading: reminderLoader }] = useLazyQuery(
    REMINDERS_LIST,
    {
      fetchPolicy: "network-only",
      variables: {
        data: {
          offset: offset,
          limit: 10,
          reminderStatus: activeTab === "Upcoming 7 days" ? "UPCOMING" : "PAST",
          spaceId,
          query: debounceSearchVal?.toLowerCase().trim() ?? null,
        },
      },
      onCompleted({ remindersList }) {
        if (offset === 0) {
          setReminders(remindersList?.contacts);
        } else {
          setReminders([...reminders, ...remindersList?.contacts]);
        }
        setRemindersCount(remindersList?.count);
      },
    }
  );

  const { data: spaceDetail, loading } = useQuery(GET_SPACE_DETAIL_V2, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(spaceId, 10),
    },
    onCompleted(spaceData) {
      dispatch({
        type: SET_USER_SUBSCRIPTION_INFO,
        data: spaceData?.spaceV2,
      });
      dispatch({
        type: SET_USER_SPACE_PERMISSION,
        data: spaceData?.spaceV2?.permission,
      });
    },
  });

  useEffect(() => {
    if (spaceId && isInteger(spaceId)) {
      getRemindersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId]);

  useEffect(() => {
    if (debounceSearchVal !== undefined) {
      getRemindersList();
      setOffset(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchVal]);

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      reminders?.length < remindersCount
    ) {
      setOffset(reminders?.length);
    }
  };

  const renderTabs =
    filterTabs.length > 0 &&
    filterTabs.map((tabs) => {
      return (
        <TabPane tab={tabs?.tab} key={tabs?.tab}>
          {
            // eslint-disable-next-line no-nested-ternary
            reminderLoader && offset === 0 ? (
              <ContactCardSkeleton loading={reminderLoader} active />
            ) : reminders?.length > 0 ? (
              <RemindersListItem
                reminders={reminders}
                handleInfiniteScroll={handleInfiniteScroll}
                reminderLoader={reminderLoader}
              />
            ) : (
              remindersCount === 0 && (
                <div className="cms-reminders all-contacts cms-no-reminder-screen">
                  <NoGroupPlaceholder
                    title="No reminders found"
                    icon={<NoReminderIcon />}
                    showButton={false}
                  />
                  <IconButton
                    iconClass="cms-reminders-contact-link"
                    text="Go to all contacts"
                    type="primary"
                    handleClick={() => push(`/contacts/${spaceId}/group/all`)}
                  />
                </div>
              )
            )
          }
        </TabPane>
      );
    });

  useEffect(() => {
    if (state?.isReminderActivated && spaceId > 0) {
      push(`/addOns/${spaceId}/reminders`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId, state]);

  useEffect(() => {
    if (!spaceDetail?.spaceV2?.spaceAddon?.active && !loading) {
      push(`/addOns/${spaceId}/reminders/activate`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceDetail, spaceId]);

  return (
    <div>
      <ContactListHeader
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        title="Reminders"
        icon={<ReminderIcon className="mr-8" />}
        setReminderType={setReminderType}
        reminderType={reminderType}
        spaceId={spaceId}
        remindersCount={remindersCount}
      />
      <div
        className={`${
          remindersCount === 0 ? "cms-no-contact-list-height" : ""
        } cms-reminders all-contacts`}
      >
        <div className="cms-reminders-filters">
          <Tabs
            activeKey={activeTab}
            onChange={(e) => {
              setActiveTab(e);
              setReminders([]);
              setOffset(0);
            }}
          >
            {renderTabs}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Reminders;
