import React, { useContext, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import filter from "lodash/filter";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import { useWindowSize } from "react-use";

//! Ant Imports
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Text from "antd/lib/typography/Text";
import List from "antd/lib/list";

//! User Files
import { AppContext } from "../../../../../AppContext";
import Loading from "../../../../../common/components/Loading";
import ContactCard from "../../../../../common/components/ContactCard/ContactCard";
import * as ActionTypes from "../../../../../common/actionTypes";
import {
  capitalizeWord,
  getNameTag,
  hasGroupRight,
  hasSpaceRight,
  isPlanActive,
  notificationToast,
  showContactDetail,
} from "../../../../../common/utils";
import {
  ADDON_PARAMS,
  DELETE_CONTACT,
  ROUTES,
  WINDOW_SIZE,
} from "../../../../../common/constants";
import { GET_TAGS_COUNT } from "../../../graphql/queries/getTagsCount";

const ContactList = ({
  data,
  tagName,
  groupId,
  setOffset,
  contactLoading,
  removeContact,
  selectAll,
  deleteContactIds,
  setDeleteContactIds,
  checkSpacePermission,
  searchTerm,
}) => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const {
    state: {
      contacts,
      contactsCount,
      unassignedContacts,
      currentSideTab,
      currentUser,
      userSubscriptionInfo,
      userSpacePermission,
      userGroupPermission,
    },
    dispatch,
  } = useContext(AppContext);
  const [contactId, setContactId] = useState();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search)?.get("search");
  const currentGroup = currentSideTab
    ? capitalizeWord(currentSideTab)
    : localStorage.getItem("currentGroup");
  const currentContactId =
    window.location.pathname?.split("/")[6] ??
    window.location.pathname?.split("/")[3];
  const { width } = useWindowSize();
  const currentSpace = parseInt(
    localStorage.getItem("currentSpace") || currentUser?.spaceId,
    10
  );
  const currentPath = window.location.pathname?.split("/")[3];

  const [getTagsCount, { data: tagsCount }] = useLazyQuery(GET_TAGS_COUNT, {
    fetchPolicy: "network-only",
    onError() {},
  });

  const handleContactClick = (e, key) => {
    if (JSON?.parse(localStorage.getItem(ADDON_PARAMS))?.length > 0) {
      localStorage.removeItem(ADDON_PARAMS);
    }
    e.preventDefault();
    e.stopPropagation();
    push(key);
  };

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight - 5 &&
      data.length < contactsCount
    ) {
      setOffset(data.length);
    }
  };

  useEffect(() => {
    if (data && data?.length > 0) {
      // eslint-disable-next-line no-nested-ternary
      const contactLink = pathname.includes("tag")
        ? `${ROUTES?.CONTACTS}/${data?.[0]?.spaceId}/tag/${tagName}/contact/${data?.[0]?.id}`
        : // eslint-disable-next-line no-nested-ternary
        pathname.includes("no-groups")
        ? `${ROUTES?.CONTACTS}/${data?.[0]?.spaceId}/group/no-groups/contact/${data?.[0]?.id}`
        : // eslint-disable-next-line no-nested-ternary
        width <= WINDOW_SIZE
        ? `${ROUTES?.CONTACTS}/${data?.[0]?.spaceId}/all/${data?.[0]?.id}`
        : pathname === `${ROUTES?.CONTACTS}` ||
          pathname === `${ROUTES?.CONTACTS}/` ||
          pathname.includes(`${ROUTES?.CONTACTS}/all`)
        ? `${ROUTES?.CONTACTS}/${data?.[0]?.spaceId}/group/all`
        : `${ROUTES?.CONTACTS}/${data?.[0]?.spaceId}/group/${groupId}/contact/${data?.[0]?.id}`;
      // eslint-disable-next-line no-unused-expressions
      currentPath === "tag"
        ? tagsCount?.tagList?.data?.count > 0 &&
          data?.[0] &&
          setContactId(
            `${contactLink}${
              searchTerm || searchParam
                ? `?search=${searchTerm || searchParam}`
                : ""
            }`
          )
        : setContactId(
            `${contactLink}${
              searchTerm || searchParam
                ? `?search=${searchTerm || searchParam}`
                : ""
            }`
          );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, tagsCount]);

  // eslint-disable-next-line no-shadow
  const removeContactFromGroup = (e, contactId) => {
    e.preventDefault();
    e.stopPropagation();
    const isContact = find(contacts, (cId) => cId?.id === contactId);
    const contactData = find(contacts, (cont) => cont?.id === contactId);

    if (isContact) {
      if (unassignedContacts?.length > 0) {
        const temp = cloneDeep(unassignedContacts);
        temp.push(isContact);
        dispatch({
          type: ActionTypes.SET_UNASSIGNED_CONTACTS,
          data: temp,
        });
      } else {
        dispatch({
          type: ActionTypes.SET_UNASSIGNED_CONTACTS,
          data: [isContact],
        });
      }
      dispatch({
        type: ActionTypes.SET_CONTACTS_COUNT,
        data: contactsCount - 1,
      });
    }

    notificationToast({
      message: `Contact ${
        // eslint-disable-next-line prefer-template
        contactData?.firstName + " " + contactData?.lastName
      } removed from ${currentGroup} group`,
      type: "error",
    });

    dispatch({
      type: ActionTypes.SET_CONTACTS,
      data: filter(contacts, (contact) => contact?.id !== contactId),
    });
    dispatch({
      type: ActionTypes.SET_CONTACTS_COUNT,
      data: contactsCount + 1,
    });
  };

  //! keeping this knowingly for default selected contact
  useEffect(() => {
    if (
      contactId &&
      (currentContactId ? contactId?.split("/").includes(currentContactId) : 1)
    ) {
      if (
        !pathname.includes("add-contacts") &&
        !pathname.includes("contact/new") &&
        !pathname.includes("settings") &&
        !pathname.includes("edit") &&
        !search.includes("notes") &&
        !search.includes("documents") &&
        !search.includes("twitter")
      ) {
        if (width >= WINDOW_SIZE) {
          push(contactId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    if (currentSpace > 0 && currentPath === "tag") {
      getTagsCount({
        variables: {
          offset: 0,
          limit: 1,
          spaceId: parseInt(currentSpace, 10),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace, currentPath]);

  const isSpacePlanActive = isPlanActive(
    userSubscriptionInfo?.subscription?.planStatus,
    userSubscriptionInfo?.subscription?.endDate
  );

  return (
    <Row className="all-contacts">
      <Col
        span={24}
        className={`${deleteContactIds?.length > 0 ? "new-list-height" : ""} ${
          !isSpacePlanActive ? "cms-contact-list-height" : ""
        } ${
          deleteContactIds?.length > 0 && !isSpacePlanActive
            ? "cms-contact-plan-height"
            : ""
        } ${
          (checkSpacePermission
            ? hasSpaceRight(userSpacePermission, DELETE_CONTACT)
            : hasGroupRight(userGroupPermission, DELETE_CONTACT)) &&
          width > WINDOW_SIZE
            ? "new-list-height"
            : ""
        } contacts-col cms-contact-scrollbar`}
        onScroll={(e) => handleInfiniteScroll(e)}
      >
        <List
          className="contacts-list"
          dataSource={data}
          renderItem={(item) => {
            // eslint-disable-next-line no-nested-ternary
            const contactLink = pathname.includes("tag")
              ? `/contacts/${item?.spaceId}/tag/${tagName}/contact/${item?.id}`
              : // eslint-disable-next-line no-nested-ternary
              pathname.includes("no-groups")
              ? `/contacts/${item?.spaceId}/group/no-groups/contact/${item?.id}`
              : // eslint-disable-next-line no-nested-ternary
              width <= WINDOW_SIZE
              ? `${ROUTES?.CONTACTS}/${item?.spaceId}/all/${item?.id}`
              : pathname === "/contacts" ||
                pathname === "/contacts/" ||
                pathname.includes("/contacts/all")
              ? `/contacts/${item?.spaceId}/group/all`
              : `/contacts/${item?.spaceId}/group/${groupId}/contact/${item?.id}`;
            const isSelected =
              width >= WINDOW_SIZE ? currentContactId === item?.id : false;
            const {
              firstName,
              middleName,
              lastName,
              photo,
              jobTitle,
              id,
              numbers,
              emails,
              space,
              company,
            } = item;
            const name =
              // eslint-disable-next-line prefer-template
              (firstName ?? "") +
              " " +
              (middleName ?? "") +
              " " +
              (lastName ?? "");
            const title = (
              <Text
                className="username cms-font-weight-medium font-inter"
                title={getNameTag(name, company)}
              >
                {getNameTag(name, company)}
              </Text>
            );
            const description = (
              <Row className="description-row">
                {(jobTitle || company || emails || numbers) && (
                  <Col span={24} className="designation-col cms-font-ellipsis">
                    <Text type="secondary" className="designation">
                      {showContactDetail(jobTitle, company, emails, numbers)}
                    </Text>
                  </Col>
                )}
              </Row>
            );
            return (
              <ContactCard
                company={company}
                firstName={firstName}
                isSelected={isSelected}
                photo={photo}
                title={title}
                description={description}
                item={item}
                contactLink={contactLink}
                handleContactClick={handleContactClick}
                size={48}
                removeContact={removeContact}
                removeContactFromGroup={removeContactFromGroup}
                contactId={id}
                numbers={numbers}
                emails={emails}
                space={space}
                actions
                shouldClick
                selectAll={selectAll}
                deleteContactIds={deleteContactIds}
                setDeleteContactIds={setDeleteContactIds}
                checkSpacePermission={checkSpacePermission}
              />
            );
          }}
        />
        {contactLoading && (
          <div className="cms-infinite-scroll-loader">
            <Loading />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ContactList;
