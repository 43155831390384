import React from "react";
import { Link } from "react-router-dom";

//! User files
import UpgradeButtonComponent from "../../UpgradeButtonComponent";

const UpgradeProPopup = ({ content, icon, spaceId, hideButton }) => {
  return (
    <div className="cms-upgrade-wrapper">
      <div className="cms-upgrade-popup">
        <div className="cms-upgrade-popup-notes">{icon}</div>
        <div className="font-inter cms-upgrade-popup-text">{content}</div>
        {!hideButton && (
          <div className="cms-upgrade-btn-text">
            <Link to={`/space/${spaceId}/plans`}>
              <UpgradeButtonComponent />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpgradeProPopup;
