import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const DELETE_ACCOUNT = gql`
  query {
    deleteAccount {
      message
      status
    }
  }
`;
