import React from "react";

//! Antd imports
import Typography from "antd/lib/typography";

//! User files
import { DOCUMENT_LINKS } from "./constants";

const TermsComponent = () => {
  return (
    <Typography.Text className="font-inter auth-terms cms-text-center">
      By signing up,
      <br /> you agree to our&nbsp;
      <a href={DOCUMENT_LINKS.TERMS} target="_blank" rel="noreferrer">
        Terms of Use&nbsp;
      </a>
      and&nbsp;
      <a href={DOCUMENT_LINKS.PRIVACY_POLICY} target="_blank" rel="noreferrer">
        Privacy Statement
      </a>
    </Typography.Text>
  );
};

export default TermsComponent;
