/* eslint-disable no-shadow */
import React, { useContext, useState } from "react";

//! Antd imports
import { Upload } from "antd";
import Dragger from "antd/lib/upload/Dragger";

//! User files
import { File, FileUpload } from "../../../../../assets/svg";
import { SET_SPACE_PLAN_STATUS } from "../../../../../common/actionTypes";
import {
  UPGRADE_PLAN_TITLE,
  IMPORT_SAMPLE_FILE_URL,
} from "../../../../../common/constants";
import { isPlanActive } from "../../../../../common/utils";
import { AppContext } from "../../../../../AppContext";
import FilesWrapper from "./FilesWrapper";
import SettingsHeader from "../../../../../common/components/SettingsHeader/SettingsHeader";
import IconButton from "../../../../../common/components/IconButton/IconButton";

function ImportContacts() {
  const [file, setFile] = useState();
  const {
    state: { userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);

  const props = {
    name: "file",
    accept: ".csv,.vcf",
    multiple: false,
    onChange: ({ file }) => {
      if (
        isPlanActive(
          userSubscriptionInfo?.subscription?.planStatus,
          userSubscriptionInfo?.subscription?.endDate
        )
      ) {
        setFile(file);
      } else {
        dispatch({
          type: SET_SPACE_PLAN_STATUS,
          data: { planExpired: true, message: UPGRADE_PLAN_TITLE },
        });
      }
    },
  };

  return (
    <>
      <SettingsHeader icon={<FileUpload />} title="Import Contacts" />
      <div className="import-contacts-wrapper">
        <div className="import-contacts-wrapper-block">
          {file ? (
            <FilesWrapper file={file} setFile={setFile} />
          ) : (
            <div className="import-card no-margin">
              <div className="import-card-contact">
                <Dragger {...props} onClick={(e) => e.stopPropagation()}>
                  <p className="ant-upload-drag-icon">
                    <File />
                  </p>
                  <p className="font-inter ant-upload-text">
                    Drag & Drop CSV/VCF files to import the contacts
                  </p>
                  <div className="cms-upload-section mt-10 import-contacts-upload-btn">
                    <div className="cms-upload-section-divider">
                      <span className="font-inter cms-import-contact-link-or-text cms-text-border">
                        OR
                      </span>
                    </div>
                    <Upload {...props} onClick={(e) => e.stopPropagation()}>
                      <IconButton
                        iconClass="import-card-upload-button import-card-button"
                        type="primary"
                        text={
                          <span className="font-inter cms-upload-text">
                            Upload File
                          </span>
                        }
                      />
                    </Upload>
                    <a
                      href={IMPORT_SAMPLE_FILE_URL}
                      download="true"
                      className="mt-16 text-decoration-underline"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Download sample file
                    </a>
                  </div>
                </Dragger>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ImportContacts;
