import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const REMOVE_MULTI_CONTACTS = gql`
  mutation removeMultiContacts($data: RemoveMultiContactInput!) {
    removeMultiContacts(data: $data) {
      message
      status
    }
  }
`;
