import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_REMINDER_SETTING = gql`
  query reminderSettings($spaceId: Int!) {
    reminderSettings(spaceId: $spaceId) {
      data {
        userId
        id
        config {
          schedule
        }
      }
      status
    }
  }
`;
