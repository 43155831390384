import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client/react/context/ApolloProvider";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga";
import amplitude from "amplitude-js";

//! Antd Imports
import Button from "antd/lib/button";
import message from "antd/lib/message";

//! User Files
import client from "./apollo";
import Routes from "./Routes";
import { AppContextProvider } from "./AppContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

//! Styles
import "./index.css";
import "./styles/main.less";

if (process.env.REACT_APP_NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_NODE_ENV,
  });
}

if (process.env.REACT_APP_NODE_ENV === "prod") {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

const user = localStorage.getItem("USER");

if (window.location.pathname.includes("pricing")) {
  localStorage.setItem("redirectToPricing", true);
}

if (user && typeof user === "string") {
  try {
    const userData = JSON.parse(user);
    if (userData?.id) {
      const userInformation = new amplitude.Identify()
        .set("User name", userData?.name)
        .set("Email", userData?.email?.toLowerCase())
        .set("Sign in method", userData?.provider)
        .set("Email verified", userData?.emailVerified);
      amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY); // initializes default instance of Amplitude client
      amplitude
        .getInstance("New Instance")
        .init(process.env.REACT_APP_AMPLITUDE_API_KEY, userData?.id);
      amplitude.getInstance().identify(userInformation);
    }
    // eslint-disable-next-line no-empty
  } catch {}
} else {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <Routes />
    </AppContextProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      // eslint-disable-next-line no-console
      console.log("Inside registration");
      message.info({
        content: (
          <>
            New version available! Click Reload to get the latest version.
            <Button
              className="ml-1 mb-0"
              type="link"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                registration &&
                  registration.waiting &&
                  registration.waiting.postMessage &&
                  registration.waiting.postMessage({ type: "SKIP_WAITING" });
                // eslint-disable-next-line no-undef
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </>
        ),
        duration: 0,
      });
    }
  },
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
