import React from "react";

//! Antd import
import Modal from "antd/lib/modal";

const RemovePopup = ({
  visible,
  removeNote,
  handleRemove,
  content,
  okText,
  id,
  deleteLoader,
  itemKey,
  disabled = false,
  memberName,
  closeText = "Close",
  modalClass,
  maskClosable,
}) => {
  return (
    <Modal
      className={`${modalClass || ""} cms-confirm-popup`}
      visible={visible}
      onCancel={removeNote}
      onOk={() => handleRemove(id, itemKey, memberName)}
      centered
      closable={false}
      okText={okText}
      cancelText={closeText}
      confirmLoading={deleteLoader}
      okButtonProps={{ disabled: disabled }}
      cancelButtonProps={{ disabled: disabled }}
      maskClosable={maskClosable}
    >
      <div className="popup-content">
        <div>{content}</div>
      </div>
    </Modal>
  );
};

export default RemovePopup;
