import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";
import { useLazyQuery } from "@apollo/client";
import isEmpty from "lodash/isEmpty";
import uniqBy from "lodash/uniqBy";
import { nth } from "lodash";

//! Antd import
import { Progress } from "antd";

//! User Files
import Gleap from "gleap";
import ThankYouPage from "./modules/main/modules/subscription/components/ThankYouPage";
import CreatePassword from "./modules/auth/components/CreatePassword";
import TellUsMore from "./modules/auth/components/TellUsMore";
import { AppContext } from "./AppContext";
import {
  ADDON_PARAMS,
  AMPLITUDE_EVENT_LOG,
  CONTACT_LIMIT,
  IMPORT_CONTACT_STATUS,
  ROUTES,
} from "./common/constants";
import Login from "./modules/auth/components/Login";
import Logout from "./modules/auth/components/Logout";
import Signup from "./modules/auth/components/Signup";
import RegisterSuccess from "./modules/auth/components/RegisterSuccess";
import EmailSent from "./modules/auth/components/EmailSent";
import ImportContacts from "./modules/onboard/components/ImportContacts";
import ImportProgress from "./modules/onboard/components/ImportProgress";
import Verify from "./modules/auth/components/Verify";
import ForgotPassword from "./modules/auth/components/ForgotPassword";
import Main from "./modules/main/Main";
import PrivateRoute from "./PrivateRoute";
import { notificationToast, createAmplitudeEvent } from "./common/utils";
import * as ActionTypes from "./common/actionTypes";
import EmailLogin from "./modules/auth/components/EmailLogin";

//! Graphql files
import { GET_SPACE_DETAIL_V2 } from "./modules/main/graphql/queries/getSpaceDetailV2";
import { GET_GROUP_LIST_V2 } from "./modules/main/graphql/queries/getGroupListV2";
import { GET_ALL_CONTACTS } from "./modules/main/graphql/queries/getContactsList";
import PublicLinkComponent from "./modules/main/modules/contacts/components/PublicLinkComponent";

const Routes = () => {
  const [status, setStatus] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [percent, setPercent] = useState(0);
  const {
    initializeAuth,
    state: { connectSourceParams, currentUser, contacts },
    dispatch,
  } = useContext(AppContext);
  const { provider, jobId, spaceId, userId } = connectSourceParams;
  const importJobId = jobId || localStorage.getItem("jobId");
  const sourceProvider = provider || localStorage.getItem("provider");
  const userSpaceId =
    parseInt(spaceId, 10) ||
    parseInt(localStorage.getItem("spaceId"), 10) ||
    parseInt(localStorage.getItem("currentSpace"), 10);
  const loginUserId = userId || localStorage.getItem("userId");
  const currentPath = window.location.pathname;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contactInfo = new URLSearchParams(document?.location?.search);

  const [
    getContacts,
    { data: allContacts, loading: contactsLoader },
  ] = useLazyQuery(GET_ALL_CONTACTS, {
    fetchPolicy: "network-only",
    onCompleted() {
      dispatch({
        type: ActionTypes.SET_IMPORT_CONTACT_STATUS,
        data: IMPORT_CONTACT_STATUS.COMPLETED,
      });
    },
    onError() {},
  });

  const [getSpaceDetail] = useLazyQuery(GET_SPACE_DETAIL_V2, {
    fetchPolicy: "network-only",
    onError() {},
  });

  const [getGroupListV2] = useLazyQuery(GET_GROUP_LIST_V2, {
    fetchPolicy: "network-only",
    onError() {},
  });

  useEffect(() => {
    if (
      Array.from(contactInfo.values())?.length !== 0 &&
      nth(Array.from(contactInfo.keys()), 0) === "name" &&
      nth(Array.from(contactInfo.keys()), 1) === "email"
    ) {
      localStorage.setItem(
        ADDON_PARAMS,
        JSON.stringify(Array.from(contactInfo.values()))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfo.values()]);

  useEffect(() => {
    try {
      if (!contactsLoader)
        if (allContacts?.getContacts?.data?.contacts) {
          dispatch({
            type: ActionTypes.SET_CONTACTS,
            data: uniqBy(
              [...contacts, ...allContacts?.getContacts?.data?.contacts],
              "id"
            ),
          });
          dispatch({
            type: ActionTypes.SET_CONTACTS_COUNT,
            data: allContacts?.getContacts?.data?.count,
          });
        } else {
          dispatch({
            type: ActionTypes.SET_CONTACTS,
            data: [],
          });
          dispatch({
            type: ActionTypes.SET_CONTACTS_COUNT,
            data: 0,
          });
        }
    } catch (err) {
      <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allContacts]);

  useEffect(() => {
    initializeAuth();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (
        importJobId &&
        status !== IMPORT_CONTACT_STATUS.COMPLETED &&
        status !== IMPORT_CONTACT_STATUS.DONE
      ) {
        axios({
          method: "get",
          url: `${process.env.REACT_APP_SERVER_REST_URL}/status/${importJobId}`,
          params: {
            userId: currentUser?.id || loginUserId,
            spaceId: userSpaceId,
          },
        }).then((res) => {
          setStatus(res?.data?.importStatus);
          if (res?.data?.contactCount && res?.data?.successCount) {
            setTotalCount(res?.data?.contactCount);
            const {
              // eslint-disable-next-line no-shadow
              data: { contactCount, successCount },
            } = res;
            setPercent((successCount * 100) / contactCount);
            setSuccessCount(successCount);
            dispatch({
              type: ActionTypes.SET_IMPORT_STATUS_AND_PROGRESS,
              data: {
                status: res?.data?.importStatus,
                percent: (successCount * 100) / contactCount,
              },
            });
          }
        });
      }
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importJobId, status]);

  useEffect(() => {
    if (status === IMPORT_CONTACT_STATUS.COMPLETED) {
      if (userSpaceId > 0) {
        getSpaceDetail({
          variables: {
            id: parseInt(userSpaceId, 10),
          },
        });
        getGroupListV2({
          variables: {
            offset: 0,
            limit: CONTACT_LIMIT,
            spaceId: parseInt(userSpaceId, 10),
          },
        });

        getContacts({
          variables: {
            // eslint-disable-next-line no-nested-ternary
            tag: null,
            spaceId: userSpaceId ? parseInt(userSpaceId, 10) : null,
            // eslint-disable-next-line no-nested-ternary
            groupId: null,
            data: {
              limit: CONTACT_LIMIT,
              offset: 0,
              sortBy: "ASC",
              sortOn: "firstName",
            },
          },
        });
      }
      notificationToast({
        message: `Contacts imported successfully`,
        type: "Success",
      });
      dispatch({
        type: ActionTypes.SET_IMPORT_CONTACT_STATUS,
        data: status,
      });
      if (sourceProvider === "GOOGLE" || sourceProvider === "MICROSOFT") {
        const eventProperties = {
          "Space Id": userSpaceId,
          Service: sourceProvider,
          "Total contacts": totalCount,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.SOURCE_CONNECTED,
          eventProperties
        );
      } else {
        const eventProperties = {
          "Space Id": userSpaceId,
          "Total contacts": totalCount,
        };
        createAmplitudeEvent(
          AMPLITUDE_EVENT_LOG.CONTACTS_IMPORTED,
          eventProperties
        );
      }
      localStorage.removeItem("jobId");
      localStorage.removeItem("provider");
      setStatus(IMPORT_CONTACT_STATUS.DONE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (!currentUser || isEmpty(currentUser)) {
      setStatus();
    }
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser || isEmpty(currentUser)) {
      setStatus();
    }
  }, [currentUser]);

  useEffect(() => {
    Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          path={ROUTES.PUBLIC_CONTACT_LINK}
          component={PublicLinkComponent}
        />
        <Route exact path={ROUTES.LOGIN} component={Login} />
        <Route exact path={ROUTES.SIGNUP} component={Signup} />

        <Route
          exact
          path={ROUTES?.CREATE_PASSWORD}
          component={CreatePassword}
        />

        <Route exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={ROUTES.SUCCESS} component={RegisterSuccess} />
        <Route exact path={ROUTES.EMAIL_SENT} component={EmailSent} />
        <Route exact path={ROUTES.EMAIL_LOGIN} component={EmailLogin} />
        <Route
          exact
          path={ROUTES.AUTH_VERIFY}
          component={() => <Verify forgotpassword={false} />}
        />
        <Route
          exact
          path={ROUTES.VERIFY}
          component={() => <Verify forgotpassword />}
        />

        <Route path={ROUTES.LOGOUT} component={Logout} />
        <PrivateRoute
          exact
          path={ROUTES?.TELL_US_MORE}
          component={TellUsMore}
        />
        <PrivateRoute
          exact
          path={ROUTES.ONBOARDING}
          component={ImportContacts}
        />
        <PrivateRoute
          exact
          path={ROUTES?.SUBSCRIPTION_CREATED}
          component={ThankYouPage}
        />
        <PrivateRoute
          exact
          path={ROUTES?.SUBSCRIPTION_UPDATED}
          component={ThankYouPage}
        />

        <PrivateRoute exact path={ROUTES.PROGRESS} component={ImportProgress} />
        <PrivateRoute path="/" component={Main} />
      </Switch>

      <div className="cms-import-help-space">
        {!currentPath?.includes(ROUTES.LOGIN) &&
          !currentPath?.includes(ROUTES.TELL_US_MORE) &&
          sourceProvider !== "undefined" &&
          sourceProvider !== undefined &&
          sourceProvider !== null &&
          importJobId &&
          status !== IMPORT_CONTACT_STATUS.COMPLETED &&
          status !== IMPORT_CONTACT_STATUS.DONE && (
            <div className="import-progress-bar">
              <div className="import-progress-bar-top">
                <span className="font-inter import-progress-bar-top-title cms-progress">
                  Importing Contacts
                </span>
                <span className="import-progress-bar-top-count cms-progress">
                  {successCount}/{totalCount}
                </span>
              </div>
              <div className="import-progress-bar-bottom">
                <Progress percent={percent} showInfo={false} status="active" />
              </div>
            </div>
          )}
      </div>
    </Router>
  );
};
export default Routes;
