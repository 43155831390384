import { gql } from "@apollo/client/core";

const SWITCH_TO_GOOGLE_SPACE_V2 = gql`
  mutation switchToGoogleSpaceV2($authorizationCode: String!) {
    switchToGoogleSpaceV2(authorizationCode: $authorizationCode) {
      message
      status
      space {
        id
      }
      jobId
    }
  }
`;

export default SWITCH_TO_GOOGLE_SPACE_V2;
