import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const MERGE_CONTACT = gql`
  mutation mergeContact($id: Int!, $data: MergeContactsInput!) {
    mergeContact(id: $id, data: $data) {
      message
      status
    }
  }
`;
