/* eslint-disable no-nested-ternary */
import { useLazyQuery } from "@apollo/client";
import clsx from "clsx";
import _isInteger from "lodash/isInteger";
import React, { useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

//! Antd imports
import List from "antd/lib/list";

//! User files
import { AppContext } from "../../../../../AppContext";
import {
  ContactSource,
  FileUpload,
  GeneralSettingIcon,
  Member,
  Setting,
  Subscription,
} from "../../../../../assets/svg";
import SpaceListSkeleton from "../../../../../common/components/SpacelistSkeleton/SpacelistSkeleton";
import {
  GOOGLE,
  SPACE_BILLING,
  SPACE_CONTACT_SOURCE,
  SPACE_IMPORT_CONTACTS,
  SPACE_MEMBERS,
  SPACE_SETTING,
} from "../../../../../common/constants";
import { hasSpaceRight } from "../../../../../common/utils";

//! Graphql queries
import { GET_BASIC_SPACE_INFO } from "../../../graphql/queries/getBasicSpaceInfo";

const SettingsNav = () => {
  const {
    state: { currentUser, userSubscriptionInfo },
  } = useContext(AppContext);
  const { pathname } = useLocation();
  const { push } = useHistory();
  const paramSpaceId = pathname?.split("/")[2] || currentUser?.spaceId;
  const currentPath = pathname?.split("/")[3];

  const [getBasicSpaceInfo, { data: spaceInfoData, loading }] = useLazyQuery(
    GET_BASIC_SPACE_INFO,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(paramSpaceId, 10) },
      onError() {},
    }
  );

  const settingsMenu = [
    {
      title: "General",
      icon: <GeneralSettingIcon />,
      pathLink: `/settings/${paramSpaceId}/general`,
      action: SPACE_SETTING,
    },
    {
      title: "Members",
      icon: <Member />,
      pathLink: `/settings/${paramSpaceId}/members`,
      action: SPACE_MEMBERS,
    },
    {
      title: "Contact Sources",
      icon: <ContactSource />,
      pathLink: `/settings/${paramSpaceId}/contact-sources`,
      action: SPACE_CONTACT_SOURCE,
    },
    {
      title: "Import Contacts",
      icon: <FileUpload />,
      pathLink: `/settings/${paramSpaceId}/import-contacts`,
      action: SPACE_IMPORT_CONTACTS,
    },
    {
      title: "Subscription",
      icon: <Subscription />,
      pathLink: `/settings/${paramSpaceId}/subscription`,
      action: SPACE_BILLING,
    },
  ];

  const noSubscriptionMenu = [
    {
      title: "General",
      icon: <GeneralSettingIcon />,
      pathLink: `/settings/${paramSpaceId}/general`,
      action: SPACE_SETTING,
    },
    {
      title: "Members",
      icon: <Member />,
      pathLink: `/settings/${paramSpaceId}/members`,
      action: SPACE_MEMBERS,
    },
  ];

  const updatedSettings =
    userSubscriptionInfo?.type === GOOGLE
      ? settingsMenu.filter((setting, index) => index !== 2)
      : settingsMenu;

  useEffect(() => {
    if (paramSpaceId && _isInteger(parseInt(paramSpaceId, 10))) {
      getBasicSpaceInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramSpaceId]);

  useEffect(() => {
    if (currentPath?.trim()?.length === 0 || currentPath === undefined) {
      push(`/settings/${paramSpaceId}/general`);
    } else {
      push(`/settings/${paramSpaceId}/${currentPath}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  return (
    <div className="settings-nav cms-contact-scrollbar">
      <div className="settings-nav-title">
        <div className="settings-nav-title-icon">
          <Setting className="cms-svg-xl" />
        </div>
        <div className="settings-nav-title-text font-inter">Settings</div>
      </div>
      <div className="settings-nav-space-container cms-contact-scrollbar">
        {loading ? (
          <SpaceListSkeleton loading={loading} />
        ) : (
          <List
            className="setting-menu-list-wrapper"
            itemLayout="horizontal"
            dataSource={
              userSubscriptionInfo?.subscription?.id === null
                ? noSubscriptionMenu
                : updatedSettings
            }
            renderItem={(item) => {
              const { title, pathLink, icon, action } = item;
              const isSelected = pathname.includes(pathLink);
              return (
                (spaceInfoData?.spaceInfo?.data?.ownerId ===
                  parseInt(currentUser?.id, 10) ||
                  hasSpaceRight(userSubscriptionInfo?.permission, action)) && (
                  <List.Item className={clsx(isSelected && "selected-list")}>
                    <List.Item.Meta
                      avatar={icon}
                      title={<Link to={pathLink}>{title}</Link>}
                    />
                  </List.Item>
                )
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SettingsNav;
