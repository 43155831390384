import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const EDIT_SPACE = gql`
  mutation updateSpace($id: Int!, $name: String, $photo: String) {
    updateSpace(id: $id, data: { name: $name, photo: $photo }) {
      data {
        id
        name
        ownerId
        updatedAt
        createdAt
      }
      message
      status
    }
  }
`;
