import React from "react";
import { Link, useParams } from "react-router-dom";

//! User files
import { RedirectArrowIcon } from "../../../assets/svg";
import { RENEW_NOW } from "../../constants";

function SubscriptionWarning({ daysLeft, subscriptionStatus, warningText }) {
  const { spaceId } = useParams();

  return (
    <div
      className={`${
        subscriptionStatus === "freeTrial" ||
        subscriptionStatus === "expired" ||
        subscriptionStatus === "cancel"
          ? "warning-container-red font-inter"
          : "warning-container-yellow font-inter"
      } `}
    >
      <div>{warningText}</div>
      {subscriptionStatus !== "freeTrial" && (
        <div
          className={`${
            subscriptionStatus === "freeTrial" ||
            subscriptionStatus === "expired" ||
            subscriptionStatus === "cancel"
              ? "warning-container-red-label font-inter"
              : "warning-container-yellow-label font-inter"
          } `}
        >
          {subscriptionStatus === "expired" ||
          subscriptionStatus === "cancel" ? (
            <Link to={`/space/${spaceId}/plans`} className="cms-renew-link">
              <span className="font-inter cms-renew-btn">{RENEW_NOW}</span>
              <RedirectArrowIcon />
            </Link>
          ) : (
            `${daysLeft} days left`
          )}
        </div>
      )}
    </div>
  );
}

export default SubscriptionWarning;
