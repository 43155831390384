import React, { useContext, useState } from "react";
import { useWindowSize } from "react-use";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

//! Antd imports
import { Col, Input, Row, Select, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Form, { useForm } from "antd/lib/form/Form";

//! Graphql files
import { USER_DETAILS } from "../graphql/Mutations";

//! User files
import { SET_CONNECT_SOURCE_PARAMS } from "../../../common/actionTypes";
import {
  INDUSTRIES,
  ROUTES,
  SOURCES,
  WINDOW_SIZE,
} from "../../../common/constants";
import { stringToObj, trimWhiteSpace } from "../../../common/utils";
import Layout from "../layout/Layout";
import { AppContext } from "../../../AppContext";
import IconButton from "../../../common/components/IconButton/IconButton";

const { Option } = Select;

function TellUsMore() {
  const {
    state: { connectSourceParams },
    dispatch,
  } = useContext(AppContext);
  const { jobId } = connectSourceParams;
  const history = useHistory();
  const [form] = useForm();
  const currentUser =
    JSON?.parse(localStorage?.getItem("user")) ||
    JSON?.parse(localStorage?.getItem("USER"));
  const currentEmail =
    localStorage?.getItem("userSignUpEmail") ||
    localStorage?.getItem("current_email") ||
    currentUser?.email;
  const currentSpace =
    currentUser?.spaceId || localStorage?.getItem("currentSpace");
  const { width } = useWindowSize();
  const [selectedSource, setSelectedSource] = useState(null);
  const cookies = new Cookies();

  const [userDetails, { loading: userDetailsLoading }] = useMutation(
    USER_DETAILS,
    {
      onCompleted(res) {
        const userInfo = localStorage?.getItem("googleUserInfo")
          ? JSON?.parse(localStorage?.getItem("googleUserInfo"))
          : {};
        if (userInfo && Object?.keys(userInfo)?.length) {
          dispatch({
            type: SET_CONNECT_SOURCE_PARAMS,
            data: {
              provider: "signIn",
              jobId: jobId,
              spaceId: userInfo?.spaceId,
              userId: userInfo?.id,
            },
          });

          localStorage?.removeItem("googleUserInfo");
          localStorage?.removeItem("user");
          if (res?.userDetails) {
            if (width <= WINDOW_SIZE) {
              history?.push(ROUTES?.CONTACTS);
            } else {
              history?.push(`/contacts/${currentSpace}/group/all`);
            }
          }
        } else if (
          (!userInfo || Object?.keys(userInfo)?.length <= 0) &&
          res?.userDetails
        ) {
          if (width <= WINDOW_SIZE) {
            history?.push(ROUTES?.CONTACTS);
          } else {
            history?.push(`/contacts/${currentSpace}/group/all`);
          }
        }
      },
    }
  );

  const onFinish = (value) => {
    let source = "";
    const { industry, jobRole, otherSource } = value;
    if (value?.source === "Other (Please specify)") {
      if (otherSource === "") source = value?.source;
      else source = otherSource;
    } else source = value?.source;
    userDetails({
      variables: {
        data: {
          industry,
          jobRole,
          source,
          attributes: stringToObj(cookies?.get("UTMTAGS")),
        },

        email: currentEmail,
      },
    })?.catch((error) => {
      return error;
    });
  };

  return (
    <Layout>
      <div className="tell-us-more-container">
        <div className="create-password-container-box">
          <Typography.Title level={4} className="dark-text">
            Tell us about yourself
          </Typography.Title>
          <div className="cms-email-container">
            <Form
              layout="vertical"
              onFinish={onFinish}
              requiredMark={false}
              form={form}
              onFieldsChange={(e) => {
                trimWhiteSpace(form, e, ["password", "confirmPassword"]);
              }}
            >
              <Form.Item label="Industry" name="industry">
                <Select
                  showSearch
                  placeholder="Which industry are you from?"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase())
                  }
                >
                  {INDUSTRIES?.map((industry) => {
                    return (
                      <Option className="ml-8" key={industry}>
                        {industry}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item label="Job Role" name="jobRole">
                <Input placeholder="Enter your job role" />
              </Form.Item>

              <span
                className={`${
                  selectedSource === "Other (Please specify)"
                    ? "form-item-margin"
                    : ""
                }`}
              >
                <Form.Item label="Source" name="source">
                  <Select
                    placeholder="Where did you hear about us?"
                    onSelect={(values) => setSelectedSource(values)}
                  >
                    {SOURCES?.map((source) => {
                      return (
                        <Option className="ml-8" key={source}>
                          {source}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </span>
              {selectedSource === "Other (Please specify)" && (
                <Form.Item name="otherSource">
                  <TextArea placeholder="Mention your source" />
                </Form.Item>
              )}
              <Row justify="end">
                <Col xs={24} sm={24} md={6} lg={6} xl={24}>
                  <IconButton
                    iconClass="cms-email-login-btn"
                    text={
                      <span className="font-inter cms-email-login-btn-text">
                        Finish
                      </span>
                    }
                    htmlType="submit"
                    loading={userDetailsLoading}
                  />
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TellUsMore;
