import React from "react";
import { useLocation, Link } from "react-router-dom";

const AddOnsList = ({ addOns }) => {
  const { pathname } = useLocation();

  return (
    <div className="cms-add-ons-list">
      <div className="cms-add-ons-list-wrapper">
        {addOns?.map((addOn) => (
          <Link to={addOn?.route} key={addOn?.name}>
            <div
              className={`${
                pathname.includes(addOn?.alias)
                  ? `cms-active-${addOn?.alias}`
                  : ""
              } cms-add-ons-list-wrapper-listing`}
            >
              <span className="cms-add-ons-list-wrapper-listing-icon">
                {addOn?.icon}
              </span>
              <span className="cms-add-ons-list-wrapper-listing-text">
                {addOn?.name}
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AddOnsList;
