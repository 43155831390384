import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

//! Antd imports
import Form from "antd/lib/form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import Radio from "antd/lib/radio";
import Space from "antd/lib/space";
import Button from "antd/lib/button";

//! User files
import { RadioChecked } from "../../../../../assets/svg";
import GooglePlus from "../../../../../assets/images/google-plus 1.png";
import Outlook from "../../../../../assets/images/outlook-1 1.png";
import { AppContext } from "../../../../../AppContext";
import { SET_SPACE_PLAN_STATUS } from "../../../../../common/actionTypes";
import { isPlanActive } from "../../../../../common/utils";
import { UPGRADE_PLAN_TITLE } from "../../../../../common/constants";

//! Graphql files
import {
  CONNECT_WITH_GOOGLE,
  CONNECT_WITH_MICROSOFT,
} from "../../../../onboard/graphql/Queries";

const AddContactSourceCard = ({ spaceId }) => {
  const [service, setService] = useState("google");
  const [isDisabled, setIsDisabled] = useState(true);
  const {
    state: { userSubscriptionInfo },
    dispatch,
  } = useContext(AppContext);

  useEffect(() => {
    if (service) {
      setIsDisabled(false);
    }
  }, [service]);

  const [connect, { loading, data }] = useLazyQuery(CONNECT_WITH_GOOGLE);
  const [
    connectMicrosoft,
    { data: microsoftData, loading: microsoftLoading },
  ] = useLazyQuery(CONNECT_WITH_MICROSOFT);

  const onFinish = () => {
    if (
      !isPlanActive(
        userSubscriptionInfo?.subscription?.planStatus,
        userSubscriptionInfo?.subscription?.endDate
      )
    ) {
      dispatch({
        type: SET_SPACE_PLAN_STATUS,
        data: { planExpired: true, message: UPGRADE_PLAN_TITLE, spaceId },
      });
      return false;
    }

    if (service === "google") {
      connect();
      localStorage.setItem("contactSource", true);
      localStorage.setItem("currentSpaceId", spaceId);
    } else if (service === "outlook") {
      connectMicrosoft();
      localStorage.setItem("contactSource", true);
      localStorage.setItem("currentSpaceId", spaceId);
    }
  };

  useEffect(() => {
    if (data) {
      window.location.href = data.connectWithGoogle.authorizationUri;
    } else if (microsoftData) {
      window.location.href =
        microsoftData.connectWithMicrosoft.authorizationUri;
    }
  }, [data, microsoftData]);

  return (
    <div className="cms-source-card">
      <Form onFinish={onFinish}>
        <Space direction="vertical" size="middle" className="cms-space-wrap">
          <div className="cms-space-wrap-block">
            <div className="mb-20">
              <Typography.Text className="font-inter cms-card-title">
                Select a source to add
              </Typography.Text>
            </div>
            <div className="cms-radio-btn-wrap">
              <Form.Item name="serviceName">
                <Radio.Group
                  onChange={(e) => {
                    setService(e.target.value);
                  }}
                  defaultValue={service}
                >
                  <Row gutter={24}>
                    <Col className="mr-8">
                      <Radio.Button
                        value="google"
                        className={`${
                          service === "google" ? "cms-active-card" : ""
                        } cms-card-radio-btn`}
                      >
                        <RadioChecked
                          className={
                            service === "google"
                              ? "cms-radio-btn-checked"
                              : "hide"
                          }
                        />
                        <img src={GooglePlus} alt="Googleplus" />
                      </Radio.Button>
                    </Col>
                    <Col className="ml-8">
                      <Radio.Button
                        value="outlook"
                        className={`${
                          service === "outlook" ? "cms-active-card" : ""
                        } cms-card-radio-btn`}
                      >
                        <RadioChecked
                          className={
                            service === "outlook"
                              ? "cms-radio-btn-checked"
                              : "hide"
                          }
                        />
                        <img src={Outlook} alt="outlook" />
                      </Radio.Button>
                    </Col>
                    {/* Hidding for MVP */}
                    {/* <Col>
                      <Radio.Button
                        value="icloud"
                        className={`${
                          service === "icloud" ? "cms-active-card" : ""
                        } cms-card-radio-btn`}
                      >
                        <RadioChecked
                          className={
                            service === "icloud"
                              ? "cms-radio-btn-checked"
                              : "hide"
                          }
                        />
                        <img src={Icloud} alt="icloud" />
                      </Radio.Button>
                    </Col> */}
                  </Row>
                </Radio.Group>
              </Form.Item>
              <div className="cms-card-service-name">
                <Row>
                  <Col span={12}>
                    <Typography.Text className="cms-right-margin">
                      Google
                    </Typography.Text>
                  </Col>
                  <Col span={12}>
                    <Typography.Text>Outlook</Typography.Text>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <Button
            type="primary"
            htmlType="submit"
            disabled={isDisabled}
            className="import-card-button"
            loading={loading || microsoftLoading}
          >
            Connect Service
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default AddContactSourceCard;
