import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-use";
import clsx from "clsx";

//! User Files
import {
  DeletedContactIcon,
  NoGroupContactIcon,
} from "../../../../../assets/svg";
import GroupListItem from "./GroupListItem";
import CountTag from "../../../../../common/components/CountTag";
import { DEFAULT, GOOGLE, LIMITED } from "../../../../../common/constants";
import { AppContext } from "../../../../../AppContext";

const GroupListItems = ({
  noGroupContactsCount,
  groups,
  spacePermission,
  spaceId,
  deletedContactsCount,
  checkAll,
  setGroupCheckList,
  groupCheckList,
  spaceType,
  spaceOwnerId,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    state: { userSubscriptionInfo, currentUser },
  } = useContext(AppContext);

  const isNoGroupSelected =
    pathname.split("/")[4] === "no-groups" &&
    pathname.split("/")[2] === spaceId;
  const isDeletedContactSelected =
    pathname.split("/")[4] === "deleted-contacts" &&
    pathname.split("/")[2] === spaceId;

  return (
    <>
      {groups?.length > 0 && (
        <GroupListItem
          dataSource={groups}
          spacePermission={spacePermission}
          spaceId={spaceId}
          checkAll={checkAll}
          setGroupCheckList={setGroupCheckList}
          groupCheckList={groupCheckList}
        />
      )}
      {spacePermission && spacePermission !== LIMITED && (
        <>
          {noGroupContactsCount > 0 &&
            (spaceType === DEFAULT ||
              spaceType === "" ||
              spaceType === null ||
              (spaceType === GOOGLE &&
                spaceOwnerId === parseInt(currentUser?.id, 10))) && (
              <div
                className={clsx(
                  "no-group-contact-item",
                  isNoGroupSelected &&
                    "no-group-contact-item-selected active-bg"
                )}
                onClick={() => {
                  history.push(`/contacts/${spaceId}/group/no-groups`);
                }}
              >
                <NoGroupContactIcon className="cms-svg-sm" />
                <span className="cms-font-ellipsis">No Group Contacts</span>
                <CountTag count={noGroupContactsCount} />
              </div>
            )}
          {userSubscriptionInfo?.type !== GOOGLE && deletedContactsCount > 0 && (
            <div
              className={clsx(
                "no-group-contact-item",
                isDeletedContactSelected &&
                  "no-group-contact-item-selected active-bg"
              )}
              onClick={() => {
                history.push(`/contacts/${spaceId}/group/deleted-contacts`);
              }}
            >
              <DeletedContactIcon className="cms-svg-sm" />
              <span className="cms-font-ellipsis">Deleted Contacts</span>
              <CountTag count={deletedContactsCount} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default GroupListItems;
