import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

//! Antd import
import { Skeleton } from "antd";

//! User files
import ContactActivityList from "./ContactActivityList";

//! Graphql files
import { GET_CONTACT_ACTIVITY_LOG } from "../../../graphql/queries/getContactActivityLog";

const ContactActivityContainer = ({ contactDetail, contactPermission }) => {
  const { contactId } = useParams();
  const [contactActivityLogs, setContactActivityLogs] = useState([]);

  const [
    getActivityLogs,
    { data: activityLogs, loading: activityLoader },
  ] = useLazyQuery(GET_CONTACT_ACTIVITY_LOG, {
    fetchPolicy: "network-only",
    variables: {
      contactId: parseInt(contactId, 10),
      where: {
        sortBy: "DESC",
        sortOn: "createdAt",
      },
    },
  });

  useEffect(() => {
    if (contactId !== "new" && contactPermission) {
      getActivityLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    if (activityLogs?.getContactActivityLogs?.data) {
      setContactActivityLogs(activityLogs?.getContactActivityLogs?.data);
    }
  }, [activityLogs]);

  return (
    <div className="cms-activity-container cms-contact-scrollbar">
      {activityLoader ? (
        <div className="skeleton-layout">
          <Skeleton active />
        </div>
      ) : (
        <ContactActivityList
          activityLogs={contactActivityLogs}
          contactDetail={contactDetail}
        />
      )}
    </div>
  );
};

export default ContactActivityContainer;
