import React from "react";

//! Antd import
import Menu from "antd/lib/menu";
import { DustbinIcon } from "../../../assets/svg";
import RemovePopup from "../RemovePopup/RemovePopup";

const TagMenu = (
  setOpenTagModal,
  tagModal,
  deleteTag,
  deleteTagLoader,
  tagName
) => {
  const openTagDeletionModal = () => {
    setOpenTagModal(true);
  };

  const closeTagDeletionModal = () => {
    setOpenTagModal(false);
  };

  const popupText = (
    <div className="cms-tag-deletion-content">
      <span className="popup-text-title font-inter">
        Are you sure you want to delete "{tagName}" tag?
      </span>
      <span className="font-inter">
        Deleting a tag won't delete the contacts associated with it. It will
        just remove the tag from all associated contacts.
      </span>
    </div>
  );

  return (
    <Menu className="cms-tag-menu">
      <Menu.Item icon={<DustbinIcon />} onClick={openTagDeletionModal}>
        Delete Tag
      </Menu.Item>
      <RemovePopup
        visible={tagModal}
        okText="Delete Tag"
        removeNote={closeTagDeletionModal}
        content={popupText}
        handleRemove={deleteTag}
        deleteLoader={deleteTagLoader}
        disabled={deleteTagLoader}
        maskClosable={false}
      />
    </Menu>
  );
};

export default TagMenu;
