import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const LIST_DUPLICATE_CONTACTS = gql`
  query listDuplicateContacts($spaceId: Int!, $offset: Int!, $limit: Int!) {
    listDuplicateContacts(spaceId: $spaceId, offset: $offset, limit: $limit) {
      data {
        id
        contacts {
          id
          sourceType
          firstName
          middleName
          lastName
          jobTitle
          company
          photo
          birthday
          anniversary
          websites {
            type
            value
          }
          emails {
            type
            value
          }
          numbers {
            type
            value
          }
          tags
          groups {
            id
            name
            ownerId
            spaceId
          }
          contactAddresses {
            id
            label
            line1
            line2
            city
            county
            state
            country
            zipCode
            contactId
          }
          notes {
            id
            note
            visibility
            username
            userId
            contactId
          }
          attachments {
            id
            name
            type
            url
            username
            contactId
            userId
          }
          socialProfiles {
            type
            value
            new
          }
          spaceId
        }
      }
      count
    }
  }
`;
