import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_NOTES = gql`
  query getNotes($contactId: Int!) {
    getNotes(contactId: $contactId) {
      data {
        notes {
          id
          note
          visibility
          username
          contactId
          userId
          createdAt
          updatedAt
        }
        count
      }
    }
  }
`;
