import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";
//! Antd imports
import Drawer from "antd/lib/drawer";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
//! User files
import { AppContext } from "../../../../../AppContext";
import * as ActionTypes from "../../../../../common/actionTypes";
import { Notification as NotificationIcon } from "../../../../../assets/svg/common";
import IconButton from "../../../../../common/components/IconButton/IconButton";
import NotificationList from "./NotificationList";
import { subscriptionClient } from "../../../../../apollo";
import NoNotificationYet from "./NoNotificationYet";
import { CONTACT_LIMIT } from "../../../../../common/constants";

//! Graphql files
import { GET_NOTIFICATION_LIST } from "../../../graphql/queries/getNotificationList";
import { NOTIFICATION_SUBSCRIPTION } from "../../../graphql/subscription/notification";
import { CLEAR_NOTIFICATION } from "../../../graphql/mutations/clearNotification";

const Notification = () => {
  const {
    state: { notificationDrawer, currentUser },
    dispatch,
  } = useContext(AppContext);
  const [notification, setNotification] = useState([]);
  const [offset, setOffset] = useState(0);
  const spaceId = localStorage.getItem("currentSpace") || currentUser?.spaceId;

  const onClose = () => {
    dispatch({ type: ActionTypes.SET_NOTIFICATION_DRAWER, data: false });
  };

  useEffect(() => {
    setNotification([]);
  }, [spaceId]);

  useEffect(() => {
    try {
      subscriptionClient
        .subscribe({
          query: NOTIFICATION_SUBSCRIPTION,
          variables: {
            id: parseInt(currentUser?.id, 10),
            spaceId: parseInt(spaceId, 10),
          },
        })
        .subscribe({
          next({ data }) {
            const newNotification = cloneDeep(data?.notification);
            dispatch({ type: ActionTypes.SET_NEW_NOTIFICATION, data: true });
            newNotification.recent = true;
            setNotification([newNotification, ...notification]);
          },
          error() {},
        });
      // eslint-disable-next-line no-empty
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, notification]);

  const { data: userNotifications, loading } = useQuery(GET_NOTIFICATION_LIST, {
    fetchPolicy: "network-only",
    variables: {
      data: {
        offset: offset,
        limit: CONTACT_LIMIT,
        spaceId: parseInt(spaceId, 10),
      },
    },
  });

  const [
    clearNotification,
    { loading: clearNotificationLoading },
  ] = useMutation(CLEAR_NOTIFICATION, {
    onCompleted() {
      setNotification([]);
    },
  });

  useEffect(() => {
    if (!loading)
      if (userNotifications?.notifications?.notifications) {
        setNotification([
          ...notification,
          ...userNotifications?.notifications?.notifications,
        ]);
      } else {
        setNotification([]);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNotifications]);

  const handleClearNotification = () => {
    const ids = notification.flatMap((i) => i.id);
    clearNotification({
      variables: {
        ids: ids,
      },
    });
  };

  const title = (
    <div className="cms-notification-header">
      <div className="cms-notification-header-left">
        <span className="cms-noti-icon">
          <NotificationIcon />{" "}
        </span>
        <span className="cms-noti-title font-inter">Notifications </span>
      </div>
      <div className="cms-notification-header-right">
        <IconButton
          iconClass="cms-noti-clear-btn"
          text="Clear All"
          handleClick={handleClearNotification}
          disable={notification?.length === 0}
          loading={clearNotificationLoading}
        />
      </div>
    </div>
  );

  const handleInfiniteScroll = (e) => {
    const element = e.target;
    if (
      element.scrollHeight - Math.ceil(element.scrollTop) ===
        Math.ceil(element.clientHeight) &&
      notification?.length < userNotifications?.notifications?.count
    ) {
      setOffset(notification?.length);
    }
  };

  return (
    <Drawer
      className="cms-notification-drawer"
      title={title}
      placement="left"
      closable={false}
      onClose={onClose}
      visible={notificationDrawer}
      key="left"
      width={420}
    >
      {notification.length > 0 ? (
        <Row
          className="overflow-auto full-height cms-contact-scrollbar"
          onScroll={(e) => handleInfiniteScroll(e)}
        >
          <Col span={24}>
            <NotificationList
              notification={notification}
              closeDrawer={onClose}
              loading={loading}
            />
          </Col>
        </Row>
      ) : (
        <NoNotificationYet />
      )}
    </Drawer>
  );
};

export default Notification;
