import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import isInteger from "lodash/isInteger";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import find from "lodash/find";

//! Ant Imports
import Menu from "antd/lib/menu";
import { Popover } from "antd";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";

//! User Files
import UserAvatar from "../../../common/components/UserAvatar/UserAvatar";
import { AppContext } from "../../../AppContext";
import * as ActionTypes from "../../../common/actionTypes";
import {
  ADMIN,
  AMPLITUDE_EVENT_LOG,
  GOOGLE,
  MANAGE,
  MODULES,
  OWNER,
  ROUTES,
  SYNC,
} from "../../../common/constants";
import {
  ContactsIcon,
  NotificationIcon,
  LimitReached,
  NewNotificationIcon,
  MiniAppIcon,
  CleanUpIcon,
  Setting,
  AddSpaceIcon,
  SharedSpaceIcon,
  SyncButton,
  GoogleLogo,
  SpaceSwitchIcon,
  Logo,
  Google,
  HelpIcon,
} from "../../../assets/svg";
import SiteLogo from "../../../common/components/SiteLogo/SiteLogo";
import UpgradeButtonComponent from "../../../common/UpgradeButtonComponent";
import {
  createAmplitudeEvent,
  generateRandomColor,
  isPlanActive,
  notificationToast,
} from "../../../common/utils";
import CreateSpaceModal from "./space/components/CreateSpaceModal";
import IconButton from "../../../common/components/IconButton/IconButton";
import GooglePlus from "../../../assets/images/google-plus 1.png";
import TextTag from "../../../common/components/TextTag";

//! Graphql files
import { GET_SYNC_STATUS } from "../graphql/queries/getSyncStatus";
import { GET_GOOGLE_AUTH_URL } from "../../auth/graphql/Queries";
import { GET_SPACES_V2 } from "../graphql/queries/getSpacesV2";
import SWITCH_TO_GOOGLE_SPACE_V2 from "../graphql/mutations/switchToGoogleSpaceV2";
import START_SPACE_SYNC from "../graphql/mutations/startSpaceSync";

function SidebarItems() {
  const { push, replace } = useHistory();
  const { pathname, search } = useLocation();
  const [spaceSelected, setSpaceSelected] = useState(false);
  const [spaceContent, setSpaceContent] = useState();
  const [openPopover, setOpenPopover] = useState(false);
  const [currentSpaceData, setCurrentSpaceData] = useState();
  const [createSpaceModal, setCreateSpaceModal] = useState(false);
  const [sharedUserSpace, setSharedUserSpace] = useState();
  const [syncStatus, setSyncStatus] = useState();
  const [spaceSwitchPopover, setSpaceSwitchPopover] = useState(false);
  const [googleModal, setGoogleModal] = useState(false);
  const [hasGoogleSpace, setHasGoogleSpace] = useState(false);
  const [apiCount, setApiCount] = useState(false);

  const {
    state: {
      drawerVisible,
      isLimitReached,
      userSubscriptionInfo,
      spacePlanStatus,
      newNotification,
      currentUser,
      isSyncStarted,
    },
    dispatch,
  } = useContext(AppContext);
  const spaceId = parseInt(
    localStorage.getItem("currentSpace") || currentUser?.spaceId,
    10
  );
  const params = new URLSearchParams(search);
  const authCode = params.get("code");
  const migrate = params.get("migrate");

  const { data: allSpaces, loading } = useQuery(GET_SPACES_V2, {
    fetchPolicy: "network-only",
  });

  const [getSyncStatus] = useLazyQuery(GET_SYNC_STATUS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      setSyncStatus(data?.getSyncStatus?.inProgress);
      if (!data?.getSyncStatus?.inProgress) {
        dispatch({
          type: ActionTypes?.SET_START_SYNC,
          data: false,
        });
        notificationToast({
          message:
            "Sync has been completed successfully. Your changes will get reflected after page reload",
          type: "Success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
    onError() {},
  });

  const [googleAuthUrl, { loading: authLoader }] = useLazyQuery(
    GET_GOOGLE_AUTH_URL,
    {
      onCompleted(data) {
        window.location.href = data?.getGoogleAuthUrl?.authorizationUri;
      },
      onError() {},
    }
  );

  const [switchToGoogleSpace] = useMutation(SWITCH_TO_GOOGLE_SPACE_V2, {
    onError() {
      push(`/contacts/${spaceId}/group/all`);
    },
  });

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (spaceId && syncStatus !== false && isSyncStarted) {
        getSyncStatus({
          variables: {
            id: spaceId,
          },
        });
      }
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceId, syncStatus, isSyncStarted]);

  const onMenuSelect = (e) => {
    if (e?.key?.includes("addOns")) {
      push(
        !userSubscriptionInfo?.spaceAddon?.active
          ? `${e?.key}/reminders/activate`
          : `${e?.key}/reminders`
      );
    } else {
      push(e?.key?.includes("contacts") ? `${e?.key}/group/all` : e?.key);
    }
    dispatch({ type: ActionTypes?.SET_NOTIFICATION_DRAWER, data: false });
    setSpaceSelected(false);
  };

  const handleLogoClick = () => {
    if (spaceId) {
      push(`/contacts/${spaceId}/group/all`);
    }
  };

  const handlePopoverState = () => {
    dispatch({
      type: ActionTypes?.SET_CONTACT_LIMIT_EXCEEDED,
      data: !isLimitReached,
    });
    dispatch({
      type: ActionTypes?.SET_SPACE_PLAN_STATUS,
      data: {},
    });
  };

  const handleSpaceChange = (id) => {
    setOpenPopover(false);
    dispatch({
      type: ActionTypes.SET_ACTIVE_SWITCH,
      data: "group",
    });
    localStorage.setItem("currentSpace", parseInt(id, 10));
  };

  const handleCreateSpaceModal = () => {
    setCreateSpaceModal(!createSpaceModal);
    setOpenPopover(false);
  };

  useEffect(() => {
    if (allSpaces?.spacesV2?.data) {
      const spaces = [];
      allSpaces?.spacesV2?.data?.map(
        (space) =>
          (allSpaces?.spacesV2?.data?.length === 1 || space?.initialized) &&
          spaces.push(
            <div
              className={`${
                spaceId === parseInt(space?.id, 10)
                  ? "cms-space-popover-active"
                  : ""
              } cms-space-popover`}
              key={space?.id}
            >
              <Link
                to={`/contacts/${space?.id}/group/all`}
                onClick={() => handleSpaceChange(space?.id)}
              >
                <div className="cms-popover-content">
                  <div className="cms-popover-content-block">
                    <UserAvatar
                      className="font-inter cms-popover-content-avatar mr-8"
                      name={space?.name}
                      photo={space?.photo}
                      color={generateRandomColor(parseInt(space?.id, 10))}
                      size={24}
                    />
                    {space?.type === GOOGLE && (
                      <span className="cms-google-space-sider-logo space-list-logo">
                        <GoogleLogo />
                      </span>
                    )}
                    <span className="font-inter username cms-space-name-popover cms-font-ellipsis">
                      {space?.name}
                    </span>
                  </div>
                  {space?.ownerId !== parseInt(currentUser?.id, 10) && (
                    <SharedSpaceIcon className="mr-4" title="Shared space" />
                  )}
                </div>
              </Link>
            </div>
          )
      );
      spaces.push(
        <div className="cms-space-popover" key="create new space">
          <div className="cms-popover-content cms-create-new-space cursor-pointer">
            <div className="cms-popover-content-block">
              <span className="mr-8 cms-create-new-space-icon">
                <AddSpaceIcon />
              </span>
              <span
                className="font-inter username cms-space-name-popover cms-create-space-text"
                onClick={handleCreateSpaceModal}
              >
                Create New Space
              </span>
            </div>
          </div>
        </div>
      );
      setSpaceContent(spaces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSpaces, spaceId]);

  useEffect(() => {
    if (spaceId && allSpaces?.spacesV2?.data) {
      const hasSpace =
        find(allSpaces?.spacesV2?.data, (e) => e?.type === GOOGLE) || {};
      setHasGoogleSpace(Object.keys(hasSpace)?.length > 0);
      allSpaces?.spacesV2?.data?.map(
        (space) =>
          !space?.initialized && (
            <>
              {localStorage.setItem(
                "currentSpace",
                spaceId !== parseInt(space?.id, 10)
                  ? spaceId
                  : allSpaces?.spacesV2?.data?.[0]?.id
              )}
              {setSharedUserSpace(space)}
              {replace(
                `/contacts/${
                  spaceId !== parseInt(space?.id, 10)
                    ? spaceId
                    : allSpaces?.spacesV2?.data?.[0]?.id
                }/group/all`
              )}
              ;
            </>
          )
      );
      setCurrentSpaceData(
        find(allSpaces?.spacesV2?.data, (e) => parseInt(e.id, 10) === spaceId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSpaces, spaceId]);

  useEffect(() => {
    if (allSpaces?.spacesV2?.data && !loading) {
      dispatch({
        type: ActionTypes?.SET_SPACES,
        data: allSpaces?.spacesV2?.data,
      });
      dispatch({
        type: ActionTypes?.SET_SPACES_COUNT,
        data: allSpaces?.spacesV2?.data?.length,
      });
    } else {
      dispatch({
        type: ActionTypes?.SET_SPACES,
        data: [],
      });
      dispatch({
        type: ActionTypes?.SET_SPACES_COUNT,
        data: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSpaces]);

  useEffect(() => {
    if (authCode && migrate === "true" && !apiCount) {
      localStorage.setItem("GoogleLoader", true);
      setApiCount(true);
      switchToGoogleSpace({
        variables: {
          authorizationCode: authCode,
        },
      })
        .then((res) => {
          const { space, jobId } = res?.data?.switchToGoogleSpaceV2;
          if (space) {
            localStorage.setItem("GoogleSwitchComplete", true);
          } else {
            localStorage.removeItem("GoogleLoader");
          }
          localStorage?.setItem("jobId", jobId);
          localStorage?.setItem("currentSpace", space?.id);
          localStorage?.setItem("currentStep", 0);

          push(`/contacts/${space?.id}/group/all`);
          const eventProperties = {
            "Space Id": space?.id,
            "Plan Name": userSubscriptionInfo?.subscription?.product?.name,
            "Plan status": userSubscriptionInfo?.subscription?.planStatus,
          };
          createAmplitudeEvent(
            AMPLITUDE_EVENT_LOG.GOOGLE_SPACE_SWITCHED,
            eventProperties
          );
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(() => {
          localStorage.removeItem("GoogleLoader");
          push(`/contacts/${spaceId}/group/all`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, migrate, userSubscriptionInfo]);

  const handleNotificationDrawer = () => {
    dispatch({
      type: ActionTypes?.SET_NOTIFICATION_DRAWER,
      data: !drawerVisible,
    });
  };

  const [startSpaceSync] = useMutation(START_SPACE_SYNC, {
    onCompleted() {
      dispatch({
        type: ActionTypes?.SET_START_SYNC,
        data: true,
      });
    },
    onError() {
      dispatch({
        type: ActionTypes?.SET_START_SYNC,
        data: false,
      });
    },
  });

  const handleSpaceSync = () => {
    if (spaceId) {
      startSpaceSync({
        variables: {
          id: spaceId,
        },
      });
    }
  };

  const contactLimit = parseInt(
    userSubscriptionInfo?.subscription?.product?.features?.f_contacts,
    10
  );

  const handleSpaceSwitch = () => {
    setSpaceSwitchPopover(!spaceSwitchPopover);
  };

  const handleGoogleModal = () => {
    setGoogleModal(!googleModal);
    setSpaceSwitchPopover(false);
  };

  const spaceSwitchContent = () => {
    return (
      <div className="space-switch-content d-flex-center full-width">
        <div className="d-flex-center full-width mb-4">
          <img src={GooglePlus} alt="google" className="cms-svg-16 mr-6" />
          <span className="font-inter username">Convert to a Google Space</span>
        </div>
        <div className="d-flex-center text full-width mb-8">
          Google space syncs two-way with Google Contacts of all shared users.
        </div>
        <IconButton
          type="primary"
          text="Try Now"
          handleClick={handleGoogleModal}
        />
      </div>
    );
  };

  const getAuthUrl = () => {
    googleAuthUrl({
      variables: {
        screen: "contacts",
      },
    });
  };

  const handleHelpRedirect = () => {
    const url = "https://www.contactbook.app/help"; // Replace with the desired URL

    // Open the URL in a new tab
    window.open(url, "_blank");
  };

  return (
    <>
      <SiteLogo handleLogoClick={handleLogoClick} />
      <div className="cms-menu-wrapper">
        {/* Sidebar menu */}
        <div className="app-sidebar-content">
          <Menu
            theme="lite"
            mode="inline"
            selectedKeys={[`/${pathname.split("/")[1]}/${spaceId}`]}
            defaultSelectedKeys={[`/contacts/${spaceId}`]}
            onSelect={onMenuSelect}
          >
            <Menu.Item
              key={`/contacts/${spaceId}`}
              icon={<ContactsIcon className="cms-svg-sm" />}
            >
              <span>{MODULES.CONTACTS}</span>
            </Menu.Item>
            {userSubscriptionInfo?.type !== GOOGLE &&
              (userSubscriptionInfo?.permission === OWNER ||
                userSubscriptionInfo?.permission === ADMIN ||
                userSubscriptionInfo?.permission === MANAGE) && (
                <Menu.Item
                  key={`/clean-up/${spaceId}`}
                  icon={<CleanUpIcon className="cms-svg-sm" />}
                >
                  <span>{MODULES.CLEANUP}</span>
                </Menu.Item>
              )}
            <Menu.Item
              key={`/addOns/${spaceId}`}
              icon={<MiniAppIcon className="cms-svg-sm" />}
            >
              <span>{MODULES.ADDONS}</span>
            </Menu.Item>
            <Menu.Item
              key={`/settings/${spaceId}`}
              icon={<Setting className="cms-svg-sm" />}
            >
              <span>{MODULES.SETTINGS}</span>
            </Menu.Item>
          </Menu>
        </div>

        <div className="cms-sidebar-bottom">
          <div className="cms-sidebar-bottom-upper-section mb-16">
            {currentUser?.id && (
              <IconButton
                iconClass="cms-sidebar-notification-bg mt-24"
                icon={<HelpIcon />}
                title="HELP"
                handleClick={handleHelpRedirect}
              />
            )}
            <IconButton
              iconClass="cms-sidebar-notification-bg mt-24"
              icon={
                newNotification ? (
                  <NewNotificationIcon className="cms-svg-noti" />
                ) : (
                  <NotificationIcon className="cms-svg-noti" />
                )
              }
              handleClick={handleNotificationDrawer}
              title={MODULES.NOTIFICATIONS}
            />
            {/* Sync Button */}
            {userSubscriptionInfo?.type === GOOGLE && (
              <IconButton
                iconClass={`${
                  syncStatus ? "spin" : ""
                } cms-sidebar-icon-bg mt-24`}
                icon={<SyncButton className="cms-svg-sm" />}
                title={SYNC}
                handleClick={handleSpaceSync}
                disable={syncStatus}
              />
            )}
            {/* Popover for google space switching */}
            {userSubscriptionInfo?.type !== GOOGLE &&
              !hasGoogleSpace &&
              !loading && (
                <Popover
                  placement="rightBottom"
                  trigger="click"
                  content={spaceSwitchContent}
                  visible={spaceSwitchPopover}
                  onVisibleChange={() =>
                    setSpaceSwitchPopover(!spaceSwitchPopover)
                  }
                >
                  <IconButton
                    iconClass="cms-sidebar-notification-bg mt-24"
                    icon={<SpaceSwitchIcon className="cms-svg-sm" />}
                    handleClick={handleSpaceSwitch}
                  />
                </Popover>
              )}
            {/* End- sync button */}
          </div>
          {/* Limit reached popup */}
          <div className="limit-reached">
            <Popover
              placement="rightBottom"
              title={spacePlanStatus?.message}
              content={
                <div>
                  <div>
                    {userSubscriptionInfo?.ownerId ===
                    parseInt(currentUser?.id, 10)
                      ? "Upgrade your plan to continue using ContactBook."
                      : "Please contact your admin to re-activate the account"}
                  </div>
                  {userSubscriptionInfo?.ownerId ===
                    parseInt(currentUser?.id, 10) && (
                    <Link
                      to={`/space/${parseInt(
                        isInteger(spaceId) ? spaceId : spacePlanStatus?.spaceId,
                        10
                      )}/plans`}
                      onClick={handlePopoverState}
                    >
                      <UpgradeButtonComponent />
                    </Link>
                  )}
                </div>
              }
              trigger="click"
              visible={
                (userSubscriptionInfo?.subscription?.id !== null ||
                  userSubscriptionInfo?.subscription?.id === null) &&
                (!isPlanActive(
                  userSubscriptionInfo?.subscription?.planStatus,
                  userSubscriptionInfo?.subscription?.endDate
                ) ||
                  userSubscriptionInfo?.contactsCount >= contactLimit ||
                  userSubscriptionInfo?.membersCount >=
                    userSubscriptionInfo?.subscription?.quantity ||
                  userSubscriptionInfo?.storage?.filesSize >=
                    parseInt(
                      userSubscriptionInfo?.subscription?.product?.features
                        ?.f_documents,
                      10
                    ) *
                      1024 ||
                  userSubscriptionInfo?.contactSources?.length >=
                    parseInt(
                      userSubscriptionInfo?.subscription?.product?.features
                        ?.f_sync_sources,
                      10
                    )) &&
                spacePlanStatus?.planExpired
              }
              onVisibleChange={handlePopoverState}
            >
              {(userSubscriptionInfo?.subscription?.id !== null ||
                userSubscriptionInfo?.subscription?.id === null) &&
                spacePlanStatus?.planExpired &&
                (spacePlanStatus?.planExpired ||
                  !isPlanActive(
                    userSubscriptionInfo?.subscription?.planStatus,
                    userSubscriptionInfo?.subscription?.endDate
                  ) ||
                  userSubscriptionInfo?.contactsCount >= contactLimit ||
                  userSubscriptionInfo?.membersCount >=
                    userSubscriptionInfo?.subscription?.quantity ||
                  userSubscriptionInfo?.storage?.filesSize >=
                    parseInt(
                      userSubscriptionInfo?.subscription?.product?.features
                        ?.f_documents,
                      10
                    ) *
                      1024 ||
                  userSubscriptionInfo?.contactSources?.length >=
                    parseInt(
                      userSubscriptionInfo?.subscription?.product?.features
                        ?.f_sync_sources,
                      10
                    )) && (
                  <div className="limit-reached-icon-container">
                    <LimitReached />
                  </div>
                )}
            </Popover>
          </div>

          {/* Space listing and create new space */}
          <div className="app-layout-sider-footer">
            <Popover
              placement="rightBottom"
              trigger="click"
              content={spaceContent}
              visible={openPopover}
              onVisibleChange={() => setOpenPopover(!openPopover)}
            >
              <div
                className="user-profile-icon cms-space-selection"
                title={currentSpaceData?.name}
                onClick={() => setOpenPopover(!openPopover)}
              >
                <UserAvatar
                  className="cms-user-avatar cms-space-avatar-sider"
                  name={currentSpaceData?.name}
                  photo={currentSpaceData?.photo}
                  size={24}
                  color={generateRandomColor(
                    parseInt(spaceId || currentUser?.spaceId, 10)
                  )}
                />
                {currentSpaceData?.type === GOOGLE && (
                  <span className="cms-google-space-sider-logo">
                    <GoogleLogo />
                  </span>
                )}
              </div>
            </Popover>
            <div
              className={`user-profile-icon ${
                spaceSelected ? "space-icon-selected" : ""
              }`}
              title={currentUser?.name}
              onClick={() => {
                push(`${ROUTES.MY_PROFILE}`);
                setSpaceSelected(true);
              }}
            >
              <UserAvatar
                className="cms-user-avatar"
                name={currentUser?.name}
                photo={currentUser?.photo}
                size={24}
                color={generateRandomColor(parseInt(currentUser?.id, 10))}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="cms-create-new-space-modal"
        visible={createSpaceModal}
        footer={null}
        onCancel={handleCreateSpaceModal}
        centered
        title={
          <Title level={4} className="font-inter dark-text cms-text-strong">
            {sharedUserSpace ? "Activate default Space" : "Create New Space"}
          </Title>
        }
      >
        <CreateSpaceModal
          setCreateSpaceModal={setCreateSpaceModal}
          sharedUser={sharedUserSpace}
        />
      </Modal>
      {/* //! Modal for switching google workspace */}
      <Modal
        className="cms-google-space-modal"
        visible={googleModal}
        footer={null}
        onCancel={handleGoogleModal}
        centered
      >
        <div className="d-flex-center cms-google-space-modal-content">
          <span className="cms-google-space-modal-content-text font-inter">
            Convert your<span className="cms-contactbook"> workspace </span> to{" "}
            <span className="font-inter color-blue">G</span>
            <span className="font-inter color-red">o</span>
            <span className="font-inter color-orange">o</span>
            <span className="font-inter color-blue">g</span>
            <span className="font-inter color-green">l</span>
            <span className="font-inter color-red">e</span> workspace
          </span>
          <div className="d-flex-center cms-connect-section cms-max-width">
            <div className="d-flex-center cms-connect-wrapper mb-34">
              <IconButton
                iconClass="d-flex-center cms-cb-logo cms-cb-large-logo"
                icon={<Logo />}
                shape="circle"
              />
              <span className="cms-dash-border cms-dash-width" />
              <span className="d-flex-center cms-cb-logo cms-cb-large-logo">
                <img src={GooglePlus} alt="Google Plus" />
              </span>
            </div>
            <div className="cms-domain-admin-content mb-34">
              Your <span className="text-bold">Google Contacts</span> are synced
              with <span className="text-bold">ContactBook</span>.
              <br /> i.e. changes you make in Google Contacts will reflect in
              ContactBook and the other way around.
            </div>
            <div className="cms-user-gmail d-flex-center mb-40">
              <div className="d-flex-center">
                <img
                  src={GooglePlus}
                  alt="Google Plus"
                  className="cms-user-gmail-logo mr-12"
                />
                <span className="cms-user-gmail-text">
                  {currentUser?.email}
                </span>
              </div>
              <TextTag
                text="primary"
                className="font-inter cms-shared-tag cms-user-gmail-tag"
              />
            </div>
          </div>
          <div
            className={
              loading
                ? "hide"
                : "cms-steps-action cms-continue-google-btn mb-16"
            }
          >
            <IconButton
              iconClass="cms-steps-action-button"
              icon={<Google className="mr-8" />}
              text={
                <span className="cms-steps-action-button-text font-inter">
                  Continue with Google
                </span>
              }
              type="primary"
              handleClick={getAuthUrl}
              loading={authLoader}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SidebarItems;
