import React from "react";

const SettingsHeader = ({ title, icon }) => {
  return (
    <div className="cms-settings-header">
      <span className="cms-settings-header-icon">{icon}</span>
      <span className="cms-settings-header-title font-inter ml-8">{title}</span>
    </div>
  );
};

export default SettingsHeader;
