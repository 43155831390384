import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_CONTACT_BY_PUBLIC_ID = gql`
  query getContactByPublicId($id: String!) {
    getContactByPublicId(id: $id) {
      status
      data {
        id
        firstName
        middleName
        lastName
        jobTitle
        company
        photo
        contactAddresses {
          label
          line1
          line2
          city
          county
          state
          country
          zipCode
        }
        socialProfiles {
          type
          value
        }
        numbers {
          type
          value
        }
        emails {
          type
          value
        }
      }
    }
  }
`;
